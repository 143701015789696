/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Navbar, Nav, Form } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Enum from '../../components/common/enum';
import {Footer} from '../../components/footer/footer';
import './invite.scss';
import { SmallLoader } from '../../components/small-loader/sm-loader';

class VerifyLG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyToken: props.match.params.verifyToken,
            isValidLink: false,
            isValidMessage: null,
            loading: false,
        };
    }

    componentDidMount() {
        const verifyToken = this.state.verifyToken;
        this.setState({ loading: true });
        Helper.axiosInstance().post(`verify/lg`, {
            verification_code: verifyToken
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ clubPic: response.data.data.club.photo, isValidLink: true, loading: false, })
                }
                else if (response.data.error === 'Already verified') {
                    this.setState({
                        isValidLink: false, loading: false,
                        isValidMessage: 'The link is already verified by the other Legal Guardian of the student.'
                    })
                }
                else {
                    this.setState({ loading: false, isValidMessage: 'The link is invalid. Kindly contact the admin.', })
                }
            })
            .catch((error) => {
                this.setState({ isValidLink: false, loading: false, })
            });
    }

    render() {
        return (
            <>
                {/* <Loader loading={this.state.loading} /> */}
                {this.state.isValidLink ?
                    <Navbar bg="light" expand="lg" className="topbar invite-header">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto org-logo">
                                <Image src={this.state.clubPic} alt="Success" />
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar> : null
                }
                <div className="container">
                    <Row className="content-space">
                        <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                            <div className="widget">
                                {
                                    this.state.loading ? <div className="loader-center"><SmallLoader /> </div> :
                                        this.state.isValidLink ?
                                            <div className="text-center success-msg success-msg--font-size">
                                                <Image src={Images.SuccessTick} alt="Success" />
                                                {/*<p className="mb-4">{Enum.Student} has been added to the club.</p>*/}
                                                <p className="mb-4">You have successfully associated the invited legal guardian to your student's SafeComm account.</p>
                                            </div>
                                            :
                                            <div className="text-center py-5 px-3 confirmbox">
                                                <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                                                <h1 className="mt-3 mb-3">Error</h1>
                                                <p className="mb-4">{this.state.isValidMessage}</p>
                                                <Form>
                                                    <Link to="#" onClick={Helper.handleSupportContact} className="btn--green btn justify-content-center">Contact Us</Link>
                                                </Form>
                                            </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Footer />
                </div>
            </>
        )
    }
}
export { VerifyLG };