/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Dropdown, Tab, Tabs, Table, Media, Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Date Picker
 * Name and Version: react-datepicker, 2.9.6
 * Download link: https://www.npmjs.com/package/react-datepicker
 */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* End - React Date Picker */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */

import { NoRecord } from '../../components/no-record/no-record';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Messages } from '../../components/common/message';
import { ErrorModal } from '../../components/modal/modalbox';
import './invoice.scss';

class ClubInvoice extends Component {
    constructor(props) {
        super(props);
        let startDate = new Date();
        startDate.setMonth( startDate.getMonth() - 1 ); // By Default: We need to show the records of last month. That's why we have subtracted one from the current month.

        this.state = {
            recordLoaded: false,
            invoiceList: [],
            showError: false,
            errorMessage: "",
            startDate: startDate,
            totalRecords: null,
            filterCriteria: {
                searchKeyword: '',
                invoiceType: "NP",
                page: 1,
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            },
            pageSize: 20,
            cancelToken: axios.CancelToken,
            cancel: undefined
        };
        /* Using Debounce to prevent repeated events
        Windows scroll event debounce */
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
        /*  Filter Criteria Debounce */
        let filterDelay = 500;
        this.doDebouncedSearchKeywordChange = debounce(this.onSearchKeywordChange, filterDelay);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.doDebouncedTableScroll);
        window.scrollTo(0, 0);
        this.getInvoiceList();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll)
    }

    onSearchKeywordChange = (target) => {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.searchKeyword = target.value.toLowerCase();
        this.reloadInvoiceList(newFilterCriteria);
    }

    reloadInvoiceList = (newFilterCriteria) => {
        newFilterCriteria.page = 1;
        this.setState({ filterCriteria: newFilterCriteria, invoiceList: [], recordLoaded: false },
            () => {
                this.getInvoiceList();
            }
        );
    }

    onInvoiceTabSelect = (key) => {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.invoiceType = key === "active" ? "NP" : 'P';
        this.reloadInvoiceList(newFilterCriteria);
    }

    handleChange = date => {
        let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.month = date.getMonth() + 1; //The month array index start with 0, that's why we added 1 to get the correct value of the selected month.
            newFilterCriteria.year = date.getFullYear();
            newFilterCriteria.page = 1;
        this.setState({
          startDate: date,
          filterCriteria: newFilterCriteria
        },
            () => {
                this.getInvoiceList();
            }
        );
    }

    getInvoiceList = () => {
        const user = Storage.Get('user'),
            { searchKeyword, page, invoiceType, month, year } = this.state.filterCriteria,
            me = this;

        if (user) {
            this.setState({ recordLoaded: false });
            
            let cancel = this.state.cancel,
                cancelToken = this.state.cancelToken;
            if (this.state.cancel !== undefined) {
                cancel();
            }

            Helper.axiosInstance().post('invoice/list', {
                keyword: searchKeyword,
                status:  invoiceType, //(P:paid, NP:not paid)(optional)
                club_id: user.is_super_admin ? '' : user.selectedClubId, //in case of super admin the selected club should be null.
                page: page, //page number for pagination
                page_size: this.state.pageSize, //per page for pagination
                month: month,
                year: year
            },{
                cancelToken: new cancelToken(function executor(c) {
                    me.setState({ cancel: c });
                })
            })
            .then((response) => {
                if (response.data.success) {
                    if (this.state.filterCriteria.page === 1) {
                        this.setState({
                            invoiceList: response.data.data
                        });
                    } else {
                        let existingRecords = this.state.invoiceList;
                        this.setState({ invoiceList: existingRecords.concat(response.data.data) });
                    }
                    this.setState({ totalRecords: response.data.count });
                } else {
                    this.setState({ errorMessage: response.data.error, showError: true });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true});
            }).finally(() => {
                this.setState({ recordLoaded: true });
            });
        }
    }

    formatMonth = (getMonth, getYear) => {
        const date = new Date(getYear, getMonth-1, 1),
            month = date.toLocaleString('default', { month: 'short' });
        return `${month}, ${getYear}`;
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load      
        if (!this.state.recordLoaded || this.state.totalRecords === this.state.invoiceList.length || this.state.totalRecords < this.state.pageSize) return;
        // // Reduce 20percent from the offsetHeight
        let reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;
        // Check if scroll has hit bottom
        if (window.innerHeight + document.documentElement.scrollTop >= reduceOffset) {
            let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.page = newFilterCriteria.page + 1;
            this.setState({ filterCriteria: newFilterCriteria });
            this.getInvoiceList();
        }
    }

    loadInvoiceList = () => (
        <InvoiceList data={this.state.invoiceList}
            recordLoaded={this.state.recordLoaded}
            formatMonth={this.formatMonth}
        />
    )
    
    render() {
        const { startDate, showError, errorMessage } = this.state;
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm="4">
                            <h1>Invoices</h1>
                        </Col>
                        <Col sm={8} className="text-right cb-action-wrap">
                            <div className="filter-date mr-0">
                                <label>Filter by:</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={this.handleChange}
                                    dateFormat="MM/yyyy"                                    
                                    showMonthYearPicker
                                    placeholderText="Month/Year"
                                />
                            </div>
                           
                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                <div className="tab-search tab-search--big">
                                    <Form className="form-inline" onSubmit={e => { e.preventDefault(); }}>
                                        <input type="text" name="focus" maxLength="100" placeholder="Search" className="form-control tab-search__box" onChange={(e) => this.doDebouncedSearchKeywordChange(e.target)} />
                                        <button className="close-icon" type="reset" onClick={(e) => this.doDebouncedSearchKeywordChange(e.target)}></button>
                                    </Form>
                                </div>
                                <Tabs defaultActiveKey="active" onSelect={this.onInvoiceTabSelect}>
                                    <Tab eventKey="active" title="Unpaid Invoices">
                                        {this.loadInvoiceList()}
                                    </Tab>
                                    <Tab eventKey="archived" title="Paid Invoices">
                                        {this.loadInvoiceList()}
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ErrorModal
                    show={showError}
                    message={errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
            </>
        )
    }
}

const InvoiceList = (props) => {    
    return (
        <Table bordered hover className="tab-responsive table--invoice">
            <thead>
                <tr>
                    <th>Organizations </th>
                    <th>Month</th>
                    <th>New Contacts</th>
                    <th>Renewals</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {   
                (typeof props.data == "object" && Object.keys(props.data).length > 0) &&
                    props.data.map((row, i) => {
                        return (
                            <tr key={i}>
                                <td data-label="Organizations" className="td-content-space">
                                    <Media>
                                        <div className="im-rounded-outer">
                                            <Image className="mr-2" src={row.photo} alt="User" />
                                        </div>
                                        <Media.Body className="align-self-center"><h5>{row.name}</h5></Media.Body>
                                    </Media>
                                </td>
                                <td data-label="Month">{props.formatMonth(row.for_month,row.for_year)}</td>
                                <td data-label="New Contacts">{row.new_members}</td>
                                <td data-label="Renewals">{row.renew_members}</td>
                                <td data-label="Amount">
                                    $ {row.amount}
                                </td>
                                <td>
                                    <Dropdown className="more-actions">
                                        <Dropdown.Toggle>
                                            <span className="more-im"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className="dropdown-item" to={`/invoice-detail/${row.id}`}>
                                                <Image className="mr-2" src={Images.EditIc} alt="Edit" width="21" />
                                                <span>Details</span>
                                            </Link>
                                            {
                                            /**
                                             * Not required for now, might be required in the future
                                             */
                                            /*
                                            <Link className="dropdown-item" to={`/invoice-detail`}>
                                                <Image className="mr-2" src={Images.markPaid} alt="Edit" width="21" />
                                                <span>Mark as paid</span>
                                            </Link>*/
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        );
                    })
                }
                {!props.recordLoaded &&
                    <>
                        <tr>
                            <td colSpan="5">
                                <SmallLoader />
                            </td>
                        </tr>
                    </>
                }
                {(props.recordLoaded && props.data.length === 0) &&
                    <tr className="mb--norecord">
                        <td colSpan="5">
                            <NoRecord />
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
};

export { ClubInvoice };