/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */


import { Messages } from '../../components/common/message';
import { UsStates } from '../../components/common/us-states';
import * as Helper from '../../components/common/functions';
import '../club/club.scss';

class Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
        };
        this.isValidated = this.isValidated.bind(this);
        this.hasValidateMethodRun = false;
        this.fields = {}
        if (props.data) {
            this.fields = props.data.fields;
        }
        this.submitFormRef = null;
        this.isFormValid = false;
    }
    isValidated() {
        if (this.fields.mailAddress !== "" && (!this.hasValidateMethodRun || this.fields === this.props.data.fields)) {
            this.props.handleFormSubmit(this.fields);
            return true;
        }
        else {
            if (this.submitFormRef)
                this.submitFormRef();
            if (this.isFormValid) {
                this.props.handleFormSubmit(this.fields);
            }
            return this.isFormValid;
        }
    }
    bindSubmitForm = (submitForm, errors, isValid) => {
        if (!this.submitFormRef)
            this.submitFormRef = submitForm;
        this.isFormValid = this.props.data.firstLoad ? isValid : Object.keys(errors).length === 0;
    };
    onstateChange = (event, setFieldValue) => {
        setFieldValue('mailState', event.target.value);
    }
    onBillStateChange = (event, setFieldValue) => {
        setFieldValue('billState', event.target.value);
    }
    render() {
        let labelClassInActive = "form-label";
        let labelClassActive = "form-label label-active";
        const formContent = (
            <Formik
                enableReinitialize={true}
                initialValues={this.fields}
                validationSchema={Yup.object().shape({
                    mailAddress: Yup.string().trim()
                        .max(255, Messages.MAX_LENGTH_255)
                        .required('Please enter mailing address.'),
                    mailAddress2: Yup.string().trim()
                        .max(255, Messages.MAX_LENGTH_255),
                    mailCity: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .required('Please enter mailing address city'),
                    mailState: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .required('Please select mailing address state.'),
                    mailZip: Yup.number()
                        .typeError(Messages.INVALID_ZIP_TYPE)
                        .min(10000, Messages.INVALID_ZIP_LENGTH)
                        .max(99999, Messages.INVALID_ZIP_LENGTH)
                        .required('Please enter mailing address zip code.'),

                })}
                validate={(values) => {  this.hasValidateMethodRun = true; if (this.isFormValid) { this.fields = values; } }}
                render={
                    (formikProps) => {
                        const { errors, touched, submitForm, setFieldValue, values, isValid } = formikProps;
                        this.bindSubmitForm(submitForm, errors, isValid);
                        return (
                            <Form noValidate>
                                <div className="row">
                                    <Col md={12}>
                                        <div className="block-heading mb-3">
                                            Mailing Address
                                        </div>
                                    </Col>
                                </div>
                                <div className="field form-group">
                                    <Field  maxlength="255" name="mailAddress" type="text" id="mailAddress" autoComplete="off" className={`form-control ${(errors.mailAddress && touched.mailAddress && 'is-invalid')}`} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="mailAddress" className={values.mailAddress ? labelClassActive : labelClassInActive}>Mailing Address line 1</label>
                                    <ErrorMessage name="mailAddress" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field  maxlength="255" name="mailAddress2" type="text" id="mailAddress2" autoComplete="off" className={`form-control ${(errors.mailAddress2 && touched.mailAddress2 && 'is-invalid')}`} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="mailAddress2" className={values.mailAddress2 ? labelClassActive : labelClassInActive}>Mailing Address line 2</label>
                                    <ErrorMessage name="mailAddress2" component="div" className="invalid-feedback" />
                                </div>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <div className="field form-group">
                                            <Field maxlength="150" name="mailCity" type="text" id="mailCity" autoComplete="off" className={'form-control' + (errors.mailCity && touched.mailCity ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="mailCity" className={values.mailCity ? labelClassActive : labelClassInActive}>City</label>
                                            <ErrorMessage name="mailCity" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div className="field form-group">
                                            <div className="select-wrap">
                                                <Field component="select" placeholder="Select State"
                                                       className={`form-control ${(errors.mailState && touched.mailState && 'is-invalid')}`}
                                                       name="mailState" id="mailState"
                                                       onChange={(event) => this.onstateChange(event, setFieldValue)}>
                                                    <option value="">-- Select State --</option>
                                                    {
                                                        Object.keys(UsStates).map((key) => {
                                                            return (
                                                                <option key={key} value={UsStates[key]}>{UsStates[key]}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="mailState" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <div className="field form-group">
                                            <Field maxlength="5" name="mailZip" type="text" id="mailZip" autoComplete="off" className={'form-control' + (errors.mailZip && touched.mailZip ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="mailZip" className={values.mailZip ? labelClassActive : labelClassInActive}>Zip Code</label>
                                            <ErrorMessage name="mailZip" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                }
            />
        )
        return (
            <>
                <Row className="steps-content">
                    <Col sm={10} className="offset-sm-1">
                        {formContent}
                    </Col>
                </Row>
            </>
        )
    }
}
export { Address };