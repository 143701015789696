/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Button, Modal } from 'react-bootstrap';
/* End - React Bootstrap */

import { ModalBox, SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';

export default class ResendAllInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            formProcessing: false,
            serverError: '',
            serverSuccess: '',
            modalShow: true,
        }
    }

    handleClose = () => {
        this.props.closeInvite();
    };

    resendAllInvite = () => {
        this.setState({ formProcessing: true });
        let inviteId = [ ...this.props.data.keys() ];

        Helper.axiosInstance().post('invite/resend-multi', { invite_id: inviteId })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ serverSuccess: response.data.message, formProcessing: false });
                } else {
                    this.setState({ serverError: Messages.SERVER_ERROR, formProcessing: false });
                }
            })
            .catch((error) => {
                this.setState({ serverError: error, formProcessing: false });
            });
    }


    render() {
        const { serverSuccess, serverError, modalShow } = this.state,
            modalContent = (
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} width="100" height="100" />
                    <h1>Are you sure?</h1>
                    <p>Do you want to resend invite for all selected members?</p>
                    <div className="confirmbox__footer mt-5">
                        <Button variant="secondary" className="ripple btn-cancel mr-3" disabled={this.state.formProcessing} onClick={this.handleClose} >
                            Cancel
                            </Button>
                        <Button disabled={this.state.formProcessing} className={`ripple btn-activate ${this.state.formProcessing ? 'btn-loader' : ''}`} onClick={this.resendAllInvite}>
                            Resend <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>
                    </div>
                </Modal.Body>
            );
        return (
            <>
                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalShow}
                            onConfirmClick={this.handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                            onHide={this.handleClose}
                        />
                        :
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={modalShow}
                                onConfirmClick={this.handleClose}
                                onHide={this.handleClose}
                            />
                            :
                            <ModalBox
                                size="md"
                                show={modalShow}
                                onHide={this.handleClose}
                                animation={true}
                                modalcontent={modalContent}
                            />
                }
            </>
        )
    }
}

export { ResendAllInvite };