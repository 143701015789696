/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Form} from 'react-bootstrap';
import '../club/club.scss';
import * as SessStorage from '../../components/common/sess-storage';
import {AgreementText} from "./agreement-text";


class Agreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            isTermChecked: SessStorage.Get('isTermChecked'),
        };

        this.isValidated = this.isValidated.bind(this);
    }

    onChangeTerm = (checked) => {
        SessStorage.Set('isTermChecked', checked);
        this.setState({
            showTermErr: !checked,
            isTermChecked: checked
        });
    }

    isValidated() {
        console.log('isValidated', this.state.isTermChecked)
        if (!this.state.isTermChecked) {
            this.setState({showTermErr: true});
            return false;
        }
    }

    render() {
        return (
            <>
                <Row className="steps-content">
                    <AgreementText general={this.props.general} address={this.props.address} />
                </Row>
                <Row className="steps-content">
                    <Col sm={12}>
                        <div className="disp-inline">
                            <div className="checkbox-wrap pt-2">
                                <input type="checkbox" id="chkTerm" checked={this.state.isTermChecked}
                                       onClick={(e) => this.onChangeTerm(e.target.checked)}/>
                                <label htmlFor="chkTerm">I accept the terms and conditions.</label>
                            </div>
                        </div>
                        <div className="disp-inline mb-0">
                            {(this.state.showTermErr) ?
                                <p className="error-msg invalid-feedback mb-0">Please accept the term and
                                    conditions.</p> : ''}
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export {Agreement};
