export const UserRolesDisplay = {
    "1": "Super Admin",
    "2": "Organization Admin",
    "3": "Organization Sub Admin",
    "4": "Instructor",
    "5": "Legal Guardian",
    "6": "Student",
    "7": "Marketing Affiliate"
};

export const UserRoles = {
    SUPER_ADMIN:1,
    CLUB_ADMIN: 2,
    CLUB_SUB_ADMIN: 3,
    COACH: 4,
    LEGAL_GUARDIAN: 5,
    ATHLETE: 6,
    ISR : 7
};

export const
    OrgAdmin = UserRolesDisplay[UserRoles.CLUB_ADMIN],
    OrgSubAdmin = UserRolesDisplay[UserRoles.CLUB_SUB_ADMIN],
    Instructor = UserRolesDisplay[UserRoles.COACH],
    LegalGuardian = UserRolesDisplay[UserRoles.LEGAL_GUARDIAN],
    Student = UserRolesDisplay[UserRoles.ATHLETE];


export const SERVER_ERROR = "There was a problem connecting to the server.";
export const InviteError = "This link is not valid. Please contact the SafeComm support.";
export const SupportEmail = "support@safecommapp.com";
export const SafeCommWebsite = "https://safecommapp.com/";
export const WebsiteUrl = "https://app.safecommapp.com/";
export const OrgStatus= {
    "PV": "Pending Verification",
    "PA": "Pending Approval",
    "A": "Approved"
};
export const GoogleRecaptchaKey = "6LfkO9gUAAAAAHDniCDjtQqdSP38d9Z61i_i4MXQ";

export const MaxLgPerStudent = 2;

export const memberSelectionWarning = "Please select contacts using checkboxes to perform this action.";
export const groupSelectionWarning = "Please select groups using checkboxes to perform this action.";
export const GroupList = {
    SAFE_CHAT:0,
    INDIVIDUAL_CHAT: 1,
    MEMBER_CHAT: 2,
};
 export const marketingWebsiteUrl = 'https://safecommapp.com/faq/';
 export const SafeCommWebsiteSupportUrl = 'https://safecommapp.com/technical-support/';
 export const inviteMsg = 'We have sent an email with a link to verify your account. Please click the link to complete account verification.';
