/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import * as Images from '../../components/common/images';
import { Messages } from '../../components/common/message';
import { ErrorModal } from '../../components/modal/modalbox';
import * as Helper from '../../components/common/functions';
import '../club/club.scss';
import {PhoneInput} from "../../components/input/phone";
import * as Regex from '../../components/common/regex';
import {password, phone} from "../../components/common/regex";
class GeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            showError: false,
            errorMessage: null,
            uploadedPhotoUrl: null
        };
        this.isValidated = this.isValidated.bind(this);
        this.hasValidateMethodRun = false;
        this.photo = {};
        this.photoEvent = false;
        this.submitFormRef = null;
        this.isFormValid = false;
        this.fields = {};
        if (this.props.data) {
            this.fields = this.props.data.fields;
            this.photo = this.props.data.photo;
        }
    }

    componentDidMount() {
        if (this.photo) {
            this.setState({ uploadedPhotoUrl: this.photo.url });
        }
    }

    isValidated() {
        // This means that correct data is already filled
        // and no changes were made to the form so bypass the validation
        if (this.fields.firstname && this.fields.firstname !== "" && !this.hasValidateMethodRun) {
            this.props.handleFormSubmit(this.fields, this.photo);
            return this.isFormValid;
        }
        else {
            if (this.submitFormRef)
                this.submitFormRef();

            if (this.isFormValid)
                this.props.handleFormSubmit(this.fields, this.photo, this.resetForm);

            return this.isFormValid;
        }
    }

    bindSubmitForm = (submitForm, errors, isValid) => {
        if (!this.submitFormRef)
            this.submitFormRef = submitForm;

        this.isFormValid = this.props.data.firstLoad ? isValid : Object.keys(errors).length === 0;
    };

    photoEventBlockHack = () => {
        this.photoEvent = true;
        setTimeout(() => {
            this.photoEvent = false;
        }, 1000);
    }

    onPhotoChange = (event) => {
        this.photoEventBlockHack();
        let uploadedFile = event.target.files[0];
        if (uploadedFile !== null && uploadedFile !== undefined) {

            if (uploadedFile.size / 1024 > 6000) {
                this.setState({ errorMessage: Messages.PHOTO_MAX_SIZE, showError: true, });
                event.target.value = null;
                return;
            }
            if (uploadedFile.type !== "image/jpeg" && uploadedFile.type !== "image/jpg") {
                this.setState({ errorMessage: Messages.PHOTO_TYPE_ALLOWED, showError: true, });
                event.target.value = null;
                return;
            }
            this.photo = {
                file: uploadedFile,
                url: window.URL.createObjectURL(uploadedFile)
            };
            this.setState({ uploadedPhotoUrl: this.photo.url });
        }
        this.props.data.firstLoad = false;
    }

    render() {
        let labelClass = `form-label ${this.fields.firstname !== undefined && this.fields.firstname !== "" ? 'label-active' : ''}`;
        const formContent = (
            <Formik
                //  enableReinitialize={true}
                initialValues={{
                    firstname: this.fields.firstname,
                    lastname: this.fields.lastname,
                    email: this.fields.email,
                    phone: this.fields.phone,
                    username: this.fields.username,
                    password: this.fields.password,
                    confirmPassword: this.fields.confirmPassword,
                    phoneCode: this.fields.phoneCode ? this.fields.phoneCode : '+1',
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().trim()
                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    password: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .min(8, Messages.MIN_LENGTH_8)
                        .required(Messages.REQUIRED_ERR)
                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                    confirmPassword: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .oneOf([Yup.ref('password'), null], Messages.PASSWORD_CPASS_MATCH_ERR),
                    firstname: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    lastname: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    email: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_50)
                        .email(Messages.INVALID_EMAIL)
                        .required(Messages.REQUIRED_ERR),
                    phone: Yup.string().trim()
                        .matches(Regex.phone, { message: Messages.INVALID_PHONE, excludeEmptyString: false })
                        .max(15, Messages.INVALID_PHONE)
                        .required(Messages.REQUIRED_ERR),
                    phoneCode: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR),

                })}
                validate={(values) => { this.hasValidateMethodRun = true; if (this.isFormValid) { this.fields = values; } }}
                render={
                    (formikProps) => {
                        const { errors, touched, submitForm, isValid, setFieldValue, values } = formikProps;
                        if (!this.photoEvent) this.bindSubmitForm(submitForm, errors, isValid);
                        return (
                            <Form>
                                <div className="field form-group">
                                    <Field  maxLength="100" name="firstname" type="text" id="firstname" autoComplete="off" className={'form-control' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="firstname" className={labelClass}>First Name</label>
                                    <ErrorMessage name="firstname" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field maxLength="100" name="lastname" type="text" id="lastname" autoComplete="off" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="lastname" className={labelClass}>Last Name</label>
                                    <ErrorMessage name="lastname" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field maxLength="150" name="email" type="text" id="email" autoComplete="off" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="email" className={labelClass}>Email</label>
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field maxLength="150" name="username" type="text" id="username" autoComplete="off" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="username" className={labelClass}>Username</label>
                                    <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field name="password" type="password" id="password" autoComplete="off" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                    <label htmlFor="password" className={labelClass}>Password</label>
                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <Field name="confirmPassword" type="password" id="confirmPassword" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.confirmPassword && touched.confirmPassword)  ? ' is-invalid' : '')} />
                                    <label htmlFor="confirmPassword" className={labelClass}>Confirm Password</label>
                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                </div>
                                <div className="field form-group">
                                    <PhoneInput
                                        parent={this}
                                        codeField={'phoneCode'}
                                        defaultCode={this.fields.phoneCode ? this.fields.phoneCode : '+1'}
                                        phoneField={'phone'}
                                        fieldLabel={'Phone'}
                                        errors={errors}
                                        touched={touched}
                                        serverValid={false}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                </div>



                            </Form>
                        )
                    }
                }
            />
        )
        return (
            <>
                <Row className="steps-content">
                    <Col sm={12} md={6}>
                        <div className="info-box info-box--no-shadow">
                            <div className="info-box__header info-box__header--org">
                                <div className="info-box__uplaod">
                                    <input name="clubPicture" type='file' onChange={this.onPhotoChange} accept=".jpg,.jpeg" />
                                    <Image src={Images.CameraIcRed} alt="camera" width="24" />
                                </div>
                                <Image src={this.state.uploadedPhotoUrl ? this.state.uploadedPhotoUrl : Images.DefaultLogo} />
                            </div>
                            <div className="info-box__content mt-2 info-box__content--no-padding">
                                <div className="content__lft-col">Upload Image</div>
                                <div className="content__rgt-col">Recommended dimensions of 300 x 169 pixels</div>
                            </div>
                        </div>

                    </Col>
                    <Col sm={12} md={6}>
                        {formContent}
                    </Col>
                </Row>
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }); this.photoEventBlockHack(); }}
                ></ErrorModal>
            </>
        )
    }
}
export { GeneralInfo };