export const Get = (key) => (
    JSON.parse(sessionStorage.getItem(key))
);

export const Set = (key ,val) => (
    sessionStorage.setItem(key, val)
);

export const Delete = (key) => (
    sessionStorage.removeItem(key)
);