/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Tab, Tabs, Image, Button, Modal, Media, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import { DeleteConfirmModal, ErrorModal, ErrorAccessDeniedModal, UpdateMiddleNameModal } from '../../components/modal/modalbox';
import { ModalBox } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import { Messages } from '../../components/common/message';
import BroadcastMesaage from '../../components/common/broadcast-message';
import './team.scss';
import { TeamMessages } from './team-message';
import { KidsGroupList } from './kids-group-list';
import { TeamList } from './team-List';
import * as Enum from "../../components/common/enum";


class TeamListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            show: false,
            teamshow: false,
            successshow: false,
            isSuccess: true,
            accessMessage: null,
            showAccessDeniedError: null,
            showMiddleNameWarning: null,
            activeTeamListSearch: [],
            archivedTeamListSearch: [],
            kidsSearchlist: [],
            createdTeamId: null,
            recordLoaded: false,
            handelActivate: {
                deleteConfirmShow: false,
                memberIdForDeletion: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
                status: 0
            },
            userInfo: Storage.Get('user'),
            coachId: null,
            errorMessage: null,
            broadcastBtnLoader: false,
            showLoader: false,
            modalLoader: false,
            groupModal: false,
            broadcastModal:false,
            modalVisibility: false,
            checkedAll: false,
            checkedItems: new Map(),
            selectedMemberGroups: new Map(),
            selectedMembersCount: null,
            sortCriteria: {
                column: 'name',
                direction: 'asc',
                sortingInProcess: false,
                sortKids:false
            },
            archiveMultipleGroups: false,
            activeTab: 'active'
        };
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    componentDidMount = () => {
        const user = Storage.Get('user');
        const addMiddleName = Storage.Get('add_middle_name');
        this.setState({ userId: user.id, selectedClubId: user.selectedClubId, isInstructorLogin: Helper.isCoachOrAdminLogin() });
        if(Helper.isLegalGuardian() && typeof addMiddleName != 'undefined' && addMiddleName == true){
            this.setState({ showMiddleNameWarning: true });
        }
        this.loadData(user.selectedClubId);
    }

    loadData = (selectedClubId) => {
        this.setState({ recordLoaded: false, activeTeamListSearch:[], kidsTeamList:[], kidsSearchlist:[] });
        let sortCriteria = this.state.sortCriteria;
        
        var me = this;
        Helper.axiosInstance().get(`team/list/${selectedClubId}/web/${sortCriteria.column}/${sortCriteria.direction}`)
            .then(function (response) {
                if (response.data.success) {
                    let activeTeam = response.data.my_teams.filter(x => x.status === 1);
                    let archivedTeam = response.data.my_teams.filter(x => x.status === 0);
                    me.setState({
                        coachList: response.data.coaches,
                        teamMemberOf: response.data.team_member_of,
                        activeTeamList: activeTeam,
                        activeTeamListSearch: activeTeam,
                        archivedTeamList: archivedTeam,
                        archivedTeamListSearch: archivedTeam,
                        kidsTeamList: response.data.kids_teams,
                        kidsSearchlist: response.data.kids_teams,
                        recordLoaded: true
                    });
                }
                else {
                    me.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
                }
            })
            .catch((error) => {
                if (error.response.status === 403 && error.response.data !== undefined) {
                    this.setState({ accessMessage: error.response.data.error, showAccessDeniedError: true, recordLoaded: true });
                } else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
                }
            })
            .finally(() => {
                let { sortCriteria } = this.state;
                sortCriteria.sortingInProcess = false;
                me.setState({sortCriteria: sortCriteria, recordLoaded: true });
            });
    }



    setShow(action) {
        this.setState({
            show: action,
            isSuccess: true,
        })
    }
    setTeamShow(action) {
        this.setState({
            teamshow: action
        })
    }
    setSuccessShow(action) {
        this.setState({
            successshow: action
        })
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    filterList = (target) => {
        this.setState({activeTeamListSearch: this.state.activeTeamList.filter(
            x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1
                || (x.location && x.location.toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1)
        ) })
        this.setState({ archivedTeamListSearch: this.state.archivedTeamList.filter(
            x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1
                || (x.location && x.location.toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1)
        ) })
    }

    filterKidsList = (target, index) => {
        let kSearchList = JSON.parse(JSON.stringify(this.state.kidsTeamList))
        kSearchList[index].teams = kSearchList[index].teams.filter(
            x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1
                || (x.location && x.location.toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1)
        );
        this.setState({ kidsSearchlist: kSearchList });
    }

    onKidMultiSelectChange = (event, setFieldValue) => {
        this.setState({
            coachId: event.target.value
        });
        setFieldValue('instructor', event.target.value);
    }

    saveCreatedGroup = (fields) => {
        this.setState({ showLoader: true });
        let data = {
            name: fields.groupName.trim(),
            description: fields.description.trim(),
            club_id: this.state.selectedClubId,
            coach_id: this.state.coachId,
            location: fields.location.trim()
        }

        Helper.axiosInstance().post('team/create', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        createdTeamId: response.data.data.id,
                        isSuccess: false,
                        showLoader: false,
                    })
                    this.loadData(this.state.selectedClubId);
                    const user = Storage.Get('user');
                    if (user.id === parseInt(this.state.coachId, 10)) {
                        if (!Helper.isInstructor()) {
                            let user = Storage.Get('user');
                            user.role[user.selectedClubId].push(4);
                            Storage.Set('user', JSON.stringify(user));
                        }
                    }
                }
                else {
                    if (response.data.error.name) { this.setState({ errorMessage: response.data.error.name }); }
                    else if (response.data.error.description) { this.setState({ errorMessage: response.data.error.description }); }
                    else { this.setState({ errorMessage: Messages.SERVER_ERROR, }); }
                    this.setState({ showError: true, showLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, showLoader: false });
            });
    }

    onDeactivateTeamClick = (teamId, status) => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = true;
            handelActivate.memberIdForDeletion = teamId;
            if (status) {
                handelActivate.status = 0;
                handelActivate.confirmText = TeamMessages.DEACTIVATE_GROUP;
                handelActivate.confirmButtonText = "Deactivate";
                handelActivate.confirmTypeStatus = "Deactivate";
            }
            else {
                handelActivate.status = 1;
                handelActivate.confirmText = TeamMessages.ACTIVATE_GROUP;
                handelActivate.confirmButtonText = "Activate";
                handelActivate.confirmTypeStatus = "Activate";
            }
            return { handelActivate };
        });
    }

    onDeactivateTeamConfirmClick = () => {
        const {archiveMultipleGroups, handelActivate, selectedClubId} = this.state;
        if (this.state.userInfo != null) {
            if (handelActivate.memberIdForDeletion != null) {
                this.setState({
                    modalLoader: true,
                });
                let data = {
                    id: handelActivate.memberIdForDeletion,
                    status: handelActivate.status
                };
                let apiPath = 'team/update-status';
                if(archiveMultipleGroups) {
                    data.club_id = selectedClubId;
                    apiPath = 'team/update-status-multi';
                }
                Helper.axiosInstance()
                    .put(apiPath, data)
                    .then((response) => {
                        if (response.data.success === true) {
                            this.setState(prevState => {
                                let handelActivate = Object.assign({}, prevState.handelActivate);
                                handelActivate.deleteConfirmShow = false;
                                handelActivate.memberIdForDeletion = null;
                                return { handelActivate };
                            });
                            this.loadData(this.state.selectedClubId);
                            this.setState({checkedItems: new Map(), selectedMemberGroups: new Map(), modalLoader: false, archiveMultipleGroups: false});
                        }
                    })
                    .catch(function (error) {
                        this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                    });
            }
        }
    }
    onDeactivateTeamCancelClick = () => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = false;
            return { handelActivate };
        })
    }

    hideModal = () => {
        this.setState({
            isSuccess: true,
        })
        this.setShow(false);
    }
    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }
    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }
    selectGroup = (group) => {
        const groupId = group.id;
        let selectedMemberGroups = this.state.selectedMemberGroups,
            checkedItems = this.state.checkedItems;
        if (typeof selectedMemberGroups.get(groupId) == "undefined") {
            selectedMemberGroups.set(groupId, { groupId });
            checkedItems.set(groupId, true);
        } else {
            selectedMemberGroups.delete(groupId);
            checkedItems.delete(groupId);
        }
        this.setState({ selectedMemberGroups: selectedMemberGroups, checkedItems: checkedItems }, () => {
            this.state.checkedItems.size === this.state.activeTeamListSearch.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }
    handleAllChecked = () => {
        let {  activeTeamListSearch, checkedItems } = this.state;
        !(checkedItems.size === activeTeamListSearch.length) ?
            this.setState({ checkedItems: new Map(), selectedMemberGroups: new Map() }, () => {
                activeTeamListSearch.forEach(group => this.selectGroup(group));
            }) : activeTeamListSearch.forEach(group => this.selectGroup(group));
        this.setState({ activeTeamListSearch: activeTeamListSearch }, () => {
            checkedItems.size === activeTeamListSearch.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }
    deselectAll = () => {
        let selectedMemberGroups = this.state.selectedMemberGroups;
        let checkedItems = this.state.checkedItems;
        selectedMemberGroups.clear();
        checkedItems.clear();
        this.setState({ selectedMemberGroups, checkedItems }, () => {
            this.state.checkedItems.size === this.state.selectedMemberGroups.length ? this.setState({ checkedAll: true }) : this.setState({ checkedAll: false })
        });
    }
    toggleGroupModal = (visible) => {
        this.setState({ groupModal: visible });
    }

    sortHandler = (column) => {
        const {sortCriteria, selectedClubId} = this.state;
        if(!sortCriteria.sortingInProcess){
            let newSortCriteria = sortCriteria;
            if(sortCriteria.column  == column){
                newSortCriteria.direction = (sortCriteria.direction== 'asc') ? 'desc' : 'asc';
            } else {
                newSortCriteria.direction = 'asc';
            }
            newSortCriteria.column = column;
            newSortCriteria.sortingInProcess = true;

            this.setState({sortCriteria: newSortCriteria}, ()=>this.loadData(selectedClubId));
        }
    }

    btnClickHandler = (action) => {
        const {selectedMemberGroups} = this.state;
        let membersArr = [];
        for (let val of selectedMemberGroups.values()) {
            membersArr.push(val.groupId);
        }
        console.log('btnClickHandler')
        if(selectedMemberGroups.size > 0){
            switch(action) {
                case 'archive-groups':
                    let handelActivate = {
                        deleteConfirmShow: true,
                        memberIdForDeletion: membersArr,
                        confirmButtonText: "Deactivate",
                        confirmText: TeamMessages.DEACTIVATE_MULTIPLE_GROUP,
                        confirmTypeStatus: "Deactivate",
                        status: 0
                    };
                    this.setState({archiveMultipleGroups: true, handelActivate: handelActivate});
                    break;
                case 'broadcast':
                    Helper.fetchSelectedMembersCount(false, this, 'teams');
                    break;
                default:
                    console.log(action);
            }
        } else {
            this.setState({  errorMessage: Enum.groupSelectionWarning, showError: true});
        }
    }

    onRoleTabSelect = (key) => {
        this.setState({activeTab: key});
    }

    render() {
        const { show, checkedAll, checkedItems, selectedMemberGroups, selectedMembersCount, broadcastModal, sortCriteria, activeTab} = this.state;
        const handleShow = () => this.setShow(true);
        const createModalHeader = (
            <>
                <Modal.Title>Create a Member Group</Modal.Title>
            </>
        )
        const createTeamContent = (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    groupName: '',
                    description: '',
                    instructor: '',
                    location: ''
                }}
                validationSchema={Yup.object().shape({
                    groupName: Yup.string().trim()
                        .max(50, TeamMessages.MAX_LENGTH_50)
                        .required(TeamMessages.NAME_REQUIRED_ERROR),
                    description: Yup.string().trim()
                        .max(500, TeamMessages.MAX_LENGTH_500)
                        .required(TeamMessages.DESC_REQUIRED_ERROR),
                    instructor: Yup.string()
                        .required('Please select an instructor.'),
                    location: Yup.string().trim()
                        .max(50, TeamMessages.MAX_LENGTH_50)
                })}
                onSubmit={fields => { this.saveCreatedGroup(fields); }}
                render={({ errors, status, touched, setFieldValue, setFieldTouched, handleChange }) => (
                    <Form className="contact-us" noValidate>
                        {
                            this.state.isSuccess ?
                                <>
                                    <Modal.Body>
                                        <div className="d-flex flex-row">
                                            <div className="ml-left">
                                                <Image src={Images.MemberGroupNew} alt="Member Group" width="100" />
                                            </div>
                                            <div className="ml-right">
                                                <div className="field form-group">
                                                    <Field name="groupName" type="text" id="groupName" maxLength="50" autoComplete="off" className={'form-control' + (errors.groupName && touched.groupName ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} />
                                                    <label htmlFor="groupName" className="form-label">Member Group Name</label>
                                                    <ErrorMessage name="groupName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="field form-group">
                                                    <Field name="description" type="text" id="description" maxLength="500" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} />
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="field form-group">
                                                    <div className="select-wrap">
                                                        <Field component="select" placeholder="Select Instructor"
                                                            className={' form-control' + (errors.instructor && touched.instructor ? ' is-invalid' : '')}
                                                            name="instructor" id="instructor"
                                                            onBlur={() => setFieldTouched("instructor", true)}
                                                            onChange={(event) => this.onKidMultiSelectChange(event, setFieldValue)}
                                                        >
                                                            <option value="">-- Select Instructor --</option>
                                                            {this.state.coachList !== undefined && this.state.coachList.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </Field>
                                                        <ErrorMessage name="instructor" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="field form-group">
                                                    <Field name="location" type="text" id="location" maxLength="50" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} onFocus={this.inputFocus} onBlur={this.inputBlur} />
                                                    <label htmlFor="location" className="form-label">Location</label>
                                                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="m-footer">
                                        <div className="m-footer__btnwrap">
                                            <Button variant="secondary" className="ripple" onClick={this.hideModal} disabled={this.state.showLoader}>
                                                Cancel </Button>
                                            <Button type="submit" disabled={this.state.showLoader} className={`btn-primary btn-medium ripple ml-auto align-self-center btn ${this.state.showLoader ? 'btn-loader' : ''}`} >
                                                <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /> </div>
                                                Create <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>
                                        </div>
                                    </Modal.Footer>
                                </>
                                :
                                <>
                                    <Modal.Body>
                                        <div className="text-center success-msg">
                                            <Image src={Images.SuccessTick} alt="Success" />
                                            <p>You’ve successfully added a new member group. Go to your group details page to add the members. </p>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="m-footer">
                                        <div className="m-footer__btnwrap">
                                            <Button variant="secondary" className="ripple" onClick={this.hideModal}> Cancel </Button>
                                            <Link to={`/team-details/${this.state.createdTeamId}`} className="ripple btn-medium d-inline-flex align-items-center btn btn-primary"><div className="btn__icon btn__icon--red"><Image src={Images.AddMemberIc} alt="Complete" /></div> Go to group </Link>
                                        </div>
                                    </Modal.Footer>
                                </>
                        }
                    </Form>
                )}
            />
        )
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space align-items-center">
                        <Col md={4}>
                            <h1>Member Groups</h1>
                            { 
                                selectedMemberGroups.size > 0 &&
                                <label className="selected-count"> {selectedMemberGroups.size == 1 ? `${selectedMemberGroups.size} member group selected` : `${selectedMemberGroups.size} member groups selected`}</label>
                            }
                        </Col>
                        <Col md={8}>
                            <div className="c-filter-outer">
                                <div className="wgt-btnwrap">
                                {
                                    Helper.isAdminOrSubAdmin() &&
                                    <OverlayTrigger placement="auto"
                                        overlay={
                                            <Tooltip id={`tooltip`}>
                                                Create a Member Group
                                            </Tooltip>}>
                                            <a href={null} onClick={handleShow}>
                                            <div className="outline-circle">
                                                <Image src={Images.GroupIcRed} alt="Add member" width="30" />
                                            </div>
                                        </a>
                                    </OverlayTrigger>
                                }
                                {
                                    Helper.isAdminOrSubAdmin() && typeof activeTab != 'undefined' && activeTab == 'active' &&
                                    <OverlayTrigger placement="auto"
                                        overlay={
                                        <Tooltip id={`tooltip`}>
                                        Archive Group(s)
                                        </Tooltip>}>
                                        <a href={null} onClick={() => {this.btnClickHandler('archive-groups')}}>
                                            <div className="outline-circle">
                                                <Image src={Images.archiveGroup} alt="Delete" width="30" />
                                            </div>
                                        </a>
                                    </OverlayTrigger> 
                                }
                                {Helper.isCoachOrAdminLogin() && <OverlayTrigger placement="auto"
                                                                                 overlay={
                                                                                     <Tooltip id={`tooltip`}>
                                                                                        Send Announcement
                                                                                     </Tooltip>}>
                                    <a href={null} onClick={() => {
                                        this.btnClickHandler('broadcast')
                                    }}>
                                        <div className="outline-circle">
                                            <Image src={Images.AnnouncementRed} alt="Upload" width="30"/>
                                        </div>
                                    </a>
                                </OverlayTrigger>
                                }
                                {selectedMemberGroups.size > 0 &&
                                <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Cancel
                                                    </Tooltip>}>
                                    <a href={null} onClick={this.deselectAll}>
                                        <div className="outline-circle">
                                            <Image src={Images.Cancel} alt="Cancel" width="25"/>
                                        </div>
                                    </a>
                                </OverlayTrigger>
                                }
                            </div>
                                
                            </div>
                            {/* {
                                selectedMemberGroups.size > 0 &&
                                <FixedActionBar
                                    broadcastBtnHandler = {Helper.fetchSelectedMembersCount}
                                    cancelBtnHandler = {this.deselectAll}
                                    broadcastBtnLoader = {broadcastBtnLoader}
                                    selectedMemberTxt = {selectedMemberGroups.size == 1 ? `${selectedMemberGroups.size} member group selected` : `${selectedMemberGroups.size} member groups selected`}
                                    showBroadcastBtn = {Helper.isCoachOrAdminLogin()}
                                    mainObj={this}
                                    type={'teams'}
                                />
                            } */}
                        </Col>
                       
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            {
                                Helper.isCoachOrAdminLogin() &&
                                <>
                                    {
                                        Helper.isLegalGuardian() && 
                                        <Media className="lg-view">
                                            <Media.Body className="align-self-center">
                                                <h5>My Groups</h5>
                                            </Media.Body>
                                        </Media>
                                    }
                                    <div className="widget">
                                        <div className="tab-search tab-search--big">
                                            <Form className="form-inline">
                                                <input type="text" name="focus" maxLength="100" onChange={(e) => this.filterList(e.target)} placeholder="Search" className="form-control tab-search__box" />
                                                <button className="close-icon" onClick={(e) => this.filterList(e.target)} type="reset"></button>
                                            </Form>
                                        </div>
                                        <Tabs defaultActiveKey="active" onSelect={this.onRoleTabSelect}>
                                            <Tab eventKey="active" title="Active">
                                                <TeamList data={this.state.activeTeamListSearch}
                                                    teamMemberOf={this.state.teamMemberOf}
                                                    recordLoaded={this.state.recordLoaded}
                                                    handleTeamShow={() => this.handleTeamShow()}
                                                    onDeactivateTeamClick={(id, status) => this.onDeactivateTeamClick(id, status)}
                                                    isInstructorLogin={this.state.isInstructorLogin}
                                                    checkedAll={checkedAll}
                                                    checkedItems={checkedItems}
                                                    mainObj={this}
                                                    onCheckboxChange={(item) => { this.selectGroup(item) }}
                                                    handleAllChecked={this.handleAllChecked}
                                                    sortHandler={(column)=>this.sortHandler(column)}
                                                    sortCriteria={sortCriteria}
                                                />
                                            </Tab>
                                            <Tab eventKey="archived" title="Archived">
                                                <TeamList data={this.state.archivedTeamListSearch}
                                                    memberGroupsType = 'archived'
                                                    recordLoaded={this.state.recordLoaded}
                                                    teamMemberOf={this.state.teamMemberOf}
                                                    handleTeamShow={() => this.handleTeamShow()}
                                                    onDeactivateTeamClick={(id, status) => this.onDeactivateTeamClick(id, status)}
                                                    isInstructorLogin={this.state.isInstructorLogin}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </>
                            }
                            {
                                Helper.isLegalGuardian() &&
                                <KidsGroupList data={this.state.kidsSearchlist}
                                    filterKidsList={(e, index) => this.filterKidsList(e, index)}
                                    recordLoaded={this.state.recordLoaded}
                                    teamMemberOf={this.state.teamMemberOf}
                                    sortHandler={(column)=>this.sortHandler(column)}
                                    sortCriteria={sortCriteria}
                                    mainObj={this}
                                />
                            }
                            {
                                Helper.isKidLogin() &&
                                <div className="widget">
                                    <div className="tab-search tab-search--big tab-search--widget">
                                        <Form className="form-inline">
                                            <input type="text" name="focus" maxLength="100" onChange={(e) => this.filterList(e.target)} placeholder="Search" className="form-control tab-search__box" />
                                            <button className="close-icon" onClick={(e) => this.filterList(e.target)} type="reset"></button>
                                        </Form>
                                    </div>
                                    <div>
                                        <TeamList data={this.state.activeTeamListSearch}
                                            recordLoaded={this.state.recordLoaded}
                                            teamMemberOf={this.state.teamMemberOf}
                                            handleTeamShow={() => this.handleTeamShow()}
                                            onDeactivateTeamClick={(id, status) => this.onDeactivateTeamClick(id, status)}
                                            isInstructorLogin={this.state.isInstructorLogin}
                                            sortHandler={(column)=>this.sortHandler(column)}
                                            sortCriteria={sortCriteria}
                                        />
                                    </div>
                                </div>

                            }
                        </Col>
                    </Row>
                </div>
                <BroadcastMesaage
                    selectedMembers = {selectedMemberGroups}
                    selectedMembersCount={selectedMembersCount}
                    broadcastModal={broadcastModal}
                    toggleBroadcastModal={Helper.toggleBroadcastModal}
                    checkedAll={checkedAll}
                    deselectAll={this.deselectAll}
                    mainObj={this}
                    type = "team"
                />
                <ModalBox
                    size="lg"
                    show={show}
                    onHide={this.hideModal}
                    animation={true}
                    modalheader={createModalHeader}
                    modalcontent={createTeamContent}
                >
                </ModalBox>
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handelActivate.deleteConfirmShow}
                    onHide={this.onDeactivateTeamConfirmCancel}
                    onCancelClick={this.onDeactivateTeamCancelClick}
                    onConfirmClick={this.onDeactivateTeamConfirmClick}
                    confirmText={this.state.handelActivate.confirmText}
                    confirmButtonText={this.state.handelActivate.confirmButtonText}
                    confirmType={this.state.handelActivate.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.accessMessage}
                    currentProps={this.props}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                ></ErrorModal>
                <UpdateMiddleNameModal
                    show={this.state.showMiddleNameWarning}
                    handleLogout={this.props.handleLogout}
                    currentProps={this.props}
                />
            </>
        )
    }
}

export { TeamListing };