/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Media, Image } from 'react-bootstrap';
/* End - React Bootstrap */

// Guardians list if kid is logged in.
class GuardianList extends Component {
    render() {
        let props = this.props;
        let guardians = '';
        if (typeof props.data == "object" && Object.keys(props.data).length > 0) {
            guardians = props.data.map((row, j) => {
                return (
                    <div className="col-sm-6 col-lg-4 guardian-list--media">
                        <Media className="flex-wrap">
                            <Image
                                className="border-semirounded mr-3 border-semirounded--width-70"
                                src={row.photo}
                                alt="child"
                            />
                            <Media.Body className="media-body--width-calc">
                                <h5><span>{row.first_name ? row.first_name : null} {row.last_name ? row.last_name : null}</span>
                                </h5>
                                <p>{row.email}</p>
                            </Media.Body>
                        </Media>
                    </div >
                )
            })

        }
        return guardians;
    }
}


export { GuardianList };