/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, OverlayTrigger, Tooltip, Image} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import '../club/club.scss';

import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { SSNInput } from "../../components/input/ssn";
import * as Regex from '../../components/common/regex';
import * as Images from "../../components/common/images";

class BankingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
        };
        this.isValidated = this.isValidated.bind(this);
        this.fields = {}
        this.submitFormRef = null;
        this.isFormValid = false;
        this.hasValidateMethodRun = false;
        this.fields = {};
        if (this.props.data) {
            this.fields = this.props.data.fields;
        }
    }

    isValidated() {
        if (this.fields.bankRoutingNumber !== "" && !this.hasValidateMethodRun) {
            this.props.handleFormSubmit(this.fields);
            return true;
        }
        else {
            if (this.submitFormRef)
                this.submitFormRef();
            if (this.isFormValid) {
                this.props.handleFormSubmit(this.fields);
            }
            return this.isFormValid;
        }
    }

    bindSubmitForm = (submitForm, errors, isValid) => {
    if (!this.submitFormRef)
            this.submitFormRef = submitForm;
        this.isFormValid = this.props.data.firstLoad ? isValid : Object.keys(errors).length === 0;
    };

    render() {
        let labelClassInActive = "form-label";
        let labelClassActive = "form-label label-active";
        const formContent = (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    bankRoutingNumber: this.fields.bankRoutingNumber,
                    bankAccountNumber: this.fields.bankAccountNumber,
                    bankAccountName: this.fields.bankAccountName,
                    confirmBankAccountNumber: this.fields.confirmBankAccountNumber,
                    socialSecurityNumber: this.fields.socialSecurityNumber,
                    confirmSocialSecurityNumber: this.fields.confirmSocialSecurityNumber,

                }}
                validationSchema={Yup.object().shape({
                    bankRoutingNumber:Yup.string().trim()
                        .matches(Regex.numbers, { message: Messages.ONLY_NUMBERS, excludeEmptyString: false })
                        .required('Bank Routing Number is required'),
                    bankAccountNumber:Yup.string().trim()
                        .required('Bank Account Number is required')
                        .matches(Regex.numbers, { message: Messages.ONLY_NUMBERS, excludeEmptyString: false })
                        .test('account-number-length', 'Must be exactly between 10 to 12 characters', function(val){
                          if(val && (val.toString().length <10 || val.toString().length >12)){
                              return  false;
                          }
                          return true;
                        }),

                    confirmBankAccountNumber:Yup.string().trim()
                        .max(12, Messages.MAX_LENGTH_17)
                        .required('Confirm Bank Account Number is required')
                        .test('account-match', 'Bank Account Number and Confirm Bank Account Number must match', function(value) {
                            return this.parent.bankAccountNumber === value;
                        }),
                    bankAccountName: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .required('Bank Account Name is required'),
                    socialSecurityNumber:Yup.string().trim()
                        .matches(Regex.ssn, { message: Messages.INVALID_SSN, excludeEmptyString: false })
                        .required('Social Security Number is required'),
                    confirmSocialSecurityNumber:Yup.string().trim()
                        .required('Confirm Social Security Number is required')
                        .matches(Regex.ssn, { message: Messages.INVALID_SSN, excludeEmptyString: false })
                        .test('ssn-match', 'Social Security Number and Confirm Social Security Number must match', function(value) {
                            return this.parent.socialSecurityNumber === value;
                        }),
                    })}
                validate={(values) => { this.hasValidateMethodRun = true; if (this.isFormValid) { this.fields = values; } }}
                render={
                    (formikProps) => {
                        const { errors, touched, submitForm, isValid, values,setFieldValue } = formikProps;
                        this.bindSubmitForm(submitForm, errors, isValid);
                        return (
                            <Form noValidate>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field  maxLength="150" name="bankAccountName" type="text" id="bankAccountName" autoComplete="off" className={'form-control' + (errors.bankAccountName && touched.bankAccountName ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="bankAccountName" className={values.bankAccountName === "" ? labelClassInActive : labelClassActive}>Bank Account Name</label>
                                            <ErrorMessage name="bankAccountName" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field maxLength="9" name="bankRoutingNumber" type="text" id="bankRoutingNumber" autoComplete="off" className={'form-control' + (errors.bankRoutingNumber && touched.bankRoutingNumber ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="bankRoutingNumber" className={values.bankRoutingNumber === "" ? labelClassInActive : labelClassActive}>Bank Routing Number</label>
                                            <ErrorMessage name="bankRoutingNumber" component="div" className="invalid-feedback" />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field  maxLength="12" name="bankAccountNumber" type="text" id="bankAccountNumber" autoComplete="off" className={'form-control' + (errors.bankAccountNumber && touched.bankAccountNumber ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="bankAccountNumber" className={values.bankAccountNumber === "" ? labelClassInActive : labelClassActive}>Bank Account Number</label>
                                            <ErrorMessage name="bankAccountNumber" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex w-100 help-icon-wrap">
                                        <div className="field form-group">
                                            <Field  maxLength="12" name="confirmBankAccountNumber" type="text" id="confirmBankAccountNumber" autoComplete="off" className={'form-control' + (errors.confirmBankAccountNumber && touched.confirmBankAccountNumber ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="confirmBankAccountNumber" className={values.confirmBankAccountNumber === "" ? labelClassInActive : labelClassActive}>Confirm Bank Account Number</label>
                                            <ErrorMessage name="confirmBankAccountNumber" component="div" className="invalid-feedback" />
                                        </div>
                                        <OverlayTrigger overlay={
                                            <Tooltip id={`tooltip`} className='tooltip-help'>
                                                SafeComm will be depositing commissions directly to your bank account, we need your banking information.
                                            </Tooltip>}>
                                            <div className="help-icon-wrap__icon">
                                                <Image src={Images.helpIcon} alt="Help" width="25" />
                                            </div>
                                        </OverlayTrigger>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <SSNInput
                                                parent={this}
                                                fieldLabel={'Social Security Number'}
                                                SSNField={'socialSecurityNumber'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={false}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                type={"text"}
                                                guide={false}

                                            />
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        <div className="d-flex w-100 help-icon-wrap">
                                        <div className="field form-group">
                                            <SSNInput
                                                parent={this}
                                                fieldLabel={'Confirm Social Security Number'}
                                                SSNField={'confirmSocialSecurityNumber'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={false}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                guide={false}

                                            />
                                        </div>
                                        <OverlayTrigger overlay={
                                            <Tooltip id={`tooltip`} className='tooltip-help'>
                                                The income generated from your comissions may be taxable and SafeComm will need your Social Security number to generate the appropriate tax form(s).
                                            </Tooltip>}>
                                            <div className="help-icon-wrap__icon">
                                                <Image src={Images.helpIcon} alt="Help" width="25" />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            />
        )
        return (
            <>
                <Row className="steps-content steps-content--stepsfont-size">
                    <Col lg={10} md={12} className="offset-lg-1">
                        {formContent}
                    </Col>
                </Row>
            </>
        )
    }
}
export { BankingInfo };
