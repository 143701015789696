/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */


/* End - React Router Dom */
import { SafeCommWebsiteSupportUrl, SafeCommWebsite } from '../common/enum'


export const FooterLogin = () => (
    <footer className="l-footer">
        <p>© {(new Date().getFullYear())} by Safe Communication Consultants. </p>
        <ul>
            <li><a target="_blank" href={ SafeCommWebsite }>SafeComm Website</a></li>
            <li><a target="_blank" href={ SafeCommWebsiteSupportUrl }>Support</a></li>
        </ul>
    </footer>
);
