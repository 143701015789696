/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Alert, Button, Col, Image, Media, Modal, Row, Table} from 'react-bootstrap';
/* End - React Bootstrap */
/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {ErrorMessage, Field, Form, Formik} from 'formik';
/* End - Formik */
/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */
import {DeleteConfirmModal, ErrorModal, ModalBox, SuccessModal} from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Storage from '../../components/common/storage';
import './profile.scss';
import * as Helper from '../../components/common/functions';
import * as Enum from '../../components/common/enum';
import {SmallLoader} from '../../components/small-loader/sm-loader';
import {Messages} from '../../components/common/message';
import {TeamMessages} from '../team/team-message';
import {GuardianList} from './guardian-list';
import {KidsList} from './kids-list';
import {SecEmailLists} from './secondary-email-list';
import {PhoneInput} from '../../components/input/phone';
import * as Regex from "../../components/common/regex";
import {ISRAddress} from "./isr-address";


class Profile extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showLoader: false,
            kidPicLoader: false,
            kidDetailLoader: false,
            addSecEmailLoader: false,
            resendInviteLoader: {
                show: false,
                index: null,
                resendLoader: false,
            },
            show: false,
            updateNameLoader: false,
            updateSecEmailLoader: false,
            changePasswordLoader: false,
            modalLoader: false,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phoneCode: '',
            kidPhoto: null,
            file: {
                imagesrc: null,
                data: null
            },
            updateChildPhoto: null,
            newSecEmailList: [],
            editSecEmail: false,
            updateSecEmail: true,
            disableDiv: { display: 'none' },
            disabled: true,
            addRowStyle: { display: 'inline-block' },
            updatePasswordFlag: { display: 'block' },
            editPasswordFlag: { display: 'none' },
            editNameFlag: { display: 'block' },
            updateNameFlag: { display: 'none' },
            editKidFlag: false,
            updateKidFlag: true,
            deleteConfirmShow: false,
            successConfirmShow: false,
            successText: '',
            deleteEmailInfo: {
                row: null,
                index: null
            },
            newkidList: [],
            addGuardianList: [],
            editGuardianList: [],
            inviteGuardianIndex: null,
            inviteLoader: false,
            countSecEmailFlag: { display: 'block' },
            errorEmailFlag: { display: 'none' },
            errorPasswordFlag: { display: 'none' },
            errorMessage: null,
            popoverOpen: false,
            isAdded: true,
            countLG: { display: 'none' },
            serverValid: {
                first_name: '',
                last_name: '',
                middle_name: ''
            },
            serverSuccess: '',
            serverError: '',
            mailAddress:'',
            mailAddress2:'',
            bankAccountName:'',
            bankAccountNumber:'',
            bankRoutingNumber:'',
            state:'',
            city:'',
            SSN:'',
            zip:'',
            isrAddressFieldDisabled: true,
            isrBankInfoFieldDisabled: true,
            editAddressFlag: { display: 'none' },
            editBankInfoFlag: { display: 'none' },
            updateAddressFlag: { display: 'inline-block' },
            updateBankInfoFlag: { display: 'inline-block' },
            updateISRAddressLoader:false,
            updateISRBankLoader:false,


        };
        this.fields = {};
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
        this.editKidDetails = this.editKidDetails.bind(this);
        this.countSecEmails = this.countSecEmails.bind(this);
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }
    componentDidMount() {
        const user = Storage.Get('user');
        this.setState({
            first_name: user.first_name,
            last_name: user.last_name,
            userName: user.username,
            email: user.email,
            phone: user.phone ?? '',
            phoneCode: user.phone_code ?? '+1',
            file: {imagesrc: user.photo},
            role: user.role[user.selectedClubId],
            isKidLogin: Helper.isKidLogin()
        });
        this.loadData();
    }
    addMoreGuardian = () => {
        this.setState({
            isAdded: true
        })
    }
    inviteNewGuardian = (fields, kidId) => {
        this.setState({ inviteLoader: true });
        var data = {
            first_name: fields.gfirstName.trim(),
            last_name: fields.glastName.trim(),
            kid_id: kidId,
            email: fields.gemailId.trim(),
            phone: fields.gPhone.trim(),
            phone_code: fields.gPhoneCode.trim(),
            send: 1,
            club_id: Storage.Get('user').selectedClubId
        }
        Helper.axiosInstance().post('user/invite-legal-guardian', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        isAdded: false
                    });
                    this.formik.resetForm();
                    this.loadData();
                    this.setState({ inviteLoader: false });
                }
                else {
                    this.setState({ errorMessage: response.data.error, showLoader: false, showError: true, inviteLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true, inviteLoader: false });
            });
    }

    handlePhotoChange = (event) => {
        if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            if (event.target.files[0].size / 1024 > 6000) {
                this.setState({ errorMessage: Messages.PHOTO_MAX_SIZE, showError: true, });
                event.target.value = null;
                return;
            }
            if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg") {
                this.setState({ errorMessage: Messages.PHOTO_TYPE_ALLOWED, showError: true, });
                event.target.value = null;
                return;
            }
            this.setState({ showLoader: true });
            const formData = new FormData();
            formData.append('photo', event.target.files[0]);
            Helper.axiosInstance().post('user/update-profile-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
                .then((response) => {
                    if (response.data.success) {
                        let user = Storage.Get('user');
                        user.photo = response.data.imageUrl;
                        Storage.Set('user', JSON.stringify(user));
                        this.setState({
                            file: { imagesrc: response.data.imageUrl },
                            successConfirmShow: true,
                            successText: "Profile photo updated successfully",
                        })
                        this.setState({ showLoader: false });
                        this.props.handleProfilePic(this.state.file.imagesrc);
                    }
                    else {
                        this.setState({ errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true, });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true, });
                });
        }
    }


    handleChangePassword = () => {
        this.setState({
            editPasswordFlag: this.state.editPasswordFlag.display === 'none' ? { display: 'block' } : { display: 'none' },
            updatePasswordFlag: this.state.updatePasswordFlag.display === 'none' ? { display: 'block' } : { display: 'none' },
            disabled: !this.state.disabled,
            errorPasswordFlag: { display: 'none' }
        });
        document.querySelector(".jsRemoveLabelActive1").classList.remove('label-active');
        document.querySelector(".jsRemoveLabelActive2").classList.remove('label-active');
        document.querySelector(".jsRemoveLabelActive3").classList.remove('label-active');
    }
    addSecondaryEmail = () => {
        this.setState({
            disableDiv: this.state.disableDiv.display === "none" ? { display: 'block' } : { display: 'none' },
            addRowStyle: this.state.addRowStyle.display === "none" ? { display: 'inline-block' } : { display: 'none' },
        })
        this.countSecEmails();
    }
    saveSecondaryEmail(fields, formProps) {
        this.setState({
            addSecEmailLoader: true,
        })
        var data = {
            email: fields.secEmail
        }
        Helper.axiosInstance().post('user/add-email', data)
            .then((response) => {
                if (response.data.success === true) {
                    let addedSecEmail = this.state.newSecEmailList;
                    let newEmailAdded = {
                        data: { id: response.data.id, email: fields.secEmail, status: 0 },
                        index: addedSecEmail.length,
                        editSecEmailStyle: false,
                        updateSecEmailStyle: true,
                    };
                    addedSecEmail.push(newEmailAdded);
                    this.setState({
                        newSecEmailList: addedSecEmail,
                        successConfirmShow: true,
                        successText: "Email address added successfully. We have sent an email with a confirmation link to your email address. Please click the link to verify the email address.",
                        errorEmailFlag: { display: 'none' },
                        addSecEmailLoader: false,
                    }, () => {
                        this.addSecondaryEmail();
                        formProps.resetForm();
                    });
                }
                else {
                    this.setState({ errorMessage: response.data.error.email, showError: true, addSecEmailLoader: false, });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, addSecEmailLoader: false, });
            });
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }
    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    changePassword(fields) {
        this.setState({
            changePasswordLoader: true
        });
        var me = this;
        var data = {
            password: fields.password,
            confirm_password: fields.confirmPassword,
            current_password: fields.currentPass
        }
        Helper.axiosInstance().put('user/change-password', data)
            .then(function (response) {
                if (response.data.success === true) {
                    me.setState({
                        disableDiv: { display: 'none' },
                        successConfirmShow: true,
                        successText: "Password successfully changed.",
                        changePasswordLoader: false,
                    });
                    me.handleChangePassword();
                    me.setState({ errorPasswordFlag: { display: 'none' } })
                    me.formik.resetForm();
                }
                else {
                    me.setState({ errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true, });
                    me.setState({ changePasswordLoader: false, errorPasswordFlag: { display: 'block' } });
                }
            })
            .catch(function (error) {
                me.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                me.setState({ changePasswordLoader: false, });
            });
    }
    updateNameDiv = () => {
        this.setState({
            editNameFlag: this.state.editNameFlag.display === "none" ? { display: 'block' } : { display: 'none' },
            updateNameFlag: this.state.updateNameFlag.display === "none" ? { display: 'block' } : { display: 'none' },
        });
    }

    updateName(fields) {
        this.setState({ updateNameLoader: true });
        var me = this;
        var data = {
            first_name: fields.firstName.trim(),
            last_name: fields.lastName.trim(),
            phone: fields.phone.trim(),
            phone_code: fields.phoneCode.trim(),
        }
        if (this.state.first_name === fields.firstName && this.state.last_name === fields.lastName && this.state.phone === fields.phone
            && this.state.phoneCode === fields.phoneCode) {
            this.updateNameDiv();
            this.setState({
                updateNameLoader: false
            })
        }
        else {
            Helper.axiosInstance().put('user/update-name', data)
                .then((response) => {
                    var me = this;
                    if (response.data.success === true) {
                        var parsedValue = JSON.parse(response.config.data);
                        let user = Storage.Get('user');
                        user.first_name = parsedValue.first_name;
                        user.last_name = parsedValue.last_name;
                        user.phone = parsedValue.phone;
                        user.phone_code = parsedValue.phone_code;
                        Storage.Set('user', JSON.stringify(user));
                        me.setState({
                            first_name: parsedValue.first_name,
                            last_name: parsedValue.last_name,
                            phone: parsedValue.phone,
                            phoneCode: parsedValue.phone_code,
                            successText: "Details updated successfully.",
                            successConfirmShow: true,
                        });
                        this.updateNameDiv();
                        me.setState({ updateNameLoader: false });
                    }
                    else {
                        me.setState({ updateNameLoader: false });
                    }
                })
                .catch(function (error) {
                    me.setState({ errorMessage: Messages.SERVER_ERROR, kidPicLoader: false, showError: true, updateNameLoader: false });
                });
        }
    }

    editKidDetails = (id) => {
        let kidList = this.state.newkidList;
        kidList = kidList.map((row, i) => {
            return (
                {
                    data: kidList[i].data,
                    index: i,
                    editKidStyle: (id === i) ? !kidList[i].editKidStyle : kidList[i].editKidStyle,
                    updateKidStyle: (id === i) ? !kidList[i].updateKidStyle : kidList[i].updateKidStyle
                })
        });
        this.setState({
            newkidList: kidList
        });
    }

    editKidPhotoChange = (event, index, kidId) => {
        if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            if (event.target.files[0].size / 1024 > 6000) {
                this.setState({ errorMessage: TeamMessages.PHOTO_MAX_SIZE, showError: true, });
                event.target.value = null;
                return;
            }
            if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg") {
                this.setState({ errorMessage: TeamMessages.PHOTO_TYPE_ALLOWED, showError: true, });
                event.target.value = null;
                return;
            }

            this.setState({
                kidPicLoader: true
            })
            const formData = new FormData();
            formData.append('photo', event.target.files[0]);
            formData.append('user_id', kidId);
            Helper.axiosInstance().post('user/update-profile-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
                .then((response) => {
                    if (response.data.success) {
                        let updateKidListData = this.state.newkidList;
                        updateKidListData[index].data.photo = response.data.imageUrl;
                        this.setState({
                            newkidList: updateKidListData,
                            kidPicLoader: false,
                            successConfirmShow: true,
                            successText: `${Enum.Student} photo updated successfully`,
                        });
                    }
                    else {
                        this.setState({ errorMessage: response.data.error, kidPicLoader: false, showError: true, });
                    }

                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, kidPicLoader: false, showError: true, });
                });
        }
    }
    updateKidDetails(fields, index, kidId) {
        this.setState({ kidDetailLoader: true })
        var data = {
            first_name: fields.fName,
            last_name: fields.lName,
            middle_name: fields.mName,
            user_id: kidId
        }
        Helper.axiosInstance().put('user/update-detail', data)
            .then((response) => {
                if (response.data.success) {
                    let updateKidListData = this.state.newkidList;
                    updateKidListData[index].data.first_name = fields.fName;
                    updateKidListData[index].data.last_name = fields.lName;
                    updateKidListData[index].data.middle_name = fields.mName;
                    this.setState({
                        kidDetailLoader: false,
                        successConfirmShow: true,
                        successText: `${Enum.Student} name updated successfully`,
                        newkidList: updateKidListData
                    });
                    this.editKidDetails(index);
                    if(typeof response.data.add_middle_name == 'undefined' || !response.data.add_middle_name){
                        Storage.Delete('add_middle_name');
                    }
                } else {
                    const getErrors = response.data.error;
                    let serverValid = this.state.serverValid;
                    // eslint-disable-next-line
                    for (let errorIndex in getErrors) {
                        if (getErrors[errorIndex][0] !== "") {
                            serverValid[errorIndex] = getErrors[errorIndex][0];
                        }
                    }
                    this.setState({ serverValid, kidDetailLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, kidDetailLoader: false, showError: true, });
            });
    }

    editSecondaryEmail = (id, i) => {
        let secEmailIdList = this.state.newSecEmailList;
        secEmailIdList = secEmailIdList.map((row, i) => {
            return (
                {
                    data: secEmailIdList[i].data,
                    index: i,
                    editSecEmailStyle: (id === i) ? !secEmailIdList[i].editSecEmailStyle : secEmailIdList[i].editSecEmailStyle,
                    updateSecEmailStyle: (id === i) ? !secEmailIdList[i].updateSecEmailStyle : secEmailIdList[i].updateSecEmailStyle
                })
        });
        this.setState({
            newSecEmailList: secEmailIdList
        });
    }

    updateSecondaryEmail = (fields, rowId, i) => {
        this.setState({ updateSecEmailLoader: true });
        const me = this,
            data = {
                id: rowId.rowDataId,
                email: fields.secEmailId,
            },
            indexVal = i.i;
        Helper.axiosInstance().put('user/update-email', data)
            .then(function (response) {
                if (response.data.success) {
                    let updatedListItem = me.state.newSecEmailList;
                    updatedListItem[indexVal].data.email = fields.secEmailId;
                    updatedListItem[indexVal].data.status = 0;
                    updatedListItem[indexVal].editSecEmailStyle = !updatedListItem[indexVal].editSecEmailStyle
                    updatedListItem[indexVal].updateSecEmailStyle = !updatedListItem[indexVal].updateSecEmailStyle
                    me.setState({
                        newSecEmailList: updatedListItem,
                        successText: "Email Address updated successfully. We have sent an email with a confirmation link to your email address. Please click the link to verify the email address.",
                        successConfirmShow: true,
                        updateSecEmailLoader: false,
                    });
                }
                else {
                    me.setState({ errorMessage: response.data.error.email, updateSecEmailLoader: false, showError: true, });
                }
            })
            .catch(function (error) {
                me.setState({ errorMessage: Messages.SERVER_ERROR, updateSecEmailLoader: false, showError: true, });
            });
    }

    loadData() {
        var me = this;
        Helper.axiosInstance().get(process.env.REACT_APP_API_URL + 'profile')
            .then(function (response) {
                if (response) {
                    if(response.data.isr_details){
                        me.setState({
                            ISRDetail:response.data.isr_details,
                        });
                    }

                    if (response.data.secondary_emails.length !== 0) {
                        var secEmailList = response.data.secondary_emails;
                        const newSecEmailList = secEmailList.map((row, i) => {
                            return (
                                {
                                    data: row,
                                    index: i,
                                    editSecEmailStyle: false,
                                    updateSecEmailStyle: true
                                })
                        });
                        me.setState({
                            secondary_email_list: secEmailList,
                            newSecEmailList
                        });
                    }
                    if (response.data.guardians) {
                        me.setState({
                            kidGuardians: response.data.guardians
                        })
                    }
                    if (response.data.kids.length !== 0) {
                        let kidList = null;
                        kidList = response.data.kids.map((row, i) => {
                            return (
                                {
                                    data: row,
                                    index: i,
                                    editKidStyle: false,
                                    updateKidStyle: true
                                })
                        });
                        me.setState({
                            kidsList: response.data.kids,
                            newkidList: kidList,
                        })
                    };
                    if (me.state.newSecEmailList.length >= 5) {
                        me.setState({
                            countSecEmailFlag: { display: 'none' },
                        })
                    }
                    if (me.state.inviteGuardianIndex !== null) {
                        me.handleGuardianShow(me.state.inviteGuardianIndex);
                    }
                }
            })
            .catch(function (error) {
                me.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
            });
    }
    countSecEmails = () => {
        if (this.state.newSecEmailList.length === 1) {
            this.loadData()
        }
        this.setState({
            countSecEmailFlag: this.state.newSecEmailList.length >= 5 ? { display: 'none' } : { display: 'block' },
        })
    }

    onDeleteCancelClick = () => {
        this.setState({ deleteConfirmShow: false });
    }

    onDeleteConfirmClick = () => {
        this.setState({
            modalLoader: true,
        })
        Helper.axiosInstance().delete(
            'user/delete-email',
            {
                data: {
                    id: this.state.deleteEmailInfo.row
                }
            })
            .then((response) => {
                if (response.data.success === true) {
                    let deletedSecEmail = this.state.newSecEmailList;
                    deletedSecEmail.splice(this.state.deleteEmailInfo.index, 1);
                    this.setState({
                        newSecEmailList: deletedSecEmail,
                        deleteConfirmShow: false,
                        modalLoader: false,
                    });
                    this.countSecEmails();
                }
                else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
            });
    }

    onDeleteEmailClick = (rowId, index) => {
        let deleteEmailInfo = {
            row: rowId,
            index: index
        };
        this.setState({ deleteEmailInfo: deleteEmailInfo, deleteConfirmShow: true });
    }
    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }
    setShow(action) {
        this.setState({
            show: action,
            isSuccess: true,
        })
    }
    hideModal = () => {
        this.setState({
            isSuccess: true,
            isAdded: true,
        })
        this.setShow(false);
    }
    handleGuardianShow(index) {
        let editGuardian = this.state.kidsList[index].guardian.map((row, i) => {
            return (
                {
                    data: row,
                    index: i,
                    editGuardianStyle: { display: 'table-row' },
                    updateGuardianStyle: { display: 'none' },
                    editGuardianLoader: false,
                }
            )
        })
        if (editGuardian.length >= Enum.MaxLgPerStudent) {
            this.setState({ countLG: { display: 'none' } })
        }
        else {
            this.setState({ countLG: {} })
        }
        this.setState({
            addGuardianList: this.state.kidsList[index],
            editGuardianList: editGuardian,
            inviteGuardianIndex: index,
            kidPhoto: this.state.kidsList[index].photo
        });
        this.setShow(true);
    }
    toggleGuardianEmail = (id) => {
        let editGuardian = this.state.editGuardianList;
        editGuardian = editGuardian.map((row, i) => {
            return ({
                data: editGuardian[i].data,
                index: i,
                editGuardianStyle: (id === i) ? editGuardian[i].editGuardianStyle.display === 'none' ? { display: 'table-row' } : { display: 'none' } : { display: 'table-row' },
                updateGuardianStyle: (id === i) ? editGuardian[i].updateGuardianStyle.display === 'none' ? { display: 'table-row' } : { display: 'none' } : { display: 'none' },
                editGuardianLoader: false,
            })
        });
        this.setState({
            editGuardianList: editGuardian
        });
    }
    editGuardianDetails = (fields, kidId, index) => {
        let editGuardianData = this.state.editGuardianList;
        editGuardianData[index].editGuardianLoader = true;
        this.setState({ editGuardianList: editGuardianData });
        var data = {
            first_name: fields.fName,
            last_name: fields.lName,
            email: fields.emailId,
            phone: fields.phone,
            phone_code: fields.phoneCode,
            kid_id: kidId,
            edit: 1,
            invite_id: fields.inviteId
        }
        Helper.axiosInstance().post('user/invite-legal-guardian', data)
            .then((response) => {
                if (response.data.success) {
                    this.toggleGuardianEmail(index);
                    this.formik.resetForm();
                    this.loadData();
                    editGuardianData[index].editGuardianLoader = false;
                    this.setState({ editGuardianList: editGuardianData });
                    this.setState({
                        successText: `${Enum.LegalGuardian} Details updated and invite Sent Successfully.`,
                        successConfirmShow: true,
                    });
                }
                else {
                    editGuardianData[index].editGuardianLoader = false;
                    this.setState({ errorMessage: response.data.error, showError: true, editGuardianList: editGuardianData });
                }
            })
            .catch((error) => {
                editGuardianData[index].editGuardianLoader = false;
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, editGuardianList: editGuardianData });
            });

    }
    resendEmail = (inviteId, kidId, i) => {
        this.setState({ resendInviteLoader: { show: true, index: i, resendLoader: true } });
        var data = {
            invite_id: inviteId,
            kid_id: kidId,
            resend: 1
        }
        Helper.axiosInstance().post('user/invite-legal-guardian', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        resendInviteLoader: { show: false, index: null },
                        successText: "Invitation re-sent successfully",
                        successConfirmShow: true
                    });
                }
                else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, resendInviteLoader: { show: false, index: null, resendLoader: false } });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, resendInviteLoader: { show: false, index: null, resendLoader: false } });
            });
    }

    /**
     * function to show hide and add set filed attribute to disable on toggle
     * @param isAddress
     */

    toggleEdit = (isAddress) => {
        if(isAddress) {
            this.setState({
                editAddressFlag: this.state.editAddressFlag.display === 'none' ? {display: 'flex'} : {display: 'none'},
                updateAddressFlag: this.state.updateAddressFlag.display === 'none' ? {display: 'flex'} : {display: 'none'},
                isrAddressFieldDisabled: !this.state.isrAddressFieldDisabled,
                isrBankInfoFieldDisabled: true,
                editBankInfoFlag: {display: 'none'},
                updateBankInfoFlag: {display: 'flex'},
            })
        }else{
            this.setState({
                editBankInfoFlag: this.state.editBankInfoFlag.display === 'none' ? {display: 'flex'} : {display: 'none'},
                updateBankInfoFlag: this.state.updateBankInfoFlag.display === 'none' ? {display: 'flex'} : {display: 'none'},
                isrBankInfoFieldDisabled: !this.state.isrBankInfoFieldDisabled,
                isrAddressFieldDisabled: true,
                editAddressFlag: {display: 'none'},
                updateAddressFlag: {display: 'flex'},
            })
        }
    }

    /**
     * function to set previous values of address info on cancel
     * @param event
     * @param setFieldValue
     */

    cancelAddressClick = (event, setFieldValue) => {
        const { address, address2, bank_account_name, bank_account_number, bank_routing_number, city, state, zip, ssn } = this.state.ISRDetail;
        this.toggleEdit(true);
        setFieldValue("mailingAddress", address);
        setFieldValue("mailingAddress2", address2);
        setFieldValue("bankAccountNumber", bank_account_number);
        setFieldValue("bankAccountName", bank_account_name);
        setFieldValue("bankRoutingNumber", bank_routing_number);
        setFieldValue("city", city);
        setFieldValue("ssn", ssn);
        setFieldValue("state", state);
        setFieldValue("zip", zip);
    }
    /**
     * function to set previous values of bank info on cancel
     * @param event
     * @param setFieldValue
     */
    cancelBankInfoClick = (event, setFieldValue)=>{
        const { bank_account_number, bank_account_name, bank_routing_number, ssn } = this.state.ISRDetail;
        this.toggleEdit(false);
        setFieldValue("bankAccountName", bank_account_name);
        setFieldValue("bankAccountNumber", bank_account_number);
        setFieldValue("bankRoutingNumber", bank_routing_number);
        setFieldValue("ssn", ssn);
    }

    /**
     * Funciton to update ISR address and bank info
     * @param fields
     */
    updateISRDetails = (fields)=>{
         this.setState({
            updateISRAddressLoader: this.state.editAddressFlag.display == 'flex' ? true :false,
            updateISRBankLoader: this.state.editAddressFlag.display == 'none' ? true :false,
        })
        let data = {
            address: fields.mailingAddress.trim(),
            address2: fields.mailingAddress2.trim(),
            bank_account_name: fields.bankAccountName.trim(),
            bank_account_number: fields.bankAccountNumber.trim(),
            bank_routing_number: fields.bankRoutingNumber.trim(),
            city: fields.city.trim(),
            state: fields.state,
            ssn: fields.ssn,
            zip: fields.zip
        }
        Helper.axiosInstance().put('isr/update-details', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        updateISRAddressLoader: false,
                        updateISRBankLoader: false,
                        mailingAddress: fields.mailingAddress,
                        mailingAddress2: fields.mailingAddress2,
                        bankAccountName: fields.bankAccountName,
                        bankAccountNumber: fields.bankAccountNumber,
                        bankRoutingNumber: fields.bankRoutingNumber,
                        city: fields.city,
                        state: fields.state,
                        ssn: fields.ssn,
                        zip: fields.zip,
                    });
                    let checkFlag = this.state.editAddressFlag.display == 'flex' ? true: false;
                    this.toggleEdit(checkFlag);
                }
                else {
                    const getErrors = response.data.error;
                    let errorMsg = [];
                    Object.keys(getErrors).forEach((key,value) =>{
                        errorMsg.push(getErrors[key]);
                    });
                    this.setState({ showError: true, errorMessage: errorMsg.join(' '), updateISRAddressLoader: false, updateISRBankLoader:false});
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, updateISRAddressLoader: false,updateISRBankLoader:false });
            });
    }

    /**
     * function to set value on change state dropdown
     * @param event
     * @param setFieldValue
     */

    onSelectStateChange = (event, setFieldValue) => {
        setFieldValue("state", event.target.value);
    }

    render() {
        const {showLoader, kidPhoto, kidPicLoader, updateNameLoader, first_name, last_name, userName, email, role, kidsList,
            secondary_email_list, addGuardianList, phone, phoneCode} = this.state;
        const {
            disableDiv: divStyle, editNameFlag: editNameStyle, updateNameFlag: updateNameStyle, countSecEmailFlag: countSecEmailStyle, errorEmailFlag: errorEmail,
            errorPasswordFlag: errorPassword, editPasswordFlag: changePasswordDiv, updatePasswordFlag: hidePasswordDiv, addRowStyle: rowStyle, updateAddressFlag: hideAddressDiv,updateBankInfoFlag
        } = this.state;

        var editSecEmailStyle = this.state.editSecEmail ? { display: 'none' } : {};
        var updateSecEmailStyle = this.state.updateSecEmail ? { display: 'none' } : {};
        var editKidStyle = this.state.editKidFlag ? { display: 'none' } : {};
        var updateKidStyle = this.state.updateKidFlag ? { display: 'none' } : {};

        const { show } = this.state;
        const createModalHeader = (
            <>
                <Modal.Title>Invite {Enum.LegalGuardian} </Modal.Title>
            </>
        );

        const createModalContent = (
            <>
                <div className="mdl-topbar">
                    <Image src={kidPhoto} alt="Photo" width="45" /><span>You can assign upto {Enum.MaxLgPerStudent} {Enum.LegalGuardian}s to {addGuardianList.first_name} {addGuardianList.last_name}</span>
                </div>
                <Modal.Body>
                    {this.state.isAdded ?
                        this.state.countLG.display === 'none' ?
                            <Alert variant='warning' className="mt-4">
                                You have already added maximum number of {Enum.LegalGuardian}s. You can not add more now.
                                    </Alert>
                            :
                            <Formik
                                ref={(ref) => this.formik = ref}
                                enableReinitialize
                                initialValues={{
                                    gfirstName: '',
                                    glastName: '',
                                    gemailId: '',
                                    gPhone: '',
                                    gPhoneCode: '+1',
                                }}
                                validationSchema={Yup.object().shape({
                                    gfirstName: Yup.string().trim()
                                        .max(50, Messages.MAX_LENGTH_50)
                                        .required(Messages.REQUIRED_ERR),
                                    glastName: Yup.string().trim()
                                        .max(50, Messages.MAX_LENGTH_50)
                                        .required(Messages.REQUIRED_ERR),
                                    gemailId: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .email(Messages.INVALID_EMAIL)
                                        .max(100, Messages.MAX_LENGTH_100),
                                    gPhone: Yup.string().trim()
                                        .matches(Regex.phone, {
                                            message: Messages.INVALID_PHONE,
                                            excludeEmptyString: false
                                        })
                                        .max(15, Messages.INVALID_PHONE)
                                        .required(Messages.REQUIRED_ERR),
                                    gPhoneCode: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR),
                                })}
                                onSubmit={fields => {
                                    this.inviteNewGuardian(fields, addGuardianList.id);
                                }}
                                render={({ errors, status, touched, setFieldValue, values }) => (
                                    <Form className="contact-us" noValidate>
                                        <>
                                            <div className="d-flex flex-row">
                                                <div className="ml-left">
                                                    <Image src={Images.inviteContact} width="100" alt="Team" />
                                                </div>
                                                <div className="ml-right">
                                                    <div className="field form-group">
                                                        <Field name="gfirstName" type="text" id="gfirstName" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.gfirstName && touched.gfirstName ? ' is-invalid' : '')} />
                                                        <label htmlFor="gfirstName" className="form-label">First Name</label>
                                                        <ErrorMessage name="gfirstName" component="div" className="invalid-feedback" />
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="glastName" type="text" id="glastName" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.glastName && touched.glastName ? ' is-invalid' : '')} />
                                                        <label htmlFor="glastName" className="form-label">Last Name</label>
                                                        <ErrorMessage name="glastName" component="div" className="invalid-feedback" />
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="gemailId" type="text" id="gemailId" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.gemailId && touched.gemailId ? ' is-invalid' : '')} />
                                                        <label htmlFor="gemailId" className="form-label">Email Id</label>
                                                        <ErrorMessage name="gemailId" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="field form-group">
                                                        {/*<Field name="gPhone" type="text" id="gPhone" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.gPhone && touched.gPhone ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="gPhone" component="div" className="invalid-feedback" />*/}
                                                        <PhoneInput
                                                            parent={this}
                                                            codeField={'gPhoneCode'}
                                                            defaultCode={'+1'}
                                                            phoneField={'gPhone'}
                                                            fieldLabel={'Phone Number'}
                                                            errors={errors}
                                                            touched={touched}
                                                            serverValid={false}
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <Button className="ripple align-self-center btn-cancel" disabled={this.state.inviteLoader} onClick={this.hideModal}> Cancel </Button>
                                                        <Button type="submit" disabled={this.state.inviteLoader} className={`btn-primary  btn-medium ripple align-self-center btn__sendic btn ${this.state.inviteLoader ? 'btn-loader' : ''}`} >
                                                            <div className="btn__icon btn__icon--red"><Image src={Images.SendIc} alt="sned invite" /> </div>
                                                            Send Invite <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Form >
                                )
                                }
                            />
                        :
                        <div className="text-center">
                            <div className=" success-msg">
                                <Image src={Images.SuccessTick} alt="Success" />
                                <p>Invite Sent Successfully</p>
                            </div>
                            {
                                this.state.countLG.display === 'none' ?
                                    <Alert variant='warning' className="mt-4">
                                        You cannot add more guardians. Maximum {Enum.MaxLgPerStudent} guardians can be assigned to a {Enum.Student}.
                                    </Alert>
                                    :
                                    <Button className="mt-4 ripple btn-medium d-inline-flex align-items-center btn btn-primary" onClick={this.addMoreGuardian}>
                                        <div className="btn__icon btn__icon--red">
                                            <Image src={Images.AddMemberIc} alt="Complete" />
                                        </div>
                                        Add More
                             </Button>

                            }


                        </div>
                    }
                    <div className="mdl-table-outer">
                        <h3>{Enum.LegalGuardian}s <span>({this.state.editGuardianList.length})</span></h3>
                        {this.state.editGuardianList.map((row, i) => {
                            let editGuardianStyle = row.editGuardianStyle.display === 'none' ? { display: 'none' } : { display: 'table-row' };
                            let updateGuardianStyle = row.updateGuardianStyle.display === 'none' ? { display: 'none' } : { display: 'table-row' };
                            let editRowLoader = row.editGuardianLoader;
                            let formId = 'formId' + i, phoneCode=row.data.phone_code ? row.data.phone_code : '+1';
                            return (
                                <Formik
                                    key={formId}
                                    ref={(ref) => this.formik = ref}
                                    enableReinitialize
                                    initialValues={{
                                        fName: row.data.first_name,
                                        lName: row.data.last_name,
                                        emailId: row.data.email,
                                        phone: row.data.phone ? row.data.phone : '',
                                        phoneCode: phoneCode,
                                        inviteId: row.data.invite_id !== undefined ? row.data.invite_id : ''
                                    }}
                                    validationSchema={Yup.object().shape({
                                        fName: Yup.string().trim()
                                            .max(50, Messages.MAX_LENGTH_50)
                                            .required(Messages.REQUIRED_ERR),
                                        lName: Yup.string().trim()
                                            .max(50, Messages.MAX_LENGTH_50)
                                            .required(Messages.REQUIRED_ERR),
                                        emailId: Yup.string().trim()
                                            .max(150, Messages.MAX_LENGTH_150)
                                            .email(Messages.INVALID_EMAIL)
                                            .required(Messages.REQUIRED_ERR),
                                        phone: Yup.string().trim()
                                            .matches(Regex.phone, {
                                                message: Messages.INVALID_PHONE,
                                                excludeEmptyString: false
                                            })
                                            .max(15, Messages.INVALID_PHONE)
                                            .required(Messages.REQUIRED_ERR),
                                        phoneCode: Yup.string().trim()
                                            .required(Messages.REQUIRED_ERR),
                                    })}
                                    onSubmit={fields => {
                                        this.editGuardianDetails(fields, addGuardianList.id, i);
                                    }}
                                    render={({ errors, status, touched, setFieldValue, values }) => (
                                        <Form noValidate>
                                            <Table className="mdl-table" >
                                                <tbody>
                                                    <tr style={editGuardianStyle}>
                                                        <td>
                                                            <Media>
                                                                <Image
                                                                    className="mr-2 im-rounded"
                                                                    src={row.data.photo}
                                                                    alt={row.data.first_name}
                                                                />
                                                                {
                                                                    row.data.invite_accepted ?
                                                                        <Media.Body className="align-self-center">
                                                                            <h5>{row.data.first_name} {row.data.last_name} </h5>
                                                                        </Media.Body>
                                                                        :
                                                                        <Media.Body className="align-self-center">
                                                                            <h5>
                                                                                <span>{row.data.first_name} {row.data.last_name} </span>
                                                                                <Button type="reset" className="btn--reset add-linkbtn" onClick={() => this.toggleGuardianEmail(i)}>
                                                                                    <Image className="edit-icon" src={Images.EditBlue} alt="Edit" />
                                                                                </Button>
                                                                            </h5>
                                                                        </Media.Body>
                                                                }
                                                            </Media>
                                                        </td>
                                                        <td>
                                                            {row.data.email}
                                                        </td>
                                                        <td className="text-right">
                                                            {row.data.invite_accepted ? null :
                                                                this.state.resendInviteLoader.show && this.state.resendInviteLoader.index === i ?
                                                                    <span className="badge-resend btn-loader badge--disabled">
                                                                        Resend<span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    </span> :
                                                                    <span className="badge-resend" onClick={() => this.resendEmail(row.data.invite_id, addGuardianList.id, i)}>
                                                                        Resend
                                                                     </span>
                                                            }
                                                            {row.data.invite_accepted ? null :
                                                                <span className="badge-pndg">Pending</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr style={updateGuardianStyle}>
                                                        <td>
                                                            <div className="field form-group mb-0 mt-2">
                                                                <Field name="fName" type="text" id={"fNameLG" + i} onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.fName && touched.fName ? ' is-invalid' : '')} />
                                                                <label htmlFor="fName" className="form-label label-active">First Name</label>
                                                                <ErrorMessage name="fName" component="div" className="invalid-feedback" />
                                                            </div>
                                                            <div className="field form-group mb-2 mt-3">
                                                                <Field name="lName" type="text" id={"lNameLG" + i} onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.lName && touched.lName ? ' is-invalid' : '')} />
                                                                <label htmlFor="lName" className="form-label label-active">Last Name</label>
                                                                <ErrorMessage name="lName" component="div" className="invalid-feedback" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="field form-group mb-0 mt-2">
                                                                <Field name="emailId" type="email" id={"emailId" + i} onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.emailId && touched.emailId ? ' is-invalid' : '')} />
                                                                <label htmlFor="emailId" className="form-label label-active">Email Id</label>
                                                                <ErrorMessage name="emailId" component="div" className="invalid-feedback" />
                                                            </div>
                                                            <div className="field form-group mb-2 mt-3">
                                                                <PhoneInput
                                                                    parent={this}
                                                                    codeField={'phoneCode'}
                                                                    i={i}
                                                                    defaultCode={phoneCode}
                                                                    phoneField={'phone'}
                                                                    fieldLabel={'Phone Number'}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    serverValid={false}
                                                                    setFieldValue={setFieldValue}
                                                                    values={values}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <>
                                                                <Button disabled={editRowLoader} className={`${editRowLoader ? 'btn-loader' : ''}`} type="submit">
                                                                    <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                </Button>
                                                                <Button disabled={editRowLoader} type="reset" onClick={() => this.toggleGuardianEmail(i)}>
                                                                </Button>
                                                            </>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Form>
                                    )} />
                            )
                        })
                        }
                    </div>
                </Modal.Body>
            </>
        );
        return (
            <>
                <div className="fixed-topbar">
                    Please enter your second Student details <span>Got It</span>
                </div>
                <div className="rgt-content profile-main-wrapper">
                    <Row className="row--space">
                        <Col sm="12">
                            <h1>My Profile</h1>
                        </Col>
                        <Col md={5} lg={3} className="profileim-box">
                            <div className="info-box info-box--profile mt-4">
                                <div>
                                    {showLoader ? <div className="smloader-min-height"><SmallLoader /></div> :
                                        <div className="info-box info-box--profile mt-4">
                                            <div className="info-box__header">
                                                <div className="info-box__uplaod">
                                                    <input type='file' accept="image/jpg,image/jpeg" onChange={this.handlePhotoChange} />
                                                    <div><Image src={Images.CameraIcRed} alt="camera" width="24" /></div>
                                                </div>
                                                <Image src={this.state.file.imagesrc} name="photo" alt={first_name} />
                                            </div>
                                        </div>
                                    }
                                    <div className="info-box__content" style={editNameStyle}>
                                        <div className="content__rgt-col content__rgt-col--position">Recommended dimensions of 339 x 339 pixels</div>
                                        <h4>
                                            <span>{first_name ? first_name : null} {last_name ? last_name : null}
                                                <span className="info-box--profile-username">@{userName}</span>
                                                <span className="info-box--profile-username">Tel: {phone ? `${phoneCode} ${phone}` : 'NA'}</span>
                                            </span>

                                            <div className="add-linkbtn" onClick={this.updateNameDiv}>
                                                <Image className="edit-icon" src={Images.EditBlue} alt="Edit" />
                                            </div>
                                        </h4>
                                        <p>{Helper.memberRole(role)}</p>
                                    </div>
                                </div>
                                <div className="info-box__content" style={updateNameStyle}>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            firstName: first_name,
                                            lastName: last_name,
                                            phone: phone,
                                            phoneCode: phoneCode,
                                        }}
                                        validationSchema={Yup.object().shape(
                                            role && role.includes(Enum.UserRoles.ATHLETE) ?
                                            {
                                                firstName: Yup.string().trim()
                                                    .max(50, Messages.MAX_LENGTH_50)
                                                    .required(Messages.REQUIRED_ERR),
                                                lastName: Yup.string().trim()
                                                    .max(50, Messages.MAX_LENGTH_50)
                                                    .required(Messages.REQUIRED_ERR),
                                                phone: Yup.string().trim()
                                                    .matches(Regex.phone, {
                                                        message: Messages.INVALID_PHONE,
                                                        excludeEmptyString: false
                                                    })
                                                    .max(15, Messages.INVALID_PHONE)
                                            }
                                            : {
                                                firstName: Yup.string().trim()
                                                    .max(50, Messages.MAX_LENGTH_50)
                                                    .required(Messages.REQUIRED_ERR),
                                                lastName: Yup.string().trim()
                                                    .max(50, Messages.MAX_LENGTH_50)
                                                    .required(Messages.REQUIRED_ERR),
                                                phone: Yup.string().trim()
                                                    .matches(Regex.phone, {
                                                        message: Messages.INVALID_PHONE,
                                                        excludeEmptyString: false
                                                    })
                                                    .max(15, Messages.INVALID_PHONE)
                                                    .required(Messages.REQUIRED_ERR),
                                                phoneCode: Yup.string().trim()
                                                    .required(Messages.REQUIRED_ERR),
                                            }
                                        )}
                                        onSubmit={fields => {
                                            this.updateName(fields);
                                        }}
                                        render={({ errors, status, touched, setFieldValue, values }) => (
                                            <Form noValidate>
                                                <div>
                                                    <div className="field form-group">
                                                        <Field name="firstName" type="text" id="firstName" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                                        <label htmlFor="firstName" className="form-label label-active">First Name</label>
                                                        <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="lastName" type="text" id="lastName" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                                        <label htmlFor="lastName" className="form-label label-active">Last Name</label>
                                                        <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className={'field form-group'}>
                                                        <PhoneInput
                                                            parent={this}
                                                            codeField={'phoneCode'}
                                                            defaultCode={'+1'}
                                                            phoneField={'phone'}
                                                            fieldLabel={'Phone Number'}
                                                            errors={errors}
                                                            touched={touched}
                                                            serverValid={false}
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                        />
                                                    </div>
                                                    <p className="mb-4">{Helper.memberRole(role)}</p>
                                                    <div>
                                                        <Button type="Submit" disabled={updateNameLoader} className={`btn btn-medium mb-2 d-inline-flex align-items-center mr-4 ${updateNameLoader ? 'btn-loader' : ''}`}>
                                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="btn" /></div>
                                                            Save <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </Button>
                                                        <Button type="reset" disabled={updateNameLoader} className="btn-cancel ripple mr-0 mb-2" onClick={this.updateNameDiv}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={9} className="md--full-width">
                            <div className="widget">
                                <div className="slab-header">
                                    <div className="slab-header__heading">My Contact <span className="slab-header__heading__subheading">You can add upto 5 email adresses</span></div>
                                </div>
                                <div className="slab-content pb-0">
                                    <div className="slab-content__block">
                                        <div className="label-group">
                                            <label>Email ID :</label>
                                            <span>{email}</span>
                                            <i className="mail-group__badge">Primary</i>
                                        </div>
                                    </div>
                                </div>
                                <>
                                    {secondary_email_list ?
                                        <>
                                            <div className="slab-content col-sm-12">
                                                <SecEmailLists data={this.state.newSecEmailList}
                                                    handleEditEmail={(id, i) => this.editSecondaryEmail(id, i)}
                                                    handleEmailStyle1={editSecEmailStyle}
                                                    handleEmailStyle2={updateSecEmailStyle}
                                                    handleUpdateSecondaryEmail={(fields, rowId, i) => this.updateSecondaryEmail(fields, rowId, i)}
                                                    handleDeleteEmail={(rowId, index) => this.onDeleteEmailClick(rowId, index)}
                                                    updateSecEmailLoader={this.state.updateSecEmailLoader}
                                                    inputFocus={this.inputFocus}
                                                    inputBlur={this.inputBlur}
                                                />
                                            </div>
                                        </>
                                        : null}

                                </>
                                <div style={divStyle} className="slab-content col-sm-8">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            secEmail: '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            secEmail: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .email(Messages.INVALID_EMAIL)
                                                .required(Messages.REQUIRED_ERR),
                                        })}
                                        onSubmit={(fields, props) => { this.saveSecondaryEmail(fields, props); }}
                                        render={({ errors, status, touched }) => (
                                            <Form noValidate>
                                                <div className="field form-group">
                                                    <Field name="secEmail" type="text" id="secEmail" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.secEmail && touched.secEmail ? ' is-invalid' : '')} />
                                                    <label htmlFor="secEmail" className="form-label">Email Address</label>
                                                    <ErrorMessage name="secEmail" component="div" className="invalid-feedback" />
                                                </div>
                                                <Alert variant="danger" style={errorEmail}>
                                                    <p className="mb-0">
                                                        Email id already exist.
                                                    </p>
                                                </Alert>
                                                <Button type="submit" disabled={this.state.addSecEmailLoader} className={`ripple btn-medium d-inline-flex align-items-center ${this.state.addSecEmailLoader ? 'btn-loader' : ''}`}>
                                                    <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="create Team" /></div>
                                                    Save <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </Button>
                                                <Button type="reset" className="btn-cancel ripple" disabled={this.state.addSecEmailLoader} onClick={this.addSecondaryEmail}>
                                                    Cancel</Button>
                                            </Form>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-12 mb-4" style={countSecEmailStyle}>
                                    <div style={rowStyle} className="add-linkbtn" onClick={this.addSecondaryEmail}>
                                        <Image className="edit-icon" src={Images.PlusIc} /> <span className="add-linkbtn__inner">Add another email address</span>
                                    </div>
                                </div>
                                {kidsList || this.state.kidGuardians ?
                                    <div className={ this.state.newkidList.length>0 ?'slab-header':''}>
                                        {
                                            this.state.isKidLogin ?
                                                <div className="slab-header__heading">{Enum.LegalGuardian} Details</div> :
                                                this.state.newkidList.length>0 && <div className="slab-header__heading">{Enum.Student} Details</div>
                                        }

                                    </div>
                                    : null}
                                <div className="slab-content">
                                    <div className="row">
                                        {
                                            this.state.isKidLogin ?
                                                <GuardianList
                                                    first_name={this.state.first_name}
                                                    last_name={this.state.last_name}
                                                    photo={this.state.file.imagesrc}
                                                    email={this.state.email}
                                                    data={this.state.kidGuardians} />
                                                :
                                                <KidsList
                                                    data={this.state.newkidList}
                                                    handleEditKid={(kidId) => this.editKidDetails(kidId)}
                                                    handleKidStyle1={editKidStyle}
                                                    handleKidStyle2={updateKidStyle}
                                                    kidPhotoChange={(event, index, kidId) => this.editKidPhotoChange(event, index, kidId)}
                                                    kidPicLoader={kidPicLoader}
                                                    kidDetailLoader={this.state.kidDetailLoader}
                                                    updateKidDetails={(fields, index, kidId) => this.updateKidDetails(fields, index, kidId)}
                                                    handleShow={(index) => this.handleGuardianShow(index)}
                                                    editKidDetailsLoader={this.state.editKidDetailsLoader}
                                                    inputFocus={this.inputFocus}
                                                    inputBlur={this.inputBlur}
                                                    serverValid={this.state.serverValid}
                                                />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Formik
                                        ref={(ref) => this.formik = ref}
                                        initialValues={{
                                            currentPass: '',
                                            password: '',
                                            confirmPassword: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            currentPass: Yup.string()
                                                .required(Messages.REQUIRED_ERR),
                                            password: Yup.string()
                                                .max(50, Messages.MAX_LENGTH_50)
                                                .min(8, Messages.MIN_LENGTH_8)
                                                .required(Messages.REQUIRED_ERR)
                                                .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                                            confirmPassword: Yup.string()
                                                .required(Messages.REQUIRED_ERR)
                                                .oneOf([Yup.ref('password'), null], Messages.PASSWORD_CPASS_MATCH_ERR)
                                        })}
                                        onSubmit={fields => {
                                            this.changePassword(fields);
                                        }}
                                        render={({ errors, status, touched }) => (
                                            <Form noValidate>
                                                <div className="slab-header">
                                                    <div className="slab-header__heading">Change Password</div>
                                                    <div className="add-linkbtn" style={hidePasswordDiv} onClick={this.handleChangePassword}>
                                                        <Image src={Images.EditBlue} /> <i>Change Password</i>
                                                    </div>
                                                </div>
                                                <div className="slab-content row">
                                                    <div className="col-sm-6 mt-2">
                                                        <div className="field form-group">
                                                            <Field name="currentPass" disabled={(this.state.disabled) ? "disabled" : ""} type="password" id="currentPass" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.currentPass && touched.currentPass ? ' is-invalid' : '')} />
                                                            <label htmlFor="currentPass" className="jsRemoveLabelActive1 form-label">Current Password</label>
                                                            <ErrorMessage name="currentPass" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="field form-group">
                                                            <Field name="password" disabled={(this.state.disabled) ? "disabled" : ""} type="password" id="password" onFocus={this.inputFocus} onBlur={this.inputBlur} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                            <label htmlFor="password" className="jsRemoveLabelActive2 form-label">New Password</label>
                                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="field form-group">
                                                            <Field name="confirmPassword" disabled={(this.state.disabled) ? "disabled" : ""} id="confirmPassword" onFocus={this.inputFocus} onBlur={this.inputBlur} type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                            <label htmlFor="confirmPassword" className="jsRemoveLabelActive3 form-label">Confirm Password</label>
                                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div>
                                                            <Alert variant="danger" style={errorPassword}>
                                                                <p className="mb-0">
                                                                    The current password is incorrect.
                                                                        </p>
                                                            </Alert>
                                                        </div>
                                                        <div style={changePasswordDiv} >
                                                            <Button type="Submit" disabled={this.state.changePasswordLoader} className={`btn ripple btn-medium d-inline-flex align-items-center ${this.state.changePasswordLoader ? 'btn-loader' : ''}`}>
                                                                <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="btn" /></div>
                                                                Save <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            </Button>
                                                            <Button type="reset" disabled={this.state.changePasswordLoader} className="btn-cancel ripple" onClick={this.handleChangePassword}>Cancel</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </div>

                                <div>
                                    <ISRAddress
                                        ISRDetail={this.state.ISRDetail}
                                        toggleEdit={(isAddress = true)=>this.toggleEdit(isAddress)}
                                        isrAddressFieldDisabled={this.state.isrAddressFieldDisabled}
                                        isrBankInfoFieldDisabled={this.state.isrBankInfoFieldDisabled}
                                        inputFocus={this.inputFocus}
                                        inputBlur={this.inputBlur}
                                        editAddressFlag={this.state.editAddressFlag}
                                        editBankInfoFlag={this.state.editBankInfoFlag}
                                        updateISRAddressLoader={this.state.updateISRAddressLoader}
                                        updateISRBankLoader={this.state.updateISRBankLoader}
                                        cancelAddressClick={this.cancelAddressClick}
                                        cancelBankInfoClick={this.cancelBankInfoClick}
                                        updateISRDetails={this.updateISRDetails}
                                        onSelectStateChange={this.onSelectStateChange}
                                        updateAddressFlag={hideAddressDiv}
                                        updateBankInfoFlag={updateBankInfoFlag} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.deleteConfirmShow}
                    onHide={this.onDeleteConfirmCancel}
                    onCancelClick={this.onDeleteCancelClick}
                    onConfirmClick={this.onDeleteConfirmClick}
                    confirmText={"Do you want to delete this email address?"}
                    confirmButtonText={"Delete"}
                    modalLoader={this.state.modalLoader}
                />
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"}
                />
                <ModalBox
                    size="lg"
                    show={show}
                    onHide={this.hideModal}
                    animation={true}
                    modalheader={createModalHeader}
                    modalcontent={createModalContent}
                >
                </ModalBox>
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                ></ErrorModal>
            </>
        )
    }
}

export { Profile };
