/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/* Start - app imports */
import './invite.scss';
import { Months } from '../../components/common/months';
/* End - app imports */

class InviteReview extends Component {
    
    render() {
        const { guardian_first_name, guardian_last_name, guardian_email, guardian_username, guardian_phone, user_first_name, user_middle_name, user_last_name, user_email, user_username,  user_birth_day,
        user_birth_month, user_phone, team, isKidChecked } = this.props.inviteDetails;
        const { handlePrevClick, handleNextClick, formProcessing } = this.props;

        return (
            <>
                <div className="step-form-details">
                    <div className="block-space">
                        <h3>Account Information</h3>
                        <div className="disp-inline">
                            <label>Name:</label><span>{guardian_first_name} {guardian_last_name}</span>
                        </div>
                        <div className="disp-inline">
                            <label>Email:</label><span>{guardian_email}</span>
                        </div>
                        <div className="disp-inline">
                            <label>Username:</label><span>{guardian_username}</span>
                        </div>
                        <div className="disp-inline">
                            <label>Phone Number:</label><span>{guardian_phone}</span>
                        </div>
                        <div className="disp-inline">
                            <label>Password:</label><span>*********</span>
                        </div>

                        <h3 className="mt-4">Student's Information</h3>

                        <div className="disp-inline">
                            <label>Name:</label><span>{user_first_name} {user_middle_name}  {user_last_name}</span>
                        </div>
                        {
                            (team && team.name) &&
                                <div className="disp-inline">
                                    <label>Member Group:</label><span>{team.name}</span>
                                </div>
                        }
                        {
                            (user_email&& !isKidChecked ) &&
                                <div className="disp-inline">
                                    <label>Email:</label><span>{user_email}</span>
                                </div>
                        }
                        {
                            (user_username && !isKidChecked ) &&
                            <div className="disp-inline">
                                <label>Username:</label><span>{user_username}</span>
                            </div>
                        }
                        <div className="disp-inline">
                            <label>Date of Birth:</label><span>{ (user_birth_day && user_birth_month) ? `${user_birth_day} ${Months[user_birth_month]}` : "-"} </span>
                        </div>
                        {
                            user_phone &&
                                <div className="disp-inline">
                                    <label>Phone Number:</label><span>{user_phone}</span>
                                </div>
                        }
                        <div className="disp-inline">
                            <label>Password:</label><span>*********</span>
                        </div>
                    </div>

                    <div className="footer-buttons">
                        <button type="button" className="btn btn-next btn-primary btn-lg pull-left" onClick={handlePrevClick} disabled={formProcessing ? true : false}>Previous</button>
                        
                        <button type="button" className={"btn btn-prev btn-primary btn-lg float-right" + (formProcessing ? " btn-loader" : "")} onClick={handleNextClick} disabled={formProcessing ? true : false}>Finish <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ 'display': 'none' }}></span></button>
                    </div>
                </div>
            </>
        )
    }
}
export { InviteReview };