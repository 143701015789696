/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Form, Row, Col, Image} from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import './club.scss';

/**
 * Start - React Google recaptcha
 * Name and Version: react-bootstrap, 2.0.1
 * Download link: https://www.npmjs.com/package/react-google-recaptcha
 */
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleRecaptchaKey} from '../../components/common/enum'
import * as Helper from '../../components/common/functions';

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            isReChecked: false

        };
        this.isValidated = this.isValidated.bind(this);
        this.editStep = this.editStep.bind(this);
        this.finishButton = null;
    }

    componentDidMount() {
        this.finishButton = document.getElementById('next-button');
        setTimeout(() => {
            this.finishButton.innerHTML = ' Finish <span style="display: none;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
        }, 1000);
    }

    isValidated() {
        if (this.isReChecked || this.props.isLoggedIn) {
            this.finishButton.classList.add('btn-loader');
            this.finishButton.disabled = true;
            this.props.onCreateClubFinishSubmit();
        } else {
            this.setState({showDiv: true});
        }
        return false;
    }

    onChangeRe = (value) => {
        if (value) {
            this.isReChecked = true;
            this.props.setRecaptchaToken(value);
            this.setState({showDiv: false});
        }
    }

    editStep(step) {
        this.props.jumpToStep(step)
    }

    render() {
        //console.log(this.props.jumpToStep(0))
        let generalInfo = this.props.generalInfo, address = this.props.address, poc = this.props.pointOfContact;

        return (
            <>
                <Row className="steps-content">
                    <Col sm={12} md={6} lg={5}>
                        <div className="info-box info-box--no-shadow">
                            <div className="info-box__header info-box__header--org">
                                <Image src={this.props.photo.url ? this.props.photo.url : Images.DefaultLogo}/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={7}>
                        <Form>
                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Organization Information</span>
                                    <a onClick={() => this.editStep(0)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>Organization Name:</label><span>{generalInfo.clubName}</span>
                            </div>
                            {
                                this.props.isSuperAdmin || !this.props.isLoggedIn
                                    ? <>
                                        <div className="disp-inline">
                                            <label>Admin First Name:</label><span>{generalInfo.adminFirstName}</span>
                                        </div>
                                        <div className="disp-inline">
                                            <label>Admin Last Name:</label><span>{generalInfo.adminLastName}</span>
                                        </div>
                                        <div className="disp-inline">
                                            <label>Admin Email:</label><span>{generalInfo.adminEmail}</span>
                                        </div>
                                        {
                                            !this.props.isSuperAdmin &&
                                            <div className="disp-inline">
                                                <label>Admin Username:</label><span>{generalInfo.adminUsername}</span>
                                            </div>
                                        }
                                        <div className="disp-inline">
                                            <label>Admin
                                                Phone:</label><span>{generalInfo.adminPhoneCode} {generalInfo.adminPhone}</span>
                                        </div>
                                    </>
                                    : null
                            }
                            <div className="disp-inline disp-inline--break-all">
                                <label>Organization Website:</label><span>{generalInfo.orgWebsite}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Mailing
                                    Address:</label><span>{`${address.mailAddress}${address.mailAddress2 && `, ` + address.mailAddress2}, ${address.mailCity}, ${address.mailState}, ${address.mailZip}`}</span>
                            </div>

                            <div className="disp-inline">
                                <label>Billing Address:</label>
                                <span>{`${address.billAddress}${address.billAddress2 && `, ` + address.billAddress2}, ${address.billCity}, ${address.billState}, ${address.billZip}`}</span>
                            </div>

                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Main Contact Information</span>
                                    <a onClick={() => this.editStep(2)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>First Name:</label><span>{poc.firstNameGeneral}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Last Name:</label><span>{poc.lastNameGeneral}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Phone:</label><span>{poc.phoneGeneralCode} {poc.phoneGeneral}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Email:</label><span>{poc.emailGeneral}</span>
                            </div>
                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Secondary Contact Information</span>
                                    <a onClick={() => this.editStep(2)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>First Name:</label><span>{poc.firstNameContact2}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Last Name:</label><span>{poc.lastNameContact2}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Phone:</label><span>{poc.phoneContact2Code} {poc.phoneContact2}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Email:</label><span>{poc.emailContact2}</span>
                            </div>
                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Billing Contact Information</span>
                                    <a onClick={() => this.editStep(2)} href="#!"
                                       className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>First Name:</label><span>{poc.firstNameBilling}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Last Name:</label><span>{poc.lastNameBilling}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Phone:</label><span>{poc.phoneBillingCode} {poc.phoneBilling}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Email:</label><span>{poc.emailBilling}</span>
                            </div>
                            {generalInfo.additionalInformation.trim() &&
                            <>
                                <div className="disp-inline">
                                    <div
                                        className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                        <span>Additional Information</span>
                                        <a onClick={() => this.editStep(0)} className="block-heading__edit">Edit</a>
                                    </div>
                                </div>
                                <div className="disp-inline">
                                    <span>{generalInfo.additionalInformation}</span>
                                </div>
                            </>
                            }

                            {!this.props.isLoggedIn &&
                            <>
                                <div className="captcha-label">
                                    Please tick the box to continue:
                                </div>

                                <ReCAPTCHA
                                    sitekey={GoogleRecaptchaKey}
                                    onChange={this.onChangeRe}
                                />
                            </>
                            }
                            <div className="disp-inline">
                                {(this.state.showDiv) ?
                                    <p style={{color: "#dc3545"}} className="invalid-feedback">Please confirm that you
                                        are not a robot</p> : ''}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }
}

export {Review};
