/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Container, Image, Form } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import * as Enum from '../../components/common/enum';
import { Messages } from '../../components/common/message';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import * as Images from '../../components/common/images';
import { InviteMessages } from './invite-message';
import {Footer} from '../../components/footer/footer';
import Loader from '../../components/loader/loader';
import './invite.scss';
import { RegisterSuccess, clubTeamName } from './invite-helper';

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.match.params.token,
            uId: props.match.params.uId,
            successMsg: "",
            verifiedUserRole: null,
            errorMsg: "",
            loading: false,
            message: ""
        };
    }

    componentDidMount() {
        this.validateInvite();
    }

    validateInvite() {
        const { token, uId } = this.state;
        this.setState({ loading: true }
            , () => {
                Helper.axiosInstance().post(`link/email`, {
                    invite_code: token,
                    user_id: uId
                })
                    .then((response) => {
                        if (response.data.success) {
                            const data = response.data.data;
                            if (data.role_id === Enum.UserRoles.COACH) {
                                this.props.history.push(`/invite/${token}/true`)
                            }
                            else {
                                const name = clubTeamName(data);
                                let msg = InviteMessages.LINK_VERIFICATION_SUCCESS;
                                msg = msg.replace("{@username}", data.username);
                                msg = msg.replace("{@organizationAndMembergroup}", name);
                                this.setState({ successMsg: msg, verifiedUserRole: data.role_id });
                            }
                        } else {
                            this.setState({ errorMsg: Messages.SERVER_ERROR })
                        }
                    })
                    .catch((error) => {
                        this.setState({ errorMsg: Messages.SERVER_ERROR });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        );
    }

    render() {
        const { errorMsg, loading, successMsg, token, verifiedUserRole } = this.state;
        return (
            <Container>
                <Loader loading={loading} />
                {(errorMsg !== "" || verifiedUserRole === Enum.UserRoles.LEGAL_GUARDIAN) ?
                    <VerificationSuccess
                        message={successMsg}
                        token={token}
                        loading={loading}
                        errorMsg={errorMsg}
                    />
                    : <RegisterSuccess
                        registerMsg={successMsg}
                    />
                }

                <Footer />
            </Container>
        )
    }
}

const VerificationSuccess = (props) => {
    return (
        <div className="container">
            <Row className="content-space">
                <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                    <div className="widget">
                        {props.loading && <div className="loader-center"><SmallLoader /> </div>}
                        {
                            props.errorMsg !== "" ?
                                <div className="text-center py-5 px-3 confirmbox">
                                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                                    <h1 className="mt-3 mb-3">Error</h1>
                                    <p className="mb-4">{InviteMessages.LINK_INVALID_ERROR}</p>
                                    <Form>
                                        <Link to="#" onClick={Helper.handleSupportContact} className="btn--green btn justify-content-center">Contact Us</Link>
                                    </Form>
                                </div>
                                :
                                <>
                                    <div className="text-center success-msg success-msg--font-size">
                                        <Image src={Images.SuccessTick} alt="Success" />
                                        <p className="mb-4">{props.message}</p>
                                    </div>
                                    <div className="text-center d-flex flex-column">
                                        <h3 className="success-msg__heading mt-4">Proceed with the <Link to={`/invite/${props.token}`}>student registration.</Link></h3>
                                    </div>
                                </>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export { VerifyEmail };

