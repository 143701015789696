import {ErrorMessage, Field} from "formik";
import * as Helper from "../common/functions";
import React from "react";

export const SSNInput = (props)=>{
    return(
        <>
            <Field name={props.SSNField} type="text" >
                {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }) => (
                    <Helper.SSNMaskedInput
                        field={field}
                        disabled={props.disabled ? true : false}
                        onChange={(e)=>{
                            props.setFieldValue(props.SSNField, e.target.value);
                        }}
                        className={'form-control' + ((props.errors[props.SSNField] && props.touched[props.SSNField]) || (props.serverValid && props.serverValid[props.SSNField] !== '') ? ' is-invalid' : '')}
                        id={props.SSNField}
                        type={props.type}
                        guide={props.guide}
                    />
                )}
            </Field>
            <label htmlFor={props.SSNField} className={Helper.getLabelClass(props.values ? props.values[props.SSNField] : null)}>{props.fieldLabel}</label>
            {
                (props.errors[props.SSNField] && props.touched[props.SSNField]) ?
                    <ErrorMessage name={props.SSNField} component="div" className="invalid-feedback" />
                    :
                    <div className="invalid-feedback">{props.serverValid ? props.serverValid[props.SSNField] : ''}</div>
            }
        </>
    );
}
