//export const username = /^(?=.{1,50}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/
export const username1 = /^[^\-_].*[^\-_]$/
export const username2 = /^[a-zA-Z0-9-_]+$/
export const username3 = /^[a-zA-Z0-9_]+[\-]?[a-zA-Z0-9_]+$/
export const username4 = /^[a-zA-Z0-9\-]+[_]?[a-zA-Z0-9\-]+$/
export const username5 = /^(?!.*_\-|.*\-_).*$/
export const ssn = /^(?!000|666)[0-8][0-9]{2}-(?! 00)[0-9]{2}-(?!0000)[0-9]{4}$/
export const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const phone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
export const numbers = /^[0-9]+$/
