/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Button, Modal } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form as FormValid, ErrorMessage } from 'formik';
/* End - Formik */

import { ModalBox, SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import {PhoneInput} from "../../components/input/phone";
import * as Regex from "../../components/common/regex";

export default class EditPendingInstructor extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            inviteId: this.props.data.invite_id,
            user_first_name: this.props.data.user_first_name,
            user_last_name: this.props.data.user_last_name,
            user_email: this.props.data.user_email ? this.props.data.user_email : '',
            user_phone: this.props.data.user_phone ? this.props.data.user_phone : '',
            user_phone_code: this.props.data.user_phone_code ? this.props.data.user_phone_code : '',
            serverValid: {
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_phone: '',
                user_phone_code: '',
            },
            serverSuccess: '',
            serverError: '',
            formProcessing: false,
        }
    }

    updateInstructor = (fields) => {
        this.setState({ formProcessing: true });
        var data = {
            edit: 1,
            invite_id: this.state.inviteId,
            user_first_name: fields.user_first_name,
            user_last_name: fields.user_last_name,
            user_email: fields.user_email,
            user_phone: fields.user_phone,
            user_phone_code: fields.user_phone_code,
        }
        Helper.axiosInstance().post('invite/edit-resend', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ serverSuccess: response.data.message, formProcessing: false }, () => {
                        this.props.getPendingContacts();
                    });
                } else {
                    const getErrors = response.data.error;
                    let serverValid = this.state.serverValid;
                    // eslint-disable-next-line
                    for (let errorIndex in getErrors) {
                        if (getErrors[errorIndex][0] !== "") {
                            serverValid[errorIndex] = getErrors[errorIndex][0];
                        }
                    }
                    this.setState({ serverValid, formProcessing: false });
                }
            })
            .catch((error) => {
                this.setState({ serverError: error, formProcessing: false });
            });
    }

    resetGroupModal = () => {
        this.setState({ serverSuccess: '', serverValid: this.state.serverValid, formProcessing: false });
        this.props.toggleModal(!this.props.modalVisibility)
    }

    render() {
        const { modalVisibility } = this.props,
            { user_first_name, user_last_name, user_email, user_phone, user_phone_code, serverValid, formProcessing, serverSuccess, serverError } = this.state;
        const handleClose = () => this.resetGroupModal(),
            modalHeader = (
                <Modal.Title>Update Instructor</Modal.Title>
            ),
            modalContent = (
                <Formik
                    enableReinitialize
                    initialValues={{
                        user_first_name: user_first_name ? user_first_name : '',
                        user_last_name: user_last_name ? user_last_name : '',
                        user_email: user_email ? user_email : '',
                        user_phone: user_phone,
                        user_phone_code: user_phone_code,
                    }}
                    validationSchema={Yup.object().shape({
                        user_first_name: Yup.string().trim()
                            .required(Messages.REQUIRED_ERR)
                            .max(100, Messages.MAX_LENGTH_100),
                        user_last_name: Yup.string().trim()
                            .required(Messages.REQUIRED_ERR)
                            .max(100, Messages.MAX_LENGTH_100),
                        user_email: Yup.string().trim()
                            .max(150, Messages.MAX_LENGTH_150)
                            .email(Messages.INVALID_EMAIL)
                            .required(Messages.REQUIRED_ERR),
                        user_phone: Yup.string().trim()
                            .matches(Regex.phone, {
                                message: Messages.INVALID_PHONE,
                                excludeEmptyString: false
                            })
                            .max(15, Messages.INVALID_PHONE)
                            .required(Messages.REQUIRED_ERR),
                        user_phone_code: Yup.string().trim()
                            .required(Messages.REQUIRED_ERR),
                    })}
                    onSubmit={fields => {
                        this.updateInstructor(fields);
                    }}
                    render={({ errors, status, touched, setFieldValue, values }) => (
                        <FormValid noValidate>
                            <Modal.Body>
                                <div className="d-flex flex-row">
                                    <div className="ml-left">
                                        <Image src={Images.MemberGroup} width="70" alt="group" />
                                    </div>
                                    <div className="ml-right">
                                        <div className="field form-group">
                                            <Field name="user_first_name" type="text" id="coach_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_first_name && touched.user_first_name) || serverValid.user_first_name !== '' ? ' is-invalid' : '')} />

                                            <label htmlFor="coach_first_name" className="form-label label-active">Instructor First Name</label>
                                            {
                                                (errors.user_first_name && touched.user_first_name) ?
                                                    <ErrorMessage name="user_first_name" component="div" className="invalid-feedback" />
                                                    :
                                                    <div className="invalid-feedback">{serverValid.user_first_name}</div>
                                            }
                                        </div>
                                        <div className="field form-group">
                                            <Field name="user_last_name" type="text" id="coach_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_last_name && touched.user_last_name) || serverValid.user_last_name !== '' ? ' is-invalid' : '')} />

                                            <label htmlFor="coach_last_name" className="form-label label-active">Instructor Last Name</label>
                                            {
                                                (errors.user_last_name && touched.user_last_name) ?
                                                    <ErrorMessage name="user_last_name" component="div" className="invalid-feedback" />
                                                    :
                                                    <div className="invalid-feedback">{serverValid.user_last_name}</div>
                                            }
                                        </div>
                                        <div className="field form-group">
                                            <Field name="user_email" type="text" id="coach_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_email && touched.user_email) || serverValid.user_email !== '' ? ' is-invalid' : '')} />

                                            <label htmlFor="coach_email" className="form-label label-active">Instructor Email Address</label>
                                            {
                                                (errors.user_email && touched.user_email) ?
                                                    <ErrorMessage name="user_email" component="div" className="invalid-feedback" />
                                                    :
                                                    <div className="invalid-feedback">{serverValid.user_email}</div>
                                            }
                                        </div>
                                        <div className="field form-group">
                                            <PhoneInput
                                                parent={this}
                                                codeField={'user_phone_code'}
                                                defaultCode={user_phone_code ? user_phone_code : '+1'}
                                                phoneField={'user_phone'}
                                                fieldLabel={'Instructor Phone Number'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={serverValid}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="m-footer">
                                <div className="m-footer__btnwrap">
                                    <Button variant="secondary" className="ripple" disabled={formProcessing ? true : false} onClick={handleClose}>
                                        Cancel
                                         </Button>
                                    <Button type="Submit" disabled={formProcessing ? true : false} className={"btn-primary btn-medium ripple ml-auto align-self-center btn__sendic" + (formProcessing ? " btn-loader" : "")}>
                                        <div className="btn__icon btn__icon--red"><Image src={Images.SendIc} alt="Send Invite" /></div>
                                        <span className="align-self-center">Send Invite </span>
                                        <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </FormValid>
                    )}
                />
            );

        return (
            <>
                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                            onHide={handleClose}
                        />
                        :
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={modalVisibility}
                                onConfirmClick={Helper.handleErrorClose}
                                onHide={Helper.handleErrorClose}
                            />
                            :
                            <ModalBox
                                size="lg"
                                show={modalVisibility}
                                onHide={handleClose}
                                animation={true}
                                modalheader={modalHeader}
                                modalcontent={modalContent}
                            />
                }
            </>
        )
    }
}

export { EditPendingInstructor };