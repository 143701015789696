/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Dropdown, Image } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Storage from '../common/storage';
import * as Helper from '../common/functions';
import {ChatIc} from '../common/images';

export default class IndividualGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: '',
            userId: ''
        };
    }

    componentDidMount(){
        const userId = Storage.Get('user').id;
        this.setState({ userId});
    }

    getFirstName(name){
        const output = name.indexOf(" ").length !== -1 ? name.split(" ")[0] : name;
        return output.trim();
    }

    createGroupName(firstMem,secondMem){
        const groupName = `${firstMem.trim()}-${this.getFirstName(secondMem)}`;
        return groupName;
    }

    checkIsGroup(data){
        const {id, first_name, last_name, username, guardian} = Storage.Get('user');
        const {id: athleteId, name: athleteName, username: athleteUsername, guardian: memGuardian} = data.member;
        const me = this;

        this.props.toggleLoader(true);
        
        let memberUsername = [username, athleteUsername];

        // Add legal guardians of the Logged-In Athlete
        if(guardian.length > 0){
            // eslint-disable-next-line
            for (let val of guardian) {
                memberUsername.push(val);
            }
        }

        if(memGuardian && memGuardian.length > 0){
            // eslint-disable-next-line
            for (let key in memGuardian) {
                memberUsername = memberUsername.concat(memGuardian[key].username);
            }
        }
        const groupName = me.createGroupName(first_name ? first_name : last_name, athleteName);
        Helper.axiosInstance().post(`group/get-individual-id`, {
            member1_id: id,
            member2_id: athleteId,
            group_name: groupName,
            members: memberUsername
        })
        .then((response) => {
            if(response.data.chat_group_id !== null){
                // open the group, already exists
                me.props.superProps.setChatWithGroupId(response.data.chat_group_id);
                me.props.toggleLoader(false);
            }
        })
        .catch(function (error) {
            me.setState({ serverError: true });
            me.props.toggleLoader(false);
        });
    }

    render() {
        const {id, status} = this.props.member,
            userId = this.state.userId;
        return (
            <>
                {
                    userId && userId !== id && status ?
                        <>
                            <Dropdown.Item onClick={() => this.checkIsGroup(this.props)}>
                                <Image
                                    className="mr-2"
                                    src={ChatIc}
                                    alt="Porfirio"
                                    width="21"
                                />
                                <span>Chat</span>
                            </Dropdown.Item>
                        </>
                    :
                        null
                }
            </>
        );
    }
}