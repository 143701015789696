export const Get = (key) => (
    JSON.parse(localStorage.getItem(key))
);

export const Set = (key ,val) => (
    localStorage.setItem(key, val)
);

export const Delete = (key) => (
    localStorage.removeItem(key)
);