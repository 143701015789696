/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form, ErrorMessage} from 'formik';
/* End - Formik */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Dropdown, Table, Media, Image, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* End - React Bootstrap */

import {SuccessModal, ErrorModal, DeleteConfirmModal, ErrorAccessDeniedModal} from '../../components/modal/modalbox';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import * as Images from '../../components/common/images';
import {Messages} from '../../components/common/message';
import {TeamMessages} from '../team/team-message';
import {SmallLoader} from '../../components/small-loader/sm-loader';
import * as Enum from '../../components/common/enum';
import Loader from '../../components/loader/loader';
import IndividualGroup from '../../components/chat/individual-group';
import {NoRecord} from '../../components/no-record/no-record';
import {Link} from 'react-router-dom';
import AddMemberToGroup from './add-member-to-group';
import './group.scss';


class GroupDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            innerCheckboxChecked: false,
            groupId: props.match.params.group_id,
            file: {
                imagesrc: null,
                data: null
            },
            handelActivate: {
                deleteConfirmShow: false,
                memberIdForDeletion: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
            },
            editNameFlag: false,
            updateNameFlag: true,
            isAdminTrue: false,
            successConfirmShow: false,
            successText: '',
            showLoader: false,
            updateNameLoader: false,
            loading: false,
            filteredGroups: [],
            recordLoaded: false,
            modalLoader: false,
            checkedItems: new Map(),
            selectedMember: new Map(),
            show: false,
            groupName: "",
            showAccessDeniedError: false,
            sortCriteria: {
                column: 'first_name',
                direction: 'asc',
                sortingInProcess: false
            },
        };
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tableActionsOnChange = this.tableActionsOnChange.bind(this);
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    componentDidMount() {

        const user = Storage.Get('user');
        this.setState({userId: user.id});
        //, first_name: user.first_name, last_name: user.last_name, email: user.email, phone: user.phone, file: { imagesrc: user.photo }, role: user.role[Object.keys(user.role)[0]]
        this.loadData();
    }

    selectMember(member) {
        const userId = member.id;
        let selectedMember = this.state.selectedMember;
        let checkedItems = this.state.checkedItems;

        if (typeof selectedMember.get(userId) == "undefined") {
            const name = Helper.getMemberName(member),
                avatar = Helper.generateAvatar(member),
                username = member.username,
                guardians = member.guardian,
                roleId = member.role_id;
            selectedMember.set(userId, {name, avatar, username, guardians, userId, roleId});
            checkedItems.set(userId, true);
        } else {
            selectedMember.delete(userId);
            checkedItems.delete(userId);
        }
        this.setState({selectedMember, checkedItems});
    }

    deleteSelectedMember = (id) => {
        let selectedMember = this.state.selectedMember,
            checkedItems = this.state.checkedItems;
        selectedMember.delete(id);
        checkedItems.delete(id);

        this.setState({
            selectedMember,
            checkedItems
        });
        if (selectedMember.size === 0) {
            this.toggleGroupModal(false);
        }
    }

    deselectAll = () => {
        let selectedMember = this.state.selectedMember;
        let checkedItems = this.state.checkedItems;
        selectedMember.clear();
        checkedItems.clear();
        this.setState({
            selectedMember,
            checkedItems
        });
    }

    // removeMembersFromGroup = () => {
    //  this.toggleGroupModal(!this.state.show)

    // }
    toggleGroupModal = (visible) => {
        this.setState({show: visible});
    }

    loadData = () => {
        this.setState({recordLoaded: false, filteredGroups: []});

        var me = this;
        let sortCriteria = this.state.sortCriteria;
        Helper.axiosInstance().get(`group/members/${this.state.groupId}/${sortCriteria.column}/${sortCriteria.direction}`)
            .then(function (response) {
                if (response) {
                    for (let i = 0; i < response.data.members.length; i++) {
                        if (me.state.userId === response.data.members[i].id && response.data.members[i].is_group_admin === 1) {
                            me.setState({
                                isAdminTrue: true,
                            });
                        }
                    }
                    let membersLength = Helper.fetchUniqueMembersCount(response.data.members, false, null);
                    me.setState({
                        groupName: response.data.group.name,
                        file: {imagesrc: response.data.group.photo},
                        groupMembersLength: membersLength,
                        memberList: response.data.members,
                        filteredGroups: response.data.members,
                        recordLoaded: true,
                    });
                } else {
                    me.setState({
                        errorMessage: Messages.SERVER_ERROR,
                        showLoader: false,
                        showError: true,
                        recordLoaded: true
                    });
                }
            })
            .catch(function (error) {
                if ((error.response.status === 403 || error.response.status === 404) && error.response.data !== undefined) {
                    me.setState({
                        errorMessage: error.response.data.error,
                        showAccessDeniedError: true,
                        showLoader: false,
                        recordLoaded: true
                    });
                } else {
                    me.setState({
                        errorMessage: Messages.SERVER_ERROR,
                        showLoader: false,
                        showError: true,
                        recordLoaded: true
                    });
                }
            })
            .finally(() => {
                let {sortCriteria} = this.state;
                sortCriteria.sortingInProcess = false;
                me.setState({sortCriteria: sortCriteria, recordLoaded: true});
            });
    }

    toggleLoader = (visible) => {
        this.setState({loading: visible});
    }

    tableActionsOnChange() {
        this.setState({
            innerCheckboxChecked: !this.state.innerCheckboxChecked
        })
    }

    handleChange() {
        this.setState({
            checked: !this.state.checked
        })
        this.tableActionsOnChange();
    }

    searchGroupList = (target) => {
        this.setState({filteredGroups: this.state.memberList.filter(x => (x.first_name + ' ' + x.last_name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1)})
    }

    handlePhotoChange = (event) => {
        if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            if (event.target.files[0].size / 1024 > 6000) {
                this.setState({errorMessage: TeamMessages.PHOTO_MAX_SIZE, showError: true,});
                event.target.value = null;
                return;
            }
            if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg") {
                this.setState({errorMessage: TeamMessages.PHOTO_TYPE_ALLOWED, showError: true,});
                event.target.value = null;
                return;
            }
            this.setState({showLoader: true});
            const formData = new FormData();
            formData.append('id', this.state.groupId);
            formData.append('photo', event.target.files[0]);
            Helper.axiosInstance().post('group/update-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            file: {imagesrc: response.data.imageUrl},
                            successText: "Group Photo updated successfully.",
                            successConfirmShow: true,
                            showLoader: false,
                        })
                    } else {
                        this.setState({errorMessage: response.data.error, showLoader: false, showError: true,});
                    }
                })
                .catch((error) => {
                    this.setState({errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true,});
                });
        }
    }

    toggleGroupNameState = () => {
        this.setState({
            editNameFlag: !this.state.editNameFlag,
            updateNameFlag: !this.state.updateNameFlag,
        });
    }

    updateGroupName(fields) {
        var me = this;
        this.setState({updateNameLoader: true});
        if (this.state.groupName === fields.gName.trim()) {
            this.toggleGroupNameState();
            this.setState({updateNameLoader: false});
        } else {
            Helper.axiosInstance().post('group/update', {
                id: parseInt(this.state.groupId, 10),
                name: fields.gName.trim(),
            })
                .then((response) => {
                    if (response.data.success === true) {
                        me.setState({
                            groupName: JSON.parse(response.config.data).name,
                            successText: "Group Name updated successfully.",
                            successConfirmShow: true,
                            updateNameLoader: false
                        });
                        me.toggleGroupNameState();
                    } else {
                        me.setState({errorMessage: response.data.error, updateNameLoader: false, showError: true,});
                    }
                })
                .catch(function (error) {
                    me.setState({errorMessage: Messages.SERVER_ERROR, updateNameLoader: false, showError: true,});
                });
        }
    }

    onDeactivateTeamClick = (memberId) => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = true;
            handelActivate.memberIdForDeletion = memberId;
            handelActivate.confirmText = "Do you want to remove this contact from the group?";
            handelActivate.confirmButtonText = "Remove";
            handelActivate.confirmTypeStatus = "Remove";

            return {handelActivate};
        });
    }
    onDeactivateTeamConfirmClick = () => {
        if (this.state.handelActivate.memberIdForDeletion != null) {
            this.setState({modalLoader: true});
            Helper.axiosInstance().delete('group/delete-member-multi', {
                data: {
                    group_id: this.state.groupId,
                    member_id: [this.state.handelActivate.memberIdForDeletion]
                }
            })
                .then((response) => {
                    if (response.data.success === true) {
                        this.deleteSelectedMember(this.state.handelActivate.memberIdForDeletion);
                        this.setState(prevState => {
                            let handelActivate = Object.assign({}, prevState.handelActivate);
                            handelActivate.deleteConfirmShow = false;
                            handelActivate.memberIdForDeletion = null;
                            return {handelActivate};
                        });
                        this.loadData();
                    } else {
                        this.setState({errorMessage: response.data.error, showError: true,});
                    }
                    this.setState({modalLoader: false,});
                })
                .catch((error) => {
                    this.setState({errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false,});
                });
        }
    }

    onDeactivateTeamCancelClick = () => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = false;
            return {handelActivate};
        })
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    sortHandler = (column) => {
        const {sortCriteria} = this.state;
        if (!sortCriteria.sortingInProcess) {
            let newSortCriteria = sortCriteria;
            newSortCriteria.column = column;
            if (sortCriteria.column == column) {
                newSortCriteria.direction = (sortCriteria.direction == 'asc') ? 'desc' : 'asc';
            } else {
                newSortCriteria.direction = 'asc';
            }
            newSortCriteria.sortingInProcess = true;
            newSortCriteria.recordLoaded = false;

            this.setState({sortCriteria: newSortCriteria}, this.loadData);
        }
    }

    removeMembersBtnHandler = () => {
        const {selectedMember, show} = this.state;
        if (selectedMember.size > 0) {
            this.toggleGroupModal(!show);
        } else {
            this.setState({errorMessage: Enum.memberSelectionWarning, showError: true});
        }
    }

    render() {
        const {sortCriteria, selectedMember} = this.state;
        let sortImage = Images.ArrowSort;
        if (typeof sortCriteria != 'undefined' && sortCriteria.direction == 'desc') {
            sortImage = Images.ArrowDown;
        } else if (typeof sortCriteria != 'undefined' && sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }
        var editNameStyle = this.state.editNameFlag ? {display: 'none'} : {};
        var updateNameStyle = this.state.updateNameFlag ? {display: 'none'} : {};

        let includeLoggedInUser = false;
        let membersLength = Helper.fetchUniqueMembersCount(selectedMember, includeLoggedInUser);
        return (
            <>
                <Loader loading={this.state.loading}/>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12}>
                            <h1>{this.state.groupName}</h1>
                            {
                                selectedMember.size > 0 &&
                                <label
                                    className="selected-count">{membersLength} {membersLength == 1 ? " contact selected" : " contacts selected"}</label>
                            }
                        </Col>
                        <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                            {this.state.isAdminTrue || Helper.isCoachOrAdminLogin() ?
                                <div className="info-box info-box--mrgn">
                                    {
                                        this.state.showLoader ?
                                            <div className="smloader-min-height"><SmallLoader/></div> :
                                            <div className="info-box__header">
                                                {
                                                    this.state.isAdminTrue &&
                                                    <div className="info-box__uplaod">
                                                        <input type='file' onChange={this.handlePhotoChange}/>
                                                        <div><Image src={Images.CameraIcRed} alt="camera" width='24'/>
                                                        </div>
                                                    </div>
                                                }
                                                <Image src={this.state.file.imagesrc} alt={this.state.groupName}/>
                                            </div>
                                    }
                                    <div className="info-box__content" style={editNameStyle}>
                                        <h4>
                                            <span>{this.state.groupName}</span>
                                            {
                                                this.state.isAdminTrue &&
                                                <div className="add-linkbtn" onClick={this.toggleGroupNameState}>
                                                    <Image className="edit-icon" src={Images.EditBlue} alt="Edit"/>
                                                </div>
                                            }
                                        </h4>
                                    </div>
                                    <div className="info-box__content" style={updateNameStyle}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                gName: this.state.groupName,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                gName: Yup.string().trim()
                                                    .required(Messages.REQUIRED_ERR)
                                                    .max(100, Messages.MAX_LENGTH_100)
                                            })}
                                            onSubmit={fields => {
                                                this.updateGroupName(fields);
                                            }}
                                            render={({errors, status, touched}) => (
                                                <Form className="contact-us" noValidate>
                                                    <div>
                                                        <div className="field form-group">
                                                            <Field name="gName" type="text" id="gName"
                                                                   onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                   className={'form-control' + (errors.gName && touched.gName ? ' is-invalid' : '')}/>
                                                            <label htmlFor="gName" className="form-label label-active">Group
                                                                Name</label>
                                                            <ErrorMessage name="gName" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                        <div>
                                                            <Button type="Submit" disabled={this.state.updateNameLoader}
                                                                    className={`btn btn-medium d-inline-flex align-items-center mr-4 ${this.state.updateNameLoader ? 'btn-loader' : ''}`}>
                                                                <div className="btn__icon btn__icon--red"><Image
                                                                    src={Images.CompleteIc} alt="Save"/></div>
                                                                Save <span style={{'display': 'none'}}
                                                                           className="spinner-border spinner-border-sm"
                                                                           role="status" aria-hidden="true"></span>
                                                            </Button>
                                                            <Button type="reset" disabled={this.state.updateNameLoader}
                                                                    className="btn-cancel ripple"
                                                                    onClick={this.toggleGroupNameState}>Cancel</Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="info-box mt-4">
                                    <div className="info-box__header">
                                        <Image src={this.state.file.imagesrc} alt={this.state.groupName}/>
                                    </div>
                                    <div className="info-box__content">
                                        <h4>
                                            <span>{this.state.groupName}</span>
                                        </h4>
                                    </div>
                                </div>
                            }
                            <div className="info-box info-box--space">
                                <div className="info-box__content d-flex justify-content-between">
                                    <div className="icon-wrap">
                                        <Image src={Images.DashboardTotalMember} alt="Members" width="38"/>
                                    </div>
                                    <div className="info-box__content__desc">
                                        <p>Total Contacts</p>
                                        <div className="content__desc__heading">{this.state.groupMembersLength}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">

                            {
                                this.state.isAdminTrue || Helper.isCoachOrAdminLogin() ?
                                    <div className="block-pos block-pos--position">
                                        {
                                            // this.state.selectedMember.size > 0 ?
                                            // <div className="fixed-actionbar wgt-btnwrap">
                                            //     <span className="fixed-actionbar__count">{membersLength} {membersLength == 1 ? " member selected" : " members selected"}</span>
                                            //     <div>
                                            //         <Button className="ripple btn-icon btn-icon--delete btn-rounded d-inline-flex align-items-center" onClick={this.removeMembersFromGroup}>
                                            //         </Button>
                                            //         <Button onClick={() => this.deselectAll()} className="ripple btn-cancel btn-rounded align-items-center text-center ml-3">Cancel</Button>

                                            //     </div>
                                            // </div>
                                            // :
                                            // null
                                        }

                                        {
                                            this.state.isAdminTrue &&
                                            <>
                                                <OverlayTrigger placement="auto"
                                                                overlay={
                                                                    <Tooltip id={`tooltip`}>
                                                                        Add Contacts
                                                                    </Tooltip>}>
                                                    <Link
                                                        to={`/groups/add-member/${this.state.groupId}/${this.state.groupMembersLength}`}>
                                                        <div className="outline-circle">
                                                            <Image src={Images.AddContactRed} alt="Add member"
                                                                   width="30"/>
                                                        </div>
                                                    </Link>
                                                </OverlayTrigger>

                                                <OverlayTrigger placement="auto"
                                                                overlay={
                                                                    <Tooltip id={`tooltip`}>
                                                                        Remove Contact(s)
                                                                    </Tooltip>}>
                                                    <a href={null} onClick={() => {
                                                        this.removeMembersBtnHandler()
                                                    }}>
                                                        <div className="outline-circle">
                                                            <Image src={Images.Trash} alt="Delete" width="25"/>
                                                        </div>
                                                    </a>
                                                </OverlayTrigger>
                                            </>
                                        }
                                        {
                                            selectedMember.size > 0 &&
                                            <OverlayTrigger placement="auto"
                                                            overlay={
                                                                <Tooltip id={`tooltip`}>
                                                                    Cancel
                                                                </Tooltip>}>
                                                <a href={null} onClick={() => {
                                                    this.deselectAll();
                                                }}>
                                                    <div className="outline-circle">
                                                        <Image src={Images.Cancel} alt="Cancel" width="25"/>
                                                    </div>
                                                </a>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                    : null
                            }
                            <div className="widget widget--mrgn">
                                <div className="tab-search tab-search--big tab-search--widget">
                                    <Form className="form-inline">
                                        <input type="text" name="focus" maxLength="100"
                                               onChange={(e) => this.searchGroupList(e.target)} placeholder="Search"
                                               className="form-control tab-search__box"/>
                                        <button className="close-icon" type="reset"
                                                onClick={(e) => this.searchGroupList(e.target)}></button>
                                    </Form>
                                </div>
                                <div>
                                    <Table bordered hover
                                           className={`tab-responsive ${!this.state.isAdminTrue ? "table-td_first-child" : ''} table--listing--detail`}>
                                        <thead>
                                        <tr>
                                            {this.state.isAdminTrue ? <th></th> : ''}
                                            {
                                                (typeof sortCriteria != 'undefined' && this.state.filteredGroups.length > 1) ?
                                                    <th className='custom-width-column col-sortable'
                                                        onClick={() => this.sortHandler('first_name')}>First Name
                                                        {sortCriteria.column == 'first_name' ?
                                                            <Image src={sortImage} alt="Sort Direction"
                                                                   className="ml-2"/> :
                                                            <Image src={Images.ArrowSort} alt="Sort Direction"
                                                                   className="ml-2"/>
                                                        }</th>
                                                    : <th>First Name</th>

                                            }
                                            {
                                                (typeof sortCriteria != 'undefined' && this.state.filteredGroups.length > 1) ?
                                                    <th className='custom-width-column col-sortable'
                                                        onClick={() => this.sortHandler('last_name')}>Last Name
                                                        {sortCriteria.column == 'last_name' ?
                                                            <Image src={sortImage} alt="Sort Direction"
                                                                   className="ml-2"/> :
                                                            <Image src={Images.ArrowSort} alt="Sort Direction"
                                                                   className="ml-2"/>
                                                        }</th>
                                                    : <th>Last Name</th>

                                            }
                                            {
                                                (typeof sortCriteria != 'undefined' && this.state.filteredGroups.length > 1) ?
                                                    <th className='custom-width-column col-sortable date_created-column--detail'
                                                        onClick={() => this.sortHandler('created_at')}>Created Date
                                                        {sortCriteria.column == 'created_at' ?
                                                            <Image src={sortImage} alt="Sort Direction"
                                                                   className="ml-2"/> :
                                                            <Image src={Images.ArrowSort} alt="Sort Direction"
                                                                   className="ml-2"/>
                                                        }</th>
                                                    : <th>Created Date</th>

                                            }
                                            {Helper.isCoachOrAdminLogin() || this.state.isAdminTrue ?
                                                <th>Legal Guardian</th> : null}

                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <MemberList data={this.state.filteredGroups}
                                                    toggleLoader={this.toggleLoader}
                                                    removeMember={(memberId) => this.onDeactivateTeamClick(memberId)}
                                                    isAdmin={this.state.isAdminTrue}
                                                    userId={this.state.userId}
                                                    onCheckboxChange={(item) => {
                                                        this.selectMember(item)
                                                    }}
                                                    checkedItems={this.state.checkedItems}
                                                    fiterByStatus={"1"}
                                        />
                                        {!this.state.recordLoaded &&
                                        <tr className="mb--norecord">
                                            <td colSpan="5">
                                                <SmallLoader/>
                                            </td>
                                        </tr>
                                        }
                                        {
                                            (this.state.recordLoaded && this.state.filteredGroups.length === 0) &&
                                            <tr className="mb--norecord">
                                                <td colSpan="5">
                                                    <NoRecord/>
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => {
                        this.setState({showError: false})
                    }}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.errorMessage}
                    currentProps={this.props}
                    buttonText={"Go to Chat Groups"}
                    buttonLink={"/groups"}
                />
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handelActivate.deleteConfirmShow}
                    onHide={this.onDeactivateTeamConfirmCancel}
                    onCancelClick={this.onDeactivateTeamCancelClick}
                    onConfirmClick={this.onDeactivateTeamConfirmClick}
                    confirmText={this.state.handelActivate.confirmText}
                    confirmButtonText={this.state.handelActivate.confirmButtonText}
                    confirmType={this.state.handelActivate.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />
                <AddMemberToGroup
                    selectedMember={this.state.selectedMember}
                    memberModal={{show: this.state.show, group_id: this.state.groupId}}
                    toggleMemberModal={this.toggleGroupModal}
                    deleteSelectedMember={this.deleteSelectedMember}
                    redirect={this.props.history}
                    deleteMembers={true}
                />
            </>

        )
    }
}


const MemberList = (props) => {
    let members = '';
    if (typeof props.data == "object" && Object.keys(props.data).length > 0) {

        members = props.data.map((row, i) => {
            return (
                <tr key={i}>

                    {
                        (props.isAdmin && props.fiterByStatus !== undefined && props.fiterByStatus > 0) ?
                            <td>
                                {
                                    row.id !== props.userId ?
                                        <span className="checkwrap">
                                     <input className="checkwrap__cbx" id={i} type="checkbox"
                                            checked={props.checkedItems.get(row.id) ? true : false}
                                            onChange={() => props.onCheckboxChange(row)}/>

                                    <label className="checkwrap__label" htmlFor={i}>
                                        <span className="checkwrap__label_chk">
                                            <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </svg>
                                        </span>
                                    </label>
                                    </span>
                                        : null
                                }

                            </td>
                            : ''
                    }

                    {/*<td data-label="Name" className={Helper.isCoachOrAdminLogin() ? 'td-content-space' : 'td-content-space column--width'}>*/}
                    {/*    <Media>*/}
                    {/*        <div className="im-rounded-outer">*/}
                    {/*            <Image*/}
                    {/*                src={row.photo}*/}
                    {/*                alt={row.first_name}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <Media.Body className="align-self-center">*/}
                    {/*            <h5><span>{row.first_name ? row.first_name : null} {row.last_name ? row.last_name : null}</span></h5>*/}
                    {/*        </Media.Body>*/}
                    {/*    </Media>*/}
                    {/*</td>*/}
                    <td data-label="First Name" className='td-content-space first-column--width'>
                        {row.first_name}
                    </td>
                    <td data-label="Last Name" className='td-content-space first-column--width'>
                        {row.last_name}
                    </td>
                    <td data-label="Created Date" className='td-content-space first-column--width'>
                        {row.created_at}
                    </td>
                    {Helper.isCoachOrAdminLogin() || props.isAdmin ?
                        <td data-label="Legal Guardian" className="td-content-space">
                            <GuardianHtml guardian={row.guardian}/>
                        </td> : null
                    }
                    <td className={Helper.isKidLogin() && !props.isAdmin ? 'student-badge-width' : ''}>

                        <UserTypeBadgeHtml data={row.role_id}/>
                        {
                            (row.id === props.userId) ? null :
                                props.isAdmin ?
                                    <Dropdown className="more-actions">
                                        <Dropdown.Toggle>
                                            <span className="more-im"></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <IndividualGroup
                                                member={{
                                                    id: row.id,
                                                    username: row.username,
                                                    name: row.first_name,
                                                    guardian: row.guardian
                                                }}
                                                toggleLoader={props.toggleLoader}
                                                superProps={props.allProps}
                                            />
                                            {props.isAdmin ?
                                                <Dropdown.Item onClick={() => props.removeMember(row.id)}>
                                                    <Image
                                                        className="mr-2"
                                                        src={Images.DeleteIc}
                                                        alt="Remove"
                                                        width="21"
                                                    />
                                                    <span className="de-color">Remove from Group</span>
                                                </Dropdown.Item>
                                                : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : ''
                        }

                    </td>
                </tr>
            );
        });
    } else {
        // return (<tr><td colSpan="5"></td></tr>);
    }
    return members;

};

function TeamHtml(props) {
    if (props.team !== null && props.team !== undefined) {
        return (props.team.map((teamitem, teamkey) => {
                return (
                    <OverlayTrigger key={teamkey}
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            {teamitem.name}
                                        </Tooltip>}>
                        <div className="im-semirounded-outer">
                            <Image src={teamitem.photo}/>
                        </div>
                    </OverlayTrigger>
                )
            })
        )
    } else {
        return "";
    }
}

function GuardianHtml(props) {
    if (props.guardian !== null && props.guardian !== undefined) {
        return (
            props.guardian.map((guardianItem, guradianKey) => {
                return (
                    <Media key={guradianKey} className="inline-flex">
                        <div className="im-rounded-outer">
                            <Image
                                src={guardianItem.photo}
                                alt={guardianItem.first_name}
                            />
                        </div>
                        <Media.Body className="align-self-center">
                            <h5>{guardianItem.first_name}</h5>
                        </Media.Body>
                    </Media>
                )
            })
        );
    } else {
        return "";
    }
}

function UserTypeBadgeHtml(props) {
    if (props.data !== null && props.data !== undefined) {
        return (
            props.data.map((item, key) => {
                    let badgeName = Enum.UserRolesDisplay[item];
                    let className = "t-badge"

                    if (item === Enum.UserRoles.COACH) {
                        className = `${className} t-badge--coach`;
                    } else if (item === Enum.UserRoles.LEGAL_GUARDIAN) {
                        className = `${className} t-badge--lg`;
                        badgeName = `LG`
                    } else if (item === Enum.UserRoles.CLUB_SUB_ADMIN) {
                        className = `${className} t-badge--admin`;
                        badgeName = `Organization SA`
                    } else if (item === Enum.UserRoles.CLUB_ADMIN) {
                        className = `${className} t-badge--admin`;
                        badgeName = `Organization Admin`
                    }
                    return (
                        <OverlayTrigger key={key}
                                        overlay={
                                            <Tooltip id={`tooltip`}>
                                                {Enum.UserRolesDisplay[item]}
                                            </Tooltip>}>
                            <div className="badge-wrap"><span className={className}>{badgeName}</span></div>
                        </OverlayTrigger>
                    )
                }
            ));
    } else {
        return "";
    }
}


export {GroupDetail}