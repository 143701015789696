/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from "react";
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Dropdown, Tab, Tabs, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Select
 * Name and Version: react-select, 3.0.8
 * Download link: https://www.npmjs.com/package/react-select
 */
import Select from "react-select";
/* End - React Select */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from "axios";
/* End - axios */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from "lodash.debounce";
/* End - Lodash.debounce */

/* app imports */
import './contact.scss';
import { ContactMessages } from './contact-message';
import { NoRecord } from '../../components/no-record/no-record';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { GoTop } from '../../components/common/goto-top';
import { Messages } from '../../components/common/message';
import { DeleteConfirmModal, ErrorModal, UpdateMiddleNameModal, ConfirmModal, SuccessModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { ContactList } from '../../components/contact/contact-list';
import { PendingContactList } from '../../components/contact/pending-contact-list';
import * as Enum from '../../components/common/enum';
import * as Storage from '../../components/common/storage';
import CreateChatGroup from '../../components/chat/create-chat-group';
import BroadcastMesaage from '../../components/common/broadcast-message';
// import FixedActionBar from '../../components/common/fixed-action-bar';
import SingleInvite from './single-invite';
import EditPendingLG from './edit-pending-lg';
import EditPendingInstructor from './edit-pending-instructor';
import ResendInvite from './resend-invite';
import ResendAllInvite from './resend-all-invite';
import Loader from '../../components/loader/loader';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: null,
            pendingCancel: undefined,
            pendingCancelToken: axios.CancelToken,
            cancelToken: axios.CancelToken,
            cancel: undefined,
            showMenu: true,
            checked: false,
            checkedAll: false,
            modalLoader: false,
            broadcastBtnLoader: false,
            innerCheckboxChecked: false,
            dropDownValue: "Active",
            addRevokeSubAdminFlag: false,
            revokeInstructorFlag: false,
            disabled: false,
            updateContact: {
                deleteConfirmShow: false,
                memberIdForDeletion: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
                newStatus: 0
            },
            filterCriteria: {
                searchKeyword: '',
                teams: [],
                status: 1,
                role: null,
                page: 1,
                pageSize: 150,
                excludeMe: true,
            },
            pendingFilterCriteria: {
                searchKeyword: '',
                teams: [],
                page: 1,
                pageSize: 150,
            },
            selectedOption: null,
            pendingContacts: [],
            filteredContacts: [],
            groupModal: false,
            broadcastModal:false,
            modalVisibility: false,
            checkedItems: new Map(),
            checkedPendingItems: new Map(),
            selectedMember: new Map(),
            selectedPendingMember: new Map(),
            teamSelectList: [],
            recordLoaded: false,
            totalRecords: null,
            pendingRecords: null,
            userInfo: Storage.Get('user'),
            loading: false,
            pendingInviteFilter: false,
            keywordToBeSearched: null,
            editPendingUser: {
                invite_id: '',
                role_id: '',
                guardian_first_name: '',
                guardian_last_name: '',
                guardian_email: '',
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_photo: '',
                guardian_photo: ''
            },
            editPendingModalVisibility: false,
            editInstructorModalVisibility: false,
            resendInviteVisibility: false,
            resendAllInviteVisibility: false,
            selectedMembersCount: null,
            sortCriteria: {
                column: 'last_name',
                direction: 'asc',
                sortingInProcess: false
            },
            showMiddleNameWarning: null,
            successConfirmShow: false,
            successText: '',
            selectedMemberId: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.tableActionsOnChange = this.tableActionsOnChange.bind(this);
        this.reloadContacts = this.reloadContacts.bind(this);
        this.reloadPendingContacts = this.reloadPendingContacts.bind(this);
        this.showBulkUpload = this.showBulkUpload.bind(this);

    /* Using Debounce to prevent repeated events
         Windows scroll event debounce */
    this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
    /*  Filter Criteria Debounce */
    let filterDelay = 500;

    this.doDebouncedTeamMuliSelectChange = debounce(
      this.onTeamMultiSelectChange,
      filterDelay
    );
    this.doDebouncedStatusFilterSelect = debounce(
      this.onStatusFilterSelect,
      filterDelay
    );
    this.doDebouncedSearchKeywordChange = debounce(
      this.onSearchKeywordChange,
      filterDelay
    );
    // props.setChatWithGroupId('I_100');
  }

  componentDidMount() {
      window.addEventListener("scroll", this.doDebouncedTableScroll);
      let locationHash = this.props.location.hash;
    if(typeof locationHash != "undefined" && locationHash != "" && locationHash == "#pending-invites"){
        this.setState({ pendingInviteFilter: true }, this.getPendingContacts);
    } else {
        this.getTeamList();
        this.getContactList();
        const addMiddleName = Storage.Get("add_middle_name");
        if (
        Helper.isLegalGuardian() &&
        typeof addMiddleName != "undefined" &&
        addMiddleName == true
        ){
          this.setState({ showMiddleNameWarning: true });
        }

        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.role = this.fetchActiveTabKey(locationHash);
        this.setState({ pendingInviteFilter: false });
        this.reloadContacts(newFilterCriteria);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.doDebouncedTableScroll);
  }

  // changeValue(text) {
  //     console.log(text)
  //     this.setState({ dropDownValue: text })
  // }

  tableActionsOnChange() {
    this.setState({
      innerCheckboxChecked: !this.state.innerCheckboxChecked
    });
  }

  handleChange() {
    this.setState({
      checked: !this.state.checked,
    });
    this.tableActionsOnChange();
  }

  onTableScroll = () => {
    // Don't proceed if:
    // old records are already loading
    // there's nothing left to load
    if (this.state.pendingInviteFilter) {
      if (
        !this.state.recordLoaded ||
        this.state.pendingRecords === this.state.pendingContacts.length ||
        this.state.totalRecords < this.state.pageSize
      )
        return;
    } else {
      if (
        !this.state.recordLoaded ||
        this.state.totalRecords === this.state.filteredContacts.length ||
        this.state.totalRecords < this.state.pageSize
      )
        return;
    }
    // Get document.documentElement.scrollTop for all browsers
    let docTop =
      typeof window.pageYOffset != "undefined"
        ? window.pageYOffset
        : document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop
        ? document.body.scrollTop
        : 0;
    // // Reduce 20percent from the offsetHeight
    let reduceOffset =
      document.documentElement.offsetHeight -
      (20 / 100) * document.documentElement.offsetHeight;
    // Check if scroll has hit bottom
    if (window.innerHeight + docTop >= reduceOffset) {
      if (this.state.pendingInviteFilter) {
        let newPendingFilterCriteria = this.state.pendingFilterCriteria;
        newPendingFilterCriteria.page = newPendingFilterCriteria.page + 1;
        this.setState({ pendingFilterCriteria: newPendingFilterCriteria });
        this.getPendingContacts();
      } else {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.page = newFilterCriteria.page + 1;
        this.setState({ filterCriteria: newFilterCriteria });
        this.getContactList();
      }
    }
  };

  sortHandler = (column) => {
    const { sortCriteria, filterCriteria } = this.state;
    if (!sortCriteria.sortingInProcess) {
      let newSortCriteria = sortCriteria;
      newSortCriteria.column = column;
      if (sortCriteria.column == column) {
        newSortCriteria.direction =
          sortCriteria.direction == "asc" ? "desc" : "asc";
      } else {
        newSortCriteria.direction = "asc";
      }
      newSortCriteria.sortingInProcess = true;
      let newFilterCriteria = {
        searchKeyword: "",
        teams: [],
        status: filterCriteria.status,
        role: filterCriteria.role,
        page: 1,
        pageSize: filterCriteria.pageSize,
        excludeMe: true,
      };

      this.setState(
        {
          filteredContacts: [],
          sortCriteria: newSortCriteria,
          filterCriteria: newFilterCriteria,
        },
        this.getContactList
      );
    }
  };

  getTeamList() {
    Helper.axiosInstance()
      .get(`team/list/${this.state.userInfo.selectedClubId}/web`)
      .then((response) => {
        if (response) {
          this.setState({
            teamSelectList: response.data.my_teams.map((row, i) => {
              return { label: row.name, value: row.id };
            }),
          });
        }
      })
      .catch(function (error) {});
  }

  getPendingContacts = () => {
    this.setState({ recordLoaded: false });
    if (this.state.userInfo != null) {
      const selectedClubId = this.state.userInfo.selectedClubId,
        filterCriteria = this.state.pendingFilterCriteria;
      if (selectedClubId != null) {
        let cancelReq = this.state.pendingCancel,
          pendingCancelReq = this.state.pendingCancelToken;
        if (this.state.pendingCancel !== undefined) {
          cancelReq();
        }
        let me = this;
        Helper.axiosInstance()
          .post(
            `invite/pending`,
            JSON.stringify({
              club_id: selectedClubId,
              keyword: filterCriteria.searchKeyword,
              page_size: filterCriteria.pageSize,
              page: filterCriteria.page,
            }),
            {
              pendingCancelReq: new pendingCancelReq(function executor(c) {
                me.setState({ cancelReq: c });
              }),
            }
          )
          .then((response) => {
            if (response.data.success === true) {
              if (this.state.pendingFilterCriteria.page === 1) {
                this.setState({
                  pendingContacts: response.data.data,
                  totalRecords: response.data.count,
                });
              } else {
                let existingRecords = this.state.pendingContacts;
                this.setState({
                  pendingContacts: existingRecords.concat(response.data.data),
                });
              }
              this.setState({ pendingRecords: response.data.count });
            } else {
              this.showError(Messages.SERVER_ERROR);
            }
          })
          .catch((error) => {
            this.showError(Messages.SERVER_ERROR);
          })
          .finally(() => {
            this.setState({ recordLoaded: true });
          });
      }
    }
  };

  getContactList = () => {
    if (this.state.userInfo != null) {
      let selectedClubId = this.state.userInfo.selectedClubId;
      let filterCriteria = this.state.filterCriteria;
      let sortCriteria = this.state.sortCriteria;
      if (selectedClubId != null) {
        let cancel = this.state.cancel;
        let cancelToken = this.state.cancelToken;
        if (this.state.cancel !== undefined) {
          cancel();
        }
        let me = this;
        this.setState({ recordLoaded: false }, () => {
          Helper.axiosInstance()
            .post(
              `contacts`,
              JSON.stringify({
                club_id: selectedClubId,
                keyword: filterCriteria.searchKeyword,
                team_id: filterCriteria.teams,
                status: filterCriteria.status,
                role_id: filterCriteria.role,
                page_size: filterCriteria.pageSize,
                page: filterCriteria.page,
                exclude_me: filterCriteria.excludeMe,
                sort_by: sortCriteria.column,
                sort_dir: sortCriteria.direction,
              }),
              {
                cancelToken: new cancelToken(function executor(c) {
                  me.setState({ cancel: c });
                }),
              }
            )
            .then((response) => {
              if (response.data.success === true) {
                if (this.state.filterCriteria.page === 1) {
                  this.setState({
                    filteredContacts: response.data.members,
                  });
                } else {
                  let existingRecords = this.state.filteredContacts;
                  this.setState({
                    filteredContacts: existingRecords.concat(
                      response.data.members
                    ),
                  });
                }
                this.setState({ totalRecords: response.data.count });
              } else {
                this.showError(Messages.SERVER_ERROR);
              }
            })
            .catch((error) => {
              this.showError(Messages.SERVER_ERROR);
            })
            .finally(() => {
              let { sortCriteria } = this.state;
              sortCriteria.sortingInProcess = false;
              this.setState({ sortCriteria: sortCriteria, recordLoaded: true });
            });
        });
      }
    }
  };

  reloadContacts = (newFilterCriteria) => {
    let selectedMember = this.state.selectedMember;
    let checkedItems = this.state.checkedItems;
    selectedMember.clear();
    checkedItems.clear();
    newFilterCriteria.page = 1;
    this.setState(
      {
        checkedAll: false,
        filteredContacts: [],
        checkedItems: new Map(),
        selectedPendingMember: new Map(),
        checkedPendingItems: new Map(),
        selectedMember: new Map(),
        filterCriteria: newFilterCriteria,
        recordLoaded: false,
      },
      () => {
        this.getContactList();
      }
    );
  };

  reloadPendingContacts = (newPendingFilterCriteria) => {
    newPendingFilterCriteria.page = 1;
    this.setState(
      {
        pendingFilterCriteria: newPendingFilterCriteria,
        checkedAll: false,
        checkedItems: new Map(),
        pendingContacts: [],
        recordLoaded: false,
        selectedMember: new Map(),
      },
      () => {
        this.getPendingContacts();
      }
    );
  };

  onSearchKeywordChange = (target) => {
    let newPendingFilterCriteria = this.state.pendingFilterCriteria;
    newPendingFilterCriteria.searchKeyword = target.value.toLowerCase();
    let newFilterCriteria = this.state.filterCriteria;
    newFilterCriteria.searchKeyword = target.value.toLowerCase();

    if (Helper.isCoachOrAdminLogin() && this.state.pendingInviteFilter) {
      this.reloadPendingContacts(newPendingFilterCriteria);
    } else {
      this.reloadContacts(newFilterCriteria);
    }
  };

  onTeamMultiSelectChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption });
    let newFilterCriteria = this.state.filterCriteria;
    newFilterCriteria.teams = selectedOption
      ? Object.keys(selectedOption).map((key) => {
          return selectedOption[key].value;
        })
      : [];
    this.reloadContacts(newFilterCriteria);
  };

  onStatusFilterSelect = (selectedOption) => {
    if (selectedOption === "0") {
      this.setState({ dropDownValue: "Inactive" });
    } else {
      this.setState({ dropDownValue: "Active" });
    }
    let newFilterCriteria = this.state.filterCriteria;
    newFilterCriteria.status = selectedOption;
    this.reloadContacts(newFilterCriteria);
  };

  onRoleTabSelect = (key) => {
    if (key === "pendingInvites") {
      this.setState({ pendingInviteFilter: true }, () => {
        if (
          this.state.pendingInviteFilter &&
          this.state.filterCriteria.searchKeyword !== null
        ) {
          let newPendingFilterCriteria = this.state.pendingFilterCriteria;
          newPendingFilterCriteria.searchKeyword = this.state.filterCriteria.searchKeyword.toLowerCase();
          this.reloadPendingContacts(newPendingFilterCriteria);
        }
      });
    } else {
      let newFilterCriteria = this.state.filterCriteria;
      newFilterCriteria.role = key === "0" ? null : key;
      this.reloadContacts(newFilterCriteria);
      this.setState({ pendingInviteFilter: false });
    }
  };

  onDeactivateContactClick = (memberId) => {
      let selectedMember = this.state.selectedMember;
      let selectedMemberIds = [];
     // console.log(parseInt(selectedMember.size))
      if(memberId) {
          selectedMemberIds.push(memberId);
      }else{

          for (let val of selectedMember.values()) {
            selectedMemberIds.push(val.userId);
          }
      }

    this.setState((prevState) => {
      let updateContact = Object.assign({}, prevState.updateContact);
        updateContact.deleteConfirmShow = true;
        if(this.state.filterCriteria.status >0) {
            updateContact.confirmText = ContactMessages.DEACTIVATE_ACCOUNT;
            updateContact.confirmButtonText = "Deactivate";
            updateContact.confirmTypeStatus = "Deactivate";
            updateContact.memberIdForDeletion = selectedMemberIds
        }else{
          updateContact.confirmText = ContactMessages.ACTIVATE_ACCOUNT;
          updateContact.confirmButtonText = "Activate";
          updateContact.confirmTypeStatus = "Activate";
          updateContact.memberIdForDeletion = selectedMemberIds

        }
      return { updateContact };
    });
  };

    onResetPasswordClick = (item) => {
        this.setState({confirmShow: true, selectedMemberId: item.id});
    };

    onResetPasswordConfirm = () => {
        if (this.state.userInfo != null) {
            this.setState({modalLoader: true});
            Helper.axiosInstance()
                .put(`user/reset-password`, {
                    user_id: this.state.selectedMemberId,
                    club_id: this.state.userInfo.selectedClubId,
                })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState({
                            successText: response.data.message,
                            successConfirmShow: true,
                            confirmShow: false
                        });
                    } else {
                        this.showError(response.data.message);
                    }
                    this.setState({modalLoader: false});
                })
                .catch((error) => {
                    this.showError(Messages.SERVER_ERROR);
                    this.setState({modalLoader: false});
                });
        }

    };

    onActivateDeactivateContactConfirmClick = () => {
     if (this.state.userInfo != null) {
        let selectedClubId = this.state.userInfo.selectedClubId;
        if (selectedClubId != null) {
            this.setState({ modalLoader: true });
            Helper.axiosInstance()
              .put(this.state.filterCriteria.status >0 ?`user/deactivate` : `user/activate`, {
                club_id: selectedClubId,
                member_id: this.state.updateContact.memberIdForDeletion,
              })
              .then((response) => {
                if (response.data.success === true) {
                    let filteredContacts = this.state.filteredContacts,
                        deactivateCount = 1, deactivateLgs = response.data["affected-lg"];
                    /* Remove deactivated contact from list */
                        filteredContacts = filteredContacts.filter((x) => this.state.updateContact.memberIdForDeletion.indexOf(x.id) == -1)

                    /* Remove deactivated associated legal guardians from the list */
                    if ( deactivateLgs && deactivateLgs.length > 0 ) {
                        filteredContacts = filteredContacts.filter( (x) => !deactivateLgs.includes(x.id));
                        deactivateCount += deactivateLgs.length;
                    }
                    this.setState({ filteredContacts: filteredContacts, totalRecords: this.state.totalRecords - deactivateCount },() => {
                        this.deleteSelectedMember(this.state.updateContact.memberIdForDeletion);
                    });

                this.setState((prevState) => {
                    let updateContact = Object.assign({}, prevState.updateContact);
                        updateContact.deleteConfirmShow = false;
                        updateContact.memberIdForDeletion = null;
                    return { updateContact };
                });
            } else {
              this.showError(response.data.message);
            }
            this.setState({ modalLoader: false });
          })
          .catch((error) => {
            this.showError(Messages.SERVER_ERROR);
            this.setState({ modalLoader: false });
          });
      }
    }
  };



  showError = (msg) => {
    this.setState({ errorMessage: msg, showError: true });
  };

  onDeactivateContactCancelClick = () => {
    this.setState((prevState) => {
      let updateContact = Object.assign({}, prevState.updateContact);
      updateContact.deleteConfirmShow = false;
      return { updateContact };
    });
    this.setState({
      addRevokeSubAdminFlag: false,
    });
  };

    onRemoveSubAdminClick = (item) => {
    this.setState({
      addRevokeSubAdminFlag: true,
    });
    let isSubAdminStatus = 0;
    if (
      item.role_id.includes(Enum.UserRoles.COACH) &&
      !item.role_id.includes(Enum.UserRoles.CLUB_SUB_ADMIN)
    ) {
      isSubAdminStatus = 1;
    } else if (item.role_id.includes(Enum.UserRoles.CLUB_SUB_ADMIN)) {
      isSubAdminStatus = 0;
    }
    this.setState((prevState) => {
      let updateContact = Object.assign({}, prevState.updateContact);
      updateContact.deleteConfirmShow = true;
      updateContact.memberIdForDeletion = item.id;
      updateContact.status = isSubAdminStatus;
      if (!isSubAdminStatus) {
        updateContact.confirmText = ContactMessages.REMOVE_SUB_ADMIN;
        updateContact.confirmButtonText = "Remove";
        updateContact.confirmTypeStatus = "Remove";
      } else {
        updateContact.confirmText = ContactMessages.ADD_SUB_ADMIN;
        updateContact.confirmButtonText = "Add";
        updateContact.confirmTypeStatus = "Add";
      }
      return { updateContact };
    });
  };

  onRemoveSubAdminConfirmClick = () => {
    if (this.state.userInfo != null) {
      let selectedClubId = this.state.userInfo.selectedClubId;
      if (
        selectedClubId != null &&
        this.state.updateContact.memberIdForDeletion != null
      ) {
        this.setState({ modalLoader: true });
        Helper.axiosInstance()
          .post(
            this.state.updateContact.status
              ? `club/assign-subadmin`
              : `club/revoke-subadmin`,
            {
              club_id: selectedClubId,
              user_id: this.state.updateContact.memberIdForDeletion,
            }
          )
          .then((response) => {
            if (response.data.success === true) {
              this.getContactList();
              this.setState((prevState) => {
                let updateContact = Object.assign({}, prevState.updateContact);
                updateContact.deleteConfirmShow = false;
                updateContact.memberIdForDeletion = null;
                return { updateContact };
              });
            } else {
              this.showError(Messages.SERVER_ERROR);
              this.onDeactivateContactCancelClick();
            }
          })
          .catch((error) => {
            this.showError(Messages.SERVER_ERROR);
            this.onDeactivateContactCancelClick();
          })
          .finally(() => {
            this.setState({ modalLoader: false, addRevokeSubAdminFlag: false });
          });
      }
    }
  };
  /**** resend pending all ***/
  resendPendingAllInvite = () => {
    this.setState({ resendAllInviteVisibility: true });
  };
  selectPendingMembers = (member) => {
    const userId = member.invite_id;
    let selectedPendingMember = this.state.selectedPendingMember;
    let checkedPendingItems = this.state.checkedPendingItems;
    if (typeof selectedPendingMember.get(userId) == "undefined") {
      const name = Helper.getMemberName(member),
        avatar = Helper.generateAvatar(member),
        username = member.username,
        guardians = member.guardian;
      selectedPendingMember.set(userId, {
        name,
        avatar,
        username,
        guardians,
        userId,
      });
      checkedPendingItems.set(userId, true);
    } else {
      selectedPendingMember.delete(userId);
      checkedPendingItems.delete(userId);
    }
    this.setState(
      {
        selectedPendingMember: selectedPendingMember,
        checkedPendingItems: checkedPendingItems,
      },
      () => {
        this.state.checkedPendingItems.size ===
        this.state.pendingContacts.length
          ? this.setState({ checkedAll: true })
          : this.setState({ checkedAll: false });
      }
    );
  };

  selectMember = (member) => {
      console.log('member', member)
    const userId = member.id;
    let selectedMember = this.state.selectedMember;
    let checkedItems = this.state.checkedItems;
    if (typeof selectedMember.get(userId) == "undefined") {
      const name = Helper.getMemberName(member),
        avatar = Helper.generateAvatar(member),
        username = member.username,
        guardians = member.guardian;
      selectedMember.set(userId, { name, avatar, username, guardians, userId });
      checkedItems.set(userId, true);
    } else {
      selectedMember.delete(userId);
      checkedItems.delete(userId);
    }
    const guardianCount = Helper.guardianCount(selectedMember) + 1;

    this.setState(
      {
        selectedMember: selectedMember,
        checkedItems: checkedItems,
        guardianCount,
      },
      () => {
        this.state.checkedItems.size === this.state.filteredContacts.length
          ? this.setState({ checkedAll: true })
          : this.setState({ checkedAll: false });

        if (
          parseInt(selectedMember.size) + parseInt(guardianCount) <=
          process.env.REACT_APP_GROUP_LIMIT
        ) {
          this.setState({ disabled: false, showError: false });
        }
      }
    );
  };

  deleteSelectedMember = (id) => {
     let selectedMember = this.state.selectedMember,
        checkedItems = this.state.checkedItems;
        selectedMember.delete(id);
        checkedItems.delete(id);
        if(Array.isArray(id)){
            selectedMember.clear();
        }
    const guardianCount = Helper.guardianCount(selectedMember);
    this.setState(
      {
        selectedMember: selectedMember,
        checkedItems: checkedItems,
        guardianCount,
      },
      () => {
        this.state.checkedItems.size === this.state.filteredContacts.length
          ? this.setState({ checkedAll: true })
          : this.setState({ checkedAll: false });
      }
    );
    if (selectedMember.size === 0) {
      this.toggleGroupModal(false);
    }
  };

  resendPendingInvite = (index) => {
    this.setState({ editPendingUser: this.state.pendingContacts[index] });
    this.setState({ resendInviteVisibility: true });
  };

  editPendingInviteUser = (index, roleId) => {
    this.setState({ editPendingUser: this.state.pendingContacts[index] });
    if (roleId === 5) {
      this.toggleEditPendingModal(!this.state.editPendingModalVisibility);
    } else {
      this.toggleEditPendingInstructorModal(
        !this.state.editInstructorModalVisibility
      );
    }
  };

  toggleGroupModal = (visible) => {
    let selectedMember = this.state.selectedMember;

    let includeLoggedInUser = true; // add logged in member count
    let membersLength = Helper.fetchUniqueMembersCount(
      selectedMember,
      includeLoggedInUser
    );
    //console.log(membersLength);
    //const guardianCount = Helper.guardianCount(selectedMember)+1;
    if (membersLength > process.env.REACT_APP_GROUP_LIMIT) {
      this.setState({
        errorMessage: `Chat groups should be limited to ${process.env.REACT_APP_GROUP_LIMIT} members including students, instructors, and guardians. Please note, all legal guardians of a student are added to the chat group.`,
        disabled: true,
        showError: true,
      });
    } else {
      this.setState({ groupModal: visible, disabled: false, showError: false });
    }
  };

  toggleModal = (visible) => {
    this.setState({ modalVisibility: visible });
  };

  toggleEditPendingModal = (visible) => {
    this.setState({ editPendingModalVisibility: visible });
  };

  toggleEditPendingInstructorModal = (visible) => {
    this.setState({ editInstructorModalVisibility: visible });
  };

  toggleLoader = (visible) => {
    this.setState({ loading: visible });
  };

  showBulkUpload = (isInstructor) => {
    isInstructor
      ? this.props.history.push("/bulk-upload")
      : this.props.history.push("/bulk-upload-instructors");
  };

  deselectAll = () => {
    let selectedMember = this.state.selectedMember;
    let checkedItems = this.state.checkedItems;
    selectedMember.clear();
    checkedItems.clear();
    this.setState({ selectedMember, checkedItems }, () => {
      this.state.checkedItems.size === this.state.selectedMember.length
        ? this.setState({ checkedAll: true })
        : this.setState({ checkedAll: false });
    });
    this.setState({ broadcastModal: false });
  };

  deselectPendingAll = () => {
    let selectedPendingMember = this.state.selectedPendingMember;
    let checkedPendingItems = this.state.checkedPendingItems;
    selectedPendingMember.clear();
    checkedPendingItems.clear();
    this.setState({ selectedPendingMember, checkedPendingItems }, () => {
      this.state.checkedPendingItems.size === this.state.pendingContacts.length
        ? this.setState({ checkedAll: true })
        : this.setState({ checkedAll: false });
    });
  };

  handleAllChecked = () => {
    let { filteredContacts, checkedItems } = this.state;
    !(checkedItems.size === filteredContacts.length)
      ? this.setState(
          { checkedItems: new Map(), selectedMember: new Map() },
          () => {
            filteredContacts.forEach((contact) => this.selectMember(contact));
          }
        )
      : filteredContacts.forEach((contact) => this.selectMember(contact));
    this.setState({ filteredContacts: filteredContacts }, () => {
      checkedItems.size === filteredContacts.length
        ? this.setState({ checkedAll: true })
        : this.setState({ checkedAll: false });
    });
  };

  handlePendingAllChecked = () => {
    let { pendingContacts, checkedPendingItems } = this.state;
    !(checkedPendingItems.size === pendingContacts.length)
      ? this.setState(
          { checkedPendingItems: new Map(), selectedPendingMember: new Map() },
          () => {
            pendingContacts.forEach((contact) =>
              this.selectPendingMembers(contact)
            );
          }
        )
      : pendingContacts.forEach((contact) =>
          this.selectPendingMembers(contact)
        );
    this.setState({ pendingContacts: this.state.pendingContacts }, () => {
      checkedPendingItems.size === this.state.pendingContacts.length
        ? this.setState({ checkedAll: true })
        : this.setState({ checkedAll: false });
    });
  };

  addToChatGroupBtnHandler = () => {
    let selectedMember = this.state.selectedMember;
    let memberIds = [];
    for (let val of selectedMember.values()) {
      memberIds.push(val.userId);
    }
    Storage.Set("contacts", JSON.stringify(memberIds));
    let history = this.props.history;
    let location = { ...history.location };
    location.pathname = "/groups/1";
    history.replace({ ...history.location, ...location });
  };


  fetchActiveTabKey(){
    let locationHash = this.props.location.hash;
    let activeTab = 0;
    if (typeof locationHash != "undefined" && locationHash != "") {
      switch (locationHash) {
        case "#instructor":
          activeTab = Enum.UserRoles.COACH;
          break;
        case "#student":
          activeTab = Enum.UserRoles.ATHLETE;
          break;
        case "#pending-invites":
          activeTab = "pendingInvites";
         // this.onRoleTabSelect('pendingInvites');
          break;
        default:
          activeTab = 0;
      }
    }
    return activeTab;
  }

    btnClickHandler = (action, listType) => {
        const {selectedMember, selectedPendingMember, checkedPendingItems, groupModal} = this.state;
        if((listType == 'active' && selectedMember.size > 0) || (listType == 'pending' && selectedPendingMember.size > 0) ){
            switch(action) {
                case 'create-group':
                    this.toggleGroupModal(!groupModal);
                    break;
                case 'add-group-members':
                    this.addToChatGroupBtnHandler();
                    break;
                case 'broadcast':
                    Helper.fetchSelectedMembersCount(false, this, 'contacts');
                    break;
                case 'resend-invites':
                    this.resendPendingAllInvite();
                    break;
                case 'delete-invites':
                    Helper.onRemovePendingInvite(checkedPendingItems, this);
                    break;
                case 'deactivate':
                    this.onDeactivateContactClick();
                    break;
                default:
                    console.log(action);
            }
        } else {
            this.setState({  errorMessage: Enum.memberSelectionWarning, showError: true});
        }
    }

    /**
     * Remove Instructor role
     * @param item
     */
    onRemoveInstructorClick  = (item) => {
        this.setState({revokeInstructorFlag: true});
        this.setState((prevState) => {
            let updateContact = Object.assign({}, prevState.updateContact);
            updateContact.deleteConfirmShow = true;
            updateContact.confirmText = ContactMessages.REMOVE_INSTRUCTOR_ROLE;
            updateContact.memberIdForDeletion = item.id;
            updateContact.confirmButtonText = "Remove";
            updateContact.confirmTypeStatus = "Remove";
            return {updateContact};
        });
    }

    onRemoveInstructorConfirmClick = ()=>{
        if (this.state.userInfo != null) {
            let selectedClubId = this.state.userInfo.selectedClubId;
            this.setState({ modalLoader: true });
            Helper.axiosInstance()
                .post(
                    `club/revoke-instructor`,
                    JSON.stringify({
                        club_id: selectedClubId,
                        user_id: this.state.updateContact.memberIdForDeletion,
                    }),

                )
                .then((response) => {
                    console.log(response);
                    if (response.data.success === true) {
                        this.setState((prevState) => {
                            let updateContact = Object.assign({}, prevState.updateContact);
                            updateContact.deleteConfirmShow = false;
                            updateContact.memberIdForDeletion = null;
                            return { updateContact };
                        });
                        this.getContactList();
                    } else {
                        this.showError(Messages.SERVER_ERROR);
                    }
                })
                .catch((error) => {
                    this.showError(Messages.SERVER_ERROR);

                })
                .finally(() => {
                    this.setState({ modalLoader: false });
                });

        }
    }

       render() {
        const { groupModal, modalVisibility, selectedMember, loading,  editPendingModalVisibility, editInstructorModalVisibility, resendInviteVisibility, resendAllInviteVisibility, checkedAll, selectedPendingMember, filterCriteria, guardianCount, broadcastModal, selectedMembersCount, sortCriteria} = this.state;

        let actionClass = (filterCriteria.status >0) ? "more-action-btn__links": "more-action-btn__links--disable";
        let isDisabled = (filterCriteria.status >0) ? false: true;

        const isCoachOrAdminLogin = Helper.isCoachOrAdminLogin();
        const userInfo = this.state.userInfo,
            userRoles = userInfo.role[userInfo.selectedClubId];

        let showBroadcastBtn = false;

        if(Helper.isCoachOrAdminLogin() && !this.state.pendingInviteFilter && selectedMember.size > 0){
            showBroadcastBtn = true;
        }
        let selectedMemberTxt = '';
        if(this.state.pendingInviteFilter && selectedPendingMember.size > 0){
            selectedMemberTxt = selectedPendingMember.size == 1 ? `${selectedPendingMember.size} contact selected` : `${selectedPendingMember.size} contacts selected`;
        } else if(!this.state.pendingInviteFilter && selectedMember.size > 0){
            let includeLoggedInUser = true;    // add logged in member count
            let membersLength = Helper.fetchUniqueMembersCount(selectedMember, includeLoggedInUser, null);
            selectedMemberTxt = (membersLength == 1) ? `${membersLength} contact selected` :  `${membersLength} contacts selected`;
        }
        let activeTab = this.fetchActiveTabKey();
        return (
            <>
                <Loader loading={loading} />
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={4} md={2}>
                            <h1>Contacts</h1>
                            <label className="selected-count">{selectedMemberTxt}</label>
                        </Col>
                        <Col md={10}>
                            <div className="c-filter-outer">
                                {
                                    (isCoachOrAdminLogin && !this.state.pendingInviteFilter) &&
                                    <div className="wgt-filters">
                                        <label>Filter by:</label>
                                        <div className="sel-wrap">
                                            <Dropdown className="sel-dropdown" onSelect={(e) => this.doDebouncedStatusFilterSelect(e)} >
                                                <Dropdown.Toggle id="dropdown-item-button" title={this.state.dropDownValue} className="format">
                                                    {this.state.dropDownValue}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="1"><div>Active</div></Dropdown.Item>
                                                    <Dropdown.Item eventKey="0" ><div>Inactive</div></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="sel-wrap">
                                            <Select className="sel-dropdown"
                                                closeMenuOnSelect={false}
                                                isMulti="true"
                                                value={this.state.selectedOption}
                                                onChange={this.doDebouncedTeamMuliSelectChange}
                                                options={this.state.teamSelectList}
                                                placeholder="Member Groups"
                                            />
                                        </div>
                                    </div>
                                }
                                {/* {
                                (selectedPendingMember.size > 0 || selectedMember.size > 0 )&&
                                    <FixedActionBar
                                        checkedPendingItems={this.state.checkedPendingItems}
                                        createChatBtnHandler = {this.toggleGroupModal}
                                        broadcastBtnHandler = {Helper.fetchSelectedMembersCount}
                                        resendBtnHandler = {this.resendPendingAllInvite}
                                        cancelBtnHandler = {this.deselectAll}
                                        resendCancelBtnHandler = {this.deselectPendingAll}
                                        addToChatGroupBtnHandler = {this.addToChatGroupBtnHandler}
                                        deletePendingInvitesHandler = {Helper.onRemovePendingInvite}
                                        broadcastBtnLoader = {broadcastBtnLoader}
                                        selectedMemberTxt = {selectedMemberTxt}
                                        showBroadcastBtn = {showBroadcastBtn}
                                        showPendingInvitesBtn = {(this.state.pendingInviteFilter && selectedPendingMember.size > 0) ? true : false}
                                        showcreateChatBtn = {(!this.state.pendingInviteFilter && selectedMember.size > 0) ? true : false}
                                        showAddToChatGroupBtn = {(!this.state.pendingInviteFilter && selectedMember.size > 0) ? true : false}
                                        addToChatGroupBtnTxt = {'Add to Chat Groups'}
                                        groupModal = {groupModal}
                                        disabled={this.state.disabled}
                                        mainObj={this}
                                        type={'contacts'}
                                    />
                                } */}
                                <div className="wgt-btnwrap">

                                    {
                                        isCoachOrAdminLogin &&
                                        <OverlayTrigger placement="auto"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                   Invite New Contact
                                                </Tooltip>}>
                                                <a href={null} onClick={() => this.toggleModal(!modalVisibility)}>
                                                <div className="outline-circle">
                                                    <Image src={Images.AddContactRed} alt="Add member" width="30" />
                                                </div>
                                            </a>
                                        </OverlayTrigger>
                                    }

                                    {
                                        (selectedMember.size > 0 || selectedPendingMember.size > 0) &&
                                        <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                    Clear Selection
                                                    </Tooltip>}>
                                                    <a href={null} onClick= {() => {
                                                        this.deselectAll();
                                                        this.deselectPendingAll();
                                                    }}>
                                                <div className="outline-circle">
                                                    <Image src={Images.Cancel} alt="Cancel" width="25" />
                                                </div>
                                            </a>
                                        </OverlayTrigger>
                                    }


                                        <Dropdown className="more-action-btn">
                                          <OverlayTrigger placement="auto"
                                            overlay={
                                            <Tooltip id={`tooltip`}>
                                              More Actions
                                            </Tooltip>}>
                                            <Dropdown.Toggle id="more-actions">
                                              <div className="outline-circle">
                                                  <Image src={Images.moreActions} alt="More Actions" width="25" />
                                              </div>
                                            </Dropdown.Toggle>
                                          </OverlayTrigger>
                                          <Dropdown.Menu>
                                          {
                                            !this.state.pendingInviteFilter &&
                                            <>
                                              {
                                                isCoachOrAdminLogin &&
                                                <Dropdown.Item disabled={isDisabled} className={actionClass}>
                                                  <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('add-group-members', 'active')}}>
                                                    <Image src={Images.editSafechat} alt="Upload" width="21" />
                                                    <em>Add to SafeChat Group</em>
                                                  </span>
                                                </Dropdown.Item>
                                              }
                                              <Dropdown.Item disabled={isDisabled} className={actionClass}>
                                                    <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('create-group', 'active')}}>
                                                      <Image src={Images.createSafechat} alt="Upload" width="21" />
                                                      <em>Create SafeChat Group</em>
                                                    </span>

                                              </Dropdown.Item>
                                              {
                                                  Helper.isCoachOrAdminLogin() &&
                                                  <>
                                                      <Dropdown.Item disabled={isDisabled} className={actionClass} >
                                                    <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('broadcast', 'active')}}>
                                                    <Image src={Images.AnnouncementRed} alt="Upload" width="21" />
                                                      <em>Send Announcement</em>
                                                    </span>
                                                  </Dropdown.Item>

                                                  <Dropdown.Item>
                                                          <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('deactivate', 'active')}}>
                                                          <Image src={filterCriteria.status >0 ? Images.DeleteIc: Images.ActivateIcon } alt="Upload" width="21" />
                                                          <em> { filterCriteria.status >0 ? `Deactivate`: `Activate`} contacts</em>
                                                          </span>
                                                  </Dropdown.Item>
                                                      </>
                                              }
                                            </>
                                          }
                                              {
                                                  (isCoachOrAdminLogin && this.state.pendingInviteFilter) &&
                                                  <>
                                                      <Dropdown.Item>
                                                          <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('delete-invites', 'pending')}}>
                                                          <Image src={Images.DeleteIc} alt="Delete" width="21" />
                                                          <em> Remove Contact(s)</em>
                                                          </span>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item>
                                                          <span className="more-action-btn__links" onClick={() => {this.btnClickHandler('resend-invites', 'pending')}}>
                                                          <Image src={Images.resendRed} alt="Resend" width="19" />
                                                          <em> Resend</em>
                                                          </span>
                                                      </Dropdown.Item>
                                                  </>
                                              }

                                        </Dropdown.Menu>
                                      </Dropdown>

                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="widget">
                                {/* Contact Listing */}
                                <div>
                                    <div className={isCoachOrAdminLogin ? "tab-search tab-search--big" : "tab-search tab-search--big tab-search--widget"}>
                                        <Form inline onSubmit={e => { e.preventDefault(); }}>
                                            <input type="text" name="focus" onChange={(e) => this.doDebouncedSearchKeywordChange(e.target)} placeholder="Search" className={isCoachOrAdminLogin ? "form-control tab-search__box" : "form-control tab-search__box"} />
                                            <button className="close-icon" type="reset" onClick={(e) => this.doDebouncedSearchKeywordChange(e.target)}></button>
                                        </Form>
                                    </div>
                                    {isCoachOrAdminLogin &&
                                        <Tabs defaultActiveKey={activeTab} onSelect={this.onRoleTabSelect}>
                                            <Tab eventKey="0" title="All">
                                                <ContactList
                                                    data={this.state.filteredContacts}
                                                    onCheckboxChange={(item) => { this.selectMember(item) }}
                                                    checkedItems={this.state.checkedItems}
                                                    onDeactivateContactClick={(item) => this.onDeactivateContactClick(item.id)}
                                                    onRemoveSubAdminClick={(item) => this.onRemoveSubAdminClick(item)}
                                                    onRemoveInstructorClick={(item) => this.onRemoveInstructorClick(item)}
                                                    toggleLoader={this.toggleLoader}
                                                    screen={"Contact"}
                                                    fiterByStatus={filterCriteria.status}
                                                    checkedAll={checkedAll}
                                                    handleAllChecked={this.handleAllChecked}
                                                    sortHandler={this.sortHandler}
                                                    sortCriteria={sortCriteria}
                                                    allProps={this.props}
                                                    onResetPasswordClick={this.onResetPasswordClick}
                                                    filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                                    getContactList={this.getContactList}
                                                />
                                            </Tab>
                                            <Tab eventKey={Enum.UserRoles.ATHLETE} title={Helper.memberRole([Enum.UserRoles.ATHLETE])}>
                                                <ContactList
                                                    data={this.state.filteredContacts}
                                                    onCheckboxChange={(item) => { this.selectMember(item) }}
                                                    checkedItems={this.state.checkedItems}
                                                    onDeactivateContactClick={(item) => this.onDeactivateContactClick(item.id)}
                                                    onRemoveSubAdminClick={(item) => this.onRemoveSubAdminClick(item)}
                                                    toggleLoader={this.toggleLoader}
                                                    screen={"Contact"}
                                                    fiterByStatus={filterCriteria.status}
                                                    handleAllChecked={this.handleAllChecked}
                                                    checkedAll={checkedAll}
                                                    sortHandler={this.sortHandler}
                                                    sortCriteria={sortCriteria}
                                                    allProps={this.props}
                                                    onResetPasswordClick={this.onResetPasswordClick}
                                                    filterContactList={(id, isUpdated)=>Helper.filterContactList(id, isUpdated, this)}
                                                    getContactList={this.getContactList}
                                                />
                                            </Tab>
                                            <Tab eventKey={Enum.UserRoles.LEGAL_GUARDIAN} title={Helper.memberRole([Enum.UserRoles.LEGAL_GUARDIAN])}>
                                                <ContactList
                                                    data={this.state.filteredContacts}
                                                    onCheckboxChange={(item) => { this.selectMember(item) }}
                                                    checkedItems={this.state.checkedItems}
                                                    onDeactivateContactClick={(item) => this.onDeactivateContactClick(item.id)}
                                                    onRemoveSubAdminClick={(item) => this.onRemoveSubAdminClick(item)}
                                                    onRemoveInstructorClick={(item) => this.onRemoveInstructorClick(item)}
                                                    toggleLoader={this.toggleLoader}
                                                    selectedTab={Enum.UserRoles.LEGAL_GUARDIAN}
                                                    screen={"Contact"}
                                                    fiterByStatus={filterCriteria.status}
                                                    handleAllChecked={this.handleAllChecked}
                                                    checkedAll={checkedAll}
                                                    sortHandler={this.sortHandler}
                                                    sortCriteria={sortCriteria}
                                                    onResetPasswordClick={this.onResetPasswordClick}
                                                    allProps={this.props}
                                                />
                                            </Tab>
                                            <Tab eventKey={Enum.UserRoles.COACH} title={Helper.memberRole([Enum.UserRoles.COACH])}>
                                                <ContactList
                                                    data={this.state.filteredContacts}
                                                    onCheckboxChange={(item) => { this.selectMember(item) }}
                                                    checkedItems={this.state.checkedItems}
                                                    onDeactivateContactClick={(item) => this.onDeactivateContactClick(item.id)}
                                                    onRemoveSubAdminClick={(item) => this.onRemoveSubAdminClick(item)}
                                                    onRemoveInstructorClick={(item) => this.onRemoveInstructorClick(item)}
                                                    toggleLoader={this.toggleLoader}
                                                    selectedTab={Enum.UserRoles.COACH}
                                                    screen={"Contact"}
                                                    fiterByStatus={filterCriteria.status}
                                                    handleAllChecked={this.handleAllChecked}
                                                    checkedAll={checkedAll}
                                                    sortHandler={this.sortHandler}
                                                    sortCriteria={sortCriteria}
                                                    onResetPasswordClick={this.onResetPasswordClick}
                                                    allProps={this.props}
                                                />
                                            </Tab>
                                            <Tab eventKey="pendingInvites" title="Pending Invites">
                                                <PendingContactList
                                                    data={this.state.pendingContacts}
                                                    selectPendingMembers={(item) => this.selectPendingMembers(item)}
                                                    checkedPendingItems={this.state.checkedPendingItems}
                                                    editPendingInviteUser={(index, roleId) => this.editPendingInviteUser(index, roleId)}
                                                    resendPendingInvite={(index) => this.resendPendingInvite(index)}
                                                    onRemovePendingInviteClick={(item) => Helper.onRemovePendingInvite(item, this)}
                                                    checkedAll={checkedAll}
                                                    screen={"Contact"}
                                                    handlePendingAllChecked={this.handlePendingAllChecked}
                                                    fiterByStatus={filterCriteria.status}
                                                    sortHandler={this.sortHandler}
                                                    sortCriteria={sortCriteria}
                                                    copyInviteLink={(item)=>Helper.copyInviteLink(item, this)}
                                                    copyInviteId={this.state.copyInviteId}
                                                />
                                            </Tab>
                                        </Tabs>
                                    }
                                    {!isCoachOrAdminLogin &&
                                        <ContactList
                                            data={this.state.filteredContacts}
                                            onCheckboxChange={(item) => { this.selectMember(item) }}
                                            checkedItems={this.state.checkedItems}
                                            onDeactivateContactClick={(item) => this.onDeactivateContactClick(item.id)}
                                            toggleLoader={this.toggleLoader}
                                            onRemoveSubAdminClick={(item) => this.onRemoveSubAdminClick(item)}
                                            screen={"Contact"}
                                            fiterByStatus={filterCriteria.status}
                                            handleAllChecked={this.handleAllChecked}
                                            checkedAll={checkedAll}
                                            sortHandler={this.sortHandler}
                                            sortCriteria={sortCriteria}
                                            onResetPasswordClick={this.onResetPasswordClick}
                                            allProps={this.props}
                                        />
                                    }
                                    {!this.state.recordLoaded && <SmallLoader />}
                                    {(this.state.recordLoaded && (
                                        (this.state.filteredContacts.length === 0 && !this.state.pendingInviteFilter)
                                        || (this.state.pendingContacts.length === 0 && this.state.pendingInviteFilter)
                                    )) && <NoRecord />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <GoTop scrollStepInPx="50" delayInMs="10" />
                </div>


        <CreateChatGroup
          selectedMember={selectedMember}
          groupModal={groupModal}
          toggleGroupModal={this.toggleGroupModal}
          deleteSelectedMember={this.deleteSelectedMember}
          history={this.props.history}
          checkedAll={checkedAll}
          allProps={this.props}
        />
        <BroadcastMesaage
          selectedMembers={selectedMember}
          selectedMembersCount={selectedMembersCount}
          broadcastModal={broadcastModal}
          toggleBroadcastModal={Helper.toggleBroadcastModal}
          deselectAll={this.deselectAll}
          type="contact"
          mainObj={this}
        />
        <SingleInvite
          modalVisibility={modalVisibility}
          toggleModal={this.toggleModal}
          history={this.props.history}
          userRoles={userRoles}
          checkRoles={Enum.UserRoles}
          defaultTab={"legalguardian"}
          getPendingContacts={() => this.getPendingContacts()}
        />
        {editPendingModalVisibility && (
          <EditPendingLG
            modalVisibility={editPendingModalVisibility}
            toggleModal={this.toggleEditPendingModal}
            data={this.state.editPendingUser}
            getPendingContacts={() => this.getPendingContacts()}
          />
        )}
        {editInstructorModalVisibility && (
          <EditPendingInstructor
            modalVisibility={editInstructorModalVisibility}
            toggleModal={this.toggleEditPendingInstructorModal}
            data={this.state.editPendingUser}
            getPendingContacts={() => this.getPendingContacts()}
          />
        )}

        {resendInviteVisibility && (
          <ResendInvite
            closeInvite={() => {
              this.setState({
                resendInviteVisibility: false,
              });
            }}
            data={this.state.editPendingUser}
            getPendingContacts={() => this.getPendingContacts()}
          />
        )}
        {resendAllInviteVisibility && (
          <ResendAllInvite
            closeInvite={() => {
              this.setState({
                resendAllInviteVisibility: false,
                selectedPendingMember: new Map(),
                checkedPendingItems: new Map(),
                checkedAll: false,
              });
            }}
            data={this.state.selectedPendingMember}
          />
        )}
        <DeleteConfirmModal
          deleteConfirmShow={this.state.updateContact.deleteConfirmShow}
          onHide={this.onActivateDeactivateContactConfirmClick}
          onCancelClick={this.onDeactivateContactCancelClick}
          onConfirmClick={
              this.state.addRevokeSubAdminFlag
                  ? this.onRemoveSubAdminConfirmClick
                  : (this.state.revokeInstructorFlag)? this.onRemoveInstructorConfirmClick : this.onActivateDeactivateContactConfirmClick
          }
          confirmText={this.state.updateContact.confirmText}
          confirmButtonText={this.state.updateContact.confirmButtonText}
          confirmType={this.state.updateContact.confirmTypeStatus}
          modalLoader={this.state.modalLoader}
          // onRemoveSubAdminConfirmClick={this.onRemoveSubAdminConfirmClick}
        />
        <ConfirmModal
          show={this.state.confirmShow}
          onCancelClick={()=>this.setState({confirmShow: false})}
          onConfirmClick={this.onResetPasswordConfirm}
          buttonText="Reset Password"
          title="Reset user's password?"
          body="A new random password for this user will be generated and sent to the user via email."
          note="In case of student without email address, the password will be sent to the legal guardian."
          modalLoader={this.state.modalLoader}
        />
        <SuccessModal
            successConfirmShow={this.state.successConfirmShow}
            onHide={this.onSuccessCancel}
            onConfirmClick={() => { this.setState({ successConfirmShow: false }) }}
            successText={this.state.successText}
            successButtonText={"OK"}
        />
        <ErrorModal
            show={this.state.showError}
            message={this.state.errorMessage}
            onConfirmClick={() => { this.setState({ showError: false }) }}
        />
        <UpdateMiddleNameModal
          show={this.state.showMiddleNameWarning}
          handleLogout={this.props.handleLogout}
          currentProps={this.props}
        />

      </>
    );
  }
}

export { Contact };
