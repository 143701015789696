/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Col, Alert, Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Input Mask
 * Name and Version: react-text-mask, 5.4.3
 * Download link: https://www.npmjs.com/package/react-text-mask
 */
import MaskedInput from 'react-text-mask';
/* End - react-text-mask */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

import { CometChat } from "@cometchat-pro/chat";

import * as Storage from '../../components/common/storage';

import * as Enum from '../../components/common/enum'
import { Messages } from './message';

import { messaging } from './firebase';

/*START - Covalience Property*/
export const ScrollTop = () => {
    window.scrollTo({
        top: 0,
        left: 0
    });
};

export function axiosInstance() {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Storage.Get('user') ? Storage.Get('user').token : ''}`
        }
    })
};

export function getMemberName(member) {
    let output = '';
    if (member.first_name) {
        output += member.first_name;
        if (member.last_name) {
            output += ' ';
        }
    }
    if (member.last_name) {
        output += member.last_name;
    }
    return output;
}

export function memberRoleArray(roleIds) {
    let output = [];
    if (roleIds.length) {
        output = roleIds.map((roleId, i) => {
            return (Enum.UserRolesDisplay[roleId]);
        });
    }
    return output;
}

//Generic reuest error
export const RequestError = () => (<div className="alert alert-danger mt-4">Your request can not be submitted at the moment, please try again later.</div>);


export const NoRecordFound = () => (<Col sm={12} md={12} lg={12}><Alert variant="warning">No record found.</Alert></Col>);

//Upper case the words
export const UCWords = (str) => str.replace('-', ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });


//HTML entity decode
export const htmlDecode = (html) => {
    var e = document.createElement('div');
    e.innerHTML = html;
    //return e.childNodes[0].nodeValue;
    return e.innerHTML;
}

//Get URL param
export const checkUrlContains = (name) => {
    if (window.location.href.indexOf(name) > -1) {
        return true;
    } else {
        return null;
    }
}

export function memberRole(roleIds) {
    let output = '';
    if (roleIds !== undefined && roleIds.length) {
        roleIds.map((roleId, i) => {
            if (i > 0) {
                output += ", ";
            }
            output += Enum.UserRolesDisplay[roleId];
        });
    }
    return output;
}

export function generateAvatar(member) {
    return <Image style={{ height: '50px', width: '50px' }} className="mr-2 im-rounded" src={member.photo} alt={member.first_name} />;
}

export function inputFocus(event) {
    event.target.nextElementSibling.classList.add("label-active");
}

export function inputBlur(event) {
    if (event.target.value.length === 0) {
        event.target.nextElementSibling.classList.remove("label-active");
    }
}

export function isSuperAdminLogin() {
    let userInfo = Storage.Get('user');
    return userInfo ? userInfo.is_super_admin: false;
}

export function isISRLogin() {
    let userInfo = Storage.Get('user');
    return userInfo ? userInfo.is_isr && !userInfo.selectedClubId : false;
}

export function isCoachOrAdminLogin() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.COACH) || loggedInUserRoles.includes(Enum.UserRoles.CLUB_ADMIN) || loggedInUserRoles.includes(Enum.UserRoles.CLUB_SUB_ADMIN);
}

export function guardianCount(member, isChatGroup) {
    let guardians = [];
    //let loginUserGuardians = [];
    for (let val of member.values()) {
        if ( val.guardians &&  val.guardians.length > 0) {
            for (let key in val.guardians) {
                if(!guardians.includes(val.guardians[key].username)){
                    guardians.push(val.guardians[key].username);
                }
            }
        }
    }

    if(isChatGroup){
        return [...new Set(guardians)].length;
    }

    let userInfo = Storage.Get('user');
    for (let key in userInfo.guardian) {
        if(!guardians.includes(userInfo.guardian[key])){
            guardians.push(userInfo.guardian[key]);
        }
    }
    //loginUserGuardians.push(userInfo.guardian);
    return [...new Set(guardians)].length;
    //return [...new Set(guardians)].length+[...new Set(loginUserGuardians[0])].length;
}
export function isAdminOrSubAdmin() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.CLUB_ADMIN) || loggedInUserRoles.includes(Enum.UserRoles.CLUB_SUB_ADMIN);
}

export function isAdminLogin() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.CLUB_ADMIN);
}

export function isInstructor() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.COACH);
}

export function isLegalGuardian() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.LEGAL_GUARDIAN);
}

export function isKidLogin() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    const loggedInUserRoles = userInfo.role[userInfo.selectedClubId];
    if (!userInfo.role[userInfo.selectedClubId]) return false;
    return loggedInUserRoles.includes(Enum.UserRoles.ATHLETE);
}

// To reload the page in case of server error, on click of try again button
export function handleErrorClose() {
    window.location.reload();
}

// To open the mail to contact website support
export function handleSupportContact() {
    window.location.href = `mailto:${Enum.SupportEmail}`;
}

export function getLabelClass(val) {
    return `form-label${typeof val !== "undefined" && val !== "" ? ` label-active` : ``}`;
}

export function getSelectedClubId() {
    let userInfo = Storage.Get('user');
    if (userInfo === null || userInfo === undefined) return false;
    return userInfo.selectedClubId && userInfo.selectedClubId !== undefined ? userInfo.selectedClubId : null;
}

export function isValidEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true);
    }
    return (false);
}

export function PhoneMaskedInput(props) {
    return (<MaskedInput
        autoComplete="off"
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        className={props.className}
        disabled={props.disabled ? true : false}
        onFocus={inputFocus}
        type="text"
        id={props.id}
        guide={true}
        {...props.field}
        onChange={props.onChange}
        onBlur={inputBlur}
    />
    )
}

export function SSNMaskedInput(props) {
    return (<MaskedInput
            autoComplete="off"
            mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            className={props.className}
            disabled={props.disabled ? true : false}
            onFocus={inputFocus}
            type={props.type}
            id={props.id}
            guide={props.guide}
            {...props.field}
            onChange={props.onChange}
            onBlur={inputBlur}

        />
    )
}


// To format date to MM/DD/YYYY
export function formatDate(date, type) {
    let monthNames = [
        "01", "02", "03",
        "04", "05", "06", "07",
        "08", "09", "10",
        "11", "12"
    ];

    let day = date.getDate(),
        monthIndex = date.getMonth(),
        year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    if (type === 'checkKidExists') {
        return year + '-' + monthNames[monthIndex] + '-' + day;
    }
    else {
        return monthNames[monthIndex] + '/' + day + '/' + year;
    }
}

export function initCometChat(userData, mainObj) {
    const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
    const region = "us";
    const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
    CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
            // for users, other than super-admin and ISR
            if(!userData){
                userData = Storage.Get('user');
            }
            if(userData){
                if(!userData.is_super_admin && userData.selectedClubId) {
                    // CometChat.login(userData.username, authKey).then( //unsecure
                    CometChat.login(userData.cometchat_token).then( //secure
                        async (user) => {
                            console.log("Login Successful:", { user });
                            setUserStorage(mainObj, userData, true);

                            /******************* PUSH NOTIFICATIONS ********************/
                                // The user has successfully logged in and is on the Home page.

                                // 3. Register the FCM Token.
                                // This step is comprised of multiple smaller steps before you can
                                // actually register the FCM_TOKEN with CometChat's Push Notifications extension.
                            let FCM_TOKEN = window.localStorage.getItem("FCM_TOKEN");
                            if (!FCM_TOKEN) {
                                // 3.1. Get the settings for the app.
                                // This is required to get the list of extensions that are enabled.
                                // From the list of extensions, check if the push-notification extension is enabled.
                                // We only proceed when the Push Notifications extension is enabled.
                                const settings = await CometChat.getAppSettings();
                                const extension = settings.extensions.filter(ext => ext.id === 'push-notification');

                                if (extension.length > 0) {
                                    try {
                                        // 3.2. Request User's permission to receive Push Notifications.
                                        // If the permission is granted, use getToken() method on the messaging object.
                                        // Store the obtained token in LocalStorage of your browser.
                                        FCM_TOKEN = await messaging.getToken();
                                        console.log("FCM_TOKEN", FCM_TOKEN);
                                        if (FCM_TOKEN) {
                                            window.localStorage.setItem("FCM_TOKEN", FCM_TOKEN);

                                            // 3.3. Register the FCM Token with CometChat's Push Notifications extension.
                                            // This is important since the Push Notification will be sent directly using
                                            // FCM_TOKEN obtained above.
                                            //
                                            // Next, you can check the logout() method below to delete the FCM Token.
                                            const response = await CometChat.registerTokenForPushNotification(FCM_TOKEN);
                                            console.log('Registered FCM Token:', response);
                                        }
                                    } catch (error) {
                                        // Elegantly fail if the Permission is not granted.
                                        console.log(error);
                                    }
                                } else {
                                    // Do not use request or register a token as the extensions is not enabled.
                                    console.log('Push Notifications extension is not enabled');
                                }
                            }
                            /******************* END - PUSH NOTIFICATIONS ********************/

                        },
                        error => {
                            console.log("Login failed with exception:", { error });
                        }
                    );
                } else {
                    // for super-admin user, no chat required
                    setUserStorage(mainObj, userData, true);
                }
            }
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
    );


}

const setUserStorage = (mainObj, userData, response) => {
    if (mainObj) {
        mainObj.setState({
            showLoader: false
        });
        if (response) {
            // login successful, perform your actions if any, for example: load contacts, getting unread message count, etc
            const userProps = mainObj.props.childProps;
            if (userProps) {
                Storage.Set('user', JSON.stringify(userData));
                if(typeof userData.add_middle_name != 'undefined'){
                    Storage.Set('add_middle_name', userData.add_middle_name);
                } else {
                    Storage.Delete('add_middle_name');
                }
                userProps.userHasAuthenticated(true);
                let user = Storage.Get('user');
                user['logged_in_time'] = Date.now();
                Storage.Set('user', JSON.stringify(user));
            }
        } else {
            // error in user login/register (you can hide chat button or refresh page)
            mainObj.setState({
                messageType: 'danger',
                message: 'Invalid username or password'
            });
        }
    }
}

export function selectMember(member, selectedMember, checkedItems) {
    const userId = member.id;
    if (typeof selectedMember.get(userId) == "undefined") {
        const name = getMemberName(member),
            avatar = generateAvatar(member),
            username = member.username,
            guardians = member.guardian;
        selectedMember.set(userId, { name, avatar, username, guardians, userId });
        checkedItems.set(userId, true);
    } else {
        selectedMember.delete(userId);
        checkedItems.delete(userId);
    }
    return { selectedMember, checkedItems };
}


// set the user control to top of the page
export function scrollToTop() {
    window.scroll(0,0);
}

// redirect the user control to home page
export function redirectToHome(props){
    props.history.push('/');
}

export function teamName(selectedTeam) {
    let output = selectedTeam.name;
    output += selectedTeam.location ? ` - ${selectedTeam.location}` : "";
    return output;
}

export function toggleBroadcastModal(visible, mainObj) {
    mainObj.setState({ broadcastModal: visible, errorMessage: null });
}

export function fetchSelectedMembersCount(sendToAll, mainObj, type, groupId){

    let data = {},
        userInfo = Storage.Get('user');
    data.club_id = userInfo.selectedClubId;
    mainObj.setState({ loading: true});
    if(typeof sendToAll != 'undefined' && (sendToAll === true || sendToAll === 'admins')){
        data.send_to = sendToAll === true ? 'all' : sendToAll;
        mainObj.setState({selectedMembers: data.send_to})
    } else {
        if(typeof type != 'undefined' && type != null){
            let memberIds = [];
            if(type == 'contacts'){
                let selectedMembers = mainObj.state.selectedMember;
                for (let val of selectedMembers.values()) {
                    memberIds.push(val.userId);
                }
                data.member_id = memberIds;
            } else if(type == 'groups'){
                let selectedGroups = mainObj.state.selectedGroups;
                for (let val of selectedGroups.values()) {
                    memberIds.push(val.groupId);
                }
                data.group_id = memberIds;
            } else if(type == 'individual_groups'){
                let selectedGroups = mainObj.state.selectedGroups;
                for (let val of selectedGroups.values()) {
                    memberIds.push(val.groupId);
                }
                data.individual_group_id = memberIds;
            } else if(type == 'teams'){
                console.log('mainObj.state.selectedMemberGroups', mainObj.state.selectedMemberGroups)
                let selectedMemberGroups = mainObj.state.selectedMemberGroups ? mainObj.state.selectedMemberGroups : mainObj.state.selectedGroups;
                //If a group is directly selected for announcement from the member group list page.
                if(groupId){
                    memberIds.push(groupId);
                    selectedMemberGroups.set(groupId, { groupId });
                    mainObj.setState({ selectedMemberGroups: selectedMemberGroups });
                }else{
                    for (let val of selectedMemberGroups.values()) {
                        memberIds.push(val.groupId);
                    }
                }
                data.team_id = memberIds;
            }
        }
    }
    axiosInstance()
        .post('broadcast/members-count', data)
        .then((response) => {
            if (response.data.success === true && response.data.count > 0) {
                mainObj.setState({selectedMembersCount: response.data.count, broadcastModal: true});
            } else if(response.data.count == 0){
                mainObj.setState({ errorMessage: response.data.message, showError: true});
            } else {
                mainObj.setState({ errorMessage: Messages.NO_MEMBER_SELECTED, showError: true});
            }
        })
        .catch(function (error) {
            mainObj.setState({ errorMessage: Messages.SERVER_ERROR, showError: true});
        })
        .finally( () => {
            mainObj.setState({ loading: false});
        });

}

export function fetchUniqueMembersCount(members, includeLoggedInUser, addedMembers) {
    let membersArr = [];
    for (let val of members.values()) {
        let guardians = [];
        if ( val.guardian &&  val.guardian.length > 0){
            guardians = val.guardian;
        } else if(val.guardians &&  val.guardians.length > 0){
            guardians = val.guardians;
        }
        if (guardians.length > 0 ) {
            for (let key in guardians) {
                membersArr.push(guardians[key].username);
            }
        }
    }
    for (let val of members.values()) {
        for (let key in val) {
            membersArr.push(val.username);
        }
    }
    if(includeLoggedInUser){
        let userInfo = Storage.Get('user');
        membersArr.push(userInfo.username);
        for (let key in userInfo.guardian) {
            membersArr.push(userInfo.guardian[key].username);
        }
    }
    if(addedMembers != null && addedMembers.length > 0){
        membersArr = membersArr.filter(function(val) {
            return addedMembers.indexOf(val) == -1;
        });
    }
    return ([...new Set(membersArr)].length)? [...new Set(membersArr)].length: 0
}

export function returnNoOfDays(month){
    let totalDays = 0;
    if (month) {
        switch (parseInt(month)) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                totalDays = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                totalDays = 30;
                break;
            case 2:
                totalDays = 29;
            default:
               console.log("Invalid month.");
                break;
        }
    }
    return totalDays;
}

export function onRemovePendingInvite(selectedMembers, mainObj){
    const selectedClubId = mainObj.state.userInfo.selectedClubId;
    let memberIds = [];
    if(typeof selectedMembers.invite_id != 'undefined'){
        memberIds.push(selectedMembers.invite_id);
    } else {
        for (let val of selectedMembers.keys()) {
            memberIds.push(val);
        }
    }
    let data =  {
        invite_id: memberIds,
        club_id: selectedClubId,
    };
    if(typeof mainObj.state.teamId != 'undefined' && mainObj.state.teamId > 0){
        data.team_id = mainObj.state.teamId;
    }
    mainObj.setState({ loading: true});
    axiosInstance()
        .delete('invite/delete', {data})
        .then((response) => {
            if (response.data.success === true ) {
                mainObj.setState({
                    successConfirmShow: true,
                    successText: response.data.message,
                    checkedPendingItems: new Map(),
                    selectedPendingMember: new Map()
                });
                mainObj.reloadPendingContacts(mainObj.state.pendingFilterCriteria);
            } else {
                mainObj.setState({ errorMessage: response.data.message, showError: true});
            }
        })
        .catch((error) => {
            if ((error.response.status === 401 || error.response.status === 404) && error.response.data !== undefined) {
                mainObj.setState({ errorMessage: error.response.data.error, showAccessDeniedError: true });
            } else {
                mainObj.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
            }
        })
        .finally( () => {
            mainObj.setState({ loading: false});
        });

}

export const filterContactList = (uId, isUpdated, mainObj) =>{
    let filteredContacts = mainObj.state.filteredContacts;
    if(!isUpdated){
        filteredContacts = filteredContacts.filter((x) => x.id !== uId);
        mainObj.setState({ filteredContacts: filteredContacts})
    }
}
export const copyInviteLink = (member, mainObj) => {
    navigator.clipboard.writeText( process.env.REACT_APP_WEB_URL+'invite/'+member.invite_code);
    mainObj.setState({ copyInviteId : member.invite_id}, ()=>{
        setTimeout(()=> {
            mainObj.setState({copyInviteId: ''})
        }, 2000)
    })
}

export function fetchISR(mainObj,clubId, ISRId= null){
    axiosInstance()
        .get('isr/list')
        .then((response) => {
            mainObj.setState({ ISRClubId:clubId, iSROptions: response.data.data.map((row, i) => {
                    return { label: `${row.first_name} ${row.last_name}`, value: row.id };
                }),});
        })
        .catch((error) => {
            mainObj.setState({ errorMessage: Messages.SERVER_ERROR, showError: true});
        })
        .finally( () => {
            mainObj.setState({ loading: false});
        });

}

export const getDate = function(dateOb, type, nth=false) {
    if(type === 'd'){
        return nth ? dateOb.getUTCDate() + getNth(dateOb.getUTCDate()) : dateOb.getUTCDate()
    }else if(type === 'm'){
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        return monthNames[dateOb.getUTCMonth()];
    }if(type === 'y'){
        return dateOb.getUTCFullYear();
    }

}
export const getNth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}
/*END - Covalience Property*/
