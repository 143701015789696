/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/* Start - app imports */
import {AccountInformation} from './account-information';
import * as Helper from '../../components/common/functions';
import { ErrorModal } from '../../components/modal/modalbox';
import { Messages } from '../../components/common/message';
import * as Storage from '../../components/common/storage';
import * as Enum from '../../components/common/enum';
import {Footer} from '../../components/footer/footer';
import {RegisterSuccess, InviteHeader, RegisterError} from './invite-helper';
import './invite.scss';
/* End - app imports */

class InviteLG extends Component {  
    constructor(props){
        super(props);
        this.state = {
            inviteDetails: Storage.Get('inviteDetails'),
            inviteToken: props.match.params.inviteToken,
            inviteError: "",
            serverValid: {
                guardian_first_name: "",
                guardian_last_name: "",
                guardian_email: "",
                guardian_username: "",
                guardian_phone: "",
                guardian_password: "",
                guardian_confirm_password: "",
            },
            serverSuccess: "",
            serverError: "",
            formProcessing: false,
            registerMsg: "",     
        };
    }
    componentDidMount() {
        const { inviteToken, inviteDetails} = this.state;
        if(inviteDetails === null || inviteDetails.invite_code === "" || inviteToken !== inviteDetails.invite_code || inviteDetails.user_id === null) {
            this.setState({inviteError: Enum.InviteError});
        }
    }

    resetServerError(){
        this.setState({
            serverValid : {
                guardian_first_name: "",
                guardian_last_name: "",
                guardian_email: "",
                guardian_username: "",
                guardian_phone: "",
                guardian_password: "",
                guardian_confirm_password: "",
            }
        });
    }

    handleDetailsSubmit(fields){
        // update fields
        let inviteDetails = this.state.inviteDetails;
        // eslint-disable-next-line
        for(const index in fields){
            inviteDetails[index] = fields[index];
        }
        Storage.Set('inviteDetails',JSON.stringify(inviteDetails));

        this.setState({ formProcessing: true });
        
        const me = this;

        // Api request to save Legal guardian Info.
        Helper.axiosInstance().post('register/lg', inviteDetails)
        .then((response) => {
            // If server response message same as Data Matched
            if (response.data.success) {
                me.setState({ serverSuccess: response.data.message, formProcessing: false, registerMsg: `You have been registered successfully as a legal guardian of ${Helper.UCWords(Helper.getMemberName(inviteDetails.user))}'s SafeComm account` });
                Storage.Delete('inviteDetails');
            } else {
                if(typeof response.data.type != 'undefined' && response.data.type == 'invite_code'){
                    this.setState({inviteError: Enum.InviteError});
                } else {
                    // get the server form errors and set under state 'serverValid'
                    const getErrors = response.data.error;
                    me.resetServerError();

                    let serverValid = me.state.serverValid;
                    // eslint-disable-next-line
                    for(let errorIndex in getErrors){
                        if(getErrors[errorIndex][0] !== ""){
                            serverValid[errorIndex] = getErrors[errorIndex][0];
                        }
                    }                
                    me.setState({ serverValid, formProcessing: false });
                }
            }
        })
        .catch((error) => {
            me.setState({ serverError: error, formProcessing: false });
        });
    }
    
    render(){
        const {inviteDetails, inviteError, serverValid, serverSuccess, serverError, formProcessing, registerMsg} = this.state;
        
        let kidName="";
        if(inviteDetails && inviteDetails.user){
            kidName = <strong>{Helper.UCWords(Helper.getMemberName(inviteDetails.user))}'s</strong>;
        }

        return (
                <>
                    {
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={true}
                                onConfirmClick={Helper.handleErrorClose}
                            />
                        :
                            null
                    }

                    <InviteHeader inviteDetails={inviteDetails} />
                    
                    <div className="container">
                        {
                            serverSuccess !== "" ?
                                <RegisterSuccess 
                                    registerMsg={registerMsg}
                                />
                            :
                                inviteError !== "" ?
                                    <RegisterError
                                        inviteError={inviteError}
                                    /> 
                                :
                                    <Row className="content-space">
                                        <Col sm="12" md={8} lg={8} className="offset-md-2 offset-lg-2">
                                            <h1>Register your account</h1>
                                        </Col>
                                        <Col sm={12 } md={8} lg={8} className="offset-md-2 offset-lg-2">
                                            <div className="widget">   
                                                <div className="block-space block-space--pb-0">
                                                    You are invited to register as a {Enum.LegalGuardian} of {kidName} SafeComm account. Please fill the information below to register your account. 
                                                </div>                                   
                                                <AccountInformation 
                                                    inviteDetails={inviteDetails} 
                                                    handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                                                    getLabelClass={Helper.getLabelClass}
                                                    serverValid={serverValid}
                                                    formProcessing={formProcessing}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                            }
                            <Footer />
                    </div>
                </>  
            )  
    }
}
export {InviteLG};