/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Navbar, Nav, Image } from 'react-bootstrap';
/* End - React Bootstrap */

import SafecommLogo from '../../assets/images/logo.png';
import './header.scss';
import '../common/common.scss';
class HeaderFullWidth extends Component {
    render() {
        return (
            <>
                <Navbar bg="light" expand="lg" className="topbar">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto org-logo">                         
                            <Image src={SafecommLogo} alt="SafeComm" title="SafeComm" width="245" />
                        </Nav>                       
                    </Navbar.Collapse>
                </Navbar>                          
            </>
        )
    }
}

export { HeaderFullWidth };