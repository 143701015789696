/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Image } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import '../club/club.scss';

/**
 * Start - React Google recaptcha
 * Name and Version: react-bootstrap, 2.0.1
 * Download link: https://www.npmjs.com/package/react-google-recaptcha
 */
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleRecaptchaKey } from '../../components/common/enum'
import * as Helper from '../../components/common/functions';

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            isReChecked : false,
        };
        this.isValidated = this.isValidated.bind(this);
        this.editStep = this.editStep.bind(this);
        this.finishButton = null;
    }
    componentDidMount() {
        this.finishButton = document.getElementById('next-button');
        setTimeout(() => {
            this.finishButton.innerHTML = ' Finish <span style="display: none;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
        }, 1000);
    }

    isValidated() {
        let error = false;
        if(!this.isReChecked && !Helper.isSuperAdminLogin()){
            this.setState({showDiv:true});
            error = true;
        }

        if(!error){
            this.finishButton.classList.add('btn-loader');
            this.finishButton.disabled = true;
            this.props.onRegisterISRSubmit();
        }
        return false;
    }

    onChangeRe = (value) =>{
         if(value){
            this.isReChecked = true;
            this.setState({showDiv:false});
            this.props.setRecaptchaToken(value);
        }
    }

    editStep(step){
        this.props.jumpToStep(step)
    }

    render() {
        let generalInfo = this.props.generalInfo, addressInfo = this.props.addressFields, bankInfoFields = this.props.bankInfoFields;
        return (
            <>
                <Row className="steps-content">
                    <Col sm={12} md={6} lg={5}>
                        <div className="info-box info-box--no-shadow">
                            <div className="info-box__header info-box__header--org">
                                <Image src={this.props.photo.url ? this.props.photo.url : Images.DefaultLogo} />
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={7}>
                        <Form>
                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Marketing Affiliate Information</span>
                                    <a onClick={()=>this.editStep(0)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>First Name:</label><span>{generalInfo.firstname}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Last Name:</label><span>{generalInfo.lastname}</span>
                            </div>
                            <div className="disp-inline">
                                <label> Email:</label><span>{generalInfo.email}</span>
                            </div>
                            <div className="disp-inline">
                                <label> Phone:</label><span>{generalInfo.phoneCode} {generalInfo.phone}</span>
                            </div>
                            <div className="disp-inline">
                                <label> Username:</label><span>{generalInfo.username}</span>
                            </div>

                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Mailing Address</span>
                                    <a onClick={()=>this.editStep(1)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>
                            <div className="disp-inline">
                                <label>Mailing Address:</label><span>{`${addressInfo.mailAddress}${addressInfo.mailAddress2 && `, `+addressInfo.mailAddress2}, ${addressInfo.mailCity}, ${addressInfo.mailState}, ${addressInfo.mailZip}`}</span>
                            </div>
                            <div className="disp-inline">
                                <div className="block-heading mb-0 d-flex justify-content-between align-items-center">
                                    <span>Bank Information</span>
                                    <a onClick={()=>this.editStep(2)} className="block-heading__edit">Edit</a>
                                </div>
                            </div>

                            <div className="disp-inline">
                                <label> Bank Account Name:</label><span>{bankInfoFields.bankAccountName}</span>
                            </div>
                            <div className="disp-inline">
                                <label> Bank Routing Number:</label><span>{bankInfoFields.bankRoutingNumber}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Bank Account Number:</label><span>{bankInfoFields.bankAccountNumber}</span>
                            </div>
                            <div className="disp-inline">
                                <label>Social Security Number:</label><span>{bankInfoFields.socialSecurityNumber}</span>
                            </div>

                            {  !Helper.isSuperAdminLogin() &&
                            <>
                                <div className="captcha-label">
                                    Please tick the box to continue:
                                </div>

                                <ReCAPTCHA
                                    sitekey={GoogleRecaptchaKey}
                                    onChange={this.onChangeRe}
                                />
                             </>
                            }
                            <div  className="disp-inline" >
                                {(this.state.showDiv)? <p className="error-msg invalid-feedback">Please confirm that you are not a robot</p>: '' }
                            </div>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }
}
export { Review };
