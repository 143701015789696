/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Container, Image, Form } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import * as Images from '../../components/common/images';
import Loader from '../../components/loader/loader';
import {HeaderFullWidth} from '../../components/header/headerfullwidth';
import {Footer} from '../../components/footer/footer';
import {RegisterError, RegisterSuccess,} from './invite-helper';
class VerifyRegisteredLG extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            token: props.match.params.verifyToken,
            errorMsg: "",
            loading: false
        };
    }

    componentDidMount() {
        this.resendInvite()
    }

    resendInvite = ()=>{
        const { token } = this.state;
        this.setState({ loading: true }
            , () => {
                Helper.axiosInstance().post(`register/lg-verify`, {
                    verification_code: token
                })
                    .then((response) => {
                        const resp = response.data;
                        if (resp.success) {
                            this.setState({ successMsg: resp.message });
                        } else {
                            this.setState({ errorMsg: resp.error })
                        }
                    })
                    .catch((error) => {
                        this.setState({ errorMsg: Messages.SERVER_ERROR });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        );
    }

    render() {
        const { successMsg, errorMsg, loading } = this.state;

        return (
            <>
                <HeaderFullWidth />
                <Container>
                    <Loader loading={loading} />
                     {
                         errorMsg !="" ?
                             <RegisterError
                                 inviteError={'This link is not valid. Please contact the SafeComm support.'}
                             />
                             :
                             successMsg &&
                         <RegisterSuccess
                             registerMsg={'Thanks for the account Verification. You have been registered successfully'}
                         />
                    }
                    <Footer />
                </Container>
            </>
        )
    }
}

export { VerifyRegisteredLG };

