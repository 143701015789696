/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Button, Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import './login.scss';
import * as Images from '../../components/common/images';
import * as Storage from '../../components/common/storage';
import {FooterLogin} from '../../components/footer/footer-login';
import * as Helper from '../../components/common/functions';
import {Messages} from "../../components/common/message";
import * as Regex from "../../components/common/regex";

class ResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            message: '',
            messageType: '',
            formProcessing: false,
            serverValid : {
                password: "",
                confirm_password: "",
                otp: ""
            }
        };
    }

    componentDidMount() {
        if(!Storage.Get('resetUser')){
            Helper.redirectToHome(this.props);
        }
    }
    resetServerError() {
        this.setState({
            serverValid : {
                password: "",
                confirm_password: "",
                otp: ""
            }
        });
    }

    handleSubmit(fields) {
        let me = this;
        const resetUser = Storage.Get('resetUser');
        if(resetUser){
            this.setState({message: '', formProcessing: true});
            me.resetServerError();

            fields['username'] = resetUser;
            Helper.axiosInstance().post('password/reset', fields)
            .then((response) => {
                // If server response message same as Data Matched
                if(response.data.success){
                    me.setState({
                        formProcessing: false,
                        messageType: 'success',
                        message: response.data.message
                    }, function (){
                        Storage.Delete('resetUser');
                        setTimeout(()=>{
                            Helper.redirectToHome(this.props);
                        }, 2000);
                    });
                } else {
                    // get the server form errors and set under state 'serverValid'
                    const getErrors = response.data.error;
                    
                    let serverValid = me.state.serverValid;
                    // eslint-disable-next-line
                    for (let errorIndex in getErrors) {
                        if (getErrors[errorIndex][0] !== "") {
                            serverValid[errorIndex] = getErrors[errorIndex][0];
                        }
                    }
                    me.setState({ serverValid, formProcessing: false });
                }
            })
            .catch((error) => {
                me.setState({ messageType: 'danger', message: error, formProcessing: false });
            });
        }
    }
    
    resendOTP = (event) => {
        let me = this;
        event.preventDefault();
        event.stopPropagation();
        const resetUser = Storage.Get('resetUser');
        if(resetUser){
            me.setState({message: '', formProcessing: true});
            Helper.axiosInstance().post('password/forgot', {username: resetUser})
            .then((response) => {
                if(response.data.success === true){
                    me.setState({
                        formProcessing: false,
                        messageType: 'success',
                        message: response.data.message
                    });
                    setTimeout(() => {
                        me.setState({
                            message: ""
                        });
                    },2000);
                } else {
                    me.setState({
                        formProcessing: false,
                        messageType: 'danger',
                        message: response.data.error.username[0]
                    });
                }
            })
            .catch((error) => {
                me.setState({ messageType: 'danger', message: error, formProcessing: false });
            }); 
        }
    }

    render() {
        const { formProcessing, message, messageType, serverValid} = this.state;
        return (
            <>
                <div className="loginboard d-flex full-vh-height">
                    <div className="loginborad__form d-flex">
                        <div className="form__wrap align-self-center">
                            <div className="form__wrap-img">
                                <Image src={Images.SafecommLoginLogo} alt="Safecomm" width="245" onClick={() => Helper.redirectToHome(this.props)}/>
                            </div>    
                            <h2 className="heading mb-4">Reset your Password</h2>
                            {message ? <div className={'mt-4 alert alert-'+messageType}>{message}</div> : ''}
                        
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    password: '',
                                    confirm_password: '',
                                    otp: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().trim()
                                        .max(50, Messages.MAX_LENGTH_50)
                                        .min(8, Messages.MIN_LENGTH_8)
                                        .required(Messages.REQUIRED_ERR)
                                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                                    confirm_password: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .oneOf([Yup.ref('password'), null], Messages.PASSWORD_CPASS_MATCH_ERR),
                                    otp: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR),
                                })}
                                onSubmit={fields => {
                                    this.handleSubmit(fields);
                                }}
                                render={({ errors, status, touched }) => (
                                    <Form noValidate>
                                        <div className="field form-group">
                                            <Field name="password" type="password" id="password" maxLength="50" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} className={'form-control' + ((errors.password && touched.password) || serverValid.password !== '' ? ' is-invalid' : '')}
                                            autoComplete="new-password"/>
                                            <label htmlFor="password" className="form-label">New Password</label>
                                            {
                                                (errors.password && touched.password) ?
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                :
                                                    <div className="invalid-feedback">{serverValid.password}</div>
                                            }
                                        </div>
                                        <div className="field form-group">
                                            <Field name="confirm_password" id="confirm_password" maxLength="50" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} type="password" className={'form-control' + ((errors.confirm_password && touched.confirm_password) || serverValid.confirm_password !== '' ? ' is-invalid' : '')} />
                                            <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                            {
                                                (errors.confirm_password && touched.confirm_password) ?
                                                    <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                                                :
                                                    <div className="invalid-feedback">{serverValid.confirm_password}</div>
                                            }
                                        </div>
                                        <div className="field form-group">
                                            <Field name="otp" id="otp" maxLength="4" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} type="text" className={'form-control' + ((errors.otp && touched.otp) || serverValid.otp !== '' ? ' is-invalid' : '')} />
                                            <label htmlFor="otp" className="form-label">Verify One Time Password</label>
                                            {
                                                (errors.otp && touched.otp) ?
                                                    <ErrorMessage name="otp" component="div" className="invalid-feedback" />
                                                :
                                                    <div className="invalid-feedback">{serverValid.otp}</div>
                                            }
                                            
                                        </div>
                                        <p className="form__resend-text">Don't receive the one time password? <Link to="" onClick={this.resendOTP}> Resend</Link></p>
                                        <div className="btn-container btn-container--space">    
                                            <Button disabled={formProcessing ? true : false} className="btn btn-cancel" onClick={() => this.props.history.push('/')}>
                                                Cancel
                                            </Button>
                                            <Button type="Submit" disabled={formProcessing ? true : false} className={"btn btn-primary btn-medium ml-3 " + (formProcessing ? "btn-loader" : "")}>
                                                Reset Password<span style={{'display': 'none'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                        <FooterLogin />
                    </div>
                </div>             
            </>
        )
    }
}
export {ResetPassword};