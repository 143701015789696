/**	
 * Start - React	
 * Name and Version: react, 16.9.0	
 * Download link: https://www.npmjs.com/package/react	
 */	
import React, { Component } from 'react';	
/* End - React */
/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */
/**	
 * Start - React Bootstrap	
 * Name and Version: react-bootstrap, 1.0.0-beta.12	
 * Download link: https://www.npmjs.com/package/react-bootstrap	
 */
import {Dropdown, Table, Media, Image, Tooltip, OverlayTrigger} from 'react-bootstrap';
/* End - React Bootstrap */	

import * as Images from '../common/images';	
import * as Enum from '../common/enum';
import * as Helper from '../common/functions';
import Loader from "../loader/loader";
import { SmallLoader } from '../small-loader/sm-loader';
class GroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
        }
    }
    render() {
        let props = this.props,
            groupType = props.selectedTab == Enum.GroupList.INDIVIDUAL_CHAT ? 'I' : (props.selectedTab == Enum.GroupList.SAFE_CHAT ? 'G' : 'T' );
        return (<>
                <Loader loading={props.loading} />
            <Table bordered hover className="tab-responsive  table--action-listing">
                <thead>
                <tr>
                    {
                        (Helper.isCoachOrAdminLogin() && props.data.length > 0 && props.selectedTab != Enum.GroupList.INDIVIDUAL_CHAT) &&
                        <th>
                        <span className="checkwrap">
                            <input className="checkwrap__cbx" id="checkedall"
                                   checked={props.checkedAll ? true : false}
                                   type="checkbox" onChange={(e) => props.handleAllChecked(e)}/>
                            <label className="checkwrap__label" htmlFor="checkedall">
                                <span className="checkwrap__label_chk">
                                    <svg width="14px" height="12px" viewBox="0 0 14 12">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                            </label>
                        </span>
                        </th>
                    }
                    {
                        ( props.data && props.data.length >1)?
                            <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('name')}>{props.name}
                                { props.sortCriteria && props.sortCriteria.column == 'name' ?
                                    <Image src={props.sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>{props.name}</th>

                    }
                    {
                        ( props.data && props.data.length >1)?
                            <th className='custom-width-column date_created_col col-sortable' onClick={() => props.sortHandler('created_at')}>Date Created
                                { props.sortCriteria && props.sortCriteria.column == 'created_at' ?
                                    <Image src={props.sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>Date Created</th>

                    }
                    {props.selectedTab == Enum.GroupList.SAFE_CHAT || props.selectedTab == Enum.GroupList.MEMBER_CHAT  ?
                        <>
                        <th>Contacts </th>
                            {
                                ( props.data && props.data.length >1)?
                                    <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('admin')}>Admin
                                        { props.sortCriteria && props.sortCriteria.column == 'admin' ?
                                            <Image src={props.sortImage} alt="Sort Direction" className="ml-2"/> :
                                            <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                        }</th>
                                    : <th>Admin</th>

                            }
                        </>
                        :null
                    }
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    !props.recordLoaded && <tr key="loader"><td colSpan="5"><SmallLoader /></td></tr>
                }
                {props.data && props.data.map((item, key) => {
                    return (
                        <>
                            <tr key={key}>
                                {
                                Helper.isCoachOrAdminLogin() && props.selectedTab != Enum.GroupList.INDIVIDUAL_CHAT &&
                                    <td>
                                        <span className="checkwrap">
                                            <input className="checkwrap__cbx" id={key} type="checkbox" checked={ props.checkedItems && props.checkedItems.get(item.id) ? true : false}
                                                   onChange={() => {props.selectGroup(item)}}/>
                                            <label className="checkwrap__label" htmlFor={key}>
                                                <span className="checkwrap__label_chk">
                                                    <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </span>
                                    </td>
                                }
                                <td data-label="SafeChats" className={"td-content-space"+(props.selectedTab == Enum.GroupList.INDIVIDUAL_CHAT ? ' individual-chat-col' : '')}>
                                    <Media>
                                        <div className="im-rounded-outer">
                                            <Image
                                                src={item.photo}
                                                alt={item.name}
                                            />
                                        </div>
                                        <Media.Body className="align-self-center media-body--link">
                                            {
                                                props.selectedTab != Enum.GroupList.INDIVIDUAL_CHAT ?
                                                    <Link to={`/${props.detail}/${item.id}`}>
                                                        <h5>{item.name}</h5>
                                                    </Link>
                                                    :  <h5>{item.name}</h5>

                                            }
                                        </Media.Body>
                                    </Media>
                                </td>
                                <td data-label="Date Created">{item.created_at}</td>
                                {props.selectedTab == Enum.GroupList.SAFE_CHAT || props.selectedTab == Enum.GroupList.MEMBER_CHAT ?
                                    <>
                                <td data-label="Contacts">{item.total_members}</td>
                                <td data-label="Admin" className="td-content-space">
                                    <Media className="mb-2">
                                        <div className="im-rounded-outer">
                                            <Image
                                                src={item.admin.photo}
                                                alt={Helper.getMemberName(item.admin)}
                                            />
                                        </div>
                                        <Media.Body className="align-self-center">
                                            <h5>{Helper.getMemberName(item.admin)}</h5>
                                        </Media.Body>
                                    </Media>
                                </td>
                                </>:null}
                                <td data-label="Action">
                                    <ul className="action-listing">
                                        <li onClick={(groupId) => props.allProps.setChatWithGroupId(`${groupType}_${item.id}`)}>
                                            <OverlayTrigger placement="top"
                                                            overlay={
                                                <Tooltip id={`Chat`}>
                                                    Chat
                                                </Tooltip>}>
                                                <Image
                                                    className="mr-3"
                                                    src={Images.ChatIc}
                                                    alt="Chat"
                                                    width="21"
                                                />
                                            </OverlayTrigger>
                                        </li>
                                            {props.selectedTab == Enum.GroupList.INDIVIDUAL_CHAT ||
                                            (item.is_group_admin && props.selectedTab == Enum.GroupList.SAFE_CHAT)
                                                ?
                                                <li onClick={() => props.onDeleteGroupClick(item.id)} >
                                                    <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip`}>
                                                                           Delete
                                                                        </Tooltip>}>
                                                    <Image src={Images.DeleteIc} alt="Deactivate" width="21" />
                                                    </OverlayTrigger>
                                                </li>
                                            : null}
                                    </ul>
                                </td>
                            </tr>
                        </>
                    )
                })
                }
                </tbody>
                  </Table>
                </>
                )
    }
}

export { GroupList };