/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Dropdown, Table, Media, Image, OverlayTrigger, Tooltip, Modal, Button} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form } from 'formik';
/* End - Formik */

import { DeleteConfirmModal, SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import { NoRecord } from '../../components/no-record/no-record';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Messages } from '../../components/common/message';
import * as Enum from '../../components/common/enum';
import Loader from '../../components/loader/loader';
import { orderBy } from 'lodash';
import './../club/club.scss';

class ISRListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            recordLoaded: false,
            activeISRListSearch: [],
            modalLoader: false,
            dropDownValue: "All",
            search: "",
            loading: false,
            modalVisibility: false,
            serverSuccess: "",
            showError: false,
            errorMessage: "",
            handleApprove: {
                deleteConfirmShow:false,
                ISRIdForAction: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
                status: 0,
            },
            sortCriteria: {
                column: 'last_name',
                direction: 'asc'
            },
        };
    }

    componentDidMount() {
        this.getISRList();
    }

    getISRList = () => {
        if (Storage.Get('user') != null) {
            this.setState({ recordLoaded: false, });
            Helper.axiosInstance().get(`isr/list`)
                .then((response) => {
                    if (response.data.success) {
                        const isrList = response.data.data,
                            activeISR = isrList.filter(x => x.approval_status === 'A' || x.approval_status === 'PA'),
                            pendingActiveISR = isrList.filter(x => x.approval_status === 'PA'),
                            approvedActiveISR = isrList.filter(x => x.approval_status === 'A');
                        this.setState({
                            originalActiveISR: activeISR,
                            originalActiveISRSearch: activeISR,
                            activeISRList: activeISR,
                            activeISRListSearch: activeISR,
                            pendingActiveISR: pendingActiveISR,
                            pendingActiveISRSearch: pendingActiveISR,
                            approvedActiveISR: approvedActiveISR,
                            approvedActiveISRSearch: approvedActiveISR,
                            recordLoaded: true
                        });
                    }
                    else {
                        this.setState({ errorMessage: response.data.error, showError: true, recordLoaded: true });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
                });

        }
    }

    filterList = (target) => {
        this.setState({search: target.value})
        this.setState({
        activeISRListSearch: this.state.activeISRList.filter(
            x => (x.username).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1 || (x.first_name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1  || (x.last_name.toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1)
        )})

    }

    onISRAction = (ISRId, status) => {
        this.setState(prevState => {
            let handleApprove = Object.assign({}, prevState.handleApprove);
                handleApprove.deleteConfirmShow = true;
                handleApprove.ISRIdForAction = ISRId;
            if (status === 'A' || status === 'R') {
                const action = status === 'A' ? 'Approve' : 'Reject',
                rejectMsg = status === 'R' ? `This will remove the MA's data permanently.` : '';
                handleApprove.status = status;
                handleApprove.confirmText = `Are you sure you want to ${action.toLowerCase()} this MA account? ${rejectMsg}`;
                handleApprove.confirmButtonText = action;
                handleApprove.confirmTypeStatus = action;
            }
            return { handleApprove };
        });
    }

    onISRConfirmClick = () => {
        const handleApprove = this.state.handleApprove;
        this.setState({ modalLoader: true })
        if(handleApprove.status === 'A' || handleApprove.status === 'R'){
            Helper.axiosInstance()
                .put('isr/update-status', {
                    id: handleApprove.ISRIdForAction,
                    status: handleApprove.status
                })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState({ modalVisibility: true, serverSuccess: response.data.message })
                        this.getISRList();
                    } else {
                        this.setState({ errorMessage: response.data.error, showError: true, modalLoader: false, });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                })
                .finally(() => {
                    this.setState(prevState => {
                        let handleApprove = Object.assign({}, prevState.handleApprove);
                        handleApprove.deleteConfirmShow = false;
                        handleApprove.ISRIdForAction = null;
                        return { handleApprove };
                    });
                    this.setState({ loading: false, modalLoader: false });
                });
            }
    }

    onISRCancelClick = () => {
        this.setState(prevState => {
            let handleApprove = Object.assign({}, prevState.handleApprove);
            handleApprove.deleteConfirmShow = false;
            return { handleApprove };
        })
    }

    changeValue(text) {
        const {
            originalActiveISR,
            originalActiveISRSearch,
            pendingActiveISR,
            pendingActiveISRSearch,
            approvedActiveISR,
            approvedActiveISRSearch,

        } = this.state;
        let updatedList = {};
        this.setState({ dropDownValue: text },
            () => {
                const dropDownValue = this.state.dropDownValue;
                this.setState({search: ""});
                switch (dropDownValue){
                    case "Pending":
                        updatedList = {
                            activeISRList: pendingActiveISR,
                            activeISRListSearch: pendingActiveISRSearch,
                        };
                        break;
                    case "Approved":
                        updatedList = {
                            activeISRList: approvedActiveISR,
                            activeISRListSearch: approvedActiveISRSearch,
                        };
                        break;
                    default:
                        updatedList = {
                            activeISRList: originalActiveISR,
                            activeISRListSearch: originalActiveISRSearch,
                        };
                }
                this.setState(updatedList);
            }
        );
    }

     handleClose = () => {
        this.setState({ serverSuccess: '', modalVisibility: !this.state.modalVisibility });
    }

    sortHandler = (column) => {
        const { sortCriteria } = this.state;
        let newSortCriteria = sortCriteria;
            newSortCriteria.column = column;
        if(sortCriteria.column  == column){
            newSortCriteria.direction = (sortCriteria.direction == 'asc') ? 'desc' : 'asc';
        } else {
            newSortCriteria.direction = 'asc';
        }

        let sortedISR = orderBy(this.state.activeISRListSearch, [isr => isr[column].toLowerCase()], [newSortCriteria.direction]);
        this.setState({activeISRListSearch: sortedISR});
    }

    render() {
        const {search, loading, serverSuccess, showError, errorMessage, modalVisibility,  sortCriteria } = this.state;
        return (
            <>
                <Loader loading={loading} />
                <div className="rgt-content">
                    <Row className="row--space align-items-center">
                        <Col sm="7">
                            <h1>Marketing Affiliates</h1>
                        </Col>
                        <Col sm={5} className="text-right cb-action-wrap">
                            <div className="action-filter">
                                <label>Filter by:</label>
                                <div className="sel-wrap">
                                    <Dropdown className="sel-dropdown">
                                        <Dropdown.Toggle id="dropdown-item-button" title={this.state.dropDownValue} className="format">
                                            {this.state.dropDownValue}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="1" onClick={(e) => this.changeValue(e.target.textContent)}><div>All</div></Dropdown.Item>
                                            <Dropdown.Item eventKey="2" onClick={(e) => this.changeValue(e.target.textContent)}><div>Pending</div></Dropdown.Item>
                                            <Dropdown.Item eventKey="3" onClick={(e) => this.changeValue(e.target.textContent)}><div>Approved</div></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                <div className="tab-search tab-search--big tab-search--widget">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            search: search
                                        }}
                                        onSubmit={() => false}
                                        render={() => (
                                            <Form className="form-inline" noValidate>
                                                <Field name="search" type="text" maxLength="100" onChange={(e) => this.filterList(e.target)} className="form-control tab-search__box" placeholder="Search"/>
                                                <button className="close-icon" type="reset" onClick={(e) => this.filterList(e.target)}></button>
                                            </Form>
                                        )}
                                    />
                                </div>
                                 <ISRList data={this.state.activeISRListSearch}
                                         onISRAction={(id, status) => this.onISRAction(id, status)}
                                         recordLoaded={this.state.recordLoaded}
                                         sortCriteria={sortCriteria}
                                         sortHandler={this.sortHandler}
                                />

                            </div>
                        </Col>
                    </Row>
                </div>
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handleApprove.deleteConfirmShow}
                    onCancelClick={this.onISRCancelClick}
                    onConfirmClick={this.onISRConfirmClick}
                    confirmText={this.state.handleApprove.confirmText}
                    confirmButtonText={this.state.handleApprove.confirmButtonText}
                    confirmType={this.state.handleApprove.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />
                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={this.handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        showError !== "" ?
                            <ErrorModal
                                show={showError}
                                message={errorMessage}
                                onConfirmClick={() => { this.setState({ showError: false }) }}
                            />
                            :
                            null
                }
            </>
        )
    }
}

const ISRList = (props) => {
    let status = null,
        badgeClass = null,
        sortImage = Images.ArrowSort;
    if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'desc'){
        sortImage = Images.ArrowDown;
    } else if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'asc') {
        sortImage = Images.ArrowUp;
    }
    return (
        <Table bordered hover className="tab-responsive table-responsive table--isr-width">
            <thead>
            <tr>
                {
                    props.data && props.data.length >1  &&  props.sortCriteria   ?
                        <th className="col-sortable" onClick={() => props.sortHandler('first_name')}>First Name
                            { props.sortCriteria.column == 'first_name' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th>First Name</th>
                }
                {
                    props.data && props.data.length >1  &&  props.sortCriteria   ?
                        <th className="col-sortable" onClick={() => props.sortHandler('last_name')}>Last Name
                            { props.sortCriteria.column == 'last_name' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th>Last Name</th>
                }
                <th>Username</th>
                <th width="15%">First Year Commission(%)</th>
                <th width="15%">Renewals Commission(%)</th>
                {
                    props.data && props.data.length >1  &&  props.sortCriteria   ?
                        <th className="col-sortable" onClick={() => props.sortHandler('created_at')}>Date created
                            { props.sortCriteria.column == 'created_at' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th>Date created</th>
                }

                <th>Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                (typeof props.data == "object" && Object.keys(props.data).length > 0) &&
                props.data.map((row, i) => {
                    status = row.approval_status ?? null;
                    badgeClass = status === "A" ? "badge-acpt" : "badge-pndg";

                    return (
                        <tr key={i}>
                            <td data-label="MA" className="td-content-space">
                                <Media>
                                    <div className="im-rounded-outer">
                                        <Image className="mr-2" src={row.photo} alt="User" />
                                    </div>
                                    <Media.Body className="align-self-center">
                                        <Link className="org-detail-link" to={`/edit-isr/${row.id}`}>
                                            <h5>{row.first_name}</h5>
                                        </Link>
                                    </Media.Body>
                                </Media>
                            </td>
                            <td data-label="Last Name">{row.last_name}</td>
                            <td data-label="Username">{row.username}</td>
                            <td data-label="First Year Comm.(%)">{  row.new_commission_per}%</td>
                            <td data-label="Renewals Comm.(%)"> { row.renew_commission_per}%</td>
                            <td className="date_created_col" data-label="Date created">{row.created_at}</td>

                            <td data-label="Status">
                                {
                                    status ?
                                        <span className={`badge--f-size ${badgeClass}`}>{Enum.OrgStatus[status]}</span>
                                        :
                                        null
                                }
                            </td>
                            <td>
                                <ul className="action-listing">
                                    {
                                          status && status !== "A" ?
                                                <>
                                                    <li onClick={() => props.onISRAction(row.id, 'A')}>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip id={`Approve`}>
                                                                                Approve
                                                                            </Tooltip>}>
                                                            <Image className="mr-2 cursor-pointer" src={Images.Approve} alt="Activate" width="21" />
                                                        </OverlayTrigger>
                                                    </li>
                                                    <li onClick={() => props.onISRAction(row.id, 'R')}>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip id={`Reject`}>
                                                                                Reject
                                                                            </Tooltip>}>
                                                            <Image className="mr-2 cursor-pointer" src={Images.Reject} alt="Activate" width="21" />
                                                        </OverlayTrigger>
                                                    </li>
                                                </>
                                                :
                                                null
                                    }
                                </ul>
                            </td>
                        </tr>
                    );
                })
            }
            {!props.recordLoaded &&
            <>
                <tr>
                    <td colSpan="6">
                        <SmallLoader />
                    </td>
                </tr>
            </>
            }
            {(props.recordLoaded && props.data.length === 0) &&
            <tr className="mb--norecord">
                <td colSpan="6">
                    <NoRecord />
                </td>
            </tr>
            }
            </tbody>
        </Table>
    )
};

export { ISRListing };
