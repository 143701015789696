import firebase from "firebase/app";
import "firebase/messaging";

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDbcPTc5J_ZtZbsDGx-Bkri2ENkv0tv9uM",
    authDomain: "safecomm-91f53.firebaseapp.com",
    databaseURL: "https://safecomm-91f53.firebaseio.com",
    projectId: "safecomm-91f53",
    storageBucket: "safecomm-91f53.appspot.com",
    messagingSenderId: "175481118468",
    appId: "1:175481118468:web:1e4d7cb5d33b8bed47fa51",
    measurementId: "G-2ZBGT5EWC7"
};

// Initialize Firebase
const initializedFirebaseApp = firebase.initializeApp(FIREBASE_CONFIG);

const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;
export { messaging };