/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, OverlayTrigger,Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import { SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import { Messages } from '../../components/common/message';
import * as Helper from '../../components/common/functions';


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            costPerUser: '',
            commissionPerUserNew: '',
            commissionPerUserRenew: '',
        };
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    componentDidMount = () => {
        Helper.axiosInstance().get(`config/get`)
            .then((response) => {
                if (response.data.success) {
                    let values = response.data.data;
                    this.setState({
                        costPerUser: values.default_price_per_user,
                        commissionPerUserNew: values.default_commission_per_isr_new,
                        commissionPerUserRenew: values.default_commission_per_isr_renew,
                    })
                }
                else {
                    this.setState({ errorMessage: response.data.error, showError: true, recordLoaded: true });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
            });
    }

   toggleEdit = () => {
        //Reset the fields on cancel.
       document.querySelector('#costPerUser').focus();
       document.querySelector('#js-settings-frm').reset();

        this.setState({
            disabled: !this.state.disabled,
        })
    }

    updateData = (fields) => {
        this.setState({
            showLoader: true,
        })

        Helper.axiosInstance().put('config/update', {data:{default_price_per_user: fields.costPerUser, default_commission_per_isr_new:fields.commissionPerUserNew, default_commission_per_isr_renew:fields.commissionPerUserRenew}})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        showLoader: false,
                        successConfirmShow: true,
                        successText: response.data.message,
                        costPerUser: fields.costPerUser,
                        commissionPerUserNew: fields.commissionPerUserNew,
                        commissionPerUserRenew: fields.commissionPerUserRenew
                    });
                    this.toggleEdit();
                }
                else {
                    this.setState({ showError: true, errorMessage: Messages.SERVER_ERROR, showLoader: false,});
                }
            })
            .catch(() => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, showLoader: false, });
            });
    }


    render() {
        const { costPerUser, commissionPerUserNew, commissionPerUserRenew } = this.state;
         return (
            <>
                <div className="rgt-content">
                        <Row className="row--space">
                            <Col sm={12}>
                                <h1>Settings</h1>
                            </Col>

                            <Col md={12} lg={12} className="md--full-width order-md-2 order-lg-3">
                                <div className="block-pos block-pos--single-elem" style={this.state.updateClub}>
                                    <OverlayTrigger placement="auto"
                                                    overlay={
                                                        <Tooltip id={`tooltip`}>
                                                            Edit Organization
                                                        </Tooltip>}>
                                        <a href={null} onClick={this.toggleEdit}>
                                            <div className="outline-circle">
                                                <Image src={Images.EditIcRed} alt="Edit" width="25"/>
                                            </div>
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <div className="widget">
                                    <Formik
                                        ref={(ref) => this.formik = ref}
                                        enableReinitialize
                                        initialValues={{
                                            costPerUser: costPerUser,
                                            commissionPerUserNew: commissionPerUserNew,
                                            commissionPerUserRenew: commissionPerUserRenew,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            costPerUser: Yup.number().positive('Must be a positive number')
                                                .max(99999, 'Cost per user should not greater than 99999')
                                                .typeError('Cost per user must be a number')
                                                .required('Cost per user is required'),
                                            commissionPerUserNew: Yup.number().positive('Must be a positive number')
                                                .min(0, 'Commission per user should not be less than 0')
                                                .max(100, 'Commission per user should not greater than 100')
                                                .typeError('Commission per user must be a number')
                                                .required('Commission per user is required'),
                                            commissionPerUserRenew: Yup.number().positive('Must be a positive number')
                                                .min(0, 'Renew commission per user should not be less than 0')
                                                .max(100, 'Renew commission per user should not greater than 100')
                                                .typeError('Renew commission per user must be a number')
                                                .required('Renew commission per user is required')

                                        })}
                                        onSubmit={fields => {
                                            this.updateData(fields);
                                        }}
                                        render={({errors, status, touched, setFieldValue, setFieldTouched, values}) => (
                                            <Form id="js-settings-frm" noValidate>
                                                <>
                                                    <div className=" widget--space">
                                                        <Col md={12}>
                                                            <div className="block-heading">Master Settings</div>
                                                        </Col>

                                                        <Col md={7} lg={8}>
                                                            <div className="field form-group">
                                                                <Field name="costPerUser" maxLength="7" type="text"
                                                                       id="costPerUser"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.costPerUser && touched.costPerUser ? ' is-invalid' : '')}/>
                                                                <label htmlFor="costPerUser"
                                                                       className="form-label label-active">Cost Per User
                                                                    ($)</label>
                                                                <ErrorMessage name="costPerUser" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>
                                                        <Col md={7} lg={8}>
                                                            <div className="field form-group">
                                                                <Field name="commissionPerUserNew" maxLength="3" type="text"
                                                                       id="commissionPerUserNew"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.commissionPerUserNew && touched.commissionPerUserNew ? ' is-invalid' : '')}/>
                                                                <label htmlFor="commissionPerUserNew"
                                                                       className="form-label label-active">MA Default First Year Commission(%)</label>
                                                                <ErrorMessage name="commissionPerUserNew" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>

                                                        <Col md={7} lg={8}>
                                                            <div className="field form-group">
                                                                <Field name="commissionPerUserRenew" maxLength="3" type="text"
                                                                       id="commissionPerUserRenew"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.commissionPerUserRenew && touched.commissionPerUserRenew ? ' is-invalid' : '')}/>
                                                                <label htmlFor="commissionPerUserRenew"
                                                                       className="form-label label-active"> MA Default Renewals Commission(%)</label>
                                                                <ErrorMessage name="commissionPerUserRenew" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>

                                                    </div>
                                                    <div style={{'display' : this.state.disabled ? 'none' : 'flex'}}
                                                         className="widget__footer justify-content-between">
                                                        <Button className="btn-cancel ripple"
                                                                onClick={() => this.toggleEdit()}>
                                                            Cancel</Button>
                                                        <Button type="submit" className={`ripple btn-medium d-inline-flex align-items-center ${this.state.disabled ? 'btn-loader' : ''}`}>
                                                            <div className="btn__icon btn__icon--red"><Image
                                                                src={Images.CompleteIc} alt="Update"/></div>
                                                            Update <span style={{'display': 'none'}}
                                                                         className="spinner-border spinner-border-sm"
                                                                         role="status" aria-hidden="true"></span>
                                                        </Button>
                                                    </div>
                                                </>
                                            </Form>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"} />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }} />
            </>
        )
    }
}
export { Settings };
