/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */
import {Row, Col, Table, Dropdown, Image, ResponsiveEmbed, Modal, Button} from 'react-bootstrap';
/* End - React Bootstrap */
import ReadMoreAndLess from 'react-read-more-less';

import {SuccessModal, ErrorModal, ModalBox} from '../../components/modal/modalbox';

import Loader from "../../components/loader/loader";
import * as Storage from '../../components/common/storage';
import { NoRecord } from '../../components/no-record/no-record';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { GoTop } from '../../components/common/goto-top';
import { Messages } from '../../components/common/message';
import * as Helper from '../../components/common/functions';
import * as Images from '../../components/common/images';
import './broadcast.scss';

class BroadcastMessage extends Component {  
    constructor(props){
        super(props);
        this.state = { 
            message: '',
            serverSuccess: '',
            showSuccess: '',
            errorMessage: '',
            showError: false,
            showModal: false,
            filteredMessages: [],
            sentToMembers: [],
            recordLoaded:  false,
            loading:  false,
            totalRecords: null,
            cancelToken: axios.CancelToken,
            cancel: undefined,
            filterCriteria: {
                searchKeyword: '',
                page: 1,
                pageSize: 15,
            },    
            sortCriteria: {
                column: 'id',
                direction: 'desc',
                sortingInProcess: false
            },
        };
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
    } 

    componentDidMount() {
        window.addEventListener('scroll', this.doDebouncedTableScroll);
        this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll);
    }

    handleModalClose = () => {
        this.setState({ errorMessage: '', showError: false, serverSuccess: '', showSuccess: false });
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load
        if (!this.state.recordLoaded || this.state.filteredMessages.length >= this.state.totalRecords) return;
        // Get document.documentElement.scrollTop for all browsers
        let docTop = typeof window.pageYOffset != 'undefined' ? window.pageYOffset : document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ? document.body.scrollTop : 0;
        // // Reduce 20percent from the offsetHeight
        let reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;
        // Check if scroll has hit bottom
        if (window.innerHeight + docTop >= reduceOffset) {
            let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.page = newFilterCriteria.page + 1;
            this.setState({ filterCriteria: newFilterCriteria });
            this.loadData();
        }
    }

    showError = (msg) => {
        this.setState({ errorMessage: msg, showError: true });
    }
    
    loadData = () => {
        const user = Storage.Get('user');
        this.setState({ recordLoaded: false });
        let me = this;
        const {filterCriteria, sortCriteria, cancel, cancelToken} =  this.state;
        if (user.selectedClubId != null) {
            if (this.state.cancel !== undefined) {
                cancel();
            }
            this.setState({ recordLoaded: false }, () => {
                Helper.axiosInstance()
                    .post(`broadcast/list-message`
                        , JSON.stringify({
                            club_id: user.selectedClubId,
                            page_size: filterCriteria.pageSize,
                            page: filterCriteria.page,
                            sort_by: sortCriteria.column,
                            sort_dir: sortCriteria.direction
                        }), {
                        cancelToken: new cancelToken(function executor(c) {
                            me.setState({ cancel: c });
                        })
                    })
                    .then((response) => {
                        if (response.data.success === true) {
                            if (this.state.filterCriteria.page === 1) {
                                this.setState({
                                    filteredMessages: response.data.data
                                });
                            }
                            else {
                                let existingRecords = this.state.filteredMessages;
                                this.setState({ filteredMessages: existingRecords.concat(response.data.data) });
                            }
                            this.setState({ totalRecords: response.data.count,  recordLoaded: true});
                        }
                        else {
                            this.showError(Messages.SERVER_ERROR);
                        }
                    })
                    .catch((error) => {
                        this.showError(Messages.SERVER_ERROR);
                    })
                    .finally(() => {
                        let { sortCriteria } = this.state;
                        sortCriteria.sortingInProcess = false;
                        this.setState({ recordLoaded: true, sortCriteria: sortCriteria });
                    });
            });
        }
    }

    sortHandler = (column) => {
        const {sortCriteria, filterCriteria} = this.state;
        if(!sortCriteria.sortingInProcess){
            let newSortCriteria = sortCriteria;
            newSortCriteria.column = column;
            if(sortCriteria.column  == column){
                newSortCriteria.direction = (sortCriteria.direction== 'asc') ? 'desc' : 'asc'; 
            } else {
                newSortCriteria.direction = 'desc';
            }
            newSortCriteria.sortingInProcess = true;
            let newFilterCriteria = {
                searchKeyword: '',
                page: 1,
                pageSize: filterCriteria.pageSize
            };

            this.setState({filteredMessages: [], sortCriteria: newSortCriteria, filterCriteria: newFilterCriteria}, this.loadData);
        }
    }

    messageResendHandler = (msgId) => {
        const me = this;
        Helper.axiosInstance().post(`broadcast/resend-message`, {'message_id': msgId})
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    me.setState({ serverSuccess: response.data.message, showSuccess: true});
                } else {
                    me.showError(response.data.message);
                }
            })
            .catch((error) => {
                this.showError(Messages.SERVER_ERROR);
            });
    }

    //Get the list of members to whom the announcement has been sent.
    getMemberList = (msgId) => {
        const me = this,
            user = Storage.Get('user');

        this.setState({showModal: true, loading: true});

        Helper.axiosInstance().post(`broadcast/member-list`, {'message_id': msgId, 'club_id': user.selectedClubId})
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    me.setState({ sentToMembers: response.data.data, loading: false});
                } else {
                    this.setState({showModal: false, loading: false});
                    me.showError(Messages.SERVER_ERROR);
                }
            })
            .catch((error) => {
                this.setState({showModal: false, loading: false});
                this.showError(Messages.SERVER_ERROR);
            });
    }
 
    render() {
        const {filteredMessages, sortCriteria, serverSuccess, showSuccess, showError, errorMessage} = this.state;
        //let sortImage = Images.ArrowUp;
        let sortImage = Images.ArrowSort;
        if(sortCriteria.direction == 'desc'){
            sortImage = Images.ArrowDown;
        } else if(sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }

        const modalHeader = (<Modal.Title>Member List</Modal.Title>);
        const modalContent = (
            <>
            <Modal.Body>
                <Loader loading={this.state.loading} />
                <Row>
                    <ol className="member-listing">
                        {
                            this.state.sentToMembers.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <em>{item.first_name} {item.last_name}</em>
                                    </li>
                                )
                            })
                        }
                    </ol>
                </Row>
            </Modal.Body>
            <Modal.Footer className="m-footer mt-0">
                <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                    <Button variant="secondary" onClick={()=>this.setState({showModal: false})}>
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
            </>
        );



        return (
            <>
            <div className="rgt-content">
                <Row className="row--space">
                    <Col md={12}>
                        <h1>Announcements</h1>
                    </Col>
                </Row>
                <Row className="vh-height">
                    <Col md={12} lg={12} className="md--full-width">
                        <div className="widget">
                            <Table bordered hover className="tab-responsive table--listing table--listing--history">
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th className="col-sortable" onClick={() => this.sortHandler('first_name')}>Sender
                                        { sortCriteria.column == 'first_name' ?
                                            <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                            <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                        }
                                        </th>
                                        <th>Total Contacts</th>
                                        <th className="col-sortable" onClick={() => this.sortHandler('created_at')}>Date
                                        { sortCriteria.column == 'created_at' ?
                                            <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                            <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                        }
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (typeof filteredMessages == "object" && Object.keys(filteredMessages).length > 0) &&
                                        filteredMessages.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td data-label="Message" className="td-content-space">
                                                    <ReadMoreAndLess
                                                        ref={this.ReadMore}
                                                        className="read-more-content"
                                                        charLimit={150}
                                                        readMoreText="Read More"
                                                        readLessText="Read Less"
                                                    >
                                                        {row.message}
                                                    </ReadMoreAndLess>
                                                    </td>
                                                    <td data-label="Sender">{row.first_name} {row.last_name}</td>
                                                    <td data-label="Total Contacts"><span className={row.total_members ? "text-underline" : ''} onClick={()=>row.total_members && this.getMemberList(row.id)}>{row.total_members}</span></td>
                                                    <td data-label="Date">{row.created_at}</td>
                                                    <td>
                                                        <Dropdown className="more-actions">
                                                            <Dropdown.Toggle>
                                                                <span className="more-im"></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.messageResendHandler(row.id)}>
                                                                    <Image className="mr-2" src={Images.Refresh} alt="Activate" width="21" />
                                                                    <span className="color">Resend</span>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    {!this.state.recordLoaded &&
                                        <>
                                            <tr className="mb--norecord">
                                                <td colSpan="5">
                                                    <SmallLoader />
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {
                                        (this.state.recordLoaded && this.state.filteredMessages.length === 0) &&
                                        <tr className="mb--norecord">
                                            <td colSpan="5">
                                                <NoRecord />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <GoTop scrollStepInPx="50" delayInMs="10" />
            </div>
            <ModalBox
                size="lg"
                show={this.state.showModal}
                onHide={()=>this.setState({showModal: false})}
                animation={true}
                modalheader={modalHeader}
                modalcontent={modalContent}
            />
            {
                showSuccess && serverSuccess  !== "" &&
                    <SuccessModal
                        successConfirmShow={showSuccess}
                        onConfirmClick={this.handleModalClose}
                        successText={serverSuccess}
                        successButtonText={"OK"}
                    />
            }
            {
                showError && errorMessage !== "" &&
                    <ErrorModal
                        message={errorMessage}
                        show={showError}
                        onConfirmClick={this.handleModalClose}
                    />
            }
            </>
        )
    }
}
export {BroadcastMessage};