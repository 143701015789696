/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, Modal } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Form as FormValid } from 'formik';
/* End - Formik */

import { ModalBox, SuccessModal, ErrorModal, ConfirmModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';

export default class AddMemberToTeam extends Component {
    constructor(props) {
        super(props);
        console.log('props', props)
        this.state = {
            serverSuccess: '',
            loader: false,
            confirmShow: false,
            formProcessing: false,
            showError: false,
            errorMessage: null,
            teamType: props.teamType,
            deleteMembers: props.deleteMembers,
        }
    }

    deleteMembersFromTeam = (fields, selectedMember) => {
        let memId = [];
        // eslint-disable-next-line
        for (let val of selectedMember.values()) {
            memId.push(val.userId);
        }
        this.setState({ formProcessing: true, teamId: fields.team_id });
        Helper.axiosInstance().delete('team/remove-member-multi', {
            data: {
                team_id: fields.team_id,
                member_id: memId,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ serverSuccess: response.data.message, formProcessing: false });
                } else {
                    this.setState({ errorMessage: response.data.error, showError: true, formProcessing: false });
                }

            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, formProcessing: false });
            });
    }

    addMemberToTeam = (fields, selectedMember) => {
        let memId = [],
            teamType = this.state.teamType;
        // eslint-disable-next-line
        for (let val of selectedMember.values()) {
            memId.push(val.userId);
        }
        this.setState({ formProcessing: true, teamId: fields.team_id });
        Helper.axiosInstance().post('team/add-member-multi', {
            team_id: fields.team_id,
            type: teamType,
            member_id: memId
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ serverSuccess: response.data.message, formProcessing: false });
                } else {
                    let confirmShow = false,
                        error = response.data.error;
                    if(response.data.max_limit_reached){
                        confirmShow = true;
                        error = Messages.MEMBER_LIMIT_CROSSED_MANUAL.replaceAll('##MAX_LIMIT##', response.data.max_limit);
                    }
                    this.setState({ errorMessage: error, confirmShow: confirmShow, showError: !confirmShow, formProcessing: false });
                }

            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, formProcessing: false });
            });
    }

    resetMembersModal = () => {
        this.setState({ formProcessing: false });
        this.props.toggleMemberModal(!this.props.memberModal.show)
    }

    handleSuccessClose = (redirectTo) => {
        if (this.state.deleteMembers) {
            this.props.toggleMemberModal(!this.props.memberModal.show)
            window.location.reload()
        }
        else {
            redirectTo.push(`/team-details/${this.state.teamId}`);
        }
    }

    render() {
        const { selectedMember, memberModal, redirect } = this.props;
        const { formProcessing, serverSuccess } = this.state;
        const handleClose = () => this.resetMembersModal(),
            membersModalHeader = (
                <>
                    <Modal.Title>Confirmation</Modal.Title>
                    {
                        this.state.deleteMembers ?
                            <p className="modal-subtitle">Are you sure you want to remove the selected contacts from the group?</p>
                            :
                            <p className="modal-subtitle">Are you sure you want to add the selected contacts in the group?</p>
                    }
                </>
            ),
            membersModalContent = (
                <Formik
                    ref={(ref) => this.formik = ref}
                    enableReinitialize
                    initialValues={{
                        team_id: memberModal.team_id
                    }}
                    onSubmit={
                        this.state.deleteMembers ? fields => {
                            this.deleteMembersFromTeam(fields, selectedMember);
                        } : fields => {
                            this.addMemberToTeam(fields, selectedMember);
                        }}
                    render={() => (
                        <FormValid noValidate>
                            <Modal.Body>
                                <div className="m-footer__ml-members">
                                    <Row className="w-100" >
                                        {(typeof selectedMember !== "undefined" && selectedMember.size > 0) ?
                                            [...selectedMember.values()].map((member, i) => (
                                                <Col sm="3" key={i}>
                                                    <div className="member-block text-center">
                                                        {member.avatar}
                                                        <div className="member-block__name">{member.name}</div>
                                                        <span className="delete-action" onClick={() => this.props.deleteSelectedMember(member.userId)}>
                                                            <Image src={Images.MemberDelete} alt="delete" />
                                                        </span>
                                                    </div>
                                                </Col>
                                            )) : null}
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="m-footer">
                                <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                                    <Button disabled={formProcessing ? true : false} variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button type="Submit" disabled={formProcessing ? true : false} className={"ripple btn btn-primary d-inline-flex align-items-center btn-ternary--width-auto " + (formProcessing ? "btn-loader" : "")}>
                                        <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Member" /></div>
                                        Yes <span style={{ 'display': 'none' }} className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </FormValid>
                    )}
                />
            );
        return (
            <>
                { /** Create a add Member Modals*/
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={memberModal.show}
                            onConfirmClick={() => this.handleSuccessClose(redirect)}
                            successText={serverSuccess}
                            successButtonText={"OK"} />
                        :
                        <ModalBox
                            size="lg"
                            show={memberModal.show}
                            onHide={handleClose}
                            animation={true}
                            modalheader={membersModalHeader}
                            modalcontent={membersModalContent} />
                }
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
                <ConfirmModal
                    show={this.state.confirmShow}
                    onCancelClick={()=>this.setState({confirmShow: false})}
                    onConfirmClick={()=>{
                        this.setState(
                            {teamType: 'A', loader: true},
                            ()=>this.addMemberToTeam({team_id: this.props.memberModal.team_id}, this.props.selectedMember));
                    }}
                    buttonText="Continue"
                    title="Group Limit Exceeded"
                    body={this.state.errorMessage}
                    modalLoader={this.state.loader}
                />
            </>
        )
    }
}
