/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Alert ,Container,Row,Col} from 'react-bootstrap';
/* End - React Bootstrap */

import './no-record.scss';

class NoRecord extends Component {
    render() {
       
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col sm={12} className=" mt-3 mb-4">
                            <Alert  className="disp-block" variant="warning">
                            <p className="mb-0">No record found</p>
                                {/* <hr />
                                <p className="mb-0">
                                    There are no record found for this entry. Please contact the system administrator.
                                </p> */}
                            </Alert> 
                        </Col>
                    </Row>
                </Container>               
            </>
        )
    }
}
export { NoRecord };