/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Button, Table, Media,Image ,Dropdown, Modal} from 'react-bootstrap';
/* End - React Bootstrap */
/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

import * as Images from '../../components/common/images';
import './student-data.scss';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { DeleteConfirmModal, SuccessModal, ModalBox, ErrorModal } from '../../components/modal/modalbox';
import * as Storage from '../../components/common/storage';
import { NoRecord } from '../../components/no-record/no-record';
import { SmallLoader } from '../../components/small-loader/sm-loader';
class StudentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            show: false,
            singleKid: {},
            handelDelete: {
                deleteConfirmShow: false,
                memberIdForDeletion: null,
                memberUserNameForDeletion: "",
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: ""
            },
            modalLoader: false,
            serverError: "",
            serverSuccess: "",
            errorMessage:"",
            recordLoaded:false
            
        };
    }  

    componentDidMount() {
        this.getStudentList();
    }

    getStudentList(){
        const me = this;
        if (Storage.Get('user') != null) {
                 Helper.axiosInstance().get(`user/lg-data-review`)
                     .then((response) => {
                         if(response.data.success){
                              me.setState({ kidsData: response.data.kidsData, recordLoaded:true});
                         }else{
                            me.setState({ errorName: response.data.message ,recordLoaded:true});
                         }
                     })
                   .catch(function (error) {
                         me.setState({ serverError: error });
                });
        }
    }
    
    setShow(action, kidId) {
        const kidinfo = this.state.kidsData.filter(kId=> kId.id === kidId);
        if(kidinfo.length){
            this.setState({
                show: action,
                singleKid:kidinfo
            })
        }else{
            this.setState({
                show: action
            })
        }
    }

    hideModal = () => {
        this.setShow(false);
    }

    removeKid(memberId, username, type){
        this.setState(prevState => {
           let handelDelete = Object.assign({}, prevState.handelDelete);
                  handelDelete.deleteConfirmShow = true;
                  handelDelete.memberIdForDeletion = memberId;
                  handelDelete.memberUserNameForDeletion = username;
                  handelDelete.confirmText = (type ==='account') ?"Do you want to remove this user permanently?" :'Do you want to remove this member chat history Permanently?';
                  handelDelete.confirmButtonText = "Remove";
           return { handelDelete };
        });
    }
    onRemoveKidCancelClick = () => {
        this.setState(prevState => {
            let handelDelete = Object.assign({}, prevState.handelDelete);
            handelDelete.deleteConfirmShow = false;
            return { handelDelete };
        })
    }

    onRemoveKidConfirmClick = () => {
        if (this.state.handelDelete.memberUserNameForDeletion !=='') {
            this.setState({ modalLoader: true });
            let data = {
                kid_id:this.state.handelDelete.memberIdForDeletion
            }
             Helper.axiosInstance().post(`user/delete-kid-account`, data)
                .then((response) => {
                    if ( response.data.success) {
                        this.setState(prevState => {
                            let handelDelete = Object.assign({}, prevState.handelDelete);
                                handelDelete.deleteConfirmShow = false;
                                handelDelete.memberIdForDeletion = null;
                                handelDelete.memberUserNameForDeletion = '';
                             return { handelDelete };
                         });
                         this.setState({ serverSuccess: response.data.message });
                         this.getStudentList();
                    }
                    else {
                       this.setState({ serverError: response.data.error, showError: true, });
                    }
                    this.setState({ modalLoader: false, });
             })
            .catch((error) => {
                this.setState({ serverError: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
            });
           
        }
    }

    handleSuccessClose = () => {
        this.setState({serverSuccess: ""});
        //window.location.reload();
        window.location.href = "/student-data";
    }

    sEmailList = (sEmail)=>{
        const items = []
        for (const [index, value] of sEmail.entries()) {
             items.push(<div key={index} className="data-row__value">{value}</div>)
        }
        return items;
    }

    render() {
        const {show, serverError, lgKidList, singleKid, serverSuccess } = this.state;
        const createModalHeader = (
            <>
                <Modal.Title>Profile Detail </Modal.Title>
            </>
        );
        const createModalContent = (
            <>
                <Modal.Body>
                    <Media>
                        <div className="student-data-img">
                            <Image className="mr-3 img--max-width-100" src={(singleKid[0]) ? singleKid[0].photo:''} alt="User" />
                        </div>
                        <Media.Body className="align-self-start">
                            <h5 className="heading heading--sm">{(singleKid[0]) ? singleKid[0].first_name:null} <span>({(singleKid[0]) ? singleKid[0].username:null} )</span></h5>
                            <div className="data-row">
                            <div className="data-row__col">
                                    <label>Primary Email</label>
                                    <div className="data-row__value">{(singleKid[0]) ? singleKid[0].email:null}</div>
                                </div>
                                <div className="data-row__col">
                                    <label>Date of Birth</label>
                                    <div className="data-row__value">{(singleKid[0]) ? singleKid[0].dob:null}</div>
                                </div>
                            </div>
                            {(this.state.singleKid[0]) ? (singleKid[0].secondary_emails.length>0)? 
                                <div className="data-row">
                                    <label>Secondary Email</label>
                                    {this.sEmailList(singleKid[0].secondary_emails)}
                                </div>
                                :null
                            :null
                            }
                        </Media.Body>
                    </Media>
                 </Modal.Body>
            </>
            );
       
           return (
            <>
                {

                serverSuccess !== "" ?
                <SuccessModal
                    successConfirmShow={true}
                    onConfirmClick={this.handleSuccessClose}
                    
                    successText={serverSuccess}
                    successButtonText={"OK"}
                />
                :
                    serverError !== "" ?
                        <ErrorModal
                        message={Messages.SERVER_ERROR}
                        show={true}
                        onConfirmClick={Helper.handleErrorClose}
                    />
                    :
                    null
                }
                
                <Row className="row--space">    
                    <Col sm={12} md={12} lg={12} className="mb-3">
                        <h1>Student's Detail</h1>
                    </Col>           
                   <Col sm={12}>
                        <div className="widget">
                            <Table bordered hover className="tab-responsive table--listing">
                                <thead>
                                    <tr>
                                        <th>Name </th>
                                        <th>Profile Information </th>
                                        {/*<th>Conversation </th>*/}
                                        <th>Organization</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!this.state.recordLoaded &&
                                    <>
                                        <tr>
                                            <td colSpan="5">
                                                <SmallLoader />
                                            </td>
                                        </tr>
                                    </>
                                }
                                    {!this.state.kidsData && this.state.recordLoaded &&
                                    <tr className="mb--norecord">
                                        <td colSpan="4">
                                            <NoRecord />
                                        </td>
                                    </tr>   
                                    }

                                { 
                                 this.state.kidsData && this.state.kidsData.map((item, key) => {
                                        for( const ckey in this.state.kidsData[key].club){
                                     return (
                                    <tr key={key}>
                                        <td>
                                        <Media>
                                            <div className="im-rounded-outer">
                                                <Image className="mr-2" src={this.state.kidsData[key].photo} alt="User" />
                                            </div>
                                            <Media.Body className="align-self-center"><h5>{this.state.kidsData[key].first_name}</h5></Media.Body>
                                        </Media>
                                        </td>
                                        <td><span className="detail-link" onClick={() => this.setShow(true, this.state.kidsData[key].id)}>View Detail</span></td>
                                        {/*<td onClick={() => openChatList()}>
                                        <span className="detail-link">Chat History </span> </td>*/}
                                            <td>{this.state.kidsData[key].club[ckey].name}</td>
                                        <td>
                                        <Dropdown className="more-actions">
                                                <Dropdown.Toggle>
                                                    <span className="more-im"></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                 <Dropdown.Item  onClick={() => this.removeKid(this.state.kidsData[key].id,this.state.kidsData[key].username, 'account')}>
                                                    <Image className="mr-2" src={Images.DeleteIc} alt="Edit" width="21" />
                                                    <span className="de-color">Delete Account </span>
                                                </Dropdown.Item>
                                          { /* <Dropdown.Item  onClick={() => this.removeKid(item.kid.id, item.kid.username, 'chat')}>
                                                    <Image className="mr-2" src={Images.DeleteIc} alt="Edit" width="21" />
                                                    <span className="de-color">Delete Conversation</span>
                                     </Dropdown.Item> */}
                                                </Dropdown.Menu>
                                        </Dropdown>
                                        </td>
                                    </tr>
                                   
                                   )
                                    }
                               // })
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                   </Col>
               </Row>
               
               <ModalBox
                    size="lg"
                    show={show}
                    onHide={this.hideModal}
                    animation={true}
                    modalheader={createModalHeader}
                    modalcontent={createModalContent}

                />
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handelDelete.deleteConfirmShow}
                    onCancelClick={this.onRemoveKidCancelClick}
                    confirmText={this.state.handelDelete.confirmText}
                    confirmButtonText={this.state.handelDelete.confirmButtonText}
                    onConfirmClick={this.onRemoveKidConfirmClick}
                    modalLoader={this.state.modalLoader}
                   
                />
            </>    
        )
    }
  
}
export { StudentData };