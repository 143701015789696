/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */


/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {
    Row,
    Col,
    Table,
    Media,
    Image,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form, ErrorMessage} from 'formik';
/* End - Formik */

import { SuccessModal, ErrorModal, ConfirmModal, ModalBox} from '../../components/modal/modalbox';
import {NoRecord} from '../../components/no-record/no-record';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import {SmallLoader} from '../../components/small-loader/sm-loader';
import {Messages} from '../../components/common/message';
import * as Enum from '../../components/common/enum';
import Loader from '../../components/loader/loader';
import {orderBy} from 'lodash';
import '../club/club.scss';
import InviteClub from './invite-club';
import * as Storage from "../../components/common/storage";

class IsrClubs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordLoaded: false,
            activeClubListSearch: [],
            modalLoader: false,
            search: "",
            loading: false,
            modalVisibility: false,
            serverSuccess: "",
            showError: false,
            errorMessage: "",
            sortCriteria: {
                column: 'name',
                direction: 'asc'
            },
        };
     }

    componentDidMount() {
        this.getClubsList();
    }

    /**
     * function to let the list of the assigned club to ISR
     * return array
     */
    getClubsList = () => {
        if (Storage.Get('user') != null) {
            this.setState({recordLoaded: false,});
            Helper.axiosInstance().get(`isr/clubs`)
                .then((response) => {
                    if (response.data.success) {
                        const clubs = response.data.data;
                        this.setState({
                                activeClubList: clubs,
                                activeClubListSearch: clubs,
                                recordLoaded: true,
                            }
                        );
                    } else {
                        this.setState({errorMessage: response.data.error, showError: true, recordLoaded: true});
                    }
                })
                .catch((error) => {
                    this.setState({errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true});
                });

        }
    }

    /**
     * Function to filter the list on while searching
     * @param target
     */

    filterList = (target) => {
        this.setState({search: target.value})
        this.setState({activeClubListSearch: this.state.activeClubList.filter(x => (x.name).toLowerCase()
                .indexOf(target.value.toLowerCase().trim()) !== -1)})
    }

    /**
     * Function to sort the clubs in Ase and desc order
     * @param column
     */
    sortHandler = (column) => {
        const {sortCriteria} = this.state;
        let newSortCriteria = sortCriteria;
            newSortCriteria.column = column;

        if (sortCriteria.column === column) {
            newSortCriteria.direction = (sortCriteria.direction === 'asc') ? 'desc' : 'asc';
        } else {
            newSortCriteria.direction = 'asc';
        }
        let sortedOrg = orderBy(this.state.activeClubListSearch, [org => isNaN(org[column]) ? org[column].toLowerCase()
            : org[column]], [newSortCriteria.direction]);
        this.setState({activeClubListSearch: sortedOrg});
    }


    toggleModal = (visible) => {
        this.setState({modalVisibility: visible});
    };

    render() {
        const {search, loading, serverSuccess, showError, errorMessage, modalVisibility, sortCriteria} = this.state;
        return (
            <>
                <Loader loading={loading}/>
                <div className="rgt-content">
                    <Row className="row--space align-items-center">
                        <Col sm="4">
                            <h1>Organizations</h1>
                        </Col>
                        <Col sm={8} className="text-right cb-action-wrap">
                            <div className="action-filter">
                                <div className="c-filter-outer">
                                    <div className="wgt-btnwrap">
                                        <OverlayTrigger placement="auto"
                                                        overlay={
                                                            <Tooltip id={`tooltip`}>
                                                                Invite an Organization to Join SafeComm
                                                            </Tooltip>}>
                                            <a href={null} onClick={() => this.toggleModal(!modalVisibility)}>
                                                <div className="outline-circle">
                                                    <Image src={Images.inviteClubFull} alt="Invite New Organization" width="30"/>
                                                </div>
                                            </a>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                <div className="tab-search tab-search--big tab-search--widget">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            search: search
                                        }}
                                        onSubmit={() => false}
                                        render={() => (
                                            <Form className="form-inline" noValidate>
                                                <Field name="search" type="text" maxLength="100"
                                                       onChange={(e) => this.filterList(e.target)}
                                                       className="form-control tab-search__box" placeholder="Search"/>
                                                <button className="close-icon" type="reset"
                                                        onClick={(e) => this.filterList(e.target)}/>
                                            </Form>
                                        )}
                                    />
                                </div>
                                <ClubList data={this.state.activeClubListSearch}
                                          recordLoaded={this.state.recordLoaded}
                                          sortCriteria={sortCriteria}
                                          sortHandler={this.sortHandler}
                                          screen={"Active"}
                                />

                            </div>
                        </Col>
                    </Row>
                </div>

                  <InviteClub
                    modalVisibility={modalVisibility}
                    toggleModal={this.toggleModal}
                    history={this.props.history}
                    getPendingContacts={() => this.getPendingContacts()}
                />
               {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={this.handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        showError !== "" ?
                            <ErrorModal
                                show={showError}
                                message={errorMessage}
                                onConfirmClick={() => {
                                    this.setState({showError: false})
                                }}
                            />
                            :
                            null
                }
            </>
        )
    }
}

const ClubList = (props) => {
    let sortImage = Images.ArrowSort;
    if (props.sortCriteria && props.sortCriteria.direction == 'desc') {
        sortImage = Images.ArrowDown;
    } else if (props.sortCriteria && props.sortCriteria.direction == 'asc') {
        sortImage = Images.ArrowUp;
    }
    return (
        <Table bordered hover className="tab-responsive table--listing table--super-admin-width">
            <thead>
            <tr>
                {
                    props.data && props.data.length > 1 && props.sortCriteria ?
                        <th className="col-sortable" onClick={() => props.sortHandler('name')}>Organizations
                            {props.sortCriteria.column == 'name' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th>Organizations</th>
                }
                <th>Total Members</th>
                <th>Billable Members</th>
                <th>Date created</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {
                (typeof props.data == "object" && Object.keys(props.data).length > 0) &&
                props.data.map((row, i) => {
                    return (
                        <tr key={i}>
                            <td data-label="Organizations" className="td-content-space">
                                <Media>
                                    <div className="im-rounded-outer">
                                        <Image className="mr-2" src={row.photo} alt="User"/>
                                    </div>
                                    <Media.Body className="align-self-center">

                                        <h5>{row.name}</h5></Media.Body>
                                </Media>
                            </td>
                            <td data-label="Total Members">{row.total_member}</td>
                            <td data-label="Billable Members">{row.billable_member}</td>
                            <td data-label="Date created">{row.created_at}</td>
                            <td data-label="Status"><span className={`badge--f-size badge-${row.status ? 'acpt' : (row.approval_status === 'A' ? 'reject' : 'pndg')}`}>{
                                row.status ? 'Active' : (row.approval_status === 'A' ? 'Inactive' : Enum.OrgStatus[row.approval_status])
                            }</span>
                            </td>
                        </tr>
                    );
                })
            }
            {!props.recordLoaded &&
            <>
                <tr>
                    <td colSpan="5">
                        <SmallLoader/>
                    </td>
                </tr>
            </>
            }
            {(props.recordLoaded && props.data.length === 0) &&
            <tr className="mb--norecord">
                <td colSpan="5">
                    <NoRecord/>
                </td>
            </tr>
            }
            </tbody>
        </Table>
    )
};

export {IsrClubs};
