/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from './images';

class GoTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: 0,
            thePosition: false
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", this.setScroll);
    }
    
    setScroll = () => {
        if (window.scrollY > 170) {
            this.setState({ thePosition: true })
        } else {
            this.setState({ thePosition: false })
        }
    }

    componentWillUnmount(){
        document.removeEventListener("scroll", this.setScroll);
    }

    onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderGoTopIcon = () => {
        if (this.state.thePosition){
            return (
                <div className="go-top" onClick={this.scrollToTop}>
                    <Image src={Images.GoToTop} alt="go to top"/>
                </div>
            )
        }
    }

    render(){
        return (
            <>
                {this.renderGoTopIcon()}
            </>
        )
    }
}
export {GoTop};