/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Button, Container } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/* Start - app imports */
import * as Helper from '../../components/common/functions';
import { ErrorModal } from '../../components/modal/modalbox';
import { Messages } from '../../components/common/message';
import * as Enum from '../../components/common/enum';
import * as Storage from '../../components/common/storage';
import Loader from '../../components/loader/loader';
import {Footer} from '../../components/footer/footer';
import { RegisterSuccess, InviteHeader, clubTeamName, RegisterError } from './invite-helper';
import './invite.scss';
import { InviteMessages } from './invite-message';
import * as Regex from "../../components/common/regex";
/* End - app imports */

class InviteLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            inviteToken: props.match.params.inviteToken,
            isRedirected: props.match.params.redirected,
            inviteError: "",
            linkingError: "",
            serverError: "",
            inviteDetails: "",
            serverSuccess: "",
            loading: false,
            createAccountLink: "#",
            registerMsg: "",
        };
    }

    componentDidMount() {
        this.validateInvite();
    }
    validateInvite() {
        const me = this,
            inviteToken = this.state.inviteToken;

        this.setState({ loading: true });
        Helper.axiosInstance().post(`invite/validate`, {
            invite_code: inviteToken
        })
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    let inviteDetails = response.data.data;
                    inviteDetails.invite_code = inviteToken;

                    if (Storage.Get('inviteDetails')) {
                        Storage.Delete('inviteDetails');
                    }
                    Storage.Set('inviteDetails', JSON.stringify(inviteDetails));
                    me.setState({ inviteDetails, loading: false });

                    this.createAccountLink();
                } else if(response.data.type == 'lg_pending_verification'){
                     this.props.history.push({
                        pathname: `/verify-invite/${inviteToken}`,
                        errorMsg: response.data.error ? response.data.error :''
                    });

                } else {
                    me.setState({ inviteError: response.data.message, loading: false });
                    Storage.Delete('inviteDetails');
                }
            })
            .catch((error) => {
                me.setState({ serverError: error, loading: false });
            });
    }

    inviteUser = (fields) => {
        const inviteDetails = this.state.inviteDetails;
        this.setState({ loading: true, linkingError: "" }, () => {
            Helper.axiosInstance().post(`verify/email`, {
                invite_code: this.state.inviteToken,
                username: fields.uId
            })
                .then((response) => {
                    // If server response message same as Data Matched
                    if (response.data.success) {
                        const clubName = clubTeamName(inviteDetails);
                        let msg = InviteMessages.LINK_VERIFICATION_SENT;
                        msg = msg.replace(/{@username}/g, fields.uId);
                        msg = msg.replace("{@organizationAndMembergroup}", clubName);

                        this.setState({
                            serverSuccess: response.data.message,
                            registerMsg: msg
                        });
                    }
                    else {
                        this.setState({ linkingError: response.data.error });
                    }
                })
                .catch((error) => {
                    this.setState({ serverError: error });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    handleInviteError() {
        window.location.href = `mailto:${Enum.SupportEmail}`;
    }

    createAccountLink() {
        const { inviteToken, inviteDetails } = this.state;
        let createAccountLink = "#";

        // Invite process for LG and Student
        if (inviteDetails.role_id === Enum.UserRoles.LEGAL_GUARDIAN) {
            if (inviteDetails.guardian_id == null && inviteDetails.user_id == null) {
                // create account for LG and Kid
                createAccountLink = `/invitations/${inviteToken}`;
            } else if (inviteDetails.guardian_id !== null && inviteDetails.user_id == null) {
                // create account for Kid
                createAccountLink = false;
            } else if (inviteDetails.guardian_id == null && inviteDetails.user_id !== null) {
                // create account for LG
                createAccountLink = `/invite-lg/${inviteToken}`;
            } else {
                //your account has been created, api call, show message on this page
                this.linkAlreadyRegistered();
            }
        } else if (inviteDetails.role_id === Enum.UserRoles.COACH) {
            // Invite process for Instructor
            if (inviteDetails.user_id) {
                this.linkAlreadyRegistered();
            } else {
                createAccountLink = `/invite-instructor/${inviteToken}`;
            }
        } else if (inviteDetails.role_id === Enum.UserRoles.CLUB_ADMIN) {
            // Invite process for Org Admin
            if (inviteDetails.user_id) {
                this.linkAlreadyRegistered();
            } else {
                createAccountLink = `/invite-orgadmin/${inviteToken}`;
            }
        }

        if (createAccountLink) {
            this.setState({ createAccountLink })
        } else {
            this.props.history.push(`/invite-student/${inviteToken}`);
        }
    }

    // Api request to link already registered users.
    linkAlreadyRegistered() {
        const me = this,
            inviteDetails = this.state.inviteDetails;

        this.setState({ loading: true });

        let apiUrl = 'register/lg',
            apiParams = {
                user_id: inviteDetails.user_id,
                invite_code: inviteDetails.invite_code
            },
            getRegisterMsg = "";

        if (inviteDetails.role_id === Enum.UserRoles.COACH || inviteDetails.role_id === Enum.UserRoles.CLUB_ADMIN) {
            apiUrl = 'register/user';
            getRegisterMsg = `You have been linked successfully as an ${Helper.memberRole([inviteDetails.role_id])} of ${clubTeamName(inviteDetails)}`;
        } else {
            apiParams.guardian_id = inviteDetails.guardian_id;
            getRegisterMsg = `You have been linked successfully with ${clubTeamName(inviteDetails)}`;
        }

        Helper.axiosInstance().post(apiUrl, apiParams)
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    // Change message if user was redirected from email linking page
                    if (this.isRedirectedRequest()) {
                        const data = response.data.data;
                        getRegisterMsg = InviteMessages.LINK_VERIFICATION_SUCCESS;
                        getRegisterMsg = getRegisterMsg.replace("{@username}", data.username);
                        getRegisterMsg = getRegisterMsg.replace("{@organizationAndMembergroup}", clubTeamName(inviteDetails));
                    }
                    me.setState({ serverSuccess: response.data.message, loading: false, registerMsg: getRegisterMsg });
                    Storage.Delete('inviteDetails');
                } else {
                    // get the server errors
                    const getErrors = response.data.error;
                    me.setState({ inviteError: getErrors, loading: false });
                }
            })
            .catch((error) => {
                me.setState({ serverError: error, loading: false });
            });
    }

    // Check if user has been redirected from verify email page
    isRedirectedRequest = () => { return (this.state.isRedirected && this.state.isRedirected === 'true') }

    render() {
        const { inviteError, serverError, createAccountLink, loading, serverSuccess, inviteDetails, registerMsg, linkingError, inviteToken } = this.state;

        return (
            <>
                <InviteHeader inviteDetails={inviteDetails} />
                <Loader loading={loading} />
                <Container>
                    {
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={true}
                                onConfirmClick={Helper.handleErrorClose}
                            />
                            :
                            null
                    }

                    {
                        inviteError !== "" ?
                            <RegisterError
                                inviteError={inviteError}
                            />
                            :

                            serverSuccess !== "" ?
                                <RegisterSuccess
                                    registerMsg={registerMsg}
                                />
                                :
                                <Row className="vh-content">
                                    <Col sm={12} className="in-card-center">
                                        <div className="in-card-subheading">You have been invited to register with {clubTeamName(inviteDetails)}.</div>
                                    </Col>
                                    <Col sm={6} className="in-card-center">
                                        <div className="in-card">
                                            <div className="in-card__header">
                                                <div className="in-card__header__subheading">
                                                    New Member?
                                                </div>
                                                <div className="in-card__header__heading">
                                                    Start your Membership
                                            </div>
                                            </div>
                                            <div className="in-card__content">
                                                <p>If you are not currently a member of the Safecomm then click the button below to create an account. </p>
                                            </div>
                                            <Form>
                                                <Link to={createAccountLink} className="in-card__btn btn justify-content-center">
                                                    Register Now
                                            </Link>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="in-card-center">
                                        <div className="in-card">
                                            <div className="in-card__header">
                                                <div className="in-card__header__subheading">
                                                    Registered Member?
                                                </div>
                                                <div className="in-card__header__heading">
                                                    Link your Account
                                                </div>
                                            </div>
                                            <div className="in-card__content">
                                                <p>If you are already a member of the Safecomm but with a different email address, then add your registered username below to link the accounts. </p>
                                            </div>
                                            <Formik
                                                ref={(ref) => this.formik = ref}
                                                enableReinitialize
                                                initialValues={{
                                                    uId: "",
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    uId: Yup.string().trim()
                                                        .max(50, 'Please enter no more than 50 characters')
                                                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                                                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                                                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                                                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                                                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                                                        .required('Username is required'),
                                                })}
                                                onSubmit={fields => {
                                                    this.inviteUser(fields);
                                                }}
                                                render={({ errors, status, touched, values, setFieldValue }) => (
                                                    <Form noValidate>
                                                        <div>
                                                            <div className="field form-group">
                                                                <Field name="uId" type="text" id="uId" onFocus={Helper.inputFocus}
                                                                    onChange={(e) => {
                                                                        this.setState({ linkingError: '' });
                                                                        setFieldValue('uId', e.target.value);
                                                                    }}
                                                                    onBlur={Helper.inputBlur} className={'form-control' + ((errors.uId && touched.uId) || linkingError !== "" ? ' is-invalid' : '')} />
                                                                <label htmlFor="uId" className="form-label">Enter your username</label>
                                                                <ErrorMessage name="uId" component="div" className="invalid-feedback" />
                                                                {
                                                                    linkingError !== "" && values.uId &&
                                                                    <div className="invalid-feedback">{linkingError}</div>
                                                                }
                                                            </div>
                                                            <Button type="submit" className="in-card__btn in-card__btn--blue btn justify-content-center">
                                                                Submit
                                                        </Button>
                                                        </div>
                                                    </Form>
                                                )}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                    }
                   <Footer />

                </Container>
            </>
        )
    }
}
export { InviteLandingPage };
