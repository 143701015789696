/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Col, Row} from 'react-bootstrap';
/* End - React Bootstrap */
import {Link} from "react-router-dom";
/* End - React */

export function AboutISR({path}){
    return(
        <>
            <Row>
                <Col sm={12}>
                    <div className="alert  alert--layout">
                        <p>SafeComm's Affiliate Marketing Program gives any parent, coach, or organization leader the opportunity to help us protect hundreds, even thousands, of children from dangerous pedophiles and predators.</p>
                        <p>For simply referring people to SafeComm, you will earn 20% of each sale in the first year, 10% every year thereafter.  There is no limit to how many organizations you can refer, or the amount that you can earn!</p>
                        <p>To learn more, visit our website.  Or, if you’re ready to start sending referral, click "Next" below to get registered.</p>
                    </div>
                </Col>
            </Row>
            <div className="widget__footer justify-content-end">
                <Link to={path ? path:`/register-as-isr`} className="ripple btn btn-primary btn-medium d-inline-flex align-items-center  btn btn-secondary">
                    Next
                </Link>
            </div>
        </>
    )
}

