
const generateMaxLengthMessage = (length) => {
    return `Please enter no more than ${length} characters.`
}

export const generateMinLengthMessage = (length) => {
    return `Please enter at least ${length} characters.`
}

export const Messages = {
    /*Common errors*/
    SERVER_ERROR: "Oops! Some error occured on the server. Please try again.",

    /*Common Form Submission Error Messages*/
    /*Max length messages*/
    MAX_LENGTH_5:  generateMaxLengthMessage('5'),
    MAX_LENGTH_9:  generateMaxLengthMessage('9'),
    MAX_LENGTH_15: generateMaxLengthMessage('15'),
    MAX_LENGTH_17: generateMaxLengthMessage('17'),
    MAX_LENGTH_50: generateMaxLengthMessage('50'),
    MAX_LENGTH_100: generateMaxLengthMessage('100'),
    MAX_LENGTH_150: generateMaxLengthMessage('150'),
    MAX_LENGTH_255: generateMaxLengthMessage('255'),
    MAX_LENGTH_500: generateMaxLengthMessage('500'),

    MIN_LENGTH_8: generateMinLengthMessage('8'),

    PASSWORD_FORMAT_ERR: 'Password must contain at least 1 special character(@$!%*?&), 1 uppercase, 1 lowercase and 1 number.',
    PASSWORD_CPASS_MATCH_ERR: 'Password and confirm password must match',
    REQUIRED_ERR: 'This field is required',

    /*Invalid type messages*/
    INVALID_EMAIL: "Enter a valid email address",
    INVALID_USERNAME1: 'Usernames cannot begin and end with underscore or hyphen.',
    INVALID_USERNAME2: 'Only alphabets, digits, underscores and hyphen are allowed.',
    INVALID_USERNAME3: 'Hyphen can only be used once.',
    INVALID_USERNAME4: 'Underscore can only be used once.',
    INVALID_USERNAME5: 'Underscore and hyphen cannot be combined.',
    INVALID_PHONE: "Enter a valid phone number",
    INVALID_URL: "Enter a valid URL",
    INVALID_ZIP_LENGTH: "Zip Code must be of 5 numbers.",
    INVALID_ZIP_TYPE: "ZipCode must be a number",
    INVALID_SSN: "Social Security Number is not valid",
    ONLY_NUMBERS: "Please enter a valid account number.",

    /* Photo upload messages*/
    PHOTO_MAX_SIZE: "Maximum file size allowed is 6 MB",
    PHOTO_TYPE_ALLOWED: "Please upload image with .jpeg or .jpg extension",

    ORGSA_SHORT: "Organization SA",
    LG_SHORT: "LG",

    NO_MEMBER_SELECTED: 'Please select the members to proceed.',
    NO_MEMBER_GROUP_SELECTED: 'Please select at least one Member Group.',
    MEMBER_LIMIT_CROSSED: `The Member (roster) group(s) listed below have more than ##MAX_LIMIT## students. Member group(s) with more than ##MAX_LIMIT## students may not be used for SafeChats. Group(s) with more than ##MAX_LIMIT## students are limited to Broadcast (one way messages from organization to members) communication only.<br/><br/>

Click continue to import the group(s) below as broadcast group(s) only or cancel and update the file with fewer students in the group(s) to allow SafeChats.`,
    MEMBER_LIMIT_CROSSED_MANUAL: `This Member (roster) group has reached the maximum limit of ##MAX_LIMIT## students. Member groups with more than ##MAX_LIMIT## students may not be used for SafeChats. Groups with more than ##MAX_LIMIT## students are limited to Broadcast (one way messages from organization to members) communication only.<br/><br/>

Click continue to change this group to a broadcast only group.`
}



