/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Image, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form, ErrorMessage} from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import {SuccessModal, ErrorModal} from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import {Messages} from '../../components/common/message';
import * as Helper from '../../components/common/functions';
import {UsStates} from '../../components/common/us-states';
import './../club/club.scss';
import {AboutISR} from '../../components/common/about-isr';
import {SSNInput} from "../../components/input/ssn";
import * as Regex from "../../components/common/regex";
import {AgreementText} from "./agreement-text";
import * as Storage from "../../components/common/storage";
import * as SessStorage from "../../components/common/sess-storage";
import * as Enum from "../../components/common/enum";

class RegisterISR extends Component {
    constructor(props) {
        super(props);
        const user = Storage.Get('user');
        this.state = {
            fields: {},
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            bank_account_number: '',
            bank_routing_number: '',
            bank_account_name: '',
            confirm_bank_account_number: '',
            social_security_number: '',
            confirm_social_security_number: '',
            dataLoading: true,
            saveISRLoader: false,
            showAgreement: false,
            isTermChecked: false,
            showTermErr: false,
            isrPendingApproval: user.isr_pending_approval ?? false,
            serverValid: {
                bank_account_name: '',
                bank_account_number: '',
                bank_routing_number: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: '',

            },
        };
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    onSelectMailChange = (event, setFieldValue) => {
        setFieldValue("state", event.target.value);
    }

    onChangeTerm = (checked) => {
        this.setState({
            showTermErr: !checked,
            isTermChecked: checked
        });
    }

    showAgreement = (fields, resetForm) => {
        console.log('showAgreement')
        this.setState({showAgreement: true, fields: fields})
        this.resetForm = resetForm;
    }

    /**
     * Register as ISR
     * @param fields
     * @param resetForm
     * @return Void
     */
    registerISR = () => {
        if (!this.state.isTermChecked) {
            this.setState({showTermErr: true});
            return false;
        }
        const fields = this.state.fields, resetForm = this.resetForm;
        this.setState({saveISRLoader: true})
        let data = {
            address: fields.address.trim(),
            address2: fields.address2 ? fields.address2.trim() : '',
            bank_account_name: fields.bank_account_name.trim(),
            bank_account_number: fields.bank_account_number.trim(),
            bank_routing_number: fields.bank_routing_number.trim(),
            ssn: fields.social_security_number,
            city: fields.city.trim(),
            state: fields.state,
            zip: fields.zip,

        }
        Helper.axiosInstance().post('isr/register-user', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        isrPendingApproval: true,
                        successConfirmShow: true,
                        saveISRLoader: false,
                        successText: response.data.message,
                    });
                    let user = Storage.Get('user');
                    user.isr_pending_approval = true;
                    Storage.Set('user', JSON.stringify(user));
                    //reset the form after success
                    resetForm({
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                        bank_account_name: '',
                        bank_account_number: '',
                        bank_routing_number: '',
                        social_security_number: '',
                        confirm_social_security_number: '',
                        confirm_bank_account_number: '',
                    });
                } else {
                    let getErrors = response.data.error;
                    if (Array.isArray(getErrors) || typeof getErrors === 'object') {
                        this.resetServerError();
                        let serverValid = this.state.serverValid;
                        // eslint-disable-next-line
                        for (let errorIndex in getErrors) {
                            if (getErrors[errorIndex][0] !== "") {
                                serverValid[errorIndex] = getErrors[errorIndex][0];
                            }
                        }

                        this.setState({serverValid, saveISRLoader: false});
                    } else {
                        this.setState({showError: true, errorMessage: response.data.error, saveISRLoader: false,});
                    }
                }
            })
            .catch(() => {
                this.setState({errorMessage: Messages.SERVER_ERROR, showError: true, saveISRLoader: false,});
            });
    }

    resetServerError() {
        this.setState({
            serverValid: {
                bank_account_name: '',
                bank_account_number: '',
                bank_routing_number: '',
                address2: '',
                address: '',
                city: '',
                state: '',
                zip: '',
            }
        });
    }

    render() {
        const {
            address, address2, city, state, zip, bank_account_name, bank_account_number, bank_routing_number, serverValid,
            confirm_bank_account_number, social_security_number, confirm_social_security_number, showAgreement, isrPendingApproval
        } = this.state;
        const user = Storage.Get('user');
        return (
            <>

                <div className="rgt-content">
                    {
                        isrPendingApproval ?
                            <Row className="row--space">
                                <Col sm={12}>
                                    <div className="d-flex align-items-center flex-column">
                                        <Image src={Images.ISRPageLogo} alt="MA Logo" width="200"/>
                                        <span>Marketing Affiliate (MA)</span>
                                    </div>
                                    <div className="widget">
                                        <Row>
                                            <Col sm={12}>
                                                <div className="alert  alert--layout">
                                                    Your application to register as Marketing Affiliate has been
                                                    submitted and is pending for admin approval.
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            :
                            showAgreement ?
                                <Row className="row--space">

                                    <Col sm={12}>
                                        <div className="d-flex align-items-center flex-column">
                                            <Image src={Images.ISRPageLogo} alt="MA Logo" width="200"/>
                                            <span>AFFILIATED MARKETING AGENT AGREEMENT</span>
                                        </div>
                                        <div className="widget">
                                            <AgreementText
                                                general={{"firstname": user.first_name, "lastname": user.last_name}}
                                                address={{
                                                    "mailAddress": this.state.fields.address,
                                                    "mailAddress2": this.state.fields.address2,
                                                    "mailCity": this.state.fields.city,
                                                    "mailState": this.state.fields.state,
                                                    "mailZip": this.state.fields.zip
                                                }}
                                            />
                                            <div className="widget__footer d-block text-left">
                                                <Row className="">
                                                    <Col sm={6}>
                                                        <div className="disp-inline">
                                                            <div className="checkbox-wrap checkbox-footer pt-2">
                                                                <input type="checkbox" id="chkTerm"
                                                                       checked={this.state.isTermChecked}
                                                                       onClick={(e) => this.onChangeTerm(e.target.checked)}/>
                                                                <label htmlFor="chkTerm">I accept the terms and
                                                                    conditions.</label>
                                                            </div>
                                                        </div>
                                                        <div className="disp-inline mb-0">
                                                            {(this.state.showTermErr) ?
                                                                <p className="error-msg invalid-feedback mb-0">Please
                                                                    accept the term and
                                                                    conditions.</p> : ''}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="text-right">
                                                        <Button onClick={() => this.registerISR()}
                                                                disabled={this.state.saveISRLoader}
                                                                className={`ripple btn-medium d-inline-flex align-items-center ${this.state.saveISRLoader ?
                                                                    'btn-loader' : ''}`}>
                                                            <div className="btn__icon btn__icon--red">
                                                                <Image src={Images.CompleteIc}
                                                                       alt="Update"/>
                                                            </div>
                                                            Register <span style={{'display': 'none'}}
                                                                           className="spinner-border spinner-border-sm"
                                                                           role="status"
                                                                           aria-hidden="true"/>
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                this.props.history.location.hash === "#info" ?
                                    <Row className="row--space">
                                        <Col sm={12}>
                                            <div className="d-flex align-items-center flex-column">
                                                <Image src={Images.ISRPageLogo} alt="MA Logo" width="200"/>
                                                <span>Marketing Affiliate (MA)</span>
                                            </div>
                                            <div className="widget">
                                                <AboutISR/>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className="row--space">
                                        <Col sm={12}><h1>Register as Marketing Affiliate</h1></Col>
                                        <Col md={12} lg={12} className="md--full-width order-md-2 order-lg-3">
                                            <div className="widget">
                                                <Formik
                                                    ref={(ref) => this.formik = ref}
                                                    enableReinitialize
                                                    initialValues={{
                                                        address: '',
                                                        address2: '',
                                                        city: '',
                                                        state: '',
                                                        zip: '',
                                                        bank_account_name: bank_account_name,
                                                        bank_account_number: bank_account_number,
                                                        bank_routing_number: bank_routing_number,
                                                        confirm_bank_account_number: confirm_bank_account_number,
                                                        social_security_number: social_security_number,
                                                        confirm_social_security_number: confirm_social_security_number,
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        address: Yup.string().trim()
                                                            .max(255, Messages.MAX_LENGTH_255)
                                                            .required(Messages.REQUIRED_ERR),
                                                        address2: Yup.string().trim()
                                                            .max(190, Messages.MAX_LENGTH_255).nullable(),
                                                        bank_account_name: Yup.string().trim()
                                                            .max(150, Messages.MAX_LENGTH_150)
                                                            .required(Messages.REQUIRED_ERR),
                                                        bank_routing_number: Yup.string().trim()
                                                            .max(9, Messages.MAX_LENGTH_9)
                                                            .required(Messages.REQUIRED_ERR),
                                                        bank_account_number: Yup.string().trim()
                                                            .required(Messages.REQUIRED_ERR)
                                                            .matches(Regex.numbers, {
                                                                message: Messages.ONLY_NUMBERS,
                                                                excludeEmptyString: false
                                                            })
                                                            .test('account-number-length', 'Must be exactly between 10 to 12 characters', function (val) {
                                                                if (val && (val.toString().length < 10 || val.toString().length > 12)) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            }),

                                                        confirm_bank_account_number: Yup.string().trim()
                                                            .max(12, Messages.MAX_LENGTH_17)
                                                            .required(Messages.REQUIRED_ERR)
                                                            .test('account-match', 'Bank Account Number and Confirm Bank Account Number must match', function (value) {
                                                                return this.parent.bank_account_number === value;
                                                            }),
                                                        city: Yup.string().trim()
                                                            .max(150, Messages.MAX_LENGTH_150)
                                                            .required(Messages.REQUIRED_ERR),
                                                        state: Yup.string().trim()
                                                            .max(150, Messages.MAX_LENGTH_150)
                                                            .required(Messages.REQUIRED_ERR),
                                                        zip: Yup.number()
                                                            .typeError(Messages.INVALID_ZIP_TYPE)
                                                            .min(10000, Messages.INVALID_ZIP_LENGTH)
                                                            .max(99999, Messages.INVALID_ZIP_LENGTH)
                                                            .required(Messages.REQUIRED_ERR),
                                                        social_security_number: Yup.string().trim()
                                                            .matches(Regex.ssn, {
                                                                message: Messages.INVALID_SSN,
                                                                excludeEmptyString: false
                                                            })
                                                            .required(Messages.REQUIRED_ERR),
                                                        confirm_social_security_number: Yup.string().trim()
                                                            .required(Messages.REQUIRED_ERR)
                                                            .matches(Regex.ssn, {
                                                                message: Messages.INVALID_SSN,
                                                                excludeEmptyString: false
                                                            })
                                                            .test('ssn-match', 'Social Security Number and Confirm Social Security Number must match', function (value) {
                                                                return this.parent.social_security_number === value;
                                                            }),
                                                    })}
                                                    onSubmit={(fields, {resetForm}) => {
                                                        this.showAgreement(fields, resetForm);
                                                    }}
                                                    render={({errors, touched, values, setFieldValue}) => (
                                                        <Form className="contact-us" noValidate>
                                                            <>
                                                                <div className=" widget--space">
                                                                    <Col md={12}>
                                                                        <div className="block-heading">Address
                                                                            Information
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12} lg={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="address" type="text"
                                                                                   id="address"
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   maxLength="255"
                                                                                   className={'form-control' + ((errors.address && touched.address) || serverValid.address != '' ?
                                                                                       ' is-invalid' : '')}
                                                                            />
                                                                            <label htmlFor="address"
                                                                                   className={'form-label ' + (address ?
                                                                                       ' label-active' : '')}>Mailing
                                                                                Address
                                                                                line
                                                                                1</label>
                                                                            {
                                                                                (errors.address && touched.address) ?
                                                                                    <ErrorMessage name="address"
                                                                                                  component="div"
                                                                                                  className="invalid-feedback"/>
                                                                                    :
                                                                                    <div
                                                                                        className="invalid-feedback">{serverValid.address}</div>
                                                                            }
                                                                        </div>
                                                                        <div className="field form-group">
                                                                            <Field name="address2" type="text"
                                                                                   id="address2"
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   maxLength="255"
                                                                                   className={'form-control' + ((errors.address2 && touched.address2) || serverValid.address2 != '' ?
                                                                                       ' is-invalid' : '')}
                                                                            />
                                                                            <label htmlFor="address2"
                                                                                   className={'form-label ' + (address2 ? ' label-active' : '')}>Mailing
                                                                                Address line 2</label>
                                                                            {
                                                                                (errors.address2 && touched.address2) ?
                                                                                    <ErrorMessage name="address2"
                                                                                                  component="div"
                                                                                                  className="invalid-feedback"/>
                                                                                    :
                                                                                    <div
                                                                                        className="invalid-feedback">{serverValid.address2}</div>
                                                                            }
                                                                        </div>
                                                                        <Row>
                                                                            <Col sm={12} md={6}>
                                                                                <div className="select-wrap">
                                                                                    <Field component="select"
                                                                                           className={'form-control' + ((errors.state && touched.state) || serverValid.state != '' ?
                                                                                               ' is-invalid' : '')}
                                                                                           name="state" id="state"
                                                                                           onChange={(event) => this.onSelectMailChange(event, setFieldValue)}>
                                                                                        <option value=''>-- Select State
                                                                                            --
                                                                                        </option>
                                                                                        {
                                                                                            Object.keys(UsStates).map((key) => {
                                                                                                return (
                                                                                                    <option key={key}
                                                                                                            value={UsStates[key]}>{UsStates[key]}</option>)
                                                                                            })
                                                                                        }
                                                                                    </Field>
                                                                                    {
                                                                                        (errors.state && touched.state) ?
                                                                                            <ErrorMessage name="state"
                                                                                                          component="div"
                                                                                                          className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.state}</div>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm={12} md={6} className="sm--mr-top">
                                                                                <div className="field form-group">
                                                                                    <Field name="city" type="text"
                                                                                           id="city"
                                                                                           onFocus={this.inputFocus}
                                                                                           onBlur={this.inputBlur}
                                                                                           maxLength="150"
                                                                                           className={'form-control' + ((errors.city && touched.city) || serverValid.city != '' ?
                                                                                               ' is-invalid' : '')}
                                                                                    />
                                                                                    <label htmlFor="city"
                                                                                           className={'form-label ' + (city ? ' label-active' : '')}>City</label>
                                                                                    {
                                                                                        (errors.city && touched.city) ?
                                                                                            <ErrorMessage name="city"
                                                                                                          component="div"
                                                                                                          className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.city}</div>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm={12} md={6}>
                                                                                <div className="field form-group">
                                                                                    <Field name="zip" type="text"
                                                                                           id="zip"
                                                                                           onFocus={this.inputFocus}
                                                                                           onBlur={this.inputBlur}
                                                                                           maxLength="5"

                                                                                           className={'form-control' +
                                                                                           ((errors.zip && touched.zip) || serverValid.zip !== '' ?
                                                                                               ' is-invalid' : '')}/>
                                                                                    <label htmlFor="zip"
                                                                                           className={'form-label ' + (zip ? ' label-active' : '')}>
                                                                                        Zip Code</label>
                                                                                    {
                                                                                        (errors.zip && touched.zip) ?
                                                                                            <ErrorMessage name="zip"
                                                                                                          component="div"
                                                                                                          className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.zip}</div>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="block-heading">Bank Information
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={12} lg={12}>
                                                                        <Row>
                                                                            <Col md={6} sm={12}>
                                                                                <div className="field form-group">
                                                                                    <Field name="bank_account_name"
                                                                                           type="text"
                                                                                           id="bank_account_name"
                                                                                           onFocus={this.inputFocus}
                                                                                           onBlur={this.inputBlur}
                                                                                           maxLength="17"
                                                                                           className={'form-control' +
                                                                                           ((errors.bank_account_name && touched.bank_account_name) || serverValid.bank_account_name !== '' ?
                                                                                               ' is-invalid' : '')}/>
                                                                                    <label htmlFor="bank_account_name"
                                                                                           className={'form-label ' + (bank_account_name ?
                                                                                               ' label-active' : '')}>Bank
                                                                                        Account
                                                                                        Name</label>

                                                                                    {
                                                                                        (errors.bank_account_name && touched.bank_account_name) ?
                                                                                            <ErrorMessage
                                                                                                name="bank_account_name"
                                                                                                component="div"
                                                                                                className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.bank_account_name}</div>
                                                                                    }

                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6} sm={12}>
                                                                                <div className="field form-group">
                                                                                    <Field name="bank_routing_number"
                                                                                           type="text"
                                                                                           id="bank_routing_number"
                                                                                           onFocus={this.inputFocus}
                                                                                           onBlur={this.inputBlur}
                                                                                           maxLength="9"
                                                                                           className={'form-control' +
                                                                                           ((errors.bank_routing_number && touched.bank_routing_number) || serverValid.bank_routing_number !== '' ?
                                                                                               ' is-invalid' : '')}/>
                                                                                    <label htmlFor="bank_routing_number"
                                                                                           className={'form-label ' + (bank_routing_number ?
                                                                                               ' label-active' : '')}>Bank
                                                                                        Routing
                                                                                        Number</label>

                                                                                    {
                                                                                        (errors.bank_routing_number && touched.bank_routing_number) ?
                                                                                            <ErrorMessage
                                                                                                name="bank_routing_number"
                                                                                                component="div"
                                                                                                className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.bank_routing_number}</div>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6} sm={12}>
                                                                                <div className="field form-group">
                                                                                    <Field name="bank_account_number"
                                                                                           type="text"
                                                                                           id="bank_account_number"
                                                                                           onFocus={this.inputFocus}
                                                                                           onBlur={this.inputBlur}
                                                                                           maxLength="12"
                                                                                           className={'form-control' +
                                                                                           ((errors.bank_account_number && touched.bank_account_number) || serverValid.bank_account_number !== '' ?
                                                                                               ' is-invalid' : '')}/>
                                                                                    <label htmlFor="bank_account_number"
                                                                                           className={'form-label ' + (bank_account_number ?
                                                                                               ' label-active' : '')}>Bank
                                                                                        Account
                                                                                        Number</label>

                                                                                    {
                                                                                        (errors.bank_account_number && touched.bank_account_number) ?
                                                                                            <ErrorMessage
                                                                                                name="bank_account_number"
                                                                                                component="div"
                                                                                                className="invalid-feedback"/>
                                                                                            :
                                                                                            <div
                                                                                                className="invalid-feedback">{serverValid.bank_account_number}</div>
                                                                                    }

                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6} sm={12}>
                                                                                <div className="d-flex help-icon-wrap">
                                                                                    <div className="field form-group">
                                                                                        <Field
                                                                                            name="confirm_bank_account_number"
                                                                                            type="text"
                                                                                            id="confirm_bank_account_number"
                                                                                            onFocus={this.inputFocus}
                                                                                            onBlur={this.inputBlur}
                                                                                            maxLength="12"
                                                                                            className={'form-control' +
                                                                                            ((errors.confirm_bank_account_number && touched.confirm_bank_account_number) ?
                                                                                                ' is-invalid' : '')}/>
                                                                                        <label
                                                                                            htmlFor="confirm_bank_account_number"
                                                                                            className={'form-label ' + (confirm_bank_account_number ?
                                                                                                ' label-active' : '')}>Confirm
                                                                                            Bank Account
                                                                                            Number</label>
                                                                                        {
                                                                                            (errors.confirm_bank_account_number && touched.confirm_bank_account_number) ?
                                                                                                <ErrorMessage
                                                                                                    name="confirm_bank_account_number"
                                                                                                    component="div"
                                                                                                    className="invalid-feedback"/>
                                                                                                :
                                                                                                <div
                                                                                                    className="invalid-feedback">{serverValid.confirm_bank_account_number}</div>
                                                                                        }


                                                                                    </div>
                                                                                    <OverlayTrigger overlay={
                                                                                        <Tooltip id={`tooltip`}
                                                                                                 className='tooltip-help'>
                                                                                            SafeComm will be depositing
                                                                                            commissions directly to your
                                                                                            bank
                                                                                            account, we need your
                                                                                            banking
                                                                                            information.</Tooltip>}>
                                                                                        <div
                                                                                            className="help-icon-wrap__icon">
                                                                                            <Image src={Images.helpIcon}
                                                                                                   alt="Help"
                                                                                                   width="25"/>
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6} sm={12}>

                                                                                <div className="field form-group">
                                                                                    <SSNInput
                                                                                        parent={this}
                                                                                        fieldLabel={'Social Security Number'}
                                                                                        SSNField={'social_security_number'}
                                                                                        errors={errors}
                                                                                        touched={touched}
                                                                                        serverValid={false}
                                                                                        setFieldValue={setFieldValue}
                                                                                        values={values}
                                                                                        guide={false}

                                                                                    />
                                                                                </div>


                                                                            </Col>
                                                                            <Col md={6} sm={12}>
                                                                                <div
                                                                                    className="d-flex w-100 help-icon-wrap">
                                                                                    <div className="field form-group">
                                                                                        <SSNInput
                                                                                            parent={this}
                                                                                            fieldLabel={'Confirm Social Security Number'}
                                                                                            SSNField={'confirm_social_security_number'}
                                                                                            errors={errors}
                                                                                            touched={touched}
                                                                                            serverValid={false}
                                                                                            setFieldValue={setFieldValue}
                                                                                            values={values}
                                                                                            guide={false}

                                                                                        />
                                                                                    </div>
                                                                                    <OverlayTrigger overlay={
                                                                                        <Tooltip id={`tooltip`}
                                                                                                 className='tooltip-help'>
                                                                                            The income generated from
                                                                                            your
                                                                                            comissions may be taxable
                                                                                            and
                                                                                            SafeComm will need your
                                                                                            Social
                                                                                            Security number to generate
                                                                                            the
                                                                                            appropriate tax form(s).
                                                                                        </Tooltip>}>
                                                                                        <div
                                                                                            className="help-icon-wrap__icon">
                                                                                            <Image src={Images.helpIcon}
                                                                                                   alt="Help"
                                                                                                   width="25"/>
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </div>
                                                                {
                                                                    <div className="widget__footer justify-content-end">
                                                                        <Button type="submit"
                                                                                className={`ripple btn-medium d-inline-flex align-items-center`}>
                                                                            Next
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </>
                                                        </Form>
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                    }
                </div>

                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"}/>
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => {
                        this.setState({showError: false})
                    }}/>
            </>
        )
    }
}

export {RegisterISR};
