/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Container, Image, Form } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import * as Images from '../../components/common/images';
import Loader from '../../components/loader/loader';
import {HeaderFullWidth} from '../../components/header/headerfullwidth';
import {Footer} from '../../components/footer/footer';

class VerifyClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.match.params.token,
            successMsg: "",
            errorMsg: "",
            loading: false
        };
    }

    componentDidMount() {
        this.validateInvite();
    }

    validateInvite() {
        const { token } = this.state;
        this.setState({ loading: true }
            , () => {
                Helper.axiosInstance().post(`club/verify`, {
                    verification_code: token
                })
                .then((response) => {
                    const resp = response.data;
                    if (resp.success) {
                        this.setState({ successMsg: resp.message });
                    } else {
                        this.setState({ errorMsg: resp.message })
                    }
                })
                .catch((error) => {
                    this.setState({ errorMsg: Messages.SERVER_ERROR });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
            }
        );
    }

    render() {
        const { errorMsg, loading, successMsg } = this.state;
        return (
            <>
                <HeaderFullWidth />
                <Container>
                    <Loader loading={loading} />
                    {
                        !loading ? 
                            <VerificationSuccess
                                message={successMsg}
                                errorMsg={errorMsg}
                            />
                        :
                            null
                    }
                    <Footer />
                </Container>
            </>
        )
    }
}

const VerificationSuccess = (props) => {
    return (
        <>
            <Row className="content-space">
                <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                    <div className="widget">
                        {
                            props.errorMsg !== "" ?
                                <div className="text-center py-5 px-3 confirmbox">
                                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                                    <h1 className="mt-3 mb-3">Error</h1>
                                    <p className="mb-4">{props.errorMsg}</p>
                                    <Form>
                                        <Link to="#" onClick={Helper.handleSupportContact} className="btn--green btn justify-content-center">Contact Us</Link>
                                    </Form>
                                </div>
                            :
                                props.message !== "" ?
                                    <div className="text-center success-msg success-msg--font-size mb-3">
                                        <Image src={Images.SuccessTick} alt="Success" />
                                        <p className="mb-4">{props.message}</p>
                                    </div>
                                :
                                    null
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
};

export { VerifyClub };

