/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Table, Media, Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */

import { ErrorModal } from '../../components/modal/modalbox';
import { NoRecord } from '../../components/no-record/no-record';
import * as Helper from '../../components/common/functions';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Messages } from '../../components/common/message';
import './invoice.scss';

class ClubInvoiceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordLoaded: false,
            modalVisibility: false,
            serverSuccess: "",
            showError: false,
            errorMessage: "",
            invoiceId: parseInt(props.match.params.invoice_id),
            clubName: '',
            invoiceData: [],
            page: 1,
            pageSize: 40,
            cancelToken: axios.CancelToken,
            cancel: undefined
        };
        /* Using Debounce to prevent repeated events
        Windows scroll event debounce */
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
    }

    componentDidMount() {
        if(this.state.invoiceId){
            window.addEventListener('scroll', this.doDebouncedTableScroll);
            window.scrollTo(0, 0);
            this.getInvoiceDetail();
        } else {
            this.props.history.push('/invoice');
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll)
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load      
        if (!this.state.recordLoaded || this.state.totalRecords === this.state.invoiceData.length || this.state.totalRecords < this.state.pageSize) return;
        // // Reduce 20percent from the offsetHeight
        let reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;
        // Check if scroll has hit bottom
        if (window.innerHeight + document.documentElement.scrollTop >= reduceOffset) {
            this.setState({ page: this.state.page + 1 });
            this.getInvoiceDetail();
        }
    }

    getInvoiceDetail = () => {
        this.setState({ recordLoaded: false });
        
        let cancel = this.state.cancel,
            cancelToken = this.state.cancelToken;

        const { invoiceId, page, pageSize} = this.state,
            me = this;
        if (this.state.cancel !== undefined) {
            cancel();
        }
        Helper.axiosInstance().post(`invoice/detail`, {
                invoice_id: invoiceId,
                page: page, //page number for pagination
                page_size: pageSize, //per page for pagination
        },{
            cancelToken: new cancelToken(function executor(c) {
                me.setState({ cancel: c });
            })
        })
        .then((response) => {
            if (response.data.success) {
                this.setState({
                    clubName: response.data.club.name,
                    totalRecords: response.data.count
                });
                if (page === 1) {
                    this.setState({
                        invoiceData: response.data.data
                    });
                } else {
                    let existingRecords = this.state.invoiceData;
                    this.setState({ invoiceData: existingRecords.concat(response.data.data) });
                }
                this.setState({ });
            } else {
                this.setState({ errorMessage: response.data.error, showError: true, });
            }
        })
        .catch((error) => {
            if (error.response.status === 404 && error.response.data !== undefined) {
                this.setState({ errorMessage: error.response.data.error, showError: true });
            } else {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true });
            }
        }).finally(() => {
            this.setState({ recordLoaded: true });
        });
    }

    render() {
        const { invoiceId, clubName, invoiceData, recordLoaded, showError, errorMessage } = this.state;
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm="4" className="heading--alignment">
                            <h1>Invoice Detail</h1>
                        </Col> 
                        <Col sm="8">
                            <div className="header-row">
                                {
                                    clubName ?
                                        <div className="header-row__block">
                                            <label>Organization</label>
                                            <div className="header-row__desc">{clubName}</div>
                                        </div>
                                    :
                                        null
                                }
                                {
                                    invoiceId ?
                                        <div className="header-row__block">
                                            <label>Invoice Number</label>
                                            <div className="header-row__desc">{invoiceId}</div>
                                        </div>
                                    :
                                        null
                                }
                            </div> 
                        </Col>                  
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">                             
                                <Table bordered hover className="tab-responsive table--invoice">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Start Date</th>  
                                            <th>End Date</th>  
                                            <th>Type</th>  
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                            !recordLoaded && <tr><td colSpan="4"><SmallLoader /></td></tr>
                                        }
                                        {
                                            (recordLoaded && invoiceData.length === 0) &&
                                            <tr className="mb--norecord">
                                                <td colSpan="4">
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                        }    
                                        {
                                            invoiceData && invoiceData.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td data-label="Organizations" className="td-content-space">
                                                            <Media>
                                                                <div className="im-rounded-outer">
                                                                    <Image className="mr-2" src={item.photo}
                                                                    alt={Helper.getMemberName({first_name:item.first_name,last_name: item.last_name})} />
                                                                </div>
                                                                <Media.Body className="align-self-center"><h5>{Helper.getMemberName({first_name:item.first_name,last_name: item.last_name})}</h5></Media.Body>
                                                            </Media>
                                                        </td>                     
                                                        <td data-label="Start Date">{item.start_date}</td>
                                                        <td data-label="Expiry Date">{item.end_date}</td>
                                                        <td data-label="Type">{item.type}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ErrorModal
                    show={showError}
                    message={errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
            </>
        )
    }
}


export { ClubInvoiceDetail };
