/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, Modal } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form as FormValid, ErrorMessage } from 'formik';
/* End - Formik */

import { ModalBox, SuccessModal, ErrorModal } from '../modal/modalbox';
import * as Storage from '../common/storage';
import * as Images from '../common/images';
import * as Helper from '../common/functions';
import { Messages } from '../common/message';

export default class CreateChatGroup extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            errorName: '',
            serverSuccess: '',
            serverError: '',
            formProcessing: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    createGroup = (fields, selectedMember) => {
        if (this._isMounted) {

            this.setState({ formProcessing: true });

            let memUsername = [], memId = [];
            const groupName = fields.groupName,
                athleteGuardians = Storage.Get('user').guardian,
                me = this;
            
            // Add legal guardians of the Logged-In Athlete
            if(athleteGuardians.length > 0){
                // eslint-disable-next-line
                for (let val of athleteGuardians) {
                    memUsername.push(val);
                }
            }
            // eslint-disable-next-line
            for (let val of selectedMember.values()) {
                memUsername.push( val.username );
                memId.push(val.userId);

                const guardians = val.guardians;

                if (guardians && guardians.length > 0) {
                    // eslint-disable-next-line
                    for (let key in guardians) {
                        memUsername = memUsername.concat( guardians[key].username );
                    }
                }
            }

            Helper.axiosInstance().post(`group/create`, {
                name: groupName,
                club_id: Storage.Get('user').selectedClubId,
                members: memId,
                member_username: memUsername,
            })
                .then((response) => {
                    // If server response message same as Data Matched
                    if (response.data.success) {
                        me.props.allProps.setChatWithGroupId(response.data.chat_group_id);
                        me.setState({ serverSuccess: response.data.message, formProcessing: false });
                    } else {
                        this.setState({ errorName: response.data.message, formProcessing: false });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                    me.setState({ serverError: error, formProcessing: false });
                });
        }
    }

    resetGroupModal = () => {
        this.setState({ serverSuccess: '', errorName: '', groupName: '', formProcessing: false });
        this.props.toggleGroupModal(!this.props.groupModal)
    }

    handleSuccessClose = () => {
        this.props.history.push('/groups');
    }

    handleErrorClose = () => {
        window.location.reload();
    }
    
    render() {
        const { selectedMember, groupModal } = this.props;
        const { groupName, errorName, formProcessing, serverSuccess, serverError } = this.state;

        const handleClose = () => this.resetGroupModal(),
            groupModalHeader = (
                <>
                    <Modal.Title>Create Chat Group</Modal.Title>
                </>
            ),
            groupModalContent = (
                <>
                    <Formik
                        ref={(ref) => this.formik = ref}
                        enableReinitialize
                        initialValues={{
                            groupName: groupName
                        }}
                        validationSchema={Yup.object().shape({
                            groupName: Yup.string().trim()
                                .required('Group Name is required')
                                .max(100, Messages.MAX_LENGTH_100)
                        })}
                        onSubmit={fields => {
                            this.createGroup(fields, selectedMember);
                        }}
                        render={({ errors, status, touched }) => (
                            <FormValid noValidate>
                                <Modal.Body className="modal-body--min-height">
                                    <div className="d-flex flex-row">
                                        <div className="ml-left">
                                            <Image src={Images.MemberGroupNew} width="100" alt="group" />
                                        </div>
                                        <div className="ml-right">
                                            <div className="field form-group">
                                                <Field name="groupName" type="text" id="groupName" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + (errors.groupName && touched.groupName ? ' is-invalid' : '')} />
                                                <label htmlFor="groupName" className="form-label">Group Name</label>
                                                <ErrorMessage name="groupName" component="div" className="invalid-feedback" />
                                                <div className="invalid-feedback">{errorName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="m-footer">
                                    <div className="m-footer__ml-members">
                                        <Row className="w-100">
                                            {
                                                (typeof selectedMember !== "undefined" && selectedMember.size > 0) ?
                                                    [...selectedMember.values()].map((member, i) => (
                                                        <Col sm="3" key={i}>
                                                            <div className="member-block text-center">
                                                                {member.avatar}
                                                                <div className="member-block__name">{member.name}</div>
                                                                <span className="delete-action" onClick={() => this.props.deleteSelectedMember(member.userId)}>
                                                                    <Image src={Images.MemberDelete} alt="delete" />
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Row>
                                    </div>
                                    <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                                        <Button disabled={formProcessing ? true : false} variant="secondary" onClick={handleClose}>
                                            Cancel
                                    </Button>
                                        <Button type="Submit" disabled={formProcessing ? true : false} className={"ripple btn btn-primary btn-medium d-inline-flex align-items-center " + (formProcessing ? "btn-loader" : "")}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.EditIcon} alt="edit group" /></div>
                                            Create a Chat Group <span style={{ 'display': 'none' }} className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </FormValid>
                        )}
                    />
                </>
            );

        return (
            <>
                {
                    /*
                    * Create Chat Group Modals
                    */
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={groupModal}
                            onConfirmClick={this.handleSuccessClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={groupModal}
                                onConfirmClick={this.handleErrorClose}
                            />
                            :
                            <ModalBox
                                size="lg"
                                show={groupModal}
                                onHide={handleClose}
                                animation={true}
                                modalheader={groupModalHeader}
                                modalcontent={groupModalContent}
                            />
                }
            </>
        )
    }
}
