/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col,Button } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import { StudentData } from './student-data';
import * as Storage from '../../components/common/storage';
import * as SessStorage from '../../components/common/sess-storage';
import './student-data.scss';
import * as Regex from "../../components/common/regex";

class VerifyPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formProcessing: false,
            formFields: {
                user_password: "",
            },
            serverValid: {
                user_password: "",
            },
            serverSuccess: "",
            serverError: ""
        };
    }

    componentDidMount(){
        const canReviewStudent = Storage.Get('user').can_review_kid;
        if(!canReviewStudent){
            this.props.history.push('/');
        }
    }

    handleFormSubmit = (fields) => {
        const me = this;
        this.setState({ formProcessing: true });
        // Api request to save student Info.
        Helper.axiosInstance().post('user/confirm-password', {
            password: fields.user_password
        })
        .then((response) => {
            // If server response message same as Data Matched
            if (response.data.success) {
                me.setState({ serverSuccess: response.data.message });
                SessStorage.Set('verifiedPassword', true);
            } else {
                // get the server form errors and set under state 'serverValid'
                const getErrors = response.data.error;
                
                let serverValid = me.state.serverValid;
                serverValid['user_password'] = getErrors.password[0];
                me.setState({ serverValid});
            }
        })
        .catch((error) => {
            me.setState({ serverError: error });
        })
        .finally(() => {
            this.setState({ formProcessing: false });
        });
    }
    render() {
        const { formFields, formProcessing, serverValid, serverSuccess, serverError } = this.state,
            verifiedPassword = SessStorage.Get('verifiedPassword');
        
        return (
            <>
                <div className="rgt-content">
                    {
                        !verifiedPassword ?
                            <Row className="row--space">    
                                <Col sm={10} md={10} lg={8} className="offset-lg-2 offset-md-1 offset-sm-1 mb-3">
                                    <h1 className="panel-heading">Password Authentication</h1>
                                </Col>           
                                <Col sm={10} md={10} lg={8} className="offset-lg-2 offset-md-1 offset-sm-1">
                                    <div className="panel-wrapper">
                                        <Row>
                                            <Col sm={12} md={10} lg={10} className="offset-lg-1 offset-md-1">
                                            <p className="mb-4">To access the Child Data Review page, please verify your identity by entering your password below. </p>
                                            <Formik
                                                enableReinitialize
                                                initialValues={formFields}
                                                validationSchema={Yup.object().shape({
                                                    user_password: Yup.string().trim()
                                                        .max(50, Messages.MAX_LENGTH_50)
                                                        .min(8, Messages.MIN_LENGTH_8)
                                                        .required(Messages.REQUIRED_ERR)
                                                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                                                    })}
                                                    onSubmit={fields => {
                                                        this.handleFormSubmit(fields);
                                                    }}
                                                    render={({ errors, status, touched }) => (
                                                            <Form noValidate>                                     <div className="field form-group">
                                                                    <Field name="user_password" type="password" id="user_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_password && touched.user_password) || serverValid.user_password !== '' ? ' is-invalid' : '')} autoComplete="new-password"/>
                                                                    <label htmlFor="user_password" className={Helper.getLabelClass(formFields.user_password)}>Password</label>
                                                                    {
                                                                        (errors.user_password && touched.user_password) ?
                                                                            <ErrorMessage name="user_password" component="div" className="invalid-feedback" />
                                                                        :
                                                                            <div className="invalid-feedback">{serverValid.user_password}</div>
                                                                    }
                                                                </div>
                                                                <Button type="Submit" className={"btn btn-primary w-100 " + (formProcessing ? "btn-loader" : "")}>
                                                                    Verify Password <span style={{'display': 'none'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                </Button>
                                                            </Form>
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <StudentData />
                    }
                </div>
                
                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={true}
                            onConfirmClick={() => this.setState({serverSuccess: ""})}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                    :
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={true}
                                onConfirmClick={() => this.setState({serverError: ""})}
                            />
                        :
                            null
                }
            </>    
        )
    }
}

export { VerifyPassword };