/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Tab, Tabs, Image, Button, Modal,Dropdown,DropdownButton, InputGroup } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form as FormValid, ErrorMessage } from 'formik';
/* End - Formik */

import {ModalBox, SuccessModal, ErrorModal, ConfirmModal} from '../../components/modal/modalbox';
import * as Storage from '../../components/common/storage';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { PhoneInput } from '../../components/input/phone';
import {Messages} from "../../components/common/message";
import * as Regex from "../../components/common/regex";

export default class SingleInvite extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            guardian_first_name: '',
            guardian_last_name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_phone_code: '+1',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            user_phone: '',
            user_phone_code: '+1',
            serverValid: {
                guardian_first_name: '',
                guardian_last_name: '',
                guardian_email: '',
                guardian_phone: '',
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_phone: '',
            },
            serverSuccess: '',
            serverError: '',
            formProcessing: false,
            confirmShow: false,
            loader: false,
            teamType: props.teamType,
            fields: {},
            defaultTab: props.defaultTab,
            teamId: props.teamId ? props.teamId : null,
            lgLimitError: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sendInvite = (fields, type) => {
        if (this._isMounted) {
            const me = this;
            this.setState({ formProcessing: true });

            let api_url = `invite/coach`,
                api_params = {
                    club_id: Storage.Get('user').selectedClubId,
                    user_first_name: fields.user_first_name,
                    user_last_name: fields.user_last_name,
                    user_email: fields.user_email,
                    user_phone: fields.user_phone,
                    user_phone_code: fields.user_phone_code,
                    team_id: this.state.teamId,
                };
                
            if (type === "LG") {
                api_url = `invite/kid-guardian`;
                api_params['guardian_first_name'] = fields.guardian_first_name;
                api_params['guardian_last_name'] = fields.guardian_last_name; 
                api_params['guardian_email'] = fields.guardian_email;
                api_params['guardian_phone'] = fields.guardian_phone;
                api_params['guardian_phone_code'] = fields.guardian_phone_code;
                api_params['team_id'] = this.state.teamId;
                api_params['type'] = this.state.teamType;
            }
            if(type != "LG" && typeof this.props.type != 'undefined' && typeof this.props.record_id != 'undefined'){
                if(this.props.type == 'team'){
                    api_params['team_id'] = this.props.record_id;
                }
            }
            
            Helper.axiosInstance().post(api_url, api_params)
                .then((response) => {
                    // If server response message same as Data Matched
                    if (response.data.success) {
                        me.setState({ serverSuccess: response.data.message, formProcessing: false });
                        this.props.getPendingContacts && this.props.getPendingContacts();
                    } else {
                        if(type === "LG" && typeof response.data.errorType != 'undefined' && response.data.errorType == 'lgLimit'){
                            me.setState({ lgLimitError: response.data.error, formProcessing: false });
                        } else {
                            // get the server form errors and set under state 'serverValid'
                            let getErrors = response.data.error;
                            if(Array.isArray(getErrors) || typeof getErrors === 'object'){
                                me.resetServerError();

                                let serverValid = me.state.serverValid;
                                // eslint-disable-next-line
                                for (let errorIndex in getErrors) {
                                    if (getErrors[errorIndex][0] !== "") {
                                        serverValid[errorIndex] = getErrors[errorIndex][0];
                                    }
                                }
                                this.setState({ serverValid, formProcessing: false });
                            }else{
                                let confirmShow = false;
                                if(response.data.max_limit_reached){
                                    confirmShow = true;
                                    getErrors =  Messages.MEMBER_LIMIT_CROSSED_MANUAL.replaceAll('##MAX_LIMIT##', response.data.max_limit);
                                }
                                this.setState({ errorMessage: getErrors, confirmShow: confirmShow, formProcessing: false });
                            }
                        }
                    }
                })
                .catch((error) => {
                    me.setState({ serverError: error, formProcessing: false });
                });
        }
    }

    resetServerError() {
        this.setState({
            serverValid : {
                guardian_first_name: '',
                guardian_last_name: '',
                guardian_email: '',
                guardian_phone: '',
                guardian_phone_code: '',
                user_first_name: '',
                user_last_name: '',
                user_email: '',
                user_phone: '',
                user_phone_code: '',
            }
        });
    }

    resetGroupModal = () => {
        this.resetServerError();
        this.setState({ lgLimitError: '', serverSuccess: '', formProcessing: false });
        this.props.toggleModal(!this.props.modalVisibility)
    }

    handleErrorClose = () => {
        window.location.reload();
    }

    render() {
        const { modalVisibility } = this.props,
            { guardian_first_name, guardian_last_name, guardian_email, guardian_phone, guardian_phone_code,
                user_first_name, user_last_name, user_email, user_phone, user_phone_code, serverValid, formProcessing, serverSuccess, serverError, lgLimitError } = this.state;

        const handleClose = () => this.resetGroupModal(),
            modalHeader = (
                <>
                    <Modal.Title>Invite New Contact</Modal.Title>
                </>
            ),
            modalContent = (
                <>
                    <Tabs defaultActiveKey={this.state.defaultTab}>
                        <Tab eventKey="legalguardian" title="Invite Legal Guardian">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    guardian_first_name: guardian_first_name,
                                    guardian_last_name: guardian_last_name,
                                    guardian_email: guardian_email,
                                    guardian_phone: guardian_phone,
                                    guardian_phone_code: guardian_phone_code,
                                    user_first_name: user_first_name,
                                    user_last_name: user_last_name,
                                    user_email: user_email
                                }}
                                validationSchema={Yup.object().shape({
                                    guardian_first_name: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .max(100, Messages.MAX_LENGTH_100),
                                    guardian_last_name: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .max(100, Messages.MAX_LENGTH_100),
                                    guardian_email: Yup.string().trim()
                                        .max(150, Messages.MAX_LENGTH_150)
                                        .email(Messages.INVALID_EMAIL)
                                        .required(Messages.REQUIRED_ERR),
                                    guardian_phone: Yup.string().trim()
                                        .matches(Regex.phone, {
                                            message: Messages.INVALID_PHONE,
                                            excludeEmptyString: false
                                        })
                                        .max(15, Messages.INVALID_PHONE)
                                        .required(Messages.REQUIRED_ERR),
                                    guardian_phone_code: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR),
                                    user_first_name: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .max(100, Messages.MAX_LENGTH_100),
                                    user_last_name: Yup.string().trim()
                                        .required(Messages.REQUIRED_ERR)
                                        .max(100, Messages.MAX_LENGTH_100),
                                    user_email: Yup.string().trim()
                                        .max(150, Messages.MAX_LENGTH_150)
                                        .email(Messages.INVALID_EMAIL)
                                })}
                                onSubmit={fields => {
                                    this.setState({fields: fields});
                                    this.sendInvite(fields, 'LG');
                                }}
                                render={({ errors, status, touched, setFieldValue, values }) => (
                                    <FormValid noValidate>
                                        <Modal.Body>
                                            <div className="d-flex flex-row">
                                                <div className="ml-left">
                                                    <Image src={Images.inviteContact} width="100" alt="group" />
                                                </div>
                                                <div className="ml-right">
                                                    <div className="field form-group">
                                                        <Field name="guardian_first_name" type="text" id="guardian_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.guardian_first_name && touched.guardian_first_name) || serverValid.guardian_first_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="guardian_first_name" className={Helper.getLabelClass(values.guardian_first_name)}>Guardian First Name</label>
                                                        {
                                                            (errors.guardian_first_name && touched.guardian_first_name) ?
                                                                <ErrorMessage name="guardian_first_name" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.guardian_first_name}</div>
                                                        }
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="guardian_last_name" type="text" id="guardian_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.guardian_last_name && touched.guardian_last_name) || serverValid.guardian_last_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="guardian_last_name" className={Helper.getLabelClass(values.guardian_last_name)}>Guardian Last Name</label>
                                                        {
                                                            (errors.guardian_last_name && touched.guardian_last_name) ?
                                                                <ErrorMessage name="guardian_last_name" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.guardian_last_name}</div>
                                                        }
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="guardian_email" type="text" id="guardian_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="150"
                                                               className={'form-control' + ((errors.guardian_email && touched.guardian_email) || serverValid.guardian_email !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="guardian_email" className={Helper.getLabelClass(values.guardian_email)}>Guardian Email Address</label>
                                                        {
                                                            (errors.guardian_email && touched.guardian_email) ?
                                                                <ErrorMessage name="guardian_email" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.guardian_email}</div>
                                                        }
                                                    </div>

                                                    <div className="field form-group">
                                                        <PhoneInput
                                                            parent={this}
                                                            codeField={'guardian_phone_code'}
                                                            defaultCode={'+1'}
                                                            phoneField={'guardian_phone'}
                                                            fieldLabel={'Guardian Phone Number'}
                                                            errors={errors}
                                                            touched={touched}
                                                            serverValid={serverValid}
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                        />
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="user_first_name" type="text" id="user_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_first_name && touched.user_first_name) || serverValid.user_first_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="user_first_name" className={Helper.getLabelClass(values.user_first_name)}>Student First Name</label>
                                                        {
                                                            (errors.user_first_name && touched.user_first_name) ?
                                                                <ErrorMessage name="user_first_name" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.user_first_name}</div>
                                                        }
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="user_last_name" type="text" id="user_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_last_name && touched.user_last_name) || serverValid.user_last_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="user_last_name" className={Helper.getLabelClass(values.user_last_name)}>Student Last Name</label>
                                                        {
                                                            (errors.user_last_name && touched.user_last_name) ?
                                                                <ErrorMessage name="user_last_name" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.user_last_name}</div>
                                                        }
                                                    </div>

                                                    <div className="field form-group">
                                                        <Field name="user_email" type="text" id="user_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="150" className={'form-control' + ((errors.user_email && touched.user_email) || serverValid.user_email !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="user_email" className={Helper.getLabelClass(values.user_email)}>Student Email Address (Optional)</label>
                                                        {
                                                            (errors.user_email && touched.user_email) ?
                                                                <ErrorMessage name="user_email" component="div" className="invalid-feedback" />
                                                            :    
                                                                <div className="invalid-feedback">{serverValid.user_email}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className="m-footer">
                                            <div className="m-footer__btnwrap">
                                                <Button variant="secondary" className="ripple" disabled={formProcessing ? true : false} onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                                <Button type="Submit" disabled={formProcessing ? true : false} className={"btn-primary btn-medium ripple ml-auto align-self-center btn__sendic" + (formProcessing ? " btn-loader" : "")}>
                                                    <div className="btn__icon btn__icon--red"><Image src={Images.SendIc} alt="Send Invite" /></div>
                                                    <span className="align-self-center">Send Invite </span>
                                                    <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </Button>
                                            </div>
                                        </Modal.Footer>
                                    </FormValid>
                                )}
                            />
                            <ConfirmModal
                                show={this.state.confirmShow}
                                onCancelClick={()=>this.setState({confirmShow: false})}
                                onConfirmClick={()=>{
                                    this.setState(
                                        {teamType: 'A', loader: true},
                                        ()=>this.sendInvite(this.state.fields, 'LG')
                                    )
                                }}
                                buttonText="Continue"
                                title="Group Limit Exceeded"
                                body={this.state.errorMessage}
                                modalLoader={this.state.loader}
                            />
                        </Tab>
                        {
                            Helper.isAdminOrSubAdmin() ?
                                <Tab eventKey="invitecoach" title="Invite Instructor">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            user_first_name: user_first_name,
                                            user_last_name: user_last_name,
                                            user_email: user_email,
                                            user_phone: user_phone,
                                            user_phone_code: user_phone_code,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            user_first_name: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR)
                                                .max(100, Messages.MAX_LENGTH_100),
                                            user_last_name: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR)
                                                .max(100, Messages.MAX_LENGTH_100),
                                            user_email: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .email(Messages.INVALID_EMAIL)
                                                .required(Messages.REQUIRED_ERR),
                                            user_phone: Yup.string().trim()
                                                .matches(Regex.phone, {
                                                    message: Messages.INVALID_PHONE,
                                                    excludeEmptyString: false
                                                })
                                                .max(15, Messages.INVALID_PHONE)
                                                .required(Messages.REQUIRED_ERR),
                                            user_phone_code: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR),
                                        })}
                                        onSubmit={fields => {
                                            this.sendInvite(fields, 'Coach');
                                        }}
                                        render={({ errors, status, touched, setFieldValue, values }) => (
                                            <FormValid noValidate>
                                                <Modal.Body>
                                                    <div className="d-flex flex-row">
                                                        <div className="ml-left">
                                                            <Image src={Images.inviteInstructor} width="100" alt="group" />
                                                        </div>
                                                        <div className="ml-right">
                                                            <div className="field form-group">
                                                                <Field name="user_first_name" type="text" id="coach_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_first_name && touched.user_first_name) || serverValid.user_first_name !== '' ? ' is-invalid' : '')} />

                                                                <label htmlFor="coach_first_name" className={Helper.getLabelClass(values.user_first_name)}>Instructor First Name</label>
                                                                {
                                                                    (errors.user_first_name && touched.user_first_name) ?
                                                                        <ErrorMessage name="user_first_name" component="div" className="invalid-feedback" />
                                                                    :    
                                                                        <div className="invalid-feedback">{serverValid.user_first_name}</div>
                                                                }
                                                            </div>

                                                            <div className="field form-group">
                                                                <Field name="user_last_name" type="text" id="coach_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_last_name && touched.user_last_name) || serverValid.user_last_name !== '' ? ' is-invalid' : '')} />

                                                                <label htmlFor="coach_last_name" className={Helper.getLabelClass(values.user_last_name)}>Instructor Last Name</label>
                                                                {
                                                                    (errors.user_last_name && touched.user_last_name) ?
                                                                        <ErrorMessage name="user_last_name" component="div" className="invalid-feedback" />
                                                                    :    
                                                                        <div className="invalid-feedback">{serverValid.user_last_name}</div>
                                                                }
                                                            </div>

                                                            <div className="field form-group">
                                                                <Field name="user_email" type="text" id="coach_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="100" className={'form-control' + ((errors.user_email && touched.user_email) || serverValid.user_email !== '' ? ' is-invalid' : '')} />

                                                                <label htmlFor="coach_email" className={Helper.getLabelClass(values.user_email)}>Instructor Email Address</label>
                                                                {
                                                                    (errors.user_email && touched.user_email) ?
                                                                        <ErrorMessage name="user_email" component="div" className="invalid-feedback" />
                                                                    :    
                                                                        <div className="invalid-feedback">{serverValid.user_email}</div>
                                                                }
                                                            </div>
                                                            <div className="field form-group">
                                                                <PhoneInput
                                                                    parent={this}
                                                                    codeField={'user_phone_code'}
                                                                    defaultCode={'+1'}
                                                                    phoneField={'user_phone'}
                                                                    fieldLabel={'Instructor Phone Number'}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    serverValid={serverValid}
                                                                    setFieldValue={setFieldValue}
                                                                    values={values}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className="m-footer">
                                                    <div className="m-footer__btnwrap">
                                                        <Button variant="secondary" className="ripple" disabled={formProcessing ? true : false} onClick={handleClose}>
                                                            Cancel
                                                        </Button>

                                                        <Button type="Submit" disabled={formProcessing ? true : false} className={"btn-primary btn-medium ripple ml-auto align-self-center btn__sendic" + (formProcessing ? " btn-loader" : "")}>
                                                            <div className="btn__icon btn__icon--red"><Image src={Images.SendIc} alt="Send Invite" /></div>
                                                            <span className="align-self-center">Send Invite </span>
                                                            <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </Button>
                                                    </div>
                                                </Modal.Footer>
                                            </FormValid>
                                        )}
                                    />
                                </Tab>
                                :
                                null
                        }
                    </Tabs>
                </>
            );
        let errorMsg = '';
        if(serverError !== ""){
            errorMsg = serverError;
        } else if(lgLimitError !== ""){
            errorMsg = lgLimitError;
        }
        return (
            <>
                {
                    /*
                    * Single Invite Modals
                    */
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        errorMsg !== "" ?
                            <ErrorModal
                                message={errorMsg}
                                show={modalVisibility}
                                onConfirmClick={this.handleErrorClose}
                            />
                            :
                            <ModalBox
                                size="lg"
                                show={modalVisibility}
                                onHide={handleClose}
                                animation={true}
                                modalheader={modalHeader}
                                modalcontent={modalContent}
                            />
                }
            </>
        )
    }
}