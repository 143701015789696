/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, OverlayTrigger,Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import { SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import { Messages } from '../../components/common/message';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import * as Storage from '../../components/common/storage';
import * as Helper from '../../components/common/functions';
import { UsStates } from '../../components/common/us-states';
import './../club/club.scss';
import {PhoneInput} from "../../components/input/phone";
import {SSNInput} from "../../components/input/ssn";
import * as Regex from "../../components/common/regex";

class EditISR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            mailingAddress: '',
            mailingAddress2: '',
            mailingCity: '',
            mailingState: '',
            mailZip: '',
            phoneCode: '',
            phone: '',
            SSN: '',
            bankAccountName: '',
            bankAccountNumber: '',
            socialSecurityNumber: '',
            bankRoutingNumber: '',
            commissionAmountNew: '',
            commissionAmountReNew: '',
            dataLoading: true,
            disabledState: true,
            isSuperAdmin: false,
            updateISRLoader:false,
            file: null,
            disabled: true,

        };
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    componentDidMount = () => {
        let userInfo = Storage.Get('user');
        const isSuperAdmin = userInfo && userInfo.is_super_admin,
              ISRId = isSuperAdmin ? this.props.match.params.user_id : null;
        this.setState({ ISRId, isSuperAdmin: isSuperAdmin }, () => {
            this.getISRData();
        })
    }

    getISRData = () => {
        Helper.axiosInstance().get(`isr/details/${this.state.ISRId}`)
            .then((response) => {
                if (response.data.success) {
                    let values = response.data.data;
                    console.log(values)
                    this.setState({
                        firstName: values.first_name,
                        lastName: values.last_name,
                        email: values.email,
                        username: values.username,
                        file:  values.photo,
                        mailingAddress: values.address,
                        mailingAddress2: values.address2,
                        mailingCity: values.city,
                        mailingState: values.state,
                        mailZip: values.zip,
                        phoneCode: values.phone_code,
                        phone: values.phone,
                        bankAccountName: values.bank_account_name,
                        bankAccountNumber: values.bank_account_number,
                        socialSecurityNumber: values.ssn,
                        bankRoutingNumber: values.bank_routing_number,
                        commissionAmountNew: values.new_commission_per,
                        commissionAmountReNew: values.renew_commission_per,
                        dataLoading:false
                    })
                }
                else {
                    this.setState({ errorMessage: response.data.error, showError: true, recordLoaded: true });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
            });
    }

    onSelectMailChange = (event, setFieldValue) => {
        setFieldValue("mailingState", event.target.value);
    }

    toggleEdit = () => {
        this.setState({
            disabled: !this.state.disabled,
        })
    }
    refreshPage = () => {
        window.location.reload();
    }

    cancelClick = (event, setFieldValue) => {
        this.toggleEdit(false);
        setFieldValue("mailingAddress", this.state.mailingAddress);
        setFieldValue("mailingAddress2", this.state.mailingAddress2);
        setFieldValue("mailingCity", this.state.mailingCity);
        setFieldValue("mailingState", this.state.mailingState);
        setFieldValue("mailingZipCode", this.state.mailZip);
        setFieldValue("bankAccountName", this.state.bankAccountName);
        setFieldValue("bankAccountNumber", this.state.bankAccountNumber);
        setFieldValue("socialSecurityNumber", this.state.socialSecurityNumber);
        setFieldValue("bankRoutingNumber", this.state.bankRoutingNumber);
        setFieldValue("commissionAmountNew", this.state.commissionAmountNew);
        setFieldValue("commissionAmountReNew", this.state.commissionAmountReNew);
        setFieldValue("phone", this.state.phone);

    }
    updateISRDetails = (fields) => {
        this.setState({
            updateISRLoader: true,
        })
        let data = {
            id: this.state.ISRId,
            address: fields.mailingAddress.trim(),
            address2: fields.mailingAddress2 ? fields.mailingAddress2.trim() : '',
            bank_account_name: fields.bankAccountName.trim(),
            bank_account_number: fields.bankAccountNumber.trim(),
            ssn: fields.socialSecurityNumber,
            bank_routing_number: fields.bankRoutingNumber.trim(),
            city: fields.mailingCity.trim(),
            state: fields.mailingState,
            zip: fields.mailingZipCode,
            new_commission_per: fields.commissionAmountNew,
            renew_commission_per: fields.commissionAmountReNew
        }
        Helper.axiosInstance().put('isr/update-details', data)
            .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                    this.setState({
                        successConfirmShow: true,
                        updateISRLoader: false,
                        successText: "MA Details updated successfully",
                    });
                    this.toggleEdit();
                }
                else {
                 const getErrors = response.data.error;
                    let errorMsg = [];
                    Object.keys(getErrors).forEach((key,value) =>{
                        errorMsg.push(getErrors[key]);
                    });
                    this.setState({ showError: true, errorMessage: errorMsg.join(' '), updateISRLoader: false,});

                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, updateISRLoader: false, });
            });
    }

    render() {

        const { firstName, lastName, email, username, file, mailingAddress, mailingAddress2,  mailingCity,
            mailingState, mailZip, phoneCode, bankAccountName, bankAccountNumber,socialSecurityNumber, bankRoutingNumber, commissionAmountNew, commissionAmountReNew,
            dataLoading, phone } = this.state;
         return (
            <>
            {
                !dataLoading &&
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12}>
                            <h1>MA Settings</h1>
                        </Col>
                        <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                            <div className="info-box info-box--profile mt-4">
                                <div className="info-box__header info-box__header--org">
                                        <Image src={file} />
                                    </div>
                                <div className="info-box__content" style={this.state.editNameStyle}>
                                    <h4 className="isr__per-info">
                                        <span>{firstName} {lastName}
                                            <span className="info-box--profile-username">@{username}</span>
                                            <span className="info-box--profile-username info-box--isr-email">Email: {email}</span>
                                            <span className="info-box--profile-username">Tel: {phone ? phoneCode+ ' '+ phone:''}</span>
                                        </span>
                                    </h4>

                                </div>
                            </div>

                        </Col>
                        <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">
                            <div className="block-pos block-pos--single-elem" style={this.state.updateClub}>
                                {this.state.disabled && <OverlayTrigger placement="auto"
                                                             overlay={
                                                                 <Tooltip id={`tooltip`}>
                                                                     Edit ISR
                                                                 </Tooltip>}>
                                    <a href={null} onClick={this.toggleEdit}>
                                        <div className="outline-circle">
                                            <Image src={Images.EditIcRed} alt="Edit" width="25"/>
                                        </div>
                                    </a>
                                </OverlayTrigger>
                                }
                            </div>
                            <div className="widget">
                                <Formik
                                    ref={(ref) => this.formik = ref}
                                    enableReinitialize
                                    initialValues={{
                                        mailingAddress: mailingAddress,
                                        mailingAddress2: mailingAddress2,
                                        mailingCity: mailingCity,
                                        mailingState: mailingState,
                                        mailingZipCode: mailZip,
                                        commissionAmountNew: commissionAmountNew,
                                        commissionAmountReNew:commissionAmountReNew,
                                        bankAccountName: bankAccountName,
                                        bankAccountNumber: bankAccountNumber,
                                        socialSecurityNumber: socialSecurityNumber,
                                        bankRoutingNumber: bankRoutingNumber,
                                        phoneCode: phoneCode,
                                        phone: phone,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        mailingAddress: Yup.string().trim()
                                            .max(255, Messages.MAX_LENGTH_255)
                                            .required('Please enter mailing address'),
                                        mailingAddress2: Yup.string().trim()
                                            .max(190, Messages.MAX_LENGTH_255).nullable(),
                                        bankRoutingNumber: Yup.string().trim()
                                            .max(9, Messages.MAX_LENGTH_9)
                                            .required('Bank Routing Number is required'),
                                        bankAccountName: Yup.string().trim()
                                            .max(150, Messages.MAX_LENGTH_150)
                                            .required('Bank Account Name is required').nullable(),
                                        bankAccountNumber: Yup.string().trim()
                                            .max(17, Messages.MAX_LENGTH_17)
                                            .required('Bank Account Number is required'),
                                        socialSecurityNumber:Yup.string().trim()
                                            .matches(Regex.ssn, { message: Messages.INVALID_SSN, excludeEmptyString: false })
                                            .required('Social Security Number is required'),
                                        mailingCity: Yup.string().trim()
                                            .max(150, Messages.MAX_LENGTH_150)
                                            .required('Please enter mailing address city'),
                                        mailingState: Yup.string().trim()
                                            .max(150, Messages.MAX_LENGTH_150)
                                            .required('Please select mailing address state.'),
                                        mailingZipCode: Yup.number()
                                            .typeError(Messages.INVALID_ZIP_TYPE)
                                            .min(10000, Messages.INVALID_ZIP_LENGTH)
                                            .max(99999, Messages.INVALID_ZIP_LENGTH)
                                            .required('Please enter mailing address zip code.'),
                                        commissionAmountNew: Yup.number()
                                            .min(0, 'Commission per user should not be less than 0')
                                            .max(100, 'Commission per user should not be greater than 100')
                                            .typeError('Commission per user must be a number')
                                            .required('Commission per user is required'),
                                        commissionAmountReNew: Yup.number()
                                            .min(0, 'Commission per user should not be less than 0')
                                            .max(100, 'Commission per user should not be greater than 100')
                                            .typeError('Commission per user must be a number')
                                            .required('Commission per user is required'),
                                    })}
                                    onSubmit={fields => {
                                       this.updateISRDetails(fields);
                                    }}
                                    render={({errors, status, touched, setFieldValue, setFieldTouched, values}) => (
                                        <Form className="contact-us" noValidate>
                                            <>
                                                <div className=" widget--space">
                                                <Col md={12}>
                                                    <div className="block-heading">Address Information
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={12}>
                                                    <div className="field form-group">
                                                        <Field name="mailingAddress" type="text"
                                                               id="mailingAddress"
                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                               onFocus={this.inputFocus} maxLength="250"
                                                               className={'form-control' + (errors.mailingAddress && touched.mailingAddress ?
                                                                   ' is-invalid' : '')}/>
                                                        <label htmlFor="mailingAddress"
                                                               className={'form-label ' + (mailingAddress ? ' label-active' : '')}>Mailing
                                                            Address line 1</label>
                                                        <ErrorMessage name="mailingAddress" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="mailingAddress2" type="text"
                                                               id="mailingAddress2"
                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                               onFocus={this.inputFocus} maxLength="250"
                                                               className={'form-control' + (errors.mailingAddress2 && touched.mailingAddress2 ?
                                                                   ' is-invalid' : '')}/>
                                                        <label htmlFor="mailingAddress2"
                                                               className={'form-label ' + (mailingAddress2 ? ' label-active' : '')}>Mailing
                                                            Address line 2</label>
                                                        <ErrorMessage name="mailingAddress" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>

                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <div className="select-wrap">
                                                            <Field component="select"
                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                   className={' form-control' + (errors.mailingState && touched.mailingState ?
                                                                       ' is-invalid' : '')}
                                                                   name="mailingState" id="mailingState"

                                                                   onChange={(event) => this.onSelectMailChange(event, setFieldValue)}>
                                                                <option value=''>-- Select State --</option>
                                                                {
                                                                    Object.keys(UsStates).map((key) => {
                                                                        return (
                                                                            <option key={key}
                                                                                    value={UsStates[key]}>{UsStates[key]}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Field>
                                                            <ErrorMessage name="mailingState"
                                                                          component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={6} className="sm--mr-top">
                                                        <div className="field form-group">
                                                            <Field name="mailingCity" type="text"
                                                                   id="mailingCity"
                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                   onFocus={this.inputFocus}
                                                                   maxLength="150"
                                                                   className={'form-control' + (errors.mailingCity && touched.mailingCity ?
                                                                       ' is-invalid' : '')}/>
                                                            <label htmlFor="mailingCity"
                                                                   className={'form-label ' + (mailingCity ? ' label-active' : '')}>City</label>
                                                            <ErrorMessage name="mailingCity" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="field form-group">
                                                    <Field name="mailingZipCode" type="text"
                                                           id="mailingZipCode"
                                                           disabled={(this.state.disabled) ? "disabled" : ""}
                                                           onFocus={this.inputFocus}  maxLength="5"
                                                           className={'form-control' + (errors.mailingZipCode && touched.mailingZipCode ?
                                                               ' is-invalid' : '')}/>
                                                    <label htmlFor="mailingZipCode"
                                                           className="form-label label-active">Zip
                                                        Code</label>
                                                    <ErrorMessage name="mailingZipCode" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                                </Col>

                                                <Col md={12}>
                                                    <div className="block-heading">Bank Information
                                                    </div>
                                                </Col>


                                                <Col md={12} lg={12}>
                                                    <Row>
                                                        <Col md={6} sm={12}>
                                                            <div className="field form-group">
                                                                <Field name="bankAccountName" type="text"
                                                                       id="bankAccountName"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus}
                                                                       maxLength="150"
                                                                       className={'form-control' +
                                                                       (errors.bankAccountName && touched.bankAccountName ?
                                                                           ' is-invalid' : '')}/>
                                                                <label htmlFor="bankAccountName"
                                                                       className={'form-label ' + (bankAccountName ?
                                                                           ' label-active' : '')}>Bank Account Name</label>
                                                                <ErrorMessage name="bankAccountName"
                                                                              component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} sm={12}>
                                                            <div className="field form-group">
                                                                    <Field name="bankAccountNumber" type="text"
                                                                           id="bankAccountNumber"
                                                                           disabled={(this.state.disabled) ? "disabled" : ""}
                                                                           onFocus={this.inputFocus}
                                                                           maxLength="50"
                                                                           className={'form-control' +
                                                                           (errors.bankAccountNumber && touched.bankAccountNumber ?
                                                                               ' is-invalid' : '')}/>
                                                                    <label htmlFor="bankAccountNumber"
                                                                           className={'form-label ' + (bankAccountNumber ?
                                                                               ' label-active' : '')}>Bank Account Number</label>
                                                                    <ErrorMessage name="bankAccountNumber"
                                                                                  component="div"
                                                                                  className="invalid-feedback"/>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} sm={12}>
                                                            <div className="field form-group">
                                                                <Field name="bankRoutingNumber" type="text"
                                                                       id="bankRoutingNumber"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus}
                                                                       maxLength="50"
                                                                       className={'form-control' +
                                                                       (errors.bankRoutingNumber && touched.bankRoutingNumber ?
                                                                           ' is-invalid' : '')}/>
                                                                <label htmlFor="bankRoutingNumber"
                                                                       className={'form-label ' + (bankRoutingNumber ?
                                                                           ' label-active' : '')}>Bank Routing Number</label>
                                                                <ErrorMessage name="bankRoutingNumber"
                                                                              component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} sm={12}>
                                                                <div className="field form-group">
                                                                    <SSNInput
                                                                        parent={this}
                                                                        fieldLabel={'Social Security Number'}
                                                                        SSNField={'socialSecurityNumber'}
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        serverValid={false}
                                                                        setFieldValue={setFieldValue}
                                                                        values={values}
                                                                        guide={true}
                                                                        disabled={(this.state.disabled) ? "disabled" : ""}

                                                                    />
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                </Col>
                                                    <Col md={12}>
                                                        <div className="block-heading">Commission Information
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12}>
                                                        <Row>
                                                            <Col md={6} sm={12}>
                                                                <div className="field form-group">
                                                                    <Field name="commissionAmountNew" maxLength="5" type="text"
                                                                           id="commissionAmountNew"
                                                                           disabled={(this.state.disabled) ? "disabled" : ""}
                                                                           onFocus={this.inputFocus}  maxLength="3"
                                                                           className={'form-control' + (errors.commissionAmountNew && touched.commissionAmountNew ?
                                                                               ' is-invalid' : '')}/>
                                                                    <label htmlFor="new_commission_per" className={'form-label ' + (commissionAmountNew ?
                                                                        ' label-active' : ' label-active')}>First Year Commission(%)</label>
                                                                    <ErrorMessage name="commissionAmountNew" component="div"
                                                                                  className="invalid-feedback"/>
                                                                </div>
                                                            </Col>
                                                            <Col  md={6} sm={12}>
                                                                <div className="field form-group">
                                                                    <Field name="commissionAmountReNew" maxLength="5" type="text"
                                                                           id="commissionAmountReNew"
                                                                           disabled={(this.state.disabled) ? "disabled" : ""}
                                                                           onFocus={this.inputFocus}  maxLength="3"
                                                                           className={'form-control' + (errors.commissionAmountReNew && touched.commissionAmountReNew ?
                                                                               ' is-invalid' : '')}/>
                                                                    <label htmlFor="renew_commission_per" className={'form-label ' + (commissionAmountReNew ?
                                                                        ' label-active' : ' label-active')}>Renewals Commission(%)</label>
                                                                    <ErrorMessage name="commissionAmountReNew" component="div"
                                                                                  className="invalid-feedback"/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                            </div>
                                                {!this.state.disabled && <div style={this.state.editClub}
                                                                             className="widget__footer justify-content-between">
                                                    <Button disabled={this.state.updateISRLoader}
                                                            className="btn-cancel ripple"
                                                            onClick={this.refreshPage}>
                                                        Cancel</Button>
                                                    <Button type="submit"
                                                            disabled={this.state.updateISRLoader}
                                                            className={`ripple btn-medium d-inline-flex align-items-center 
                                                            ${this.state.updateISRLoader ? 
                                                                'btn-loader' : ''}`}>
                                                        <div className="btn__icon btn__icon--red"><Image
                                                            src={Images.CompleteIc} alt="Update"/></div>
                                                        Update <span style={{'display': 'none'}}
                                                                     className="spinner-border spinner-border-sm"
                                                                     role="status" aria-hidden="true"></span>
                                                    </Button>
                                                </div>
                                                }
                                            </>
                                        </Form>
                                    )}
                                            />

                            </div>
                        </Col>
                    </Row>
                    </div>

                }
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"} />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }} />
            </>
        )
    }
}
export { EditISR };
