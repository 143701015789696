/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Stepzilla
 * Name and Version: react-stepzilla, 6.0.2
 * Download link: https://www.npmjs.com/package/react-stepzilla
 */
import StepZilla from "react-stepzilla";
/* End - React Stepzilla */

import { GeneralInfo } from './general-info';
import { Address } from './address';
import { BankingInfo } from './banking-info';
import { Review } from './review';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { ErrorModal, SuccessModal } from '../../components/modal/modalbox';
import {Footer} from '../../components/footer/footer';
import {HeaderFullWidth} from '../../components/header/headerfullwidth';
import { AboutISR } from '../../components/common/about-isr';
import { Agreement } from './agreement';

import '../club/club.scss';

const nextText = {
    0: 'Next',
    1: 'Next',
    2: 'Next',
    3: 'Next',
    4: 'Finish'
}

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorShow: false,
            errorMessage: null,
            successShow: false,
            successMessage: '',
            showDiv: false,
            photo: { url: null, file: null },
        };
        this.generalInfoFirstLoad = true;
        this.generalInfoFields = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            phoneCode: '',
            username: '',
            password: '',
            confirmPassword: '',
        };
        this.addressFieldsFirstLoad = true;
        this.addressFields = {
            mailAddress: '',
            mailAddress2: '',
            mailCity: '',
            mailState: '',
            mailZip: '',
        };
        this.bankingInfoFieldsFirstLoad = true;
        this.bankInfoFields = {
            bankRoutingNumber :'',
            bankAccountNumber :'',
            bankAccountName :'',
            confirmBankAccountNumber :'',
            socialSecurityNumber :'',
        };
        this.reToken = '';
    }

    handleStepChange = (step) => {
        this.setState({
            step: step
        })
        Helper.scrollToTop();
    }
    setRecaptchaToken = token =>{
          this.reToken = token;
     }

    onRegisterISRSubmit = () => {
        const formData = new FormData();
        if (this.state.photo.file) {
            formData.append('photo', this.state.photo.file);
        }
        formData.append('first_name', this.generalInfoFields.firstname);
        formData.append('last_name', this.generalInfoFields.lastname);
        formData.append('email', this.generalInfoFields.email);
        formData.append('phone', this.generalInfoFields.phone);
        formData.append('phone_code', this.generalInfoFields.phoneCode);
        formData.append('username', this.generalInfoFields.username);
        formData.append('password', this.generalInfoFields.password);
        formData.append('confirm_password', this.generalInfoFields.confirmPassword);
        formData.append('address', this.addressFields.mailAddress);
        formData.append('address2', this.addressFields.mailAddress2);
        formData.append('city', this.addressFields.mailCity);
        formData.append('state', this.addressFields.mailState);
        formData.append('zip', this.addressFields.mailZip);
        formData.append('bank_routing_number', this.bankInfoFields.bankRoutingNumber);
        formData.append('bank_account_number', this.bankInfoFields.bankAccountNumber);
        formData.append('bank_account_name', this.bankInfoFields.bankAccountName);
        formData.append('ssn', this.bankInfoFields.socialSecurityNumber);
        formData.append('recaptcha_response', this.reToken);
        let finishButton = document.getElementById('next-button');
        const url =  !Helper.isSuperAdminLogin() ? 'isr/register': 'isr/admin/register';
        Helper.axiosInstance()
            .post(url, formData, {
                'Content-Type': 'multipart/form-data',
            })
            .then((response) => {
                if (response.data.success === true) {
                    this.setState({ successShow: true, successMessage: response.data.message, });
                }
                else {
                    const getErrors = response.data.error;
                    let errorMsg = [];
                    Object.keys(getErrors).forEach((key,value) =>{
                      errorMsg.push(getErrors[key]);
                    });
                    this.setState({ errorShow: true, errorMessage: errorMsg.join(',') });

                }
                finishButton.classList.remove('btn-loader');
                finishButton.disabled = false;

            })
            .catch((error) => {
                this.setState({ errorShow: true, errorMessage: Messages.SERVER_ERROR });
                finishButton.classList.remove('btn-loader');
                finishButton.disabled = false;
            });
    }

    handleGeneralInfoSubmit = (fields, photo, resetForm) => {
        this.generalInfoFirstLoad = false;
        this.generalInfoFields = fields;
        this.setState({ photo: photo, resetForm: resetForm });
    }

    handleBillingInfoSubmit = (fields) => {
        this.bankingInfoFieldsFirstLoad = false;
        this.bankInfoFields = fields;
    }

    handleAddressSubmit = (fields) => {
        this.addressFieldsFirstLoad = false;
        this.addressFields = fields;
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successShow: false
        });
        if(Helper.isSuperAdminLogin()){
            this.props.history.push(`/isr`)
        }else{
            this.props.history.push(`/login`)
        }
    }

    render() {
        const orgUrl = (this.props.location.pathname ==='/register-isr' || this.props.location.hash ==='#info')? <HeaderFullWidth />: null ;
        const steps = [
            { name: 'Personal Information', component: <GeneralInfo data={{ fields: this.generalInfoFields, photo: this.state.photo, firstLoad: this.generalInfoFirstLoad }} handleFormSubmit={(fields, photo) => this.handleGeneralInfoSubmit(fields, photo)} /> },
            { name: 'Address', component: <Address data={{ fields: this.addressFields, firstLoad: this.addressFieldsFirstLoad }} handleFormSubmit={(fields) => this.handleAddressSubmit(fields)} /> },
            { name: 'Banking Information', component: <BankingInfo data={{ fields: this.bankInfoFields, firstLoad: this.bankingInfoFieldsFirstLoad}} handleFormSubmit={(fields) => this.handleBillingInfoSubmit(fields)} generalInfoFields={this.generalInfoFields} /> },
            { name: 'Agreement', component: <Agreement general={this.generalInfoFields} address={this.addressFields} /> },
            { name: 'Review', component: <Review onRegisterISRSubmit={this.onRegisterISRSubmit} photo={this.state.photo} generalInfo={this.generalInfoFields} bankInfoFields={this.bankInfoFields} addressFields={this.addressFields}  setRecaptchaToken={this.setRecaptchaToken}/> }
        ];

        return (
            <>
                    { orgUrl }

                <div className={!orgUrl ? "rgt-content": "container container--pt"}> {/* class rgt-content when user logged in */}
                    <Row className="row--space">
                        <Col sm="10">
                            <h1>{this.props.history.location.hash === "#info" ? ' Affiliate Marketing Program ':'Marketing Affiliate Registration' }</h1>
                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                {this.props.history.location.hash === "#info" ? <AboutISR path='/register-isr'/> :
                                    <div className='step-progress step-progress--isr'>
                                        <StepZilla steps={steps} startAtStep={0}
                                                   nextButtonText={nextText[this.state.step]}
                                                   showNavigation={true}
                                                   preventEnterSubmission={true}
                                                   stepsNavigation={false}
                                                   prevBtnOnLastStep={true}
                                                   onStepChange={this.handleStepChange}/>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    {
                        orgUrl ? <Footer />  : null
                    }

                </div>

                }
                <SuccessModal
                    successConfirmShow={this.state.successShow}
                    onHide={this.onSuccessConfirmClick}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successMessage}
                    successButtonText={"OK"}
                />
                <ErrorModal
                    show={this.state.errorShow}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ errorShow: false }) }}
                ></ErrorModal>
            </>
        )
    }
}
export { Register };
