/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */
/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Col, Container, Row} from 'react-bootstrap';
/* Start - app imports */
import {Footer} from '../../components/footer/footer';
import '../invite-process/invite.scss';
import * as Storage from "../../components/common/storage";
/* End - app imports */

class Register extends Component {
    constructor(props) {
        super(props);
        this.inviteCode = this.props.match.params.inviteCode ?? '';
    }

    componentDidMount() {
        const user = Storage.Get('user');
        if(user){
            this.props.history.push(`/create-organization/${this.inviteCode}`);
        }
    }

    render() {
        return (
            <>
                <Container>
                    <Row className="vh-content">
                        <Col sm={12} className="in-card-center">
                            <div className="in-card-subheading">Register your Organization with SafeComm</div>
                        </Col>
                        <Col sm={6} className="in-card-center">
                            <div className="in-card">
                                <div className="in-card__header">
                                    <div className="in-card__header__subheading">
                                        New to SafeComm?
                                    </div>
                                    <div className="in-card__header__heading">
                                        Register your organization and yourself
                                    </div>
                                </div>
                                <div className="in-card__content">
                                    <p>Click the button below to register your organization and yourself as an administrator.</p>
                                </div>
                                <Link to={`/create-organization/${this.inviteCode}`}
                                      className="in-card__btn btn justify-content-center">
                                    Register Now
                                </Link>
                            </div>
                        </Col>
                        <Col sm={6} className="in-card-center">
                            <div className="in-card">
                                <div className="in-card__header">
                                    <div className="in-card__header__subheading">
                                        Registered SafeComm member?
                                    </div>
                                    <div className="in-card__header__heading">
                                        Login and register your organization
                                    </div>
                                </div>
                                <div className="in-card__content">
                                    <p>Use your existing SafeComm account to register your organization and become an administrator. </p>
                                </div>
                                <Link to={`/login?redirect=create-organization/${this.inviteCode}`}
                                      className="in-card__btn btn justify-content-center">
                                    Login and Register Organization
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Footer/>
                </Container>
            </>
        )
    }
}

export {Register};
