/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Dropdown, Table, Media, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../common/images';
import * as Enum from '../common/enum';
import * as Helper from '../common/functions';

class PendingContactList extends Component {
    render() {
        let props = this.props;
        if (props.data != null) {
            return (<Table bordered hover className="tab-responsive table--first-col-width">
                <thead>
                    <tr>
                        <th>
                            {
                            props.data.length > 0 &&
                                <span className="checkwrap">
                                    <input className="checkwrap__cbx" id="pendingcheckedall"
                                        checked={props.checkedAll ? true : false}
                                        type="checkbox" onChange={() => props.handlePendingAllChecked()} />
                                    <label className="checkwrap__label" htmlFor="pendingcheckedall">
                                        <span className="checkwrap__label_chk">
                                            <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </svg>
                                        </span>
                                    </label>
                                </span>
                            }
                        </th>
                        <th>Name </th>
                        <th>Legal Guardian </th>
                        <th>Location </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                     props.data.length > 0 &&
                    <tr className="mb-table-row">
                        <td data-label="Select All">
                            <span className="checkwrap">
                                <input className="checkwrap__cbx" id="pendingcheckedallmobile"
                                    checked={props.checkedAll ? true : false}
                                    type="checkbox" onChange={() => props.handlePendingAllChecked()} />
                                <label className="checkwrap__label" htmlFor="pendingcheckedallmobile">
                                    <span className="checkwrap__label_chk">
                                        <svg width="14px" height="12px" viewBox="0 0 14 12">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    }
                    {
                        props.data.map((item, key) => {
                            const id = item.invite_id;
                            return (
                                <tr key={key}>
                                    {
                                        <td>
                                            <span className="checkwrap">
                                                <input className="checkwrap__cbx" id={'i'+key} type="checkbox"
                                                    checked={props.checkedPendingItems.get(id) ? true : false}
                                                    onChange={() => props.selectPendingMembers(item)} />

                                                <label className="checkwrap__label" htmlFor={'i'+key}>
                                                    <span className="checkwrap__label_chk">
                                                        <svg width="14px" height="12px" viewBox="0 0 14 12">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                </label>
                                            </span>
                                        </td>
                                    }
                                    <td data-label="Name" className="td-content-space">
                                    {
                                        (item.user_first_name || item.user_last_name) ?
                                            <Media>
                                                <div className="im-rounded-outer">
                                                    <Image
                                                        src={item.user_photo}
                                                        alt={item.user_first_name}
                                                    />
                                                </div>
                                                <Media.Body className="align-self-center">
                                                    <h5>{item.user_first_name} {item.user_last_name}</h5>
                                                </Media.Body>
                                            </Media>
                                        :
                                        <span className="mb-guardian-email">{item.user_email}</span>
                                    }
                                    </td>
                                    <td data-label="Legal Guardian" className="td-content-space">
                                        {
                                            item.role_id !== 4 ?
                                                (item.guardian_first_name || item.guardian_last_name) ?
                                                    <Media>
                                                        <div className="im-rounded-outer">
                                                            <Image
                                                                src={item.guardian_photo}
                                                                alt={item.guardian_first_name}
                                                            />
                                                        </div>
                                                        <Media.Body className="align-self-center">
                                                            <h5>{item.guardian_first_name} {item.guardian_last_name}</h5>
                                                        </Media.Body>
                                                    </Media>
                                                    :
                                                    <span className="mb-guardian-email">{item.guardian_email}</span>
                                            :
                                            null
                                        }
                                    </td>
                                    <td data-label="Legal Guardian" className="td-content-space">
                                        {item.location}
                                    </td>
                                    <td>
                                        <ContactUserTypeBadge data={item.role_id} />

                                        {props.screen !== 'Add Member' &&
                                            <Dropdown className="more-actions">
                                                <Dropdown.Toggle >
                                                    <span className="more-im"></span>

                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => props.editPendingInviteUser(key, item.role_id)}>
                                                        <Image className="mr-2" src={Images.EditIc} alt="Edit" width="21" />
                                                        <span>Edit</span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item  onClick={()=>props.copyInviteLink(item)}>
                                                        <Image className="mr-2" src={Images.linkGrey} alt="Edit" width="21" />
                                                        <span>Copy Invite </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => props.resendPendingInvite(key)}>
                                                        <Image className="mr-2" src={Images.Refresh} alt="Resend" width="21" />
                                                        <span>Resend</span>
                                                    </Dropdown.Item>
                                                    {
                                                        (Helper.isAdminOrSubAdmin() || Helper.isInstructor()) && (props.screen === "Contact" || props.screen === "Member") &&
                                                        <Dropdown.Item onClick={() => this.props.onRemovePendingInviteClick(item)}>
                                                            <Image className="mr-2" src={Images.DeleteIc} alt="Remove" width="21" />
                                                            <span className='de-color'>Remove</span>
                                                        </Dropdown.Item>
                                                    }

                                                </Dropdown.Menu>
                                                {

                                                    props.copyInviteId  === id  ? <span className="copy-text-sm">Copied!</span> : ''
                                                }
                                            </Dropdown>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>)
        }
        else return "";
    }
}

function ContactUserTypeBadge(props) {
    if (props.data !== null && props.data !== undefined) {
        let item = props.data;
        let badgeName = Enum.UserRolesDisplay[item];
        let className = "t-badge"

        if (item === Enum.UserRoles.COACH) {
            className = `${className} t-badge--coach`;
        }
        else if (item === Enum.UserRoles.LEGAL_GUARDIAN) {
            className = `${className}`;
            badgeName = Enum.UserRolesDisplay[6];
        }
        return (
            <OverlayTrigger
                overlay={
                    <Tooltip id={`tooltip`}>
                        {
                            item === Enum.UserRoles.LEGAL_GUARDIAN ? "Student" : Enum.UserRolesDisplay[item]
                        }
                    </Tooltip>}>
                <div className="badge-wrap"><span className={className}>{badgeName}</span></div>
            </OverlayTrigger>
        )
    }
    else {
        return "";
    }
}
export { PendingContactList };
