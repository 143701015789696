/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React,{ Component }  from 'react';
/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Image} from 'react-bootstrap';
/* End - React Bootstrap */
import * as Images from '../common/images';
/* End - React */

class IeFooterMsg extends Component {
    render() {
        const { ieMsgClass, ieBarClose } = this.props;
        return (
            <div className={ieMsgClass + ' fixed-bar'}>
                <Image className="fixed-bar__close" src={Images.CloseWhite} alt="Delete" width="21" onClick={ieBarClose}/>
                For optimal user experience, please use our web app in a modern browser like Google Chrome, Mozilla Firefox, or Apple Safari. 
            </div>
        )
    }
}
export {IeFooterMsg};

