/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Spinner ,Container,Row,Col} from 'react-bootstrap';
/* End - React Bootstrap */

class SmallLoader extends Component {
    render() {
       
        return (
            <>
                <Container>
                    <Row>
                        <Col sm={10} className="offset-sm-1 mt-3 mb-4 text-center">
                            <Spinner animation="border" variant="danger" />
                        </Col>
                    </Row>
                </Container>               
            </>
        )
    }
}
export { SmallLoader };
