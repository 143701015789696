//#region NPM IMPORTS
/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Dropzone
 * Name and Version: papaparse, 5.1.0
 * Download link: https://www.npmjs.com/package/papaparse
 */
import * as Papa from "papaparse/papaparse.min.js";
/* End -  React Dropzone*/

/**
 * Start - React Dropzone
 * Name and Version: react-dropzone, 10.1.10
 * Download link: https://www.npmjs.com/package/react-dropzone
 */
import Dropzone from 'react-dropzone';
/* End -  React Dropzone*/

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Button, Row, Col, InputGroup, FormControl, Modal } from 'react-bootstrap';
/* End - React Bootstrap */
// #endregion

//#region APP IMPORTS
import {ModalBox, ErrorModal, GroupOverrideModal, ConfirmModal} from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import { Messages } from '../../components/common/message';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { ContactMessages } from './contact-message';
import * as Helper from '../../components/common/functions';
import { GoTop } from '../../components/common/goto-top';
import {Link} from "react-router-dom";
//#endregion 

export default class BulkUpload extends Component {
    //#region CONSTRUCTOR
    constructor(props) {
        super(props);
        this.state = {
            errorShow: false,
            errorMessage: null,
            customErrorShow: false,
            customErrorMessages: [],
            serverResponse: {
                errorMessages: [],
                inviteSentCount: 0,
                errorCount: 0,
                inviteResentCount: 0,
                deactivatedMembers:[]
            },
            files: {},
            csvData: null,
            csvHeaders: null,
            step: 1,
            loader: false,
            conflictedColumns: [],
            unmappedColumnsCount: 0,
            overrideShow: false,
            overrideGroup: 0,
            deactivateMembers: 0,
            team_id: typeof props.match.params.team_id != 'undefined' ? props.match.params.team_id : null,
            groupType:'C'
        }

        let columnsForMapping = {
            "Student First Name": { transferColumnName: "user_first_name", validationType: null, isMapped: false, isRequired: true, isInvalidMapping: false },
            "Student Last Name": { transferColumnName: "user_last_name", validationType: null, isMapped: false, isRequired: true, isInvalidMapping: false },
            "Guardian Email": { transferColumnName: "guardian_email", validationType: "email", isMapped: false, isRequired: true, isInvalidMapping: false },
            "Guardian Phone": { transferColumnName: "guardian_phone", validationType: null, isMapped: false, isRequired: false, isInvalidMapping: false },
            "Guardian Phone Country Code": { transferColumnName: "guardian_phone_code", validationType: null, isMapped: false, isRequired: false, isInvalidMapping: false },
            "Guardian First Name": { transferColumnName: "guardian_first_name", validationType: null, isMapped: false, isRequired: false, isInvalidMapping: false },
            "Guardian Last Name": { transferColumnName: "guardian_last_name", validationType: null, isMapped: false, isRequired: false, isInvalidMapping: false }
        };

        if(this.state.team_id == null){
            // visits, some users come multiple times
            columnsForMapping["Roster Group"] = { transferColumnName: "team", validationType: null, isMapped: false, isRequired: true, isInvalidMapping: false };
            columnsForMapping["Location"] = { transferColumnName: "location", validationType: null, isMapped: false, isRequired: false, isInvalidMapping: false };
        }
        

        this.columnsForMapping = columnsForMapping;
        this.columnsMapped = {};
        this.dataForTransfer = [];
        this.updateData = this.updateData.bind(this);
        this.onSendInviteClick = this.onSendInviteClick.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
        this.onOverrideClick = this.onOverrideClick.bind(this);
    }
    //#endregion

    //#region PRIVATE METHODS
    setUnmappedColumnsCount = () => {
        let count = 0;
        for (var key of Object.keys(this.columnsForMapping)) {
            // eslint-disable-next-line no-loop-func
            let csvHeadMap = this.state.csvHeaders.filter(x => x.mappedTo === key).length;
            if (csvHeadMap > 0) {
                this.columnsForMapping[key].isMapped = true;
            }
            else {
                this.columnsForMapping[key].isMapped = false;
                if(this.columnsForMapping[key].isRequired){
                    count += 1;
                }
            }
        }
        this.setState({ unmappedColumnsCount: count });
    }

    manageColumnMappingConflicts = () => {
        let csvHeaders = this.state.csvHeaders;
        /* Remove conflict from all existing columns*/
        for (let i = 0; i < csvHeaders.length; i++) {
            csvHeaders[i].isConflict = false;
        }
        /* Get distinct mappedTo columns as there might be duplicacy */
        const distinctMappedTo = [...new Set(csvHeaders.filter(x => x.mappedTo != null && x.mappedTo !== "").map(x => x.mappedTo))]
        /* Iterate through all mapped csv headers*/
        for (let i = 0; i < distinctMappedTo.length; i++) {
            let existingMappedCsvHeader = csvHeaders.filter(x => x.mappedTo === distinctMappedTo[i]);
            /* Add conflict to all duplicate mappings */
            if (existingMappedCsvHeader.length > 1) {
                for (var existingHeaderKey of Object.keys(existingMappedCsvHeader)) {
                    existingMappedCsvHeader[existingHeaderKey].isConflict = true;
                }
            }
        }
        this.setState({ csvHeaders: csvHeaders }, () => {
            this.validateCsvMapping();
        });
    }

    showError = (msg) => {
        this.setState({
            errorShow: true,
            errorMessage: msg
        });
    }

    validateCsvMapping = () => {
        let csvHeaders = this.state.csvHeaders;

        /* Remove errors from all existing columns*/
        for (let i = 0; i < csvHeaders.length; i++) {
            csvHeaders[i].isError = false;
        }

        for (var validationHeaderKey of Object.keys(this.columnsForMapping)) {
            let column = this.columnsForMapping[validationHeaderKey];
            /* Currently we are handling email type validation only we can add to this if more are required*/
            if (column.validationType !== null && column.validationType === 'email') {
                /* Check only top 4 records being transferred */
                const emails = [...new Set(this.dataForTransfer.slice(0, 4).map(x => x[column.transferColumnName]))];
                let isErrorInColumn = false;
                for (let i = 0; i < emails.length; i++) {

                    if (emails[i] !== '' && !Helper.isValidEmail(emails[i])) {
                        isErrorInColumn = true;
                        break;
                    }
                }
                if (isErrorInColumn) {
                    // eslint-disable-next-line no-loop-func
                    let matchingCsvHeader = csvHeaders.filter(x => x.mappedTo === validationHeaderKey)[0];
                    matchingCsvHeader.isError = true;
                }
            }
        }
        this.setState({ csvHeaders: csvHeaders });
    }

    onOverrideClick(){
        this.setState({ overrideGroup: 1 }, () => {
            this.sendInvite();
        })
    }

    onDeactivateConfirm(){
        this.setState({ deactivateMembers: 1 }, () => {
            this.sendInvite();
        })
    }

    createAnnouncementGroupConfirm= () => {

        this.setState({ groupType: 'A' }, () => {
            this.sendInvite();
        })
    }


    updateData(result) {
        //let data = result.data;
        let csvHeaders = Object.keys(result.data[0]);

        /* Create data row to be sent in list for data transfer */
        let dataRow = {};
        for (var emptyDataColumnKey of Object.keys(this.columnsForMapping)) {
            dataRow[this.columnsForMapping[emptyDataColumnKey].transferColumnName] = "";
        }

        if (csvHeaders.length > 0) {

            this.dataForTransfer = [];

            for (var key of Object.keys(result.data)) {

                let columnMapRow = this.columnsForMapping;
                // Hack to make a deep copy of the object
                let newDataRow = JSON.parse(JSON.stringify(dataRow));
                for (var dataColumnKey of Object.keys(columnMapRow)) {
                    if (dataColumnKey in result.data[key]) {
                        newDataRow[columnMapRow[dataColumnKey].transferColumnName] = result.data[key][dataColumnKey];
                    }
                    else {
                        newDataRow[columnMapRow[dataColumnKey].transferColumnName] = "";
                    }
                }
                this.dataForTransfer.push(newDataRow);
            };

            /* Create csv headers to db columns mapping */
            let mappedCsvHeaders = Object.keys(csvHeaders).map((headerKey) => {
                let mappedColumn = Object.keys(this.columnsForMapping).filter(key => key === csvHeaders[headerKey]);
                return {
                    value: csvHeaders[headerKey],
                    isMapped: (mappedColumn.length > 0),
                    isConflict: false,
                    mappedTo: mappedColumn ? mappedColumn[0] : null,
                    isError: false
                }
            });

            this.setState({
                step: 2,
                csvHeaders: mappedCsvHeaders,
                csvData: result.data,
                loader: false
            }, () => {
                this.manageColumnMappingConflicts();
                this.setUnmappedColumnsCount();
            });
        }
    }

    //#endregion 

    //#region EVENTS TRIGGERED
    onColumnMatchChange(e, selectedCsvHeader) {
        let selectedTargetValue = e.target.value;
        let { csvHeaders, csvData } = this.state;

        /* Get matching csv header */
        let csvHeaderMatching = csvHeaders.filter(x => x.value === selectedCsvHeader.value)[0];
        /* Make deep copy to preserve value on changes */
        const previousHeadMap = JSON.parse(JSON.stringify(csvHeaderMatching));

        /* Change csv header mapping */
        csvHeaderMatching.isConflict = false;
        if (selectedTargetValue === "") {
            csvHeaderMatching.isMapped = false;
            csvHeaderMatching.mappedTo = "";
        }
        else {
            csvHeaderMatching.isMapped = true;
            csvHeaderMatching.mappedTo = selectedTargetValue;
        }

        let mappedCsvHeaders = csvHeaders.filter(x => x.mappedTo && x.mappedTo !== undefined && x.mappedTo !== '');

        /* Remap data to be transferred to API due to change in column to csv header mapping*/
        for (let iData = 0; iData < this.dataForTransfer.length; iData++) {
            let dataRow = this.dataForTransfer[iData];

            // Empty previous values if empty selection was done in dropdown
            //if (previousHeadMap.mappedTo !== null && previousHeadMap.mappedTo !== undefined){
            if (previousHeadMap.mappedTo) {
                const prevdataRowKey = this.columnsForMapping[previousHeadMap.mappedTo].transferColumnName;
                dataRow[prevdataRowKey] = '';
            }

            // Remap dataset according to new mapping changes on dropdown change
            for (let iMapCsv = 0; iMapCsv < mappedCsvHeaders.length; iMapCsv++) {
                let dataColumnMapped = this.columnsForMapping[mappedCsvHeaders[iMapCsv].mappedTo];
                dataRow[dataColumnMapped.transferColumnName] = csvData[iData][mappedCsvHeaders[iMapCsv].value]
            }
        };

        this.setState({ state: csvHeaders }, () => {
            this.manageColumnMappingConflicts();
            this.setUnmappedColumnsCount();
        });
    }

    processAcceptedFiles(acceptedFiles) {
        if (acceptedFiles.length > 1) {
            this.showError(ContactMessages.FILE_MULTIPLE_NOT_SUPPORTED);
            return;
        }
        this.setState({
            loader: true,
            files: acceptedFiles
        }, () => {
            /* Parse csv using Papa Parse*/
            Papa.parse(acceptedFiles[0], {
                complete: this.updateData,
                header: true,
                skipEmptyLines: true,
                encoding: 'ISO-8859-1' //added this encoding to fix the apostrophe issue
            });
        });
    }

    processRejectedFiles() {
        this.showError(ContactMessages.FILE_FORMAT_NOT_SUPPORTED);
    }

    onBackToContactsClick = () => {
        let teamId = this.state.team_id;
        if(typeof teamId == 'undefined' || teamId == null ){
            this.props.history.push('/contacts');
        } else {
            this.props.history.push('/team-details/'+teamId);
        }
    }

    resetUpload = () => {
        this.setState({ step: 1, files: [] })
    }
    onSendInviteClick() {
        let conflictedColumns = [];
        let errorColumns = [];
        let customErrorMessages = [];
        const { csvHeaders, unmappedColumnsCount } = this.state;

        let conflictedCsvHeaders = csvHeaders.filter(x => x.isConflict);

        /* Check for unmapped columns */
        if (unmappedColumnsCount > 0) {
            const errorMessage = ContactMessages.UNMAPPED_COLUMNS_ERROR.replace("{0}", unmappedColumnsCount);
            customErrorMessages.push(errorMessage);
        }

        /* Check for conflicts in mapping */
        for (let i = 0; i < conflictedCsvHeaders.length; i++) {
            const conflictedMap = conflictedCsvHeaders[i].mappedTo;
            /* Dont add another error for same confict*/
            if (!conflictedColumns.includes(conflictedMap)) {
                conflictedColumns.push(conflictedMap);
                const errorMessage = ContactMessages.CONFLICTED_COLUMNS_ERROR.replace("{0}", conflictedMap);
                customErrorMessages.push(errorMessage);
            }
        }

        /* Check for validation errors in mapping */
        /* For now only email type validation is handled if there are more validation types then this will change */
        let customErrorCsvColumns = csvHeaders.filter(x => x.isError);
        for (let i = 0; i < customErrorCsvColumns.length; i++) {
            const errorMap = customErrorCsvColumns[i].mappedTo;
            if (!errorColumns.includes(errorMap)) {
                errorColumns.push(errorMap);
                customErrorMessages.push(ContactMessages.INVALID_COLUMNS_EMAIL_ERROR);
            }
        }

        if (customErrorMessages.length > 0) {
            this.setState({ customErrorShow: true, customErrorMessages: customErrorMessages });
            return;
        }else{
            //Check,If bulk upload from the teams then don't show the model box
            if (this.state.team_id != null) {
                this.sendInvite();
            }else {
                this.setState({overrideShow: true});
            }
        }
    }
    //#endregion

    sendInvite() {
        this.setState({
            loader: true,
            overrideShow: false
        }, () => {
            // Reset response count
            let {serverResponse, overrideGroup, deactivateMembers} = this.state;
            serverResponse.inviteSentCount = serverResponse.inviteResentCount = serverResponse.errorCount = 0;
            serverResponse.errorMessages = [];
            let dataToPost = {
                club_id: Helper.getSelectedClubId(),
                override: overrideGroup,
                deactivate: deactivateMembers,
                data: this.dataForTransfer,
                type: this.state.groupType

            };
            if (this.state.team_id != null) {
                dataToPost.team_id = this.state.team_id;
            }

            Helper.axiosInstance()
                .post('invite/bulk-import', dataToPost)
                .then((response) => {
                    const data = response.data;
                    if (data.success) {
                        serverResponse.addedToGroup = data.added_to_group;
                        serverResponse.alreadyMember = data.already_member;
                        serverResponse.inviteSentCount = data.sent;
                        serverResponse.inviteResentCount = data.resent;
                        serverResponse.errorMessages = data.failed_message;
                        serverResponse.errorCount = data.failed;
                        serverResponse.deactivatedMembers = data.deactivated_members;

                        this.setState({
                            step: 3,
                            serverResponse: serverResponse,
                            confirmShow: false
                        });
                    } else if (data.group_limit_error) {
                        let groupList = '<p><ul class="announcement-group-listing">';
                        for (const limitCrossedGroup in data.group_limit_error) {
                            let groupName = limitCrossedGroup.split("#"),
                                groupLocation = groupName[1] ? ' - ' + groupName[1] : '';
                            groupList += `<li>${groupName[0]} ${groupLocation}</li>`;
                        }
                        groupList += '</ul></p>';
                        let errorMsg = Messages.MEMBER_LIMIT_CROSSED.replaceAll('##MAX_LIMIT##', data.max_team_members) + groupList;
                        this.setState({confirmShow: true, errorMsg, groupList: data.group_limit_error});
                    } else if (data.deactivate_members) {
                        this.setState({
                            step: 2.1,
                            serverResponse: data.deactivate_members,
                            confirmShow: false
                        });
                    } else {
                        this.showError(Messages.SERVER_ERROR);
                    }
                })
                .catch((error) => {
                    this.showError(Messages.SERVER_ERROR);
                })
                .finally(() => {
                    this.setState({loader: false});
                });
        });
    }
    //#endregion

    //#region RENDER
    render() {
        const { csvHeaders, csvData, step, unmappedColumnsCount } = this.state;
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12} md={12}>
                            <h1>Bulk Upload Contacts</h1>
                        </Col>
                      
                        <Col md={12}>
                            <div className="widget">
                                <div className="widget-inner">
                                    {/* <div className="subheading mb-4">Bulk upload contacts</div> */}
                                    
                                        {
                                            (step === 1) &&
                                            <UploadControlView
                                                processAcceptedFiles={(files) => this.processAcceptedFiles(files)}
                                                processRejectedFiles={() => this.processRejectedFiles()}
                                                files={this.state.files}
                                                loader={this.state.loader}
                                                team_id={this.state.team_id}
                                            />
                                        }
                                    <Row>
                                        {
                                            (step === 2) &&
                                            <UploadedCsvPreview
                                                csvHeaders={csvHeaders}
                                                csvData={csvData}
                                                columnsForMapping={this.columnsForMapping}
                                                onColumnMatchChange={(e, csvHeader) => this.onColumnMatchChange(e, csvHeader)}
                                                unmappedColumnsCount={unmappedColumnsCount}
                                            />
                                        }
                                        {/*List members to be deactivated*/}
                                        {
                                            (step === 2.1) &&
                                            <DeactivateMemberList
                                                serverResponse={this.state.serverResponse}
                                                onDeactivateConfirm={() => this.onDeactivateConfirm()}
                                                onCancel={() => this.setState({'step': 1})}
                                                loader={this.state.loader}
                                            />
                                        }
                                        {
                                            (step === 3) &&
                                            <UploadSuccessView
                                                serverResponse={this.state.serverResponse}
                                                onBackToContactsClick={() => this.onBackToContactsClick()}
                                                team_id={this.state.team_id}
                                            />
                                        }
                                    </Row>
                                </div>
                                {
                                    (step === 2) &&
                                    <div className="widget__footer justify-content-between">
                                        <Button className="ripple btn btn-cancel" onClick={this.resetUpload}>
                                            Cancel
                                        </Button>
                                        <Button className={`btn-medium ripple d-inline-flex align-items-center btn__sendic ${this.state.loader ? ' btn-loader' : ''}`} onClick={this.onSendInviteClick}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.SendIc} alt="Invite" /></div>
                                            Send Invite <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <GoTop scrollStepInPx="50" delayInMs="10" />
                </div>
                <GroupOverrideModal
                    show={this.state.overrideShow}
                    onOverrideClick={this.onOverrideClick}
                    sendInvite={this.sendInvite}
                    onCancelClick={() => { this.setState({ overrideShow: false }) }}
                ></GroupOverrideModal>
                <ConfirmModal
                    show={this.state.confirmShow}
                    onCancelClick={()=>this.setState({confirmShow: false})}
                    onConfirmClick={this.createAnnouncementGroupConfirm}
                    buttonText="Continue"
                    title="Group Limit Exceeded"
                    body={this.state.errorMsg}
                    modalLoader={this.state.loader}
                />
                <ErrorModal
                    show={this.state.errorShow}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ errorShow: false }) }}
                ></ErrorModal>
                <CustomErrorModal
                    show={this.state.customErrorShow}
                    customErrorMessages={this.state.customErrorMessages}
                    onConfirmClick={() => { this.setState({ customErrorShow: false }) }}
                    errors={this.state.customErrors}
                ></CustomErrorModal>
            </>
        )
    }
    //#endregion
}

//#region CUSTOM HTML COMPONENTS

function CustomErrorModal(props) {
    return (<>
        <ModalBox
            size="md"
            show={props.show}
            animation={true}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                    <h1>Error</h1>
                    <p>Please fix the error(s) below and then try again:</p>
                    <ul className="error-listing">
                        {
                            props.customErrorMessages && Object.keys(props.customErrorMessages).map(key => {
                                return (<li key={key}>{props.customErrorMessages[key]}</li>)
                            })
                        }
                    </ul>
                    <div className="confirmbox__footer">
                        <Button className="ripple btn-ok" onClick={props.onConfirmClick}>
                            OK
                        </Button>
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>)
}

function UploadControlView(props) {
    let sampleFile = 'sample-contacts.csv',
        uploadFromTeaminfo = '';
    if(props.team_id != null){
        sampleFile = 'member-groups-sample-contacts.csv';
        uploadFromTeaminfo = 'NOTE: Members added here will only be added to this member group.';
    }
    return (
        <>
        <Row className="mob-col-orders">
            <Col sm={5}>
                <div className="heading-group">
                    <h5>Upload your file</h5>
                    <p>Select a file containing your contacts to import</p>
                </div>
            </Col>
            <Col sm={7} className="text-right rgt-link"><a target='_blank' rel='noopener noreferrer'  href={`${process.env.REACT_APP_FILES_URL}${sampleFile}`}><span className="icbg-circle">?</span><i>Download an example .csv file</i></a></Col>
            </Row>
            <Row>
            <Col sm={12}>
                <div className="alert alert-warning alert--layout">
                <p>The first row of the .csv file should contain the header row. Download the example .csv file to see how header row is implemented.</p>
                <p>If your organization has multiple locations (For eg. Aurora and Riverside) with same Member Group names (For eg. Gold team in both locations), please make sure you add a Location column with the correct location data in your .csv file. For all non-US phone numbers, please provide country code with phone numbers to receive text messages.
</p>
                    <p><b>For all non-US phone numbers, please provide country code with phone numbers to receive text messages. You can ignore it for the US phone number</b></p>
                    <p>{uploadFromTeaminfo}</p>
                </div>
            </Col>
            <Col sm={12}>
                <Dropzone accept=".csv" onDropAccepted={acceptedFiles => {
                    props.processAcceptedFiles(acceptedFiles);
                }}
                    onDropRejected={files => {
                        props.processRejectedFiles();
                    }}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="text-center">
                            <div className="upload-wrap">
                                <div className="upload-wrap__inner">
                                    <div className="upload-wrap__inner__box">
                                        <Image src={Images.uploadIm} alt="Upload" />
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon1"><Image src={Images.uploadIc} /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                placeholder="Upload File"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                readOnly
                                            />
                                        </InputGroup>
                                        <input {...getInputProps()} />
                                    </div>
                                    <p>Select your file or drag it here</p>
                                    <span>Import contacts from .csv file</span>
                                    <ul className="unordered-listing">
                                        <li>
                                            {Object.keys(props.files).map((key) => {
                                                return (
                                                    <ul key={key}>
                                                        <li className="unordered-listing__heading"><strong>Uploaded File</strong></li>
                                                        <li>
                                                            {props.files[key].name} - <strong>{props.files[key].size} bytes </strong>
                                                        </li>
                                                    </ul>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                    {props.loader && <SmallLoader />}
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </Col>
            </Row>
        </>
    )
}

function getColumns(required, props){
    return Object.keys(props.columnsForMapping).map((key) => {
        let isMapped = props.csvHeaders.filter(x => x.mappedTo === key).length > 0;
        return (
        props.columnsForMapping[key].isRequired == required ?
            <li key={key}>
                <span className="checkwrap">
                    <input className="checkwrap__cbx" id="option-1" type="checkbox"
                        checked={(isMapped)}
                        disabled='disabled' />
                    <label className="checkwrap__label" htmlFor="option-1">
                        <span className="checkwrap__label_chk">
                            <svg width="14px" height="12px" viewBox="0 0 14 12">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                        <i>{key}</i>
                    </label>
                </span>
            </li>
            :
            ''
        )
    })
}

function UploadedCsvPreview(props) {
    let requiredColumns = getColumns(true, props),
    notRequiredColumns = getColumns(false, props);

    return (
        <Col sm={12}>
            <div className="heading-group">
                <p className="mb-0">Now let’s match the columns in your uploaded file to your contact list. </p>
            </div>
            <div className="matched-col">
                <ul>
                    <li><strong>{`${props.unmappedColumnsCount} unmatched required column(s):`}</strong></li>
                    {requiredColumns}
                </ul>
                <ul>
                    <li><strong>{`Optional column(s):`}</strong></li>
                    {notRequiredColumns}
                </ul>
            </div>
            <div className="grid-wrapper">
                {
                    props.csvHeaders !== null && props.csvHeaders.length > 0 &&
                    
                    Object.keys(props.csvHeaders).map((key) => {
                        let csvHeader = props.csvHeaders[key];
                        let conflictStyle = csvHeader.isConflict || csvHeader.isError ? { border: "solid 2px", borderColor: "red" } : null;
                        return (
                            <div key={key} className="grid-col">
                                <div className="grid-col__inner">
                                    {/* Check if column exists in csv header */}
                                    {
                                        (csvHeader.isMapped)
                                            ?
                                            <div className="grid-col__inner__label grid-col__inner__label--success">
                                                <Image src={Images.successIc} alt="Success" />
                                                <span>{csvHeader.mappedTo}</span>
                                            </div>
                                            :
                                            <div className="grid-col__inner__label">
                                                <Image src={Images.warningIc} alt="warning" />
                                                <span> Column match pending</span>
                                            </div>
                                    }
                                    <label>Import “{csvHeader["value"]}” as</label>
                                    <div className="select-wrap">
                                        <select className="form-control" style={conflictStyle} onChange={(e) => props.onColumnMatchChange(e, csvHeader)} defaultValue={csvHeader["value"]}>
                                            <option value="">Select</option>
                                            {
                                                Object.keys(props.columnsForMapping).map((columnKey) => {
                                                    return (
                                                        <option key={columnKey} value={columnKey}>{columnKey}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <ul className="greenbg">
                                        {
                                            props.csvData.slice(0, 4).map((item, dataKey) => {
                                                return (
                                                    <li key={dataKey}>{item[csvHeader["value"]]}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Col>
    )
}

function UploadSuccessView(props) {
    const response = props.serverResponse;
     return (<>
        <Col sm={10} className="offset-sm-1">
            {
                (response.inviteSentCount > 0 || response.inviteResentCount > 0) &&
                <div className="alert alert--success mb-3">
                    <div className="alert__icon alert__icon--success">
                        <Image src={Images.AlertSuccess} alt="success" />
                    </div>
                    <div className="alert-content">
                        <div className="alert__heading">Success.</div>
                        <p>Invitation has been sent to {response.inviteSentCount + response.inviteResentCount} contact(s).</p>

                    </div>
                </div>
            }

            {
                (response.addedToGroup > 0 || response.addedToGroup > 0) &&
                <div className="alert alert--success mb-3">
                    <div className="alert__icon alert__icon--success">
                        <Image src={Images.AlertSuccess} alt="success" />
                    </div>
                    <div className="alert-content">
                        <div className="alert__heading">Success.</div>
                        <p>{response.addedToGroup} member(s) have been added to { props.team_id != null ? 'this group.' : 'their respective member group(s).'}</p>
                    </div>
                </div>
            }
            {
                (response.alreadyMember > 0 || response.alreadyMember > 0) &&
                <div className="alert alert--success mb-3">
                    <div className="alert__icon alert__icon--success">
                        <Image src={Images.AlertSuccess} alt="success" />
                    </div>
                    <div className="alert-content">
                        <div className="alert__heading">Success.</div>
                        <p>{response.alreadyMember} member(s) are already member of { props.team_id != null ? 'this group.':'respective member group(s).'}</p>
                    </div>
                </div>
            }
            {
                response.errorCount > 0 &&
                <div className="alert alert--danger mb-3">
                    <div className="alert__icon alert__icon--danger">
                        <Image src={Images.AlertWarning} alt="danger" />
                    </div>
                    <div className="alert-content">
                        <div className="alert__heading">We’re sorry, some error in {response.errorCount} records.</div>
                        {
                            response.errorMessages.length > 0 &&
                            <ul className="alert__listing">
                                {
                                    Object.keys(response.errorMessages).map(key => {
                                        return (<li key={key}>{response.errorMessages[key]}</li>)
                                    })
                                }
                            </ul>
                        }
                    </div>

                </div>
            }
            {
                !response.inviteSentCount && !response.inviteResentCount && !response.addedToGroup
                && !response.errorCount &&
                <div className="alert alert-warning mb-3">
                    <div className="alert__icon alert__icon--warning">
                        <Image src={Images.AlertWarning} alt="danger" />
                    </div>
                    <div className="alert-content">
                        <div className="alert__heading">Notice</div>
                        <ul className="alert__listing">
                            <li>There is nothing to import, all record are already in the system.</li>
                        </ul>
                    </div>
                </div>
            }

            {
                (response.deactivatedMembers && response.deactivatedMembers.length >0 ) &&
                <ListDeactivatedMembers memberList={response.deactivatedMembers}/>
            }
            <div className="d-flex justify-content-center mb-2">
                <Button type="button" onClick={() => { props.onBackToContactsClick() }} className="ripple mt-3 btn-medium d-inline-flex align-items-center btn">
                { props.team_id != null ? 'Back to Member Group' : 'Back to Contacts'}
                </Button>
            </div>

        </Col>
    </>)
};

function  ListDeactivatedMembers(props){
    const memberList = props.memberList;
    return (<>
            <div className="deactivated-user-view-wrap">
            <p>Following member(s) has been deactivated.</p>
            <ul className="deactivated-user-view">
            {

                    memberList.map((member, i) => (
                        <li> {member.first_name} {member.last_name} </li>
                    ))
                }
            </ul>
            </div>

    </>)
}

function DeactivateMemberList(props) {
    const memberList = props.serverResponse;
    return (<>
        <Col sm={10} className="offset-sm-1">
            <div className="deactivated-user-view-wrap"><p>Following students will be deactivated because they are not listed in any member groups.</p>
                <ul className="deactivated-user-view">
                    {
                        memberList.map((row, i) => {
                            return (
                                <li>
                                    {row.first_name} {row.last_name}
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className={`justify-content-center mb-2 ${props.loader ? ' d-flex' : 'd-none'}`}>
                <div className='alert alert-warning'>
                    Upload is in progress. This process may take some time. Please do not hit the back button or refresh this page.
                </div>
                </div>
            <div className="d-flex justify-content-center mb-2">
                <Button type="button" onClick={() => {
                    props.onDeactivateConfirm()
                }} className={`ripple mr-3 px-4 btn-medium d-inline-flex align-items-center btn ${props.loader ? ' btn-loader disabled-btn' : ''}`}>
                    Continue
                    <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </Button>
                <Button className="ripple btn btn-cancel" onClick={() => props.onCancel()}>
                    Cancel
                </Button>
            </div>

        </Col>
    </>)
}

//#endregion