/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, OverlayTrigger,Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import { SuccessModal, ErrorModal } from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import { Messages } from '../../components/common/message';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import * as Storage from '../../components/common/storage';
import * as Helper from '../../components/common/functions';
import { UsStates } from '../../components/common/us-states';
import './club.scss';
import {PhoneInput} from "../../components/input/phone";
import * as Regex from "../../components/common/regex";

class EditClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            clubName: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            adminPhone: '',
            adminPhoneCode: '+1',
            mailAddress: '',
            mailAddress2: '',
            mailCity: '',
            mailingState: '',
            mailZip: '',
            billAddress: '',
            billAddress2: '',
            billCity: '',
            billZip: '',
            billingState: '',
            generalEmail: '',
            generalFirstName: '',
            generalLastName: '',
            generalPhoneCode: '',
            contact2Email: '',
            contact2FirstName: '',
            contact2LastName: '',
            contact2PhoneCode: '',
            website: '',
            additionalInformation: '',
            billingFirstName: '',
            billingLastName: '',
            billEmail: '',
            discount: '',
            discountType: '',
            costPerUser: 0,
            billingPhoneCode: '',
            disabled: true,
            dataLoading: true,
            disabledState: true,
            isSuperAdmin: false,
            file: {
                imagesrc: null,
                data: null
            },
            showLoader: false,
            updateNameLoader: false,
            updateClubDetailsLoader: false,
            updateAdminDetailsLoader: false,
            successConfirmShow: false,
            successText: '',
            clubId: null,
            editClub: { display: 'none' },
            updateClub: { display: 'flex' },
            editNameStyle: { display: 'block' },
            updateNameStyle: { display: 'none' },
            editAdmin: { display: 'block' },
            updateAdmin: { display: 'none' }
        };
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successConfirmShow: false
        })
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    componentDidMount = () => {
        let userInfo = Storage.Get('user');
        const isSuperAdmin = userInfo && userInfo.is_super_admin;
        const clubId = isSuperAdmin ? this.props.match.params.club_id : userInfo.selectedClubId;
        this.setState({ clubId: clubId, isSuperAdmin: isSuperAdmin }, () => {
            this.getClubData();
        })
    }

    getClubData = () => {
        Helper.axiosInstance().get(`club/detail/${this.state.clubId}`)
            .then((response) => {
                if (response.data.success) {
                    let values = response.data.data;

                    this.setState({
                        clubName: values.name,
                        file: { imagesrc: values.photo },
                        adminFirstName: values.admin_first_name,
                        website: values.website,
                        additionalInformation: values.additional_info,
                        adminLastName: values.admin_last_name,
                        adminEmail: values.admin_email,
                        adminPhone: values.admin_phone,
                        adminPhoneCode: values.admin_phone_code,
                        billAddress: values.bill_address,
                        billAddress2: values.bill_address2,
                        billCity: values.bill_city,
                        billingState: values.bill_state,
                        billZip: values.bill_zip,
                        generalEmail: values.general_email,
                        generalFirstName: values.general_first_name,
                        generalPhone: values.general_phone,
                        generalPhoneCode: values.general_phone_code,
                        generalLastName: values.general_last_name,
                        contact2Email: values.contact2_email ? values.contact2_email : '',
                        contact2FirstName: values.contact2_first_name,
                        contact2Phone: values.contact2_phone,
                        contact2PhoneCode: values.contact2_phone_code,
                        contact2LastName: values.contact2_last_name,
                        billEmail: values.billing_email,
                        billingFirstName: values.billing_first_name,
                        billingLastName: values.billing_last_name,
                        billingPhone: values.billing_phone,
                        billingPhoneCode: values.billing_phone_code,
                        mailAddress: values.mail_address,
                        mailAddress2: values.mail_address2,
                        mailCity: values.mail_city,
                        mailingState: values.mail_state,
                        mailZip: values.mail_zip,
                        students: values.total_athlete,
                        teams: values.total_team,
                        instructors: values.total_coach,
                        discountType: values.discount_type,
                        costPerUser: values.cost_per_user,
                        discount: values.discount,
                        status: values.status,
                        dataLoading:false
                    })
                }
                else {
                    this.setState({ errorMessage: response.data.error, showError: true, recordLoaded: true });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
            });
    }

    onSelectBillChange = (event, setFieldValue) => {
        setFieldValue("billingState", event.target.value);
    }


    onSelectMailChange = (event, setFieldValue) => {
        setFieldValue("mailingState", event.target.value);
    }

    toggleEdit = () => {
        this.setState({
            editClub: this.state.editClub.display === 'none' ? { display: 'flex' } : { display: 'none' },
            updateClub: this.state.updateClub.display === 'none' ? { display: 'flex' } : { display: 'none' },
            disabled: !this.state.disabled,
        })
    }

    refreshPage = () => {
        window.location.reload();
    }

    cancelClick = (event, setFieldValue) => {
        this.setState({
            editClub: this.state.editClub.display === 'none' ? { display: 'flex' } : { display: 'none' },
            updateClub: this.state.updateClub.display === 'none' ? { display: 'flex' } : { display: 'none' },
            disabled: !this.state.disabled,
        });
        setFieldValue("billingAddress", this.state.billAddress);
        setFieldValue("billingAddress2", this.state.billAddress2);
        setFieldValue("billingCity", this.state.billCity);
        setFieldValue("billingZipCode", this.state.billZip);
        setFieldValue("mailingAddress", this.state.mailAddress);
        setFieldValue("website", this.state.website);
        setFieldValue("additionalInformation", this.state.additionalInformation);
        setFieldValue("mailingAddress2", this.state.mailAddress2);
        setFieldValue("mailingCity", this.state.mailCity);
        setFieldValue("mailingZipCode", this.state.mailZip);
        setFieldValue("generalEmail", this.state.generalEmail);
        setFieldValue("generalFirstName", this.state.generalFirstName);
        setFieldValue("generalLastName", this.state.generalLastName);
        setFieldValue("contact2Email", this.state.contact2Email);
        setFieldValue("contact2FirstName", this.state.contact2FirstName);
        setFieldValue("contact2LastName", this.state.contact2LastName);
        setFieldValue("billingLastName", this.state.billingLastName);
        setFieldValue("billingFirstName", this.state.billingFirstName);
        setFieldValue("billingEmail", this.state.billEmail);
        setFieldValue("mailingState", this.state.mailingState);
        setFieldValue("billingState", this.state.billingState);
    }

    handlePhotoChange = (event) => {
        if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            if (event.target.files[0].size / 1024 > 6000) {
                this.setState({ errorMessage: Messages.PHOTO_MAX_SIZE, showError: true, });
                event.target.value = null;
                return;
            }
            if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg") {
                this.setState({ errorMessage: Messages.PHOTO_TYPE_ALLOWED, showError: true, });
                event.target.value = null;
                return;
            }
            this.setState({ showLoader: true });
            const formData = new FormData();
            formData.append('id', this.state.clubId);
            formData.append('photo', event.target.files[0]);
            Helper.axiosInstance().post('club/update-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
                .then((response) => {
                    if (response.data.success) {
                        let user = Storage.Get('user');
                        user.club_photo[user.selectedClubId] = response.data.imageUrl;
                        Storage.Set('user', JSON.stringify(user));
                        this.setState({
                            file: { imagesrc: response.data.imageUrl },
                            successText: "Club Photo updated successfully.",
                            successConfirmShow: true,
                            showLoader: false,
                        })
                        this.props.handleClubPic( user.club_photo[user.selectedClubId]);
                    }
                    else {
                        this.setState({ errorMessage: response.data.error, showLoader: false, showError: true, });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showLoader: false, showError: true, });
                });
        }
    }

    toggleClubNameState = () => {
        this.setState({
            editNameStyle: this.state.editNameStyle.display === 'none' ? { display: 'block' } : { display: 'none' },
            updateNameStyle: this.state.updateNameStyle.display === 'none' ? { display: 'block' } : { display: 'none' },
        })
    }

    updateClubName(fields) {
        this.setState({ updateNameLoader: true });
        if (this.state.clubName === fields.clubName.trim()) {
            this.toggleClubNameState();
            this.setState({ updateNameLoader: false });
        }
        else {
            Helper.axiosInstance().put('club/update-name', {
                id: this.state.clubId,
                name: fields.clubName.trim(),
            })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState({
                            successText: "Club Name updated successfully.",
                            successConfirmShow: true,
                            updateNameLoader: false,
                            clubName: fields.clubName
                        });
                        this.toggleClubNameState();
                    }
                    else {
                        const getErrors = response.data.error;
                        let errorMsg = [];
                        Object.keys(getErrors).forEach((key,value) =>{
                          errorMsg.push(getErrors[key]);
                        });
                        this.setState({ showError: true, errorMessage: errorMsg.join(','), updateNameLoader: false });
}
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, updateNameLoader: false, showError: true, });
                });
        }
    }

    updateClubDetails = (fields) => {
        this.setState({
            updateClubDetailsLoader: true,
        })
        var billZip = fields.billingZipCode.toString().includes(".") ? fields.billingZipCode.toString().split('.')[0] : fields.billingZipCode;
        var mailZip = fields.mailingZipCode.toString().includes(".") ? fields.mailingZipCode.toString().split('.')[0] : fields.mailingZipCode;

        var data = {
            id: this.state.clubId,
            name: this.state.clubName,
            mail_address: fields.mailingAddress.trim(),
            mail_city: fields.mailingCity.trim(),
            mail_state: fields.mailingState,
            mail_zip: mailZip,
            bill_address: fields.billingAddress.trim(),
            bill_city: fields.billingCity.trim(),
            bill_state: fields.billingState,
            bill_zip: billZip,
            website: fields.website ? fields.website.trim() : '',
            additional_info: fields.additionalInformation ? fields.additionalInformation.trim() : '',
            general_email: fields.generalEmail.trim(),
            general_first_name: fields.generalFirstName ? fields.generalFirstName.trim() : '',
            general_last_name: fields.generalLastName ? fields.generalLastName.trim(): '',
            general_phone: fields.generalPhone ? fields.generalPhone.trim() : '',
            general_phone_code: fields.generalPhoneCode ? fields.generalPhoneCode.trim() : '',
            contact2_email: fields.contact2Email.trim(),
            contact2_first_name: fields.contact2FirstName ? fields.contact2FirstName.trim() : '',
            contact2_last_name: fields.contact2LastName ? fields.contact2LastName.trim(): '',
            contact2_phone: fields.contact2Phone ? fields.contact2Phone.trim() : '',
            contact2_phone_code: fields.contact2PhoneCode ? fields.contact2PhoneCode.trim() : '',
            billing_phone: fields.billingPhone ? fields.billingPhone.trim() : '',
            billing_phone_code: fields.billingPhoneCode ? fields.billingPhoneCode.trim() : '',
            billing_first_name: fields.billingFirstName ? fields.billingFirstName.trim() : '',
            billing_last_name: fields.billingLastName ? fields.billingLastName.trim(): '',
            billing_email: fields.billingEmail.trim(),
            discount: fields.discount,
            discount_type: fields.discountType,
        }
        Helper.axiosInstance().put('club/update', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        updateClubDetailsLoader: false,
                        successConfirmShow: true,
                        successText: "Club Details updated successfully",
                        billAddress: fields.billingAddress,
                        billAddress2: fields.billingAddress2,
                        billCity: fields.billingCity,
                        billingState: fields.billingState,
                        billZip: billZip,
                        billEmail: fields.billingEmail,
                        generalEmail: fields.generalEmail,
                        generalFirstName: fields.generalFirstName,
                        generalLastName: fields.generalLastName,
                        generalPhone: fields.generalPhone,
                        generalPhoneCode: fields.generalPhoneCode,
                        contact2Email: fields.contact2Email,
                        contact2FirstName: fields.contact2FirstName,
                        contact2LastName: fields.contact2LastName,
                        contact2Phone: fields.contact2Phone,
                        contact2PhoneCode: fields.contact2PhoneCode,
                        billingPhoneCode: fields.billingPhoneCode,
                        billingPhone: fields.billingPhone,
                        billingFirstName: fields.billingFirstName,
                        billingLastName: fields.billingLastName,
                        mailAddress: fields.mailingAddress,
                        website: fields.website,
                        additionalInformation: fields.additionalInformation,
                        mailAddress2: fields.mailingAddress2,
                        mailCity: fields.mailingCity,
                        mailingState: fields.mailingState,
                        mailZip: mailZip,
                        discount: fields.discount,
                        discountType: fields.discountType,
                    });
                    this.toggleEdit();
                }
                else {

                    const getErrors = response.data.error;
                    let errorMsg = [];
                    Object.keys(getErrors).forEach((key,value) =>{
                      errorMsg.push(getErrors[key]);
                    });
                    this.setState({ showError: true, errorMessage: errorMsg.join(','), updateClubDetailsLoader: false,});
                   
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, updateClubDetailsLoader: false, });
            });
    }

    toggelAdminDetails = () => {
        this.setState({
            editAdmin: this.state.editAdmin.display === 'none' ? { display: 'block' } : { display: 'none' },
            updateAdmin: this.state.updateAdmin.display === 'none' ? { display: 'block' } : { display: 'none' },
            disabledState: !this.state.disabledState,
        })
    }

    updateAdminDetails = (fields) => {
        this.setState({
            updateAdminDetailsLoader: true,
        })
        var data = {
            club_id: this.state.clubId,
            admin_email: fields.adminEmail,
            admin_phone: fields.adminPhone,
            admin_phone_code: fields.adminPhoneCode,
            admin_email_old: this.state.adminEmail,
        }
        Helper.axiosInstance().put('club/update-admin', data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        updateAdminDetailsLoader: false,
                        successConfirmShow: true,
                        successText: "Admin Details updated successfully",
                        adminEmail: fields.adminEmail,
                        adminPhone: fields.adminPhone,
                        adminPhoneCode: fields.adminPhoneCode,
                    });
                    this.toggelAdminDetails();
                }
                else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, updateAdminDetailsLoader: false, });
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, updateAdminDetailsLoader: false, });
            });
    }

    render() {

        const { adminEmail, adminFirstName, adminLastName, adminPhone, adminPhoneCode, billAddress, billAddress2, billCity,
            billZip, billEmail, generalEmail,generalFirstName, generalLastName, billingFirstName, billingLastName, mailAddress,
            mailAddress2, mailCity, mailZip, discountType, discount, website,generalPhone, generalPhoneCode, billingPhoneCode,
            billingPhone, additionalInformation, dataLoading, costPerUser, contact2Email,contact2FirstName, contact2LastName, contact2Phone, contact2PhoneCode } = this.state;
         return (
            <>
                {
                    !dataLoading &&
                    <div className="rgt-content">
                        <Row className="row--space">
                            <Col sm={12}>
                                <h1>Organization Settings</h1>
                            </Col>
                            <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                                <div className="info-box mt-4">
                                    {
                                        this.state.showLoader ? <SmallLoader/> :
                                            <div className="info-box__header info-box__header--org">
                                                <div className="info-box__uplaod">
                                                    <input type='file' onChange={this.handlePhotoChange}/>
                                                    <div><Image src={Images.CameraIcRed} alt="camera" width="24"/></div>
                                                </div>
                                                <Image src={this.state.file.imagesrc} alt={this.state.groupName}/>
                                            </div>
                                    }
                                    <div className="info-box__content" style={this.state.editNameStyle}>
                                        <div className="content__rgt-col content__rgt-col--position">Recommended
                                            dimensions of 300 x 169 pixels
                                        </div>
                                        <h4>
                                            <span>{this.state.clubName}</span>
                                            <div className="add-linkbtn" onClick={this.toggleClubNameState}>
                                                <Image className="edit-icon" src={Images.EditBlue} alt="Edit"/>
                                            </div>
                                        </h4>
                                    </div>
                                    <div className="info-box__content" style={this.state.updateNameStyle}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                clubName: this.state.clubName,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                clubName: Yup.string().trim()
                                                    .required('Club Name is required')
                                                    .max(100, Messages.MAX_LENGTH_100)
                                            })}
                                            onSubmit={fields => {
                                                this.updateClubName(fields);
                                            }}
                                            render={({errors, status, touched}) => (
                                                <Form className="contact-us" noValidate>
                                                    <div>
                                                        <div className="field form-group">
                                                            <Field name="clubName" type="text" id="clubName"
                                                                   onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                   className={'form-control' + (errors.clubName && touched.clubName ? ' is-invalid' : '')}/>
                                                            <label htmlFor="clubName"
                                                                   className="form-label label-active">Club Name</label>
                                                            <ErrorMessage name="clubName" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                        <div>
                                                            <Button type="Submit" disabled={this.state.updateNameLoader}
                                                                    className={`btn btn-primary btn-medium d-inline-flex align-items-center mr-4 ${this.state.updateNameLoader ? 'btn-loader' : ''}`}>
                                                                <div className="btn__icon btn__icon--red"><Image
                                                                    src={Images.CompleteIc} alt="Save"/></div>
                                                                Save <span style={{'display': 'none'}}
                                                                           className="spinner-border spinner-border-sm"
                                                                           role="status" aria-hidden="true"></span>
                                                            </Button>
                                                            <Button type="reset" disabled={this.state.updateNameLoader}
                                                                    className="btn-cancel ripple"
                                                                    onClick={this.toggleClubNameState}>Cancel</Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="info-box info-box--space">
                                    <div className="info-box__content">
                                        {
                                            this.state.isSuperAdmin ?
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={{
                                                        adminEmail: adminEmail,
                                                        adminFirstName: adminFirstName,
                                                        adminLastName: adminLastName,
                                                        adminPhone: adminPhone,
                                                        adminPhoneCode: adminPhoneCode
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        adminEmail: Yup.string().trim()
                                                            .max(150, Messages.MAX_LENGTH_150)
                                                            .email(Messages.INVALID_EMAIL)
                                                            .required(Messages.REQUIRED_ERR),
                                                        /*adminPhone: Yup.string().trim()
                                                            .matches(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, { message: 'Phone number is not Valid', excludeEmptyString: false })
                                                            .max(15, 'Phone number is not Valid')
                                                            .required('Phone number is required'),
                                                        adminPhoneCode: Yup.string().trim()
                                                            .required('Phone code is required'),*/
                                                    })}
                                                    onSubmit={fields => {
                                                        this.updateAdminDetails(fields);
                                                    }}
                                                    render={({errors, status, touched, setFieldValue, values}) => (
                                                        <Form className="contact-us" noValidate>
                                                            <div className="adm-heading" style={this.state.editAdmin}>
                                                            <span className="adm-heading__inner">Admin Details
                                                                {
                                                                    this.state.status === 0 ?
                                                                        <span className="badge-pndg">Pending</span> :
                                                                        <span className="badge-acpt">Approved</span>
                                                                }
                                                            </span>
                                                                <div className="add-linkbtn"
                                                                     onClick={this.toggelAdminDetails}>
                                                                    <Image className="edit-icon" src={Images.EditBlue}
                                                                           alt="Edit"/>
                                                                </div>
                                                            </div>
                                                            <div className="field form-group">
                                                                <Field name="adminEmail" type="text" id="adminEmail"
                                                                       disabled={(this.state.disabledState) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.adminEmail && touched.adminEmail ? ' is-invalid' : '')}/>
                                                                <label htmlFor="adminEmail"
                                                                       className="form-label label-active">Email</label>
                                                                <ErrorMessage name="adminEmail" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            {/*<div className="field form-group">
                                                           <PhoneInput
                                                                parent={this}
                                                                codeField={'adminPhoneCode'}
                                                                defaultCode={adminPhoneCode}
                                                                phoneField={'adminPhone'}
                                                                fieldLabel={'Admin Phone'}
                                                                errors={errors}
                                                                touched={touched}
                                                                serverValid={false}
                                                                setFieldValue={setFieldValue}
                                                                values={values}
                                                                disabled={this.state.disabledState}
                                                            />
                                                        </div>*/}
                                                            <div style={this.state.updateAdmin}>
                                                                <Button type="Submit"
                                                                        disabled={this.state.updateAdminDetailsLoader}
                                                                        className={`btn btn-primary btn-medium d-inline-flex align-items-center mr-4 mb-3 ${this.state.updateAdminDetailsLoader ? 'btn-loader' : ''}`}>
                                                                    <div className="btn__icon btn__icon--red "><Image
                                                                        src={Images.CompleteIc} alt="Save"/></div>
                                                                    Update <span style={{'display': 'none'}}
                                                                                 className="spinner-border spinner-border-sm"
                                                                                 role="status"
                                                                                 aria-hidden="true"></span>
                                                                </Button>
                                                                <Button type="reset"
                                                                        disabled={this.state.updateAdminDetailsLoader}
                                                                        className="btn-cancel ripple mb-3"
                                                                        onClick={this.toggelAdminDetails}>Cancel</Button>
                                                            </div>
                                                            { adminFirstName &&
                                                            <div className="field form-group">
                                                                <Field name="adminFirstName" type="text"
                                                                       id="adminFirstName" disabled="disabled"
                                                                       className={'form-control'}/>
                                                                <label htmlFor="adminFirstName"
                                                                       className="form-label label-active">First
                                                                    Name</label>
                                                            </div>
                                                            }
                                                            { adminLastName &&
                                                                <div className="field form-group">
                                                                    <Field name="adminLastName" type="text"
                                                                           id="adminLastName" disabled="disabled"
                                                                           className={'form-control'}/>
                                                                    <label htmlFor="adminLastName"
                                                                           className="form-label label-active">Last
                                                                        Name</label>
                                                                </div>
                                                            }
                                                            { adminPhone &&
                                                            <div className="field form-group mb-0">
                                                                <Field name="adminPhone" value={adminPhone ? adminPhoneCode+ ' '+values.adminPhone:''} type="text"
                                                                       id="adminPhone" disabled="disabled"
                                                                       className={'form-control'}/>

                                                                <label htmlFor="adminPhone"
                                                                       className="form-label label-active">
                                                                    Phone</label>
                                                            </div>
                                                            }
                                                        </Form>
                                                    )}
                                                />
                                                :
                                                <>
                                                    <div className="adm-heading">
                                                    <span className="adm-heading__inner">Admin Details
                                                        {
                                                            this.state.status === 0 ?
                                                                <span className="badge-pndg">Pending</span> :
                                                                <span className="badge-acpt">Approved</span>
                                                        }
                                                    </span>
                                                    </div>
                                                    <div className="a-card">
                                                        <label className="a-card__label">Admin Email</label>
                                                        <span className="a-card__content">{adminEmail}</span>
                                                    </div>
                                                    <div className="a-card mt-3">
                                                        <label className="a-card__label">Admin Phone</label>
                                                        <span
                                                            className="a-card__content">{adminPhone ? `${adminPhoneCode ? adminPhoneCode :''} ${adminPhone}` : 'NA'}</span>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="info-box info-box--space">
                                    <div className="info-box__content d-flex justify-content-between">
                                        <div className="icon-wrap">
                                            <Image src={Images.DashboardTotalMember} alt="Member Groups" width="38"/>
                                        </div>
                                        <div className="info-box__content__desc">
                                            <p>Member Groups</p>
                                            <div className="content__desc__heading">{this.state.teams}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-box info-box--space">
                                    <div className="info-box__content d-flex justify-content-between">
                                        <div className="icon-wrap">
                                            <Image src={Images.DashboardTotalStudent} alt="Total Students" width="38"/>
                                        </div>
                                        <div className="info-box__content__desc">
                                            <p>Students</p>
                                            <div className="content__desc__heading">{this.state.students}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-box info-box--space">
                                    <div className="info-box__content d-flex justify-content-between">
                                        <div className="icon-wrap">
                                            <Image src={Images.DashboardTotalInstructor} alt="Total Instructors"
                                                   width="38"/>
                                        </div>
                                        <div className="info-box__content__desc">
                                            <p>Instructors</p>
                                            <div className="content__desc__heading">{this.state.instructors}</div>
                                        </div>
                                    </div>
                                </div>
                                {!Helper.isSuperAdminLogin() &&
                                <>
                                    <div className="info-box info-box--space">
                                        <div className="info-box__content d-flex justify-content-between">
                                            <div className="icon-wrap">
                                                <Image src={Images.Renewals} alt="Discount Per User" width="38"/>
                                            </div>
                                            <div className="info-box__content__desc">
                                                <p>Cost Per User</p>
                                                <div className="content__desc__heading">${costPerUser}</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                            </Col>
                            <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">
                                <div className="block-pos block-pos--single-elem" style={this.state.updateClub}>
                                    <OverlayTrigger placement="auto"
                                                    overlay={
                                                        <Tooltip id={`tooltip`}>
                                                            Edit Organization
                                                        </Tooltip>}>
                                        <a href={null} onClick={this.toggleEdit}>
                                            <div className="outline-circle">
                                                <Image src={Images.EditIcRed} alt="Edit" width="25"/>
                                            </div>
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <div className="widget">
                                    <Formik
                                        ref={(ref) => this.formik = ref}
                                        enableReinitialize
                                        initialValues={{
                                            billingAddress: billAddress,
                                            billingAddress2: billAddress2,
                                            billingCity: billCity,
                                            billingState: this.state.billingState,
                                            billingZipCode: billZip,
                                            mailingAddress: mailAddress,
                                            website: website,
                                            additionalInformation: additionalInformation,
                                            mailingAddress2: mailAddress2,
                                            mailingCity: mailCity,
                                            mailingState: this.state.mailingState,
                                            mailingZipCode: mailZip,
                                            generalEmail: generalEmail,
                                            generalFirstName: generalFirstName,
                                            generalLastName: generalLastName,
                                            generalPhone: generalPhone,
                                            generalPhoneCode: generalPhoneCode ? generalPhoneCode :'+1',
                                            contact2Email: contact2Email,
                                            contact2FirstName: contact2FirstName,
                                            contact2LastName: contact2LastName,
                                            contact2Phone: contact2Phone,
                                            contact2PhoneCode: contact2PhoneCode ? contact2PhoneCode :'+1',
                                            billingFirstName: billingFirstName,
                                            billingLastName: billingLastName,
                                            billingEmail: billEmail,
                                            discount: discount,
                                            discountType: discountType,
                                            adminPhoneCode: this.state.adminPhoneCode ? this.state.adminPhoneCode : '+1',
                                            billingPhoneCode: billingPhoneCode ? billingPhoneCode :'+1',
                                            billingPhone: billingPhone,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            billingAddress: Yup.string().trim()
                                                .max(255, Messages.MAX_LENGTH_255)
                                                .required(Messages.REQUIRED_ERR),
                                            billingCity: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .required(Messages.REQUIRED_ERR),
                                            billingState: Yup.string()
                                                .required('Please select a state'),
                                            billingZipCode: Yup.number()
                                                .min(10000, 'Zip Code must be of 5 numbers.')
                                                .max(99999, 'Zip Code must be of 5 numbers.')
                                                .typeError('ZipCode must be a number')
                                                .integer("Decimals are not allowed.")
                                                .required(Messages.REQUIRED_ERR),
                                            mailingAddress: Yup.string().trim()
                                                .max(255, Messages.MAX_LENGTH_255)
                                                .required(Messages.REQUIRED_ERR),
                                            mailingCity: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .required(Messages.REQUIRED_ERR),
                                            mailingState: Yup.string()
                                                .required('Please select a state'),
                                            mailingZipCode: Yup.number()
                                                .min(10000, 'Zip Code must be of 5 numbers.')
                                                .max(99999, 'Zip Code must be of 5 numbers.')
                                                .typeError('ZipCode must be a number')
                                                .integer("Decimals are not allowed.")
                                                .required(Messages.REQUIRED_ERR),
                                            generalEmail: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .email(Messages.INVALID_EMAIL)
                                                .required(Messages.REQUIRED_ERR),
                                            generalFirstName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            generalLastName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            generalPhone: Yup.string().trim()
                                                .matches(Regex.phone, {
                                                    message: Messages.INVALID_PHONE,
                                                    excludeEmptyString: false
                                                })
                                                .max(15, Messages.INVALID_PHONE)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            generalPhoneCode: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR),
                                            contact2Email: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .email(Messages.INVALID_EMAIL)
                                                .required(Messages.REQUIRED_ERR),
                                            contact2FirstName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            contact2LastName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            contact2Phone: Yup.string().trim()
                                                .matches(Regex.phone, {
                                                    message: Messages.INVALID_PHONE,
                                                    excludeEmptyString: false
                                                })
                                                .max(15, Messages.INVALID_PHONE)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            contact2PhoneCode: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR),
                                            billingEmail: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .email(Messages.INVALID_EMAIL)
                                                .required(Messages.REQUIRED_ERR),
                                            discount: Yup.number()
                                                .min(0, 'Should not be less than 0')
                                                .max(99, 'Should not be greater than 99')
                                                .typeError('Must be a number')
                                                .required(Messages.REQUIRED_ERR),
                                            website: Yup.string().trim()
                                                .max(150, Messages.MAX_LENGTH_150)
                                                .matches(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, { message: 'Please enter valid url', excludeEmptyString: false })
                                                .required(Messages.REQUIRED_ERR).nullable(),

                                            billingFirstName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            billingLastName: Yup.string().trim()
                                                .max(100, Messages.MAX_LENGTH_100)
                                                .required(Messages.REQUIRED_ERR).nullable(),

                                            billingPhone: Yup.string().trim()
                                                .matches(Regex.phone, {
                                                    message: Messages.INVALID_PHONE,
                                                    excludeEmptyString: false
                                                })
                                                .max(15, Messages.INVALID_PHONE)
                                                .required(Messages.REQUIRED_ERR).nullable(),
                                            billingPhoneCode: Yup.string().trim()
                                                .required(Messages.REQUIRED_ERR),

                                        })}
                                        onSubmit={fields => {
                                            this.updateClubDetails(fields);
                                        }}
                                        render={({errors, status, touched, setFieldValue, setFieldTouched, values}) => (
                                            <Form className="contact-us" noValidate>
                                                <>
                                                    <div className=" widget--space">
                                                        <Col md={12}>
                                                            <div className="block-heading">Organization General
                                                                Information
                                                            </div>
                                                        </Col>
                                                        <Col md={7} lg={8}>
                                                            <div className="field form-group">
                                                                <Field name="website" type="text" id="website"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.website && touched.website ? ' is-invalid' : '')}/>
                                                                <label htmlFor="website"
                                                                       className={'form-label ' + (website ? ' label-active' : '')}>Organization
                                                                    Website</label>
                                                                <ErrorMessage name="website" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <div
                                                                className="field form-group form-group--additonal-info">
                                                                <Field component="textarea" name="additionalInformation"
                                                                       type="text" id="additionalInformation"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.additionalInformation && touched.additionalInformation ? ' is-invalid' : '')}/>
                                                                <label htmlFor="additionalInformation"
                                                                       className={'form-label ' + (additionalInformation ? ' label-active' : '')}>Additional
                                                                    Information</label>
                                                                <ErrorMessage name="additionalInformation"
                                                                              component="div"
                                                                              className="invalid-feedback"/>


                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="block-heading">Mailing Address</div>
                                                        </Col>
                                                        <Col md={7} lg={8}>
                                                            <div className="field form-group">
                                                                <Field name="mailingAddress" type="text"
                                                                       id="mailingAddress"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.mailingAddress && touched.mailingAddress ? ' is-invalid' : '')}/>
                                                                <label htmlFor="mailingAddress"
                                                                       className="form-label label-active">Mailing
                                                                    Address line 1</label>
                                                                <ErrorMessage name="mailingAddress" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>

                                                            <div className="field form-group">
                                                                <Field name="mailingAddress2" type="text"
                                                                       id="mailingAddress2"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.mailingAddress2 && touched.mailingAddress2 ? ' is-invalid' : '')}/>
                                                                <label htmlFor="mailingAddress2"
                                                                       className={'form-label ' + (mailAddress2 ? ' label-active' : '')}>Mailing
                                                                    Address line 2</label>
                                                                <ErrorMessage name="mailingAddress" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <Row>
                                                                <Col sm={12} md={6}>
                                                                    <div className="select-wrap">
                                                                        <Field component="select"
                                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                                               className={' form-control' + (errors.mailingState && touched.mailingState ? ' is-invalid' : '')}
                                                                               name="mailingState" id="mailingState"
                                                                               onBlur={() => setFieldTouched("mailingState", true)}
                                                                               onChange={(event) => this.onSelectMailChange(event, setFieldValue)}>
                                                                            <option value=''>-- Select State --</option>
                                                                            {
                                                                                Object.keys(UsStates).map((key) => {
                                                                                    return (
                                                                                        <option key={key}
                                                                                                value={UsStates[key]}>{UsStates[key]}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Field>
                                                                        <ErrorMessage name="mailingState"
                                                                                      component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12} md={6} className="sm--mr-top">
                                                                    <div className="field form-group">
                                                                        <Field name="mailingCity" type="text"
                                                                               id="mailingCity"
                                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                                               onFocus={this.inputFocus}
                                                                               onBlur={this.inputBlur}
                                                                               className={'form-control' + (errors.mailingCity && touched.mailingCity ? ' is-invalid' : '')}/>
                                                                        <label htmlFor="mailingCity"
                                                                               className="form-label label-active">City</label>
                                                                        <ErrorMessage name="mailingCity" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="field form-group">
                                                                <Field name="mailingZipCode" type="text"
                                                                       id="mailingZipCode"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.mailingZipCode && touched.mailingZipCode ? ' is-invalid' : '')}/>
                                                                <label htmlFor="mailingZipCode"
                                                                       className="form-label label-active">Zip
                                                                    Code</label>
                                                                <ErrorMessage name="mailingZipCode" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="block-heading block-heading--mt">Billing
                                                                Information
                                                            </div>
                                                        </Col>
                                                        <Col md={7} lg={8}>
                                                            {Helper.isSuperAdminLogin() &&
                                                                <>
                                                                    <div className="form-group form-group--mb">
                                                                        <label className="form-label">Discount Type</label>
                                                                        <div className="d-flex">
                                                                            <div className="radio radio-discount">
                                                                                <Field name="discount_type" type="radio"
                                                                                       id="discountTypeF"
                                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                       value="F"
                                                                                       checked={this.state.discountType === 'F'}
                                                                                       onClick={()=>this.setState({discountType: 'F'})}
                                                                                       className={'form-control' + (errors.discountType && touched.discountType ? ' is-invalid' : '')}
                                                                                />
                                                                                    <label htmlFor="discountTypeF">Fixed($)</label>
                                                                            </div>
                                                                            <div className="radio radio-discount">
                                                                                <Field name="discount_type" type="radio"
                                                                                       id="discountTypeP"
                                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                       value="P"
                                                                                       checked={this.state.discountType === 'P'}
                                                                                       onClick={()=>this.setState({discountType: 'P'})}
                                                                                       className={'form-control' + (errors.discountType && touched.discountType ? ' is-invalid' : '')}
                                                                                />
                                                                                    <label
                                                                                        htmlFor="discountTypeP">Percentage</label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="discountType" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                    <div className="field form-group">
                                                                        <Field name="discount" maxLength="5" type="text"
                                                                               id="discount"
                                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                                               onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                               className={'form-control' + (errors.discount && touched.discount ? ' is-invalid' : '')}/>
                                                                        <label htmlFor="discount" className="form-label label-active">Discount Per User</label>
                                                                        <ErrorMessage name="discount" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="field form-group">
                                                                <Field name="billingAddress" type="text"
                                                                       id="billingAddress"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.billingAddress && touched.billingAddress ? ' is-invalid' : '')}/>
                                                                <label htmlFor="billingAddress"
                                                                       className="form-label label-active">Billing
                                                                    Address line 1</label>
                                                                <ErrorMessage name="billingAddress" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <div className="field form-group">
                                                                <Field name="billingAddress2" type="text"
                                                                       id="billingAddress2"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.billingAddress2 && touched.billingAddress2 ? ' is-invalid' : '')}/>
                                                                <label htmlFor="billingAddress2"
                                                                       className={'form-label ' + (billAddress2 ? ' label-active' : '')}>Billing
                                                                    Address line 2</label>
                                                                <ErrorMessage name="billingAddress" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <Row>
                                                                <Col sm={12} md={6}>
                                                                    <div className="select-wrap">
                                                                        <Field component="select"
                                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                                               className={' form-control' + (errors.billingState && touched.billingState ? ' is-invalid' : '')}
                                                                               name="billingState" id="billingState"
                                                                               onBlur={() => setFieldTouched("billingState", true)}
                                                                               onChange={(event) => this.onSelectBillChange(event, setFieldValue)}>
                                                                            <option value=''>-- Select State --</option>
                                                                            {
                                                                                Object.keys(UsStates).map((key) => {
                                                                                    return (
                                                                                        <option key={key}
                                                                                                value={UsStates[key]}>{UsStates[key]}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Field>
                                                                        <ErrorMessage name="billingState"
                                                                                      component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12} md={6} className="sm--mr-top">
                                                                    <div className="field form-group">
                                                                        <Field name="billingCity" type="text"
                                                                               id="billingCity"
                                                                               disabled={(this.state.disabled) ? "disabled" : ""}
                                                                               onFocus={this.inputFocus}
                                                                               onBlur={this.inputBlur}
                                                                               className={'form-control' + (errors.billingCity && touched.billingCity ? ' is-invalid' : '')}/>
                                                                        <label htmlFor="billingCity"
                                                                               className="form-label label-active">City</label>
                                                                        <ErrorMessage name="billingCity" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="field form-group">
                                                                <Field name="billingZipCode" type="text"
                                                                       id="billingZipCode"
                                                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                                                       onFocus={this.inputFocus} onBlur={this.inputBlur}
                                                                       className={'form-control' + (errors.billingZipCode && touched.billingZipCode ? ' is-invalid' : '')}/>
                                                                <label htmlFor="billingZipCode"
                                                                       className="form-label label-active">Zip
                                                                    Code</label>
                                                                <ErrorMessage name="billingZipCode" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>

                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="block-heading block-heading--mt">Point of
                                                                Contact
                                                            </div>
                                                            <Col md={12}>
                                                                <div className="block-heading block-heading--mt">General
                                                                    Information
                                                                </div>
                                                            </Col>
                                                            <Col md={11} lg={11}>
                                                                <Row>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="generalFirstName" type="text"
                                                                                   id="generalFirstName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.generalFirstName && touched.generalFirstName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="generalFirstName"
                                                                                   className={'form-label ' + (generalFirstName ? ' label-active' : '')}>First
                                                                                Name</label>
                                                                            <ErrorMessage name="generalFirstName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="generalLastName" type="text"
                                                                                   id="generalLastName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.generalLastName && touched.generalLastName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="generalLastName"
                                                                                   className={'form-label ' + (generalLastName ? ' label-active' : '')}>Last
                                                                                Name</label>
                                                                            <ErrorMessage name="generalLastName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="generalEmail" type="text"
                                                                                   id="generalEmail"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.generalEmail && touched.generalEmail ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="generalEmail"
                                                                                   className="form-label label-active">Email</label>
                                                                            <ErrorMessage name="generalEmail"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <PhoneInput
                                                                                parent={this}
                                                                                codeField={'generalPhoneCode'}
                                                                                defaultCode={generalPhoneCode ? generalPhoneCode : '+1'}
                                                                                phoneField={'generalPhone'}
                                                                                fieldLabel={'Phone'}
                                                                                errors={errors}
                                                                                touched={touched}
                                                                                serverValid={false}
                                                                                setFieldValue={setFieldValue}
                                                                                values={values}
                                                                                disabled={this.state.disabled}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="block-heading block-heading--mt">Secondary Point of Contact</div>
                                                            </Col>
                                                            <Col md={11} lg={11}>
                                                                <Row>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="contact2FirstName" type="text"
                                                                                   id="contact2FirstName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.contact2FirstName && touched.contact2FirstName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="contact2FirstName"
                                                                                   className={'form-label ' + (contact2FirstName ? ' label-active' : '')}>First
                                                                                Name</label>
                                                                            <ErrorMessage name="contact2FirstName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="contact2LastName" type="text"
                                                                                   id="contact2LastName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.contact2LastName && touched.contact2LastName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="contact2LastName"
                                                                                   className={'form-label ' + (contact2LastName ? ' label-active' : '')}>Last
                                                                                Name</label>
                                                                            <ErrorMessage name="contact2LastName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="contact2Email" type="text"
                                                                                   id="contact2Email"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.contact2Email && touched.contact2Email ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="contact2Email"
                                                                                   className="form-label label-active">Email</label>
                                                                            <ErrorMessage name="contact2Email"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <PhoneInput
                                                                                parent={this}
                                                                                codeField={'contact2PhoneCode'}
                                                                                defaultCode={contact2PhoneCode ? contact2PhoneCode : '+1'}
                                                                                phoneField={'contact2Phone'}
                                                                                fieldLabel={'Phone'}
                                                                                errors={errors}
                                                                                touched={touched}
                                                                                serverValid={false}
                                                                                setFieldValue={setFieldValue}
                                                                                values={values}
                                                                                disabled={this.state.disabled}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="block-heading block-heading--mt">Billing
                                                                    Information
                                                                </div>
                                                            </Col>
                                                            <Col md={11} lg={11}>
                                                                <Row>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="billingFirstName" type="text"
                                                                                   id="billingFirstName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.billingFirstName && touched.billingFirstName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="billingFirstName"
                                                                                   className={'form-label ' + (billingFirstName ? ' label-active' : '')}>First
                                                                                Name</label>
                                                                            <ErrorMessage name="billingFirstName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="billingLastName" type="text"
                                                                                   id="billingLastName"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.billingLastName && touched.billingLastName ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="billingLastName"
                                                                                   className={'form-label ' + (billingLastName ? ' label-active' : '')}>Last
                                                                                Name</label>
                                                                            <ErrorMessage name="billingLastName"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <Field name="billingEmail" type="text"
                                                                                   id="billingEmail"
                                                                                   disabled={(this.state.disabled) ? "disabled" : ""}
                                                                                   onFocus={this.inputFocus}
                                                                                   onBlur={this.inputBlur}
                                                                                   className={'form-control' + (errors.billingEmail && touched.billingEmail ? ' is-invalid' : '')}/>
                                                                            <label htmlFor="billingEmail"
                                                                                   className="form-label label-active">Email</label>
                                                                            <ErrorMessage name="billingEmail"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} sm={12}>
                                                                        <div className="field form-group">
                                                                            <PhoneInput
                                                                                parent={this}
                                                                                codeField={'billingPhoneCode'}
                                                                                defaultCode={billingPhoneCode ? billingPhoneCode : '+1'}
                                                                                phoneField={'billingPhone'}
                                                                                fieldLabel={'Phone'}
                                                                                errors={errors}
                                                                                touched={touched}
                                                                                serverValid={false}
                                                                                setFieldValue={setFieldValue}
                                                                                values={values}
                                                                                disabled={this.state.disabled}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Col>
                                                    </div>
                                                    <div style={this.state.editClub}
                                                         className="widget__footer justify-content-between">
                                                        <Button disabled={this.state.updateClubDetailsLoader}
                                                                className="btn-cancel ripple"
                                                                onClick={this.refreshPage}>
                                                            Cancel</Button>
                                                        <Button type="submit"
                                                                disabled={this.state.updateClubDetailsLoader}
                                                                className={`ripple btn-medium d-inline-flex align-items-center ${this.state.updateClubDetailsLoader ? 'btn-loader' : ''}`}>
                                                            <div className="btn__icon btn__icon--red"><Image
                                                                src={Images.CompleteIc} alt="Update"/></div>
                                                            Update <span style={{'display': 'none'}}
                                                                         className="spinner-border spinner-border-sm"
                                                                         role="status" aria-hidden="true"></span>
                                                        </Button>
                                                    </div>
                                                </>
                                            </Form>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                }
                <SuccessModal
                    successConfirmShow={this.state.successConfirmShow}
                    onHide={this.onSuccessCancel}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successText}
                    successButtonText={"OK"} />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }} />
            </>
        )
    }
}
export { EditClub };
