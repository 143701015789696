/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Form, Row, Col, Image, Breadcrumb, Button,OverlayTrigger,Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

/**
 * Start - Lodash.debounce
 * Name and Version: lodash.debounce, 4.0.8
 * Download link: https://www.npmjs.com/package/lodash.debounce
 */
import debounce from 'lodash.debounce';
/* End - Lodash.debounce */

import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import './team.scss';
import { ContactList } from '../../components/contact/contact-list';
import * as Storage from '../../components/common/storage';
import * as Enum from '../../components/common/enum';
import AddInstructorToTeam from './add-instructor-to-team';
import { NoRecord } from '../../components/no-record/no-record';
import { ErrorModal, ErrorAccessDeniedModal } from '../../components/modal/modalbox';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import SingleInvite from '../contacts/single-invite';

class AddInstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: { name: '', description: '', location: '' },
            show: false,
            modalVisibility: false,
            showError: false,
            errorMessage: null,
            showAccessDeniedError : null,
            accessMessage : null,
            teamId: props.match.params.team_id,
            userInfo: Storage.Get('user'),
            cancelToken: axios.CancelToken,
            cancel: undefined,
            filterCriteria: {
                searchKeyword: '',
                teams: [],
                status: 1,
                role: Enum.UserRoles.COACH,
                page: 1,
                pageSize: 10
            },
            checkedItems: new Map(),
            selectedMember: new Map(),
            recordLoaded: false,
            totalRecords: null,
            isActive: false
        };
        /* Using Debounce to prevent repeated events
         Windows scroll event debounce */
        this.doDebouncedTableScroll = debounce(this.onTableScroll, 100);
        /*  Filter Criteria Debounce */
        let filterDelay = 500;
        this.doDebouncedSearchKeywordChange = debounce(this.onSearchKeywordChange, filterDelay);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.doDebouncedTableScroll);
        this.getTeamInfo();
        this.getContactList();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doDebouncedTableScroll);
    }

    onTableScroll = () => {
        // Don't proceed if:
        // old records are already loading
        // there's nothing left to load
        if (!this.state.recordLoaded || this.state.totalRecords === this.state.filteredContacts.length) return;

        // Get document.documentElement.scrollTop for all browsers
        let docTop = typeof window.pageYOffset != 'undefined' ? window.pageYOffset : document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ? document.body.scrollTop : 0;

        // // Reduce 20percent from the offsetHeight
        let reduceOffset = document.documentElement.offsetHeight - (20 / 100) * document.documentElement.offsetHeight;

        // Check if scroll has hit bottom
        if (window.innerHeight + docTop >= reduceOffset) {
            let newFilterCriteria = this.state.filterCriteria;
            newFilterCriteria.page = newFilterCriteria.page + 1;
            this.setState({ filterCriteria: newFilterCriteria });
            this.getContactList();
        }
    }

    getTeamInfo() {
        Helper.axiosInstance().get(`team/detail/${this.state.teamId}`)
            .then((response) => {
                if (response) {
                    this.setState({ selectedTeam: response.data.team });
                }
            })
            .catch((error) => {
                if (error.response.status === 404 && error.response.data !== undefined) {
                    this.setState({ accessMessage: error.response.data.error, showAccessDeniedError: true });
                } else {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                }
            });
    }

    deleteSelectedMember = (id) => {
        let selectedMember = this.state.selectedMember,
            checkedItems = this.state.checkedItems;
        selectedMember.delete(id);
        checkedItems.delete(id);
        this.setState({
            selectedMember,
            checkedItems
        });
        if (selectedMember.size === 0) {
            //this.toggleMemberModal(false);
            this.setState({ show: false });
        }
    }

    getContactList = () => {
        this.setState({ recordLoaded: false });
        const selectedClubId = this.state.userInfo.selectedClubId,
            filterCriteria = this.state.filterCriteria;
        if (selectedClubId != null) {
            let cancel = this.state.cancel;
            let cancelToken = this.state.cancelToken;
            if (this.state.cancel !== undefined) {
                cancel();
            }
            let me = this;
            Helper.axiosInstance()
                .post(`contacts`
                    , JSON.stringify({
                        club_id: selectedClubId,
                        keyword: filterCriteria.searchKeyword,
                        team_id: [],
                        exclude_team_id: this.state.teamId,
                        status: filterCriteria.status,
                        role_id: filterCriteria.role,
                        page_size: filterCriteria.pageSize,
                        page: filterCriteria.page
                    }), {
                    cancelToken: new cancelToken(function executor(c) {
                        me.setState({ cancel: c });
                    })
                })
                .then((response) => {
                    if (response.data.success === true) {
                        if (this.state.filterCriteria.page === 1) {
                            this.setState({
                                filteredContacts: response.data.members
                            });
                        }
                        else {
                            let existingRecords = this.state.filteredContacts;
                            this.setState({ filteredContacts: existingRecords.concat(response.data.members) });
                        }
                        this.setState({ recordLoaded: true, totalRecords: response.data.count, });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, });
                });
        }
    }

    toggleModal = (visible) => {
        this.setState({ modalVisibility: visible });
    }

    toggleMemberModal = (visible) => {
        const {selectedMember} = this.state;
        if(selectedMember.size > 0){
            this.setState({ show: visible });
        } else {
            this.setState({  errorMessage: Enum.memberSelectionWarning, showError: true});
        }
        //this.setState({ show: visible });
    }

    onSearchKeywordChange = (target) => {
        let newFilterCriteria = this.state.filterCriteria;
        newFilterCriteria.searchKeyword = target.value.toLowerCase();
        this.reloadContacts(newFilterCriteria);
    }

    reloadContacts = (newFilterCriteria) => {
        newFilterCriteria.page = 1;
        this.setState({ filterCriteria: newFilterCriteria, filteredContacts: [], recordLoaded: false },
            () => {
                this.getContactList();
            });
    }

    showBulkUpload = () => {
        this.props.history.push('/bulk-upload');
    }

    handleCheckBoxChange = (item) => {
        let memberDetail = Helper.selectMember(item, this.state.selectedMember, this.state.checkedItems)
        this.setState({ memberDetail });
    }

    render() {
        const { show, selectedMember, totalRecords, modalVisibility, selectedTeam, teamId, groupName } = this.state;
        return (
            <>
                <div className="rgt-content">
                    <Row className="row--space">
                        <Col sm={12}>
                            <h1>{Helper.teamName(selectedTeam)}</h1>
                            <Breadcrumb>                              
                                <li className="breadcrumb-item">
                                    <Link to={`/team-details/${teamId}`}>{Helper.teamName(selectedTeam)}</Link>
                                </li>
                                <Breadcrumb.Item active>Add Instructor</Breadcrumb.Item>
                            </Breadcrumb>
                            {
                                selectedMember.size > 0 &&
                            <label className="selected-count">{selectedMember.size} {selectedMember.size == 1 ? "contact selected" : "contacts selected"}</label>
                            }
                        </Col>
                        <Col md={12} lg={3} className="md--full-width order-md-3 order-lg-2">
                            <div className="info-box mt-4">
                                <div className="info-box__header">
                                    <Image src={selectedTeam.photo} alt={groupName} />
                                </div>
                                <div className="info-box__content">
                                    <h4>
                                        <span>{Helper.teamName(selectedTeam)}</span>
                                    </h4>
                                    <p>{selectedTeam.description}</p>
                                </div>
                            </div>
                            <div className="info-box info-box--space">
                                <div className="info-box__content d-flex justify-content-between">
                                    <div className="icon-wrap">
                                        <Image src={Images.DashboardTotalMember} alt="Members" width="38"/>
                                    </div>
                                    <div className="info-box__content__desc">
                                        <p>Total Contacts</p>
                                        <div className="content__desc__heading">{selectedTeam.count}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={9} className="md--full-width order-md-2 order-lg-3">
                            <div className="block-pos block-pos--position block-pos--position-top">
                                {selectedMember.size > 0 &&
                                <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Add Instructor
                                                    </Tooltip>}>
                                    <Link onClick={() => this.toggleMemberModal(!show)}>
                                        <div className="outline-circle">
                                            <Image src={Images.addInstructor} alt="Add member" width="30"/>
                                        </div>
                                    </Link>
                                </OverlayTrigger>
                                }
                                {selectedMember.size > 0 &&
                                <OverlayTrigger placement="auto"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Cancel
                                                    </Tooltip>}>
                                    <Link to={`/team-details/${teamId}`} >
                                        <div className="outline-circle">
                                            <Image src={Images.Cancel} alt="Cancel" width="25"/>
                                        </div>
                                    </Link>
                                </OverlayTrigger>
                                }
                            </div>
                            <div className="widget">
                                <div className="widget__heading">
                                    <h3>Add Instructor</h3>
                                </div>
                                <Row>
                                    <Col sm={7}>
                                        <div className="subheading-group">
                                            <div className="subheading">Contacts <span>{totalRecords} Total</span></div>
                                            <p className="notes"><strong>Note: </strong>Please select contacts using checkboxes to add Instructor.</p>
                                            <p>Not in the contact list? <span className="subheading__link" onClick={() => this.toggleModal(!modalVisibility)}>Invite a Instructor</span></p>
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <div className="tab-search tab-search--big tab-search--widget pt-0">
                                            <Form inline>
                                                <input type="text" name="focus" maxLength="100" required placeholder="Search" className="form-control tab-search__box" onChange={(e) => this.doDebouncedSearchKeywordChange(e.target)} />
                                                <button className="close-icon" type="reset" onClick={(e) => this.doDebouncedSearchKeywordChange(e.target)}></button>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>

                                <ContactList data={this.state.filteredContacts} checkedItems={this.state.checkedItems}
                                    screen={"Add Member"}
                                    fiterByStatus={"1"}
                                    onCheckboxChange={(item) => this.handleCheckBoxChange(item)}
                                />
                                {!this.state.recordLoaded && <SmallLoader />}
                                {(this.state.recordLoaded && this.state.filteredContacts.length === 0) && <NoRecord />}
                                {
                                    // selectedMember.size > 0 &&
                                    // <div className="fixed-actionbar wgt-btnwrap">
                                    //     <span className="fixed-actionbar__count">{selectedMember.size} {selectedMember.size == 1 ? "member selected" : "members selected"}</span>
                                    //     <div>
                                    //         <Button className="ripple btn btn-ternary d-inline-flex btn-rounded align-items-center" onClick={() => this.toggleMemberModal(!show)} >
                                    //             <div className="btn__icon btn__icon--sea-green"><Image src={Images.CompleteIc} alt="Complete" /> </div>
                                    //             Add Instructor
                                    //         </Button>
                                    //         <Link to={`/team-details/${teamId}`} className="ripple btn btn-rounded btn-cancel d-inline-flex align-items-center justify-content-center">Cancel</Link>
                                    //     </div>
                                    // </div>
                                }                                 
                            </div>
                        </Col>
                    </Row>
                </div>
                <SingleInvite
                    modalVisibility={modalVisibility}
                    toggleModal={this.toggleModal}
                    history={this.props.history}
                    defaultTab={'invitecoach'}
                    type={'team'}
                    record_id={this.state.teamId}
                />
                <AddInstructorToTeam
                    selectedMember={selectedMember}
                    memberModal={{ show, team_id: this.state.teamId }}
                    toggleMemberModal={this.toggleMemberModal}
                    deleteSelectedMember={this.deleteSelectedMember}
                    redirect={this.props.history}
                    type={'team'}
                    record_id={this.state.teamId}
                />
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ showError: false }) }}
                />
                <ErrorAccessDeniedModal
                    show={this.state.showAccessDeniedError}
                    message={this.state.accessMessage}
                    currentProps={this.props}
                />
            </>
        )
    }
}
export { AddInstructor };