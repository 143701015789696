/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Button, Image, Form, Modal} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - axios
 * Name and Version: axios, 0.19.0
 * Download link: https://www.npmjs.com/package/axios
 */
import axios from 'axios';
/* End - axios */

import './login.scss';
import * as Images from '../../components/common/images';
import {FooterLogin} from '../../components/footer/footer-login';
import {initCometChat} from '../../components/common/functions';
import {ModalBox} from '../../components/modal/modalbox';

class Login extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            validated: false,
            form: {},
            message: '',
            messageType: '',
            showModal: false,
            agreeErr: false,
            agreed: false,
            showLoader: false
        };
        this.redirected = this.props.match.params.redirected ?? '';
        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    handleSubmit(event) {
        let me = this;
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (this.state.messageType !== "") {
            this.setState({messageType: "", message: ""});
        }

        if (form.checkValidity() === true && this.state.agreed) {
            me.setState({showLoader: true});
            axios.post(process.env.REACT_APP_API_URL + 'login', this.state.form)
                .then(function (response) {
                    if (response.data.success === true) {
                        const userData = response.data.data;
                        me.props.handleProfilePic(userData.photo);
                        me.props.handleClubPic(userData.club_photo[userData.selectedClubId]);
                        initCometChat(userData, me);
                    }
                }, error => {
                    me.setState({showLoader: false});
                    if (error.response.status === 401) {
                        me.setState({
                            messageType: 'danger',
                            message: error.response.data.message
                        });
                    }
                })
                .catch(function (error) {
                });
        } else { //If there is any error, focus on the error field.
            setTimeout(function () {
                if (document.querySelector('.was-validated .form-control:invalid')) {
                    document.querySelector('.was-validated .form-control:invalid').focus();
                }
            }, 10);
            this.setState({agreeErr: !this.state.agreed})
        }
        this.setState({validated: true});
    }

    handleChange(e) {
        let form = Object.assign({}, this.state.form, {[e.target.name]: e.target.value});
        this.setState({form});
    }

    inputFocus(event) {
        event.target.nextElementSibling.classList.add("label-active");
    }

    inputBlur(event) {
        if (event.target.value.length === 0) {
            event.target.nextElementSibling.classList.remove("label-active");
        }
    }

    render() {
        const {validated, showLoader, message, messageType, showModal} = this.state;
        const identifiableModalHeader = (
                <>
                    <Modal.Title className="Identifiable-title">Personally Identifiable Information</Modal.Title>
                </>
            ),
            identifiableModalContent = (
                <>
                    <div className="login-popup-wrap">
                        <h1>Collection of Personally Identifiable Information and other Information</h1>

                        <p>When you use SafeComm’s website, we collect personal identifiable information from you. This
                            information is required to facilitate user accounts and communication aspects of the
                            website. The personal identifiable information we collect are:</p>

                        <ul>
                            <li>Your Email address</li>
                            <li>Name</li>
                            <li>Files uploaded by you</li>
                            <li>Your user account with SafeComm</li>
                            <li>Information about your device</li>
                            <li>Location</li>
                            <li>Contacts</li>
                        </ul>

                        <p>The aforementioned permissions are required for the website to function properly. </p>

                    </div>
                </>
            )
        return (
            <>
                <div className="loginboard d-flex full-vh-height">
                    <div className="loginborad__form d-flex">
                        <div className="form__wrap align-self-center">
                            <div className="form__wrap-img">
                                <Image src={Images.SafecommLoginLogo} alt="Safecomm" width="245"/>
                            </div>
                            <h2 className="heading mb-5">Log in to your account</h2>

                            {message ? <div className={'mt-4 alert alert-' + messageType}>{message}</div> : ''}
                            <Form noValidate validated={validated} onSubmit={e => this.handleSubmit(e)}>
                                <Form.Group className="field" controlId="username">
                                    <Form.Control type="text" required name="username" maxLength="50" autoComplete="off"
                                                  onChange={e => this.handleChange(e)} onFocus={this.inputFocus}
                                                  onBlur={this.inputBlur}/>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your username.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="field" controlId="password">
                                    <Form.Control type="password" name="password" maxLength="50"
                                                  autoComplete="new-password" required
                                                  onChange={e => this.handleChange(e)} onFocus={this.inputFocus}
                                                  onBlur={this.inputBlur}/>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="mb-2">
                                    <div className="checkbox-wrap">
                                        <input type="checkbox" id="remember"/>
                                        <label htmlFor="remember">Remember me</label>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="checkbox-wrap">
                                        <input type="checkbox" id="identifiable-info"
                                               onChange={(e) => this.setState({
                                                   agreed: e.target.checked,
                                                   agreeErr: !e.target.checked
                                               })}/>
                                        <label htmlFor="identifiable-info">I have agreed to share my <span to=""
                                                                                                           onClick={() => this.setState({showModal: true})}
                                                                                                           className="link-btn">personally identifiable information</span></label>
                                        {
                                            this.state.agreeErr ?
                                                <div className="error">Please accept sharing of your personally
                                                    identifiable information</div> : null
                                        }
                                    </div>
                                </div>
                                <Button type="Submit"
                                        className={"btn btn-primary w-100 " + (showLoader ? "btn-loader" : "")}>
                                    Sign in <span style={{'display': 'none'}}
                                                  className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span></Button>
                                <div className="d-flex loginboard__links">
                                    <Link to="/password/forgot" className="link-btn mt-4">Forgot password?</Link>
                                    <Link to="/username/forgot" className="link-btn mt-4">Forgot username?</Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <ModalBox
                    size="lg"
                    show={showModal}
                    onHide={() => this.setState({showModal: false})}
                    animation={true}
                    modalheader={identifiableModalHeader}
                    modalcontent={identifiableModalContent}
                />
                <FooterLogin/>
            </>
        )
    }
}

export {Login};