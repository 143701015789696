/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Button, Modal } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form as FormValid, ErrorMessage } from 'formik';
/* End - Formik */

import { ModalBox, SuccessModal, ErrorModal } from '../modal/modalbox';
import * as Storage from './storage';
import * as Images from './images';
import * as Helper from './functions';
import { Messages } from './message';

export default class BroadcastMesaage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            serverSuccess: '',
            serverError: '',
            formProcessing: false,
            serverValid: {
                message: '',
            },
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    resetGroupModal = () => {
        this.setState({ serverSuccess: '', message: '', formProcessing: false });
        this.props.toggleBroadcastModal(!this.props.broadcastModal, this.props.mainObj);
    }

    handleSuccessClose = () => {
        this.setState({ serverSuccess: '', formProcessing: false});
        this.props.toggleBroadcastModal(!this.props.broadcastModal, this.props.mainObj);
        if(typeof this.props.deselectAll != 'undefined'){
            this.props.deselectAll();
        }
    }

    handleErrorClose = () => {
        window.location.reload();
    }

    broadcastMessage = (fields, selectedMembers) => {
        if (this._isMounted) {
            const message = fields.message,
                me = this;
            this.setState({ formProcessing: true });
            let data = {
                club_id: Storage.Get('user').selectedClubId,
                message: message
            };

            if(selectedMembers === 'all' || selectedMembers === 'admins'){
                data.send_to = selectedMembers;
            } else {
                let memberIds = [];
                for (let val of selectedMembers.values()) {
                    if(me.props.type === 'contact'){
                        memberIds.push(val.userId);
                    } else if(me.props.type === 'team' || me.props.type === 'group' || me.props.type === 'individual_group' || me.props.type === 'team_chat'){
                        memberIds.push(val.groupId);
                    }
                }
                
                if(me.props.type === 'team' || me.props.type === 'team_chat'){
                    data.team_id = memberIds;
                } else if(me.props.type === 'group'){
                    data.group_id = memberIds;
                } else if(me.props.type === 'individual_group'){
                    data.individual_group_id = memberIds;
                } else if(me.props.type === 'contact'){
                    data.member_id = memberIds;
                }
            }
            const url = selectedMembers === 'admins' ? `broadcast/all-admins` : `broadcast/save-message`;

            Helper.axiosInstance().post(url, data)
                .then((response) => {
                    // If server response message same as Data Matched
                    if (response.data.success) {
                        me.setState({ serverSuccess: response.data.message, formProcessing: false });
                    } else {
                        const getErrors = response.data.error;
                        let serverValid = me.state.serverValid;
                        // eslint-disable-next-line
                        if(getErrors.hasOwnProperty("message")){
                            for (let errorIndex in getErrors) {
                                if (getErrors[errorIndex][0] !== "") {
                                    serverValid[errorIndex] = getErrors[errorIndex][0];
                                }
                            }
                        } else if(getErrors.hasOwnProperty("team_id") ||  getErrors.hasOwnProperty("group_id") || getErrors.hasOwnProperty("member_id")){
                            serverValid['message'] = Messages.NO_MEMBER_SELECTED;
                        }
                        me.setState({ serverValid, formProcessing: false });
                    }
                })
                .catch((error) => {
                    me.setState({ serverError: error, formProcessing: false });
                });

                if(typeof selectedMembers != 'undefined' && selectedMembers == 'all'){
                    this.props.mainObj.setState({ loading: false});
                }
        }
    }
    

    render() {
        const { selectedMembersCount, selectedMembers, broadcastModal } = this.props;
        const { formProcessing, serverSuccess, serverError, serverValid} = this.state;
        const handleClose = () => this.resetGroupModal(),
            groupModalHeader = (
                <>
                    <Modal.Title>Announcement</Modal.Title>
                </>
            ),
            groupModalContent = (
                <Formik
                    ref={(ref) => this.formik = ref}
                    enableReinitialize
                    initialValues={{
                        message: '',
                    }}
                    validationSchema={Yup.object().shape({
                        message: Yup.string().trim()
                            .required('Message is required')
                            .max(1000, 'Message is Too Long!')
                            .matches(/^[a-zA-Z\d\-_.,’!@#$%^&*()+={}|[\]:;"'\/\\<>?\s~`]+$/, { message: 'Some of the special characters are not allowed. You can use only these characters ( - _ . , ’ ! @ # $ % ^ & * ( ) + = { } | [ ] : ; " \' / < > ? ~ ` ).'}),
                    })}
                    onSubmit={fields => {
                        this.broadcastMessage(fields, selectedMembers);
                    }}
                    render={({ errors, status, touched }) => (
                        <FormValid noValidate>
                            <Modal.Body className="modal-body--min-height">
                                <div className="row-count">
                                    <label>Total selected members:</label>
                                    <span className="row-count__value">{selectedMembersCount}</span>
                                </div>

                                <div className="form-group field--text-area form-group--error-msg">
                                    <label htmlFor="message" className="form-label">Message {selectedMembers === 'admins' ? 'to Org Admins/Sub-admins' : ''}</label>
                                    <Field component="textarea" name="message" id="message" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="1000" className={'form-control textarea--min-height' + ( (errors.message && touched.message) || serverValid.message !== '' ? ' is-invalid' : '')} placeholder="" /> 
                                    
                                    {
                                        (errors.message && touched.message) ?
                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                            :
                                            <div className="invalid-feedback">{serverValid.message}</div>
                                    }
                                </div>
                                <div className="form-group broadcast-note mb-0 mt-5">
                                    <label htmlFor="message" className="form-label mb-0">Note: The announcement is sent in the batch of 100 members per batch. If you are sending it to more than 100 users then it may take a few minutes to deliver it to all the users.</label>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="m-footer mt-0">
                                <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                                    <Button disabled={formProcessing ? true : false} variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button type="Submit" variant="secondary" disabled={formProcessing ? true : false} className={"ripple btn btn-primary btn-medium d-inline-flex align-items-center " + (formProcessing ? "btn-loader" : "")}>
                                        <div className="btn__icon btn__icon--red"><Image className="send-icon" src={Images.SendIc} alt="Send Announcement" /></div> Send Announcement
                                            <span style={{ 'display': 'none' }} className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>
                            </div>
                        </Modal.Footer>   
                    </FormValid>
                    )}
                />
            );

        return (
            <>
                {
                    /*
                    * Create Chat Group Modals
                    */
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={broadcastModal}
                            onConfirmClick={this.handleSuccessClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        serverError !== "" ?
                            <ErrorModal
                                message={Messages.SERVER_ERROR}
                                show={broadcastModal}
                                onConfirmClick={this.handleErrorClose}
                            />
                            :
                            <ModalBox
                                size="md"
                                show={broadcastModal}
                                onHide={handleClose}
                                animation={true}
                                modalheader={groupModalHeader} 
                                modalcontent={groupModalContent}
                            />
                }
            </>
        )
    }
}
