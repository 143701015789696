/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Button, Image, Form } from 'react-bootstrap';
/* End - React Bootstrap */

import './login.scss';
import * as Images from '../../components/common/images';
import {FooterLogin} from '../../components/footer/footer-login';
import * as Helper from '../../components/common/functions';

class ForgotUsername extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            validated: false,
            form:{},
            message: '',
            responseType: '',
            showLoader: false
        };
    }

    handleSubmit(event) {
        let me = this;
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        
        if (form.checkValidity() === true) {
            me.setState({message: '', showLoader: true});
            Helper.axiosInstance().post('username/forgot', this.state.form)
            .then((response) => {
                if(response.data.success === true){
                    me.setState({
                        showLoader: false,
                        messageType: 'success',
                        message: response.data.message
                    });
                    setTimeout(()=>{
                        Helper.redirectToHome(this.props);
                    }, 2000);
                } else {
                    me.setState({
                        showLoader: false,
                        messageType: 'danger',
                        message: response.data.error.email[0]
                    });
                }
            })
            .catch((error) => {
                me.setState({
                    showLoader: false,
                    messageType: 'danger',
                    message: error
                });
            });
        } else { //If there is any error, focus on the error field.
            const invalidField = document.querySelector('.was-validated .form-control:invalid');
            setTimeout(function(){
                if(invalidField){
                    invalidField.focus();
                }
            }, 10);
        }
        this.setState({ validated: true });
    }
    handleChange(e) {
        let form = Object.assign({}, this.state.form, {[e.target.name]: e.target.value});
        this.setState({form});
    }
    
    render() {
        const { validated, messageType, message, showLoader} = this.state;
        
        return (
               <>
               <div className="loginboard d-flex full-vh-height">
                   <div className="loginborad__form d-flex">
                        <div className="form__wrap align-self-center">
                            <div className="form__wrap-img">
                                <Image src={Images.SafecommLoginLogo} alt="Safecomm" width="245" onClick={() => Helper.redirectToHome(this.props)}/>
                            </div>
                            <h2 className="heading">Forgot your Username?</h2>   
                            <div className="link-block">We will happy to help you.</div>
                            {message ? <div className={'mt-4 alert alert-'+messageType}>{message}</div> : ''}
                            <Form className="contact-us" noValidate validated={validated} onSubmit={e => this.handleSubmit(e)}>
                                <Form.Group className="field" controlId="email">
                                    <Form.Control type="email" required name="email" maxLength="50" onChange={e=>this.handleChange(e)} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your email address.
                                    </Form.Control.Feedback>
                                </Form.Group> 
                                <div className="btn-container btn-container--space">                              <Button disabled={showLoader ? true : false} className="btn btn-cancel" onClick={() => this.props.history.push('/')}>
                                        Cancel
                                    </Button>
                                    <Button type="Submit" disabled={showLoader ? true : false} className={"btn btn-primary btn-medium ml-3 " + (showLoader ? "btn-loader" : "")}>
                                    Send <span style={{'display': 'none'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></Button>  
                                </div>    
                            </Form>
                        </div>
                       
                   </div>
                </div>  
                <FooterLogin />             
            </>
        )
    }
}
export {ForgotUsername};