import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class TermsandConditions extends React.Component {
    render() {
        return (
            <div className={this.props.childProps.isAuthenticated ? "rgt-content": "container"}>
                <Row className="row--space">
                    <Col sm={12}>
                        <h1 className="mb-2">TERMS OF SERVICE</h1>
                        <span className="privacy-sub--heading text-dark">Last Modified: September 11, 2020</span>
                    </Col>
                    <div className="md--full-width col-lg-12 col-md-12">
                        <div className="widget widget--privacy">
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Acceptance of these Terms of Service</div>
                                <p className="privacy__text">Thank you for signing up to use the mobile message application and other services (collectively, the
“Services”) of Safe Communications Consultants, LLC, a Delaware limited liability company (“SCC”, “we”, “us” or “our”). These Terms of Service (these “Terms”) govern your (“you”, “your” or “yours”) relationship with us together with your access to and use of the Services.</p>
                                <p className="privacy__text">These Terms form a legally binding contract between you and us. By completing the registration process
or otherwise using our Services (including, without limitation downloading our mobile message application), you represent that you have read, understand, and agree to be bound by these Terms as well as the terms of our Privacy Policy (available at www.safecommapp.com) which is incorporated and deemed a part of these Terms by this reference. If you do not agree to these Terms or the Privacy Policy, you must not download, access or use the Services.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Who Can Use the Services</div>
                                <p className="privacy__text">Our Services are generally intended for users of all ages. However, if you are under the age of 13 years
old, you may only use the Services if your parent or guardian consents to your use of the Services and agrees to these Terms on your behalf. Your parent or guardian may provide consent in accordance with the terms of our Privacy Policy. </p>
                                <p className="privacy__text">Additionally, by using the Services, you (or your parent or guardian on your behalf) promise to us that (i)
you can form a binding contract with us, (ii) you reside in the United States or any of its territories or possessions, (iii) you are not a person who is barred from receiving the Services under the laws of the United States or any other applicable jurisdiction (including, for example, that you do not appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibitions); (iv) you are not a convicted sex offender; (v) you will comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations. If you access or use the Services from outside the United States, you do so on your own initiative and are responsible for all compliance with local laws.</p>
                                <p className="privacy__text">If you are using the Services on behalf of a business or some other entity, you state that you are
authorized to grant all licenses set forth in these Terms and to agree to these Terms on behalf of the business or entity.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Rights We Grant You</div>
                                <p className="privacy__text">Subject to your compliance with these Terms and any other policies we make available to you from time
to time, including without limitation any of our community standards, we grant to you a personal, royalty free, nontransferable, nonexclusive, revocable, on-sublicensable license to: (i) access and use the Services for your non-commercial use or internal business use within the United States; and (ii) download, install and use our mobile message application on one or more mobile devices that that you own or control for your non-commercial use or internal business use within the United States. This license is for the sole purpose of letting you use and enjoy the Services in a way that these Terms and our usage policies allow.</p>

                                <p className="privacy__text">You may not sell, rent, lease, assign, distribute, copy, modify or host any part of our Services and you
may not adapt, merge, make adaptations, translations or derivative works of, disassemble, decompile, reverse compile, attempt to discover the source code or reverse engineer any part of the Services, except to the extent these restrictions are expressly prohibited by applicable law.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Rights You Give Us</div>
                                <p className="privacy__text">Some of our Services may assist you with creating, uploading, posting, sending, receiving and storing
content (“User Content”). When you do that, you keep the ownership rights you had in your User Content to begin with. You represent and warrant that you have all necessary rights to perform the foregoing activities in respect of the User Content (including in respect of User Content that contains personal information), having provided all notices and disclosures, obtained all applicable consents and permissions, or otherwise have all authority, in each case as required by applicable laws, to enable us to perform the Services. Additionally, you grant us a nonexclusive, royalty free and fully paid, irrevocable, worldwide license, with the right to grant sublicenses, to (i) reproduce, distribute, host, display, and otherwise use the User Content for the purpose of operating, developing, providing and improving the
Services, including publishing, distributing and promoting your User Content and for researching and developing new products and services, (ii) to generate anonymized or deidentified information (“Aggregated Statistical Information”), as well as (iii) use your User Content in any manner as described in our Privacy Policy.</p>

                                <p className="privacy__text">You are always responsible for the User Content that you send through the Services, including for backing up of such content. Although we reserve the right to review or remove any and all User Content that appears on the Services, we do not and are not obligated to not review all of it.</p>
                                <p className="privacy__text">The Services may contain advertisements. As a condition of your access or use of the Services, you agree that SCC and our affiliates and third party partners may place advertising on the Services.</p>
                                <p className="privacy__text">We are always happy to hear from you but if you volunteer any feedback or suggestions about the Services. You agree that you will not submit to us any feedback or suggestions that you consider to be confidential or proprietary, and you further agree that we can use your feedback and suggestions in any manner we deem is appropriate without compensating you and without any restriction or obligation to you.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">The Content of Others</div>
                                <p className="privacy__text">Much of the content on our Services is produced by users, publishers, and other third parties. Whether
                                    that content is posted publicly or sent privately, the content is the sole responsibility of the person or organization that submitted it. Although we reserve the right to review or remove any and all content that appears on the Services, we do not and are not obligated to not review all of it. Accordingly, we do not and will not take responsibility for any content that others provide through the Services. Additionally, we cannot guarantee that our users’ use of our Services will always conform to our Terms.
                                </p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Automatic Downloads</div>
                                <p className="privacy__text">You agree that any Services that we provide you, including our message application, may automatically
download and install upgrades, updates or other new features and regularly send log information to our servers. You may be able to adjust these automatic downloads through your mobile device settings. These updates and upgrades are designed to improve and enhance our Services and can include bug fixes, enhancements and new modules. You consent to the installation of such application, including updates and upgrades (and authorize us to deliver such related software to you) as part of your use of our Services. You may withdraw consent by ceasing to use the Services.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Privacy</div>
                                <p className="privacy__text">Your privacy matters to us. You can learn more about how we handle your information when you use our
Services by reading our Privacy Policy. We encourage you to read it carefully because by using our Services you agree that we can collect, use and share your information in the ways described in that Privacy Policy.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Safety and Respecting the Rights of Others</div>
                                <p className="privacy__text">You may not use the Services, or enable anyone else to use the Services, in a manner that:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li>violates or infringes any SCC or other third party rights, including without limitation any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</li>
                                        <li>is false, intentionally misleading, illegal or promotes an illegal activity or that impersonates any person or entity; </li>
                                        <li>is bullying, harassing, abusive, threatening, vulgar, obscene, offensive, defamatory, or that contains pornography, nudity, or graphic or gratuitous violence, or that promotes violence, racism, discrimination, bigotry, hatred, or physical harm of any kind against any group or individual;</li>                                        
                                        <li>spams or solicits users to purchase anything;</li>
                                        <li>requests any form of identification or illegal content from users; or</li>
                                        <li>interferes with the positive experience of other users.</li>
                                    </ul>
                                </div>
                                <p className="privacy__text">You further agree not to use the Services to:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li>use the Services, any tools provided by the Services, or any content on the Services for any commercial purposes without our consent;</li>
                                        <li>use the Services for any purpose that is illegal or prohibited in these Terms;</li>
                                        <li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms;</li>
                                        <li>upload or distribute any computer viruses, worms, malicious code, or any software intended to damage or alter a computer system or data;</li>
                                        <li>collect information or data regarding other users, including email addresses or usernames, without their consent;</li>
                                        <li>disable, overly burden, impair, or otherwise interfere with servers or networks connected to the Services;</li>
                                        <li>attempt to gain unauthorized access to the Services or servers or networks connected to the Services;</li>
                                        <li>interfere with another user’s use and enjoyment of the Services;</li>
                                        <li>You will not use or attempt to use another user’s account, username, or password without their permission;</li>
                                        <li>You will not attempt to circumvent any content-filtering techniques we employ or attempt to access areas or features of the Services that you are not authorized to access;</li>
                                        <li>You will not probe, scan, or test the vulnerability of our Services or any system or network; and</li>
                                        <li>You will not encourage or promote any activity that violates these Terms.</li>
                                    </ul>
                                </div>
                                <p className="privacy__text">We also care about your safety while using our Services. Do not use our Services in a way that would distract you from obeying traffic or safety laws. For example, never use the Services while driving.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Ownership</div>
                                <p className="privacy__text">We, or our affiliates and licensors as applicable, retain all ownership and intellectual property rights in
and to (i) the Services, (ii) all Aggregated Statistical Information, and (iii) all modifications, improvements, customizations, updates, enhancements, derivative works, translations and adaptations to the foregoing.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Copyright</div>
                                <p className="privacy__text">Our policy allows copyright owners to request removal of any infringing materials and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is using the Services to unlawfully infringe copyrights in a work, and want to have the material removed, please provide the following information in writing to our Copyright Agent: </p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li>your physical or electronic signature;</li>
                                        <li>identification of the copyrighted work that you claim to have been infringed;</li>
                                        <li>identification of the material on our Services that you claim is infringing and that you request us to remove;</li>
                                        <li>sufficient information to permit us to locate such material;</li>
                                        <li>your address, telephone number, and e-mail address;</li>
                                        <li>a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>
                                        <li>a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li>
                                    </ul>
                                </div>
                                <p className="privacy__text">Please note that any misrepresentations in a written notification automatically subjects the complaining
party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement. 
</p>
                                <p className="privacy__text">Our Copyright Agent is:</p>
                                <div className="privacy__text">
                                    <ul className="list-address list-address--padding">
                                        <li>Timothy M. McLean, Esq.</li>
                                        <li>Clingen Callow & McLean, LLC</li>
                                        <li>2300 Cabot Drive, Suite 500</li>
                                        <li>Lisle, Illinois 60532</li>
                                        <li>Email: <a href="mailto: mclean@ccmlawyer.com">mclean@ccmlawyer.com</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Your Account</div>
                                <p className="privacy__text">By using the Services, you agree that, in addition to exercising common sense:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li>you will not create more than one account for yourself;</li>
                                        <li>you will not create another account if we have already disabled your account, unless you have our written permission to do so;</li>
                                        <li>you will not buy, sell, rent, or lease access to your account or username;</li>
                                        <li>you will not share your password;</li>
                                        <li>you will not log in or attempt to access the Services through unauthorized third party applications or clients.</li>
                                    </ul>
                                </div>
                                <p className="privacy__text">You are responsible for anything that happens in your account, so please keep it secure. Keeping a strong
password that you have not used for other accounts is one good way to do this. You agree that the registration information you submit to SCC is true and that you will keep all such information up to date. </p>
                                <p className="privacy__text">If you think that someone has gained access to your account, please immediately reach out to SCC at the
contact information provided on the last page of these Terms.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Data Charges and Mobile Phones</div>
                                <p className="privacy__text">You are responsible for any mobile charges that you may incur for using our Services, including text
messaging and data charges. If you’re unsure what those charges may be, you should ask your service provider before using the Services. If you change or deactivate the mobile phone number that you used to create an account, you must update your account information through within 72 hours to prevent us from sending to someone else messages intended for you.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Third-Party Services</div>
                                <p className="privacy__text">If you use a service, feature, or functionality that is operated by a third party and made available through
our Services (including Services we jointly offer with the third party), each party’s terms will govern the respective party’s relationship with you. SCC is not  responsible or liable for a third party’s terms or actions taken under the third party terms.</p>
                            </Col>                            
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Family Educational Rights and Privacy Act (FERPA) and California AB 1584 </div>
                                <p className="privacy__text">Regarding FERPA and California AB 1584 (Buchanan) Privacy of Pupil Records: 3rd-Party Digital Storage & Education Software (Education Code section 49073.1), SCC will abide by the following:</p>
                                <ul className="term-number__listing">
                                    <li>Student records obtained by SCC from an educational institution continue to be the property of and under the control of the educational institution. The educational institution retains full ownership rights to the personal information and education records it provides to SCC.
                                    </li>
                                    <li>SCC users may retain possession and control of their own generated content on SCC.</li>
                                    <li>SCC will not use any information in a student record for any purpose other than those required or specifically permitted by the SCC Terms of Service and Privacy Policy.</li>
                                    <li>Parents, legal guardians, or eligible students may review personally identifiable information in the student’s records and correct erroneous information by contacting their educational institution. Additionally, SCC users may access, correct, update, or delete personal information in their profile by signing into SCC, accessing their SCC account, and making the appropriate changes.</li>
                                    <li>SCC is committed to maintaining the security and confidentiality of student records. Towards this end, we take the following actions: (a) we limit employee access to student data to only those employees with a need to such access to fulfill their job responsibilities; (b) we conduct background checks on our employees that may have access to student data; (c) we conduct regular employee privacy and data security training and education; and (e) we protect personal information with technical, contractual, administrative, and physical security safeguards in order to protect against unauthorized access, release or use.</li>
                                    <li>In the event of an unauthorized disclosure of a student’s records, SCC will (1) promptly notify Users unless specifically directed not to provide such notification by law enforcement officials. Notification shall identify: (i) the date and nature of the unauthorized use or disclosure; (ii) the Private Data used or disclosed; (iii) general description of what occurred, including who made the unauthorized use or received the unauthorized disclosure; (iv) what SCC has done or shall do to mitigate any effect of the unauthorized use or disclosure; (v) what corrective action SCC has taken or shall take to prevent future similar unauthorized use or disclosure; and (vi) who at SCC the User can contact. SCC will keep the User fully informed until the incident is resolved.</li>
                                    <li>SCC will delete or de-identify personal information when it is no longer needed, upon expiration or termination of our agreement with an educational institution, with any deletion or de-identification to be completed according to the terms of our agreement with the educational institution, or at the direction or request of the educational institution.</li>
                                    <li>SCC agrees to work with an educational institution from which it receives student records to ensure compliance with FERPA by providing parents, legal guardians or eligible students with the ability to inspect and review student records and to correct any inaccuracies therein as described in statement (4) above.</li>
                                    <li>SCC prohibits using personally identifiable information in student records to engage in targeted advertising.</li>
                                </ul>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Modification and Termination</div>
                                <p className="privacy__text">These Terms will control the entirety of the relationship between you us. We may add or remove features, products or functionality, and we may also suspend or stop the Services, at any time without advance notice. We may also terminate or suspend your account and use of and access to the Services at any time in our sole discretion, with or without cause, without notice and liability to you. We will not be liable to you for terminating these Terms, including for termination of your account or deletion of your content. You may terminate your use of the Services at any time and for any reason or no reason by stopping all use of the Services. These Terms shall survive any termination of the parties’ relationship. </p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Indemnity</div>
                                <p className="privacy__text">To the greatest extent permitted pursuant to applicable law, you agree to defend, indemnify and hold harmless SCC and its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to (i) your use of the Services, (ii) your User Content, or (iii) your violation of these Terms. We reserve the right, at your expense, to assume the exclusive defense and control of any matter that you are required to indemnify, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without our prior written consent. We will use reasonable efforts to notify you of any claim falling under this section once we learn of it. </p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Disclaimers</div>
                                <p className="privacy__text privacy__text--uppercase">THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE DO NOT REPRESENT OR WARRANT THAT (1) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY, (2) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS, THAT THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES OR THE SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS, OR (3) THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON, FROM OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE.</p>
                                <p className="privacy__text privacy__text--uppercase">SCC TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH SCC WILL BE RESPONSIBLE FOR.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Limitation on Liability</div>
                                <p className="privacy__text privacy__text--uppercase">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL SCC OR ANY OF OUR MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, LICENSORS, ATTORNEYS, AGENTS OR SUPPLIERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SERVICES, OUR WEBSITES, PRODUCTS OR THIRD PARTY SERVICES, WEBSITES OR PRODUCTS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES, WEBSITES, PRODUCTS AND/OR THIRD PARTY SERVICES, WEBSITES AND PRODUCTS ARE AT YOUR OWN  DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE AND FOR ANY LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, SCC’S AGGREGATE LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS, THE SERVICES, THE WEBSITES AND/OR PRODUCTS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE GREATER OF $100 USD OR THE AMOUNT YOU PAID SCC IN THE LAST 12 MONTHS, IF ANY.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Governing Law and Venue</div>
                                <p className="privacy__text">All matters relating to the Services and these Terms and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Illinois without giving effect to any choice or conflict of law provision or rule (whether of the State of Illinois or any other jurisdiction). Any legal suit, action or proceeding arising out of, or related to, these Terms or the Services shall be instituted exclusively in the federal courts of the United States District Court for the Northern District of Illinois, Eastern Division, or the courts of the State of Illinois located in the County of DuPage, although we retain the right to bring any suit, action or roceeding against you for breach of these Terms of Use in your country of residence or any other relevant jurisdiction. You agree to waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Limitation on Time to File Claims</div>
                                <p className="privacy__text privacy__text--uppercase">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Waiver and Severability</div>
                                <p className="privacy__text">No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of us to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms are held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent and the remaining provisions of these Terms will continue in full force and effect.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Entire Agreement</div>
                                <p className="privacy__text">These Terms (together with any additional terms applicable to specific Services you use) make up the entire agreement between you and SCC and supersede any prior agreements. These Terms do not create or confer any third-party beneficiary rights. We reserve all rights not expressly granted to you. You may not transfer any of your rights or obligations under these Terms without our prior written consent.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Your Comments and Concerns</div>
                                <p className="privacy__text">All feedback, comments, requests for technical support and other communications relating to the Website should be directed to: </p>
                                <div className="privacy__text">
                                    <ul className="list-address list-address--padding">
                                        <li>Attn: Bill Schalz, CEO</li>
                                        <li>SafeComm</li>
                                        <li>690 Main Street #862</li>
                                        <li>Safety Harbor, FL 34695</li>
                                        <li>Email: <a href="mailto: bill@safecommapp.com">bill@safecommapp.com</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default TermsandConditions;