/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Dropdown, Table, Media, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Form} from 'formik';
/* End - Formik */

import { SmallLoader } from '../../components/small-loader/sm-loader';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { NoRecord } from '../../components/no-record/no-record';
import './team.scss';

//  * Get the Kid list for profile
class KidsGroupList extends Component {
    render() {
        let props = this.props;
        let kidsList = '';
        let sortImage = Images.ArrowSort;
        if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'desc'){
            sortImage = Images.ArrowDown;
        } else if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }
        if (typeof props.data == "object" && Object.keys(props.data).length > 0) {
            kidsList = props.data.map((row, i) => {
                let kidsTeamId = 'kidsTeams' + i;
                let teams = row.teams;
                return (
                    <div key={kidsTeamId}>
                        <Media className="lg-view">
                            
                            <Media.Body className="align-self-center">
                                <h5>{row.name}'s Member Groups</h5>
                            </Media.Body>
                        </Media>
                        <div className="widget widget--lg-view">
                            <div className="tab-search tab-search--big tab-search--widget">
                                <Form className="form-inline">
                                    <input type="text" name="focus" maxLength="100" onChange={(event) => props.filterKidsList(event.target, i)} placeholder="Search" className="form-control tab-search__box" />
                                    <button className="close-icon" onClick={(event) => props.filterKidsList(event.target, i)} type="reset"></button>
                                </Form>
                            </div>
                            <Table bordered hover className="tab-responsive table--listing">
                                <thead>
                                    <tr>
                                        {
                                            (typeof props.sortCriteria != 'undefined' && row.teams.length >1) ?
                                            <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('name')}>Member Groups
                                                { props.sortCriteria.column == 'name' ?
                                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                                }</th>
                                            : <th>Member Groups</th>

                                        }
                                        {
                                            (typeof props.sortCriteria != 'undefined' && row.teams.length >1) ?
                                                <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('teams.location')}>Location
                                                    { props.sortCriteria.column == 'teams.location' ?
                                                        <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                                        <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                                    }</th>
                                                : <th>Location</th>

                                        }
                                        {
                                            (typeof props.sortCriteria != 'undefined' && row.teams.length >1) ?
                                                <th className='custom-width-column date_created_col col-sortable' onClick={() => props.sortHandler('created_at')}>Date Created
                                                    { props.sortCriteria.column == 'created_at' ?
                                                        <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                                        <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                                    }</th>
                                                :  <th>Date Created </th>

                                        }
                                        {
                                            Helper.isCoachOrAdminLogin() ?
                                                <th>Students </th>
                                                :
                                                null
                                        }
                                        <th>Instructor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        teams.map((teamRow, j) => {
                                            return (
                                                <tr key={j}>
                                                    <td data-label="Member Groups" className="td-content-space">
                                                        <Media>
                                                            <div className="im-rounded-outer">
                                                                <Image
                                                                    src={teamRow.photo}
                                                                    alt={teamRow.name}
                                                                />
                                                            </div>
                                                            <Media.Body className="align-self-center media-body--link">
                                                                <Link to={`/team-details/${teamRow.id}`}>
                                                                    <h5>{teamRow.name}</h5>
                                                                </Link>
                                                            </Media.Body>
                                                        </Media>
                                                    </td>
                                                    <td data-label="Member Groups" className="td-content-space">
                                                        <Media>
                                                            <Media.Body className="align-self-center media-body--link">
                                                                <h5>{teamRow.location}</h5>
                                                            </Media.Body>
                                                        </Media>
                                                    </td>
                                                    <td data-label="Date Created">{teamRow.created_at}</td>
                                                    {
                                                        Helper.isCoachOrAdminLogin() ?
                                                            <td data-label="Member">{teamRow.total_member}</td> :
                                                            null
                                                    }
                                                    <td data-label="Instructor" className="td-content-space">
                                                        <div className="coach-grp">
                                                            {
                                                                (teamRow.coach !== undefined && teamRow.coach.length > 0)
                                                                &&
                                                                teamRow.coach.map((coachRow, i) => {
                                                                    let coachRowId = 'coachrow' + i;
                                                                    return (<OverlayTrigger key={coachRowId}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip`}>
                                                                                {coachRow.first_name} {coachRow.last_name}
                                                                            </Tooltip>
                                                                        }>
                                                                        <div className="im-rounded-outer"><Image src={coachRow.photo} alt={coachRow.first_name} /></div>
                                                                    </OverlayTrigger>)
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Dropdown className="more-actions">
                                                            <Dropdown.Toggle>
                                                                <span className="more-im"></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Link className="dropdown-item" to={`/team-details/${teamRow.id}`}>
                                                                    <Image
                                                                        className="mr-2"
                                                                        src={Images.EditIc}
                                                                        alt="detail"
                                                                        width="21"
                                                                    />
                                                                    <span>Details</span>
                                                                </Link>
                                                                { teamRow.chat_group_id && props.teamMemberOf.includes(teamRow.id) &&
                                                                    <Dropdown.Item onClick={() => this.props.mainObj.props.setChatWithGroupId(`T_${teamRow.id}`)}>
                                                                        <Image
                                                                            className="mr-2"
                                                                            src={Images.ChatIc}
                                                                            alt="Chat"
                                                                            width="21"
                                                                        />
                                                                        <span>Chat</span>
                                                                    </Dropdown.Item>
                                                                 }
                                                            </Dropdown.Menu>

                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {!props.recordLoaded &&
                                    <tr className="mb--norecord">
                                        <td colSpan="5">
                                            <SmallLoader />
                                        </td>
                                    </tr>
                                    }
                                    {
                                        (props.recordLoaded && row.teams.length === 0) &&
                                        <tr className="mb--norecord">
                                            <td colSpan="5">
                                                <NoRecord />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                );
            });
            return kidsList;
        }
        return kidsList;
    }
}


export { KidsGroupList };