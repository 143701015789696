/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Navbar, Nav, Dropdown, Image, OverlayTrigger, Tooltip, Media } from 'react-bootstrap';
/* End - React Bootstrap */

import HamburgerIc from '../../assets/images/hamburger.svg';
import SafecommLogo from '../../assets/images/logo.png';
import './header.scss';
import * as Storage from '../../components/common/storage';
import * as Helper from '../../components/common/functions';
import * as Enum from '../common/enum';
import '../common/common.scss';
import {initCometChat, isISRLogin} from '../common/functions';

class Header extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showMenu: true,
            userClubs: [],
            selectedClub: null,
        };
        this.handleResize = this.handleResize.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
    }
    componentDidMount = () => {
        const user = Storage.Get('user');
        if (user.is_isr || (user.club && !user.club_access_by_superadmin && user.selectedClubId !==0)) {
            var clubs = Object.keys(user.club).map(function (x) { return { id: x, value: user.club[x] }; });
            this.setState({ userClubs: clubs, selectedClub: user.selectedClubId });
        }

        if (user.club_access_by_superadmin && user.selectedClubId) {
            var clubs = [];
            clubs.push({id: 0, value: 'Switch to Super Admin'})
            this.setState({ userClubs: clubs, selectedClub: user.selectedClubId });
        }

        if (user.is_isr && user.selectedClubId) {
            clubs.push({id: 0, value: 'Switch to MA Portal'})
            this.setState({userClubs: clubs, selectedClub: user.selectedClubId});
        }
        initCometChat();

        window.addEventListener("resize", this.handleResize);
        document.addEventListener("click", (evt) => this.handleToggleMenu(evt));

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        document.removeEventListener("click", (evt) => this.handleToggleMenu(evt));
    }

    handleResize() {
        if (window.innerWidth > 1024) {
            this.setState({ showMenu: true });
        } else {
            this.setState({ showMenu: false });
        }
        this.toggleMenu();
    }

    handleToggleMenu(evt) {
        if (window.innerWidth > 1024) {
            return;
        }
        const sidebar = document.getElementById("js-sidebar-menu");
        const hamburger = document.getElementsByClassName("hamburger-ic")[0];
        let targetElement = evt.target; // clicked element

        do {
            if (targetElement == sidebar || targetElement == hamburger) {
                return;
            } else if (targetElement !== document && targetElement.closest(".menulist")) {
                this.setState({ showMenu: false });
                this.toggleMenu();
            }
            targetElement = targetElement.parentNode;

        } while (targetElement);

        // This is a click outside.
        this.setState({ showMenu: false });
        this.toggleMenu();
    }

    toggleMenu() {
        if (document.querySelector(".overlay")) {
            let left = '-400px', visibility = "hidden";
            if (this.state.showMenu) {
                left = 0;
                visibility = "visible";
                document.querySelector(".overlay").classList.remove('overlay_hidden');
                document.querySelector(".overlay").classList.add('overlay_show');
                if (window.innerWidth <= 991) {
                    document.querySelector(".body-overflow").classList.add('overflow-hidden');
                }
            } else {
                document.querySelector(".overlay").classList.add('overlay_hidden');
                document.querySelector(".overlay").classList.remove('overlay_show');
                document.querySelector(".body-overflow").classList.remove('overflow-hidden');
            }

            document.querySelector('#js-sidebar-menu').style.left = left;
            document.querySelector('#js-sidebar-menu').style.visibility = visibility;
            this.setState({ showMenu: !this.state.showMenu });
        }
    }

    closeDropdown = (event, clubId) => {
        clubId = parseInt(clubId, 10);
        let user = Storage.Get('user');
        if(user.club_access_by_superadmin) {
            user.role = [];
            user.is_super_admin = true;
            user.selectedClubId = 0;
            user.club_access_by_superadmin = false;
            Storage.Set('user', JSON.stringify(user));
            this.props.handleClubPic(Storage.Get('user').club_photo[Storage.Get('user').selectedClubId]);
            this.props.history.push("/");
        }else {
            user.selectedClubId = clubId;
            Storage.Set('user', JSON.stringify(user));
            this.setState({selectedClub: clubId});
            Helper.isCoachOrAdminLogin() || !clubId ? this.props.history.push("/") : this.props.history.push("/teams");
        }
        this.props.handleClubPic(Storage.Get('user').club_photo[Storage.Get('user').selectedClubId]);
        window.location.reload();
    }

    render() {
        const { headerPic: photo, clubPic } = this.props;
        const userData = Storage.Get('user');
        const name = userData.first_name + ' ' + userData.last_name;
        const userRoles = userData.role[userData.selectedClubId];
        const isISR = userData.is_isr ?? false ;
         return (
            <>
                <Navbar bg="light" expand="lg" className="topbar">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <span className="hamburger-ic" onClick={this.toggleMenu}><Image src={HamburgerIc} alt="Hamburger" /></span>
                        <Nav className="mr-auto org-logo">
                            {
                                userData.is_super_admin || isISRLogin() ?
                                    <Image src={SafecommLogo} alt="SafeComm" title="SafeComm" width="245" />
                                    :
                                    clubPic ?
                                        <Image src={clubPic} alt="Org Logo" />
                                        :
                                        <div className="logo-name">
                                            {userData.club[userData.selectedClubId]}
                                        </div>
                            }
                        </Nav>
                        <div className="top-right">
                            <Dropdown className="prf_user">
                                <Dropdown.Toggle id="dropdownProfile">
                                    <Media>
                                        <div className="im-semirounded-outer">
                                            <Image className="mr-2" src={photo} alt="User" />
                                        </div>
                                        <Media.Body className="mb-hide">
                                            <h5>{name}</h5>
                                            <UserTypeBadge data={userRoles} isSuperAdmin={userData.is_super_admin }  isISR={isISR}/>
                                        </Media.Body>
                                    </Media>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="desk-hide">
                                        <h5 className="mb--small-res">{name}</h5>
                                        <div className="prf-heading mb-2">
                                            My Role
                                        </div>
                                        <div className="px-2">
                                            <UserTypeBadge data={userRoles} isSuperAdmin={userData.is_super_admin } />
                                        </div>
                                        <div className="prf-heading">
                                            Organizations
                                        </div>
                                    </div>
                                    {!userData.is_super_admin && this.state.userClubs.map((row, i) => {
                                        return (
                                            this.state.selectedClub === parseInt(row.id, 10) ?
                                                <Dropdown.Item key={i} className="active">{row.value}</Dropdown.Item>
                                                :
                                                <Dropdown.Item key={i} onClick={(event, id) => this.closeDropdown(event, row.id)} className={`${userData.club_access_by_superadmin ? 'dropdown-item--switch-ic' : ''}`}>{row.value}</Dropdown.Item>
                                        )
                                    })
                                    }
                                    {this.state.userClubs.length > 0 && <div className="dropdown-divider"></div>}
                                    <Dropdown.Item onClick={() => { this.props.history.push("/profile") }} className="dropdown-item--setting">Profile Settings</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item--logout" onClick={this.props.handleLogout}>LogOut</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
}

function UserTypeBadge(props) {
     return (
        props.data  && !props.isSuperAdmin ?
            props.data.map((item, i) => {
                let badgeName = Enum.UserRolesDisplay[item];
                let className = "t-badge"

                if (item === Enum.UserRoles.COACH) {
                    className = `${className} t-badge--coach`;
                }
                else if (item === Enum.UserRoles.LEGAL_GUARDIAN) {
                    className = `${className} t-badge--lg`;
                    badgeName = `LG`
                }
                else if (item === Enum.UserRoles.CLUB_SUB_ADMIN) {
                    className = `${className} t-badge--admin`;
                    badgeName = `Organization SA`
                }
                else if (item === Enum.UserRoles.CLUB_ADMIN) {
                    className = `${className} t-badge--admin`;
                    badgeName = `Organization Admin`
                }
                return (
                    <OverlayTrigger key={i} placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip`}>
                                {Enum.UserRolesDisplay[item]}
                            </Tooltip>}>
                        <div className="badge-wrap"><span className={className}>{badgeName}</span></div>
                    </OverlayTrigger>
                )
            })
            :
            <OverlayTrigger placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip`}>
                                    Super Admin
                                </Tooltip>}>
                <div className="badge-wrap">
                        <span className="t-badge t-badge--admin">{props.isISR ? 'Marketing Affiliate' : 'Super Admin'}
                    </span>
                </div>
            </OverlayTrigger>
    )
}

export { Header };
