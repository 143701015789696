/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Stepzilla
 * Name and Version: react-stepzilla, 6.0.2
 * Download link: https://www.npmjs.com/package/react-stepzilla
 */
import StepZilla from "react-stepzilla";
/* End - React Stepzilla */

import { GeneralInfo } from './general-info';
import { Address } from './address';
import { PointOfContact } from './point-of-contact';
import { Review } from './review';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import { ErrorModal, SuccessModal } from '../../components/modal/modalbox';
import {Footer} from '../../components/footer/footer';
import {HeaderFullWidth} from '../../components/header/headerfullwidth';

import './club.scss';
import * as Storage from "../../components/common/storage";


const nextText = {
    0: 'Next',
    1: 'Next',
    2: 'Next',
    3: 'Finish'
}

class CreateClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorShow: false,
            errorMessage: null,
            successShow: false,
            successMessage: '',
            showDiv: false,
            photo: { url: null, file: null },
           
        };
        this.generalInfoFirstLoad = true;
        this.generalInfoFields = {
            clubName: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            adminUsername: '',
            adminPassword: '',
            adminConfirmPassword: '',
            adminPhone: '',
            adminPhoneCode: '',
            orgWebsite: '',
            additionalInformation: ''
        };
        this.addressFieldsFirstLoad = true;
        this.addressFields = {
            mailAddress: '',
            mailAddress2: '',
            mailCity: '',
            mailState: '',
            mailZip: '',
            billAddress: '',
            billAddress2: '',
            billCity: '',
            billState: '',
            billZip: ''
        };
        this.pocFieldsFirstLoad = true;
        this.pocFields = {
            emailGeneral: '',
            firstNameGeneral: '',
            lastNameGeneral: '',
            phoneGeneral: '',
            phoneGeneralCode: '',
            emailContact2: '',
            firstNameContact2: '',
            lastNameContact2: '',
            phoneContact2: '',
            phoneContact2Code: '',
            firstNameBilling: '',
            phoneBillingCode: '',
            lastNameBilling: '',
            phoneBilling: '',
            emailBilling: '',
        };
        this.reToken = '';
        this.isLoggedIn = !!Storage.Get('user'); //if user is logged in
        this.isSuperAdmin = !!Helper.isSuperAdminLogin();
    }

    handleStepChange = (step) => {
        this.setState({
            step: step
        })
        Helper.scrollToTop();
    }

    onCreateClubFinishSubmit = () => {
        const formData = new FormData();
        if (this.state.photo.file) {
            formData.append('photo', this.state.photo.file);
        }
        formData.append('name', this.generalInfoFields.clubName);
        formData.append('admin_first_name', this.generalInfoFields.adminFirstName);
        formData.append('admin_last_name', this.generalInfoFields.adminLastName);
        formData.append('admin_email', this.generalInfoFields.adminEmail);
        formData.append('admin_username', this.generalInfoFields.adminUsername);
        formData.append('admin_password', this.generalInfoFields.adminPassword);
        formData.append('admin_confirm_password', this.generalInfoFields.adminConfirmPassword);
        formData.append('admin_phone', this.generalInfoFields.adminPhone);
        formData.append('website', this.generalInfoFields.orgWebsite);
        formData.append('additional_info', this.generalInfoFields.additionalInformation);
        formData.append('admin_phone_code', this.generalInfoFields.adminPhoneCode);
        formData.append('mail_address', this.addressFields.mailAddress);
        formData.append('mail_address2', this.addressFields.mailAddress2);
        formData.append('mail_city', this.addressFields.mailCity);
        formData.append('mail_state', this.addressFields.mailState);
        formData.append('mail_zip', this.addressFields.mailZip);
        formData.append('bill_address', this.addressFields.billAddress);
        formData.append('bill_address2', this.addressFields.billAddress2);
        formData.append('bill_city', this.addressFields.billCity);
        formData.append('bill_state', this.addressFields.billState);
        formData.append('bill_zip', this.addressFields.billZip);
        formData.append('general_email', this.pocFields.emailGeneral);
        formData.append('general_first_name', this.pocFields.firstNameGeneral);
        formData.append('general_phone_code', this.pocFields.phoneGeneralCode);
        formData.append('general_last_name', this.pocFields.lastNameGeneral);
        formData.append('general_phone', this.pocFields.phoneGeneral);
        formData.append('contact2_email', this.pocFields.emailContact2);
        formData.append('contact2_first_name', this.pocFields.firstNameContact2);
        formData.append('contact2_phone_code', this.pocFields.phoneContact2Code);
        formData.append('contact2_last_name', this.pocFields.lastNameContact2);
        formData.append('contact2_phone', this.pocFields.phoneContact2);
        formData.append('billing_first_name', this.pocFields.firstNameBilling);
        formData.append('billing_last_name', this.pocFields.lastNameBilling);
        formData.append('billing_phone', this.pocFields.phoneBilling);
        formData.append('billing_phone_code', this.pocFields.phoneBillingCode);
        formData.append('billing_email', this.pocFields.emailBilling);
        formData.append('recaptcha_response', this.reToken);
        formData.append('invite_code', this.props.match.params.inviteCode ?? '');


        let finishButton = document.getElementById('next-button');
        let clubApiUrl =  this.isSuperAdmin ? 'club/create' : ( this.isLoggedIn ? 'club/signup' : 'club/register');
        Helper.axiosInstance()
            .post(`${clubApiUrl}`, formData, {
                'Content-Type': 'multipart/form-data',
            })
            .then((response) => {
                if (response.data.success === true) {
                    this.setState({ successShow: true, successMessage: response.data.message, });
                    //Add new club in the user clubs if user is logged in.
                    if(this.isLoggedIn && response.data.club){
                        let user = Storage.Get('user');
                        user.club = response.data.club;
                        user.club_photo = response.data.club_photo;
                        user.selectedClubId = response.data.selected_club_id;
                        user.role = response.data.role;
                        Storage.Set('user', JSON.stringify(user));
                    }
                }
                else {
                    const getErrors = response.data.error;
                    let errorMsg = [];
                    Object.keys(getErrors).forEach((key,value) =>{
                      errorMsg.push(getErrors[key]);
                    });
                    this.setState({ errorShow: true, errorMessage: errorMsg.join('<br>') });
                }
                finishButton.classList.remove('btn-loader');
                finishButton.disabled = false;

            })
            .catch((error) => {
                console.log(error);
                this.setState({ errorShow: true, errorMessage: Messages.SERVER_ERROR });
                finishButton.classList.remove('btn-loader');
                finishButton.disabled = false;
            });
    }

    handleGeneralInfoSubmit = (fields, photo, resetForm) => {
        this.generalInfoFirstLoad = false;
        this.generalInfoFields = fields;
        this.setState({ photo: photo, resetForm: resetForm });
    }

    handlePointOfContactSubmit = (fields) => {
        this.pocFieldsFirstLoad = false;
        this.pocFields = fields;
    }

    handleAddressSubmit = (fields) => {
        this.addressFieldsFirstLoad = false;
        this.addressFields = fields;
    }

    setRecaptchaToken = token =>{
        this.reToken = token;
    }

    onSuccessConfirmClick = () => {
        this.setState({
            successShow: false
        });
        if(this.isSuperAdmin){
            this.props.history.push(`/organization`)
        }else{
            this.props.history.push(`/login`)
        }
    }

    render() {
        const orgUrl = (this.props.location.pathname ==='/create-org')? <HeaderFullWidth />: null ;
        const steps = [
            { name: 'Organization Information', component: <GeneralInfo isLoggedIn={this.isLoggedIn} isSuperAdmin={this.isSuperAdmin} data={{ fields: this.generalInfoFields, photo: this.state.photo, firstLoad: this.generalInfoFirstLoad }} handleFormSubmit={(fields, photo) => this.handleGeneralInfoSubmit(fields, photo)} /> },
            { name: 'Address', component: <Address data={{ fields: this.addressFields, firstLoad: this.addressFieldsFirstLoad }} handleFormSubmit={(fields) => this.handleAddressSubmit(fields)} /> },
            { name: 'Point of Contact', component: <PointOfContact isSuperAdmin={this.isSuperAdmin} isLoggedIn={this.isLoggedIn}  data={{ fields: this.pocFields, firstLoad: this.pocFieldsFirstLoad}} handleFormSubmit={(fields) => this.handlePointOfContactSubmit(fields)} generalInfoFields={this.generalInfoFields} /> },
            { name: 'Review', component: <Review isSuperAdmin={this.isSuperAdmin} isLoggedIn={this.isLoggedIn} onCreateClubFinishSubmit={this.onCreateClubFinishSubmit} photo={this.state.photo} generalInfo={this.generalInfoFields} pointOfContact={this.pocFields} address={this.addressFields} setRecaptchaToken={this.setRecaptchaToken} /> }
        ];

        return (
            <>
                    { orgUrl }
                <div className={!orgUrl ? "rgt-content": "container container--pt"}> {/* class rgt-content when user logged in */}
                    <Row className="row--space">
                        <Col sm="7">
                            <h1>{this.isSuperAdmin ? 'Create an' : 'Register'} Organization</h1>
                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                <div className='step-progress'>
                                    <StepZilla steps={steps} startAtStep={0}
                                        nextButtonText={nextText[this.state.step]}
                                        showNavigation={true}
                                        preventEnterSubmission={true}
                                        stepsNavigation={false}
                                        prevBtnOnLastStep={true}
                                        onStepChange={this.handleStepChange} />
                                </div>

                            </div>
                        </Col>
                    </Row>  
                    {
                        orgUrl ? <Footer />  : null
                    }
                                  
                </div>

                <SuccessModal
                    successConfirmShow={this.state.successShow}
                    onHide={this.onSuccessConfirmClick}
                    onConfirmClick={this.onSuccessConfirmClick}
                    successText={this.state.successMessage}
                    successButtonText={"OK"}
                />
                <ErrorModal
                    show={this.state.errorShow}
                    message={this.state.errorMessage}
                    onConfirmClick={() => { this.setState({ errorShow: false }) }}
                ></ErrorModal>
            </>
        )
    }
}
export { CreateClub };