/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Container, Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

const Page404 = ()=>(
    <Container>
        <Row className="module-space text-center">
            <Col sm={12} className="error-page">
                <h1>404</h1>
                <h3>Page not found</h3>
                <div className="error-code">Look like the page you are trying to visit doesn't exist.<br/> Please check the URL and try again.</div>
                <Link to="/" className="btn btn-primary btn-medium mt-4">Take me Home</Link>
            </Col>
        </Row>

    </Container>
)
export default Page404;