/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Route, Switch, Redirect} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row} from 'react-bootstrap';
/* End - React Bootstrap */

import {Login} from './scenes/login/login';
import {TeamListing} from './scenes/team/team-listing';
import {addMember} from './scenes/team/add-member';
import {addGroupMember} from './scenes/group/add-member';
import {AddInstructor} from './scenes/team/add-instructor';
import {TeamDetail} from './scenes/team/team-detail';
import {Contact} from './scenes/contacts/contact';
import BulkUpload from './scenes/contacts/bulk-upload';
import BulkUploadInstructors from './scenes/contacts/bulk-upload-instructors';
import {ForgotPassword} from './scenes/login/forgot-password';
import {ForgotUsername} from './scenes/login/forgot-username';
import {ResetPassword} from './scenes/login/reset-password';
import {Profile} from './scenes/profile/profile';
import Page404 from './components/common/page404';
import Page403 from './components/common/page403';
import * as Helper from './components/common/functions';


import {ClubListing} from './scenes/club/club';
import {ClubInvoice} from './scenes/invoice/invoice';
import {ClubInvoiceDetail} from './scenes/invoice/invoice-detail';

import {CreateClub} from './scenes/club/create-club';
import {Register as RegisterClub} from './scenes/club/register';
import {EditClub} from './scenes/club/edit-club';
import {Settings} from './scenes/settings/settings';
import {VerifyClub} from './scenes/club/verify-club';
import {GroupListing} from './scenes/group/group-listing';
import {GroupDetail} from './scenes/group/group-detail';
import {Dashboard} from './scenes/dashboard/dashboard';
import {InviteStudent} from './scenes/invite-process/invite-student';
import {InviteLG} from './scenes/invite-process/invite-lg';
import {VerifyLG} from './scenes/invite-process/verify-lg';
import {InviteByInstructor} from './scenes/invite-process/invitebyinstructor';
import {InviteLandingPage} from './scenes/invite-process/landing-page';
import {VerifyEmail} from './scenes/invite-process/verify-email';
import {VerifySecEmail} from './scenes/profile/verify-email';
import {InstructorOrgAdmin} from './scenes/invite-process/instructor-orgadmin';
import {VerifyPassword} from './scenes/student-data/verify-password';
import {Header} from './components/header/header';
import * as Enum from '../src/components/common/enum';
import * as Storage from '../src/components/common/storage';
import Sidebar from './components/sidebar/sidebar';
import {OverlayBg} from './components/common/overlaybg';
import {IeFooterMsg} from './components/common/ie-footer-msg';
import {TimingMsgBar} from './components/common/timing-msg-bar';

import {BroadcastMessage} from './scenes/broadcast-message/broadcastMessage';
import Privacy from './scenes/privacy/privacypolicy';
import TermsandConditions from './scenes/terms-and-conditions/terms-and-conditions';
import {Register} from './scenes/isr/register';
import {RegisterISRLandingPage} from './scenes/isr/landing-page';
import {VerifyIsr} from './scenes/isr/verify-isr';
import {ISRListing} from './scenes/isr/isr';
import {EditISR} from './scenes/isr/edit-isr';
import {RegisterISR} from './scenes/isr/register-isr';
import {IsrCommission} from './scenes/commission/commission';
import {VerifyInvite} from './scenes/invite-process/verify-invite';
import {VerifyRegisteredLG} from './scenes/invite-process/verify-registered-lg';

/**
 * If a user is not logged in then redirect him to the login page
 * @type {{component: *}}
 */

export const Routes = (childProps, handleProfilePic, handleClubPic, setChatWithGroupId) => {
    const roles = Enum.UserRoles;
    return (
        <Switch>
            {/* PUBLIC ROUTES */}
            <PublicRoute path="/login/:redirected?" exact component={Login} props={childProps}
                         handleProfilePic={handleProfilePic}/>
            <PublicRoute path="/password/forgot" exact component={ForgotPassword} props={childProps}/>
            <PublicRoute path="/password/reset" exact component={ResetPassword} props={childProps}/>
            <PublicRoute path="/username/forgot" exact component={ForgotUsername} props={childProps}/>
            <PublicRoute path="/invite-instructor/:inviteToken" exact component={InstructorOrgAdmin} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/invite-orgadmin/:inviteToken" exact component={InstructorOrgAdmin} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/invite-student/:inviteToken" exact component={InviteStudent} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/invite-lg/:inviteToken" exact component={InviteLG} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/invitations/:inviteToken" exact component={InviteByInstructor} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/invite/:inviteToken/:redirected?" exact component={InviteLandingPage} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/verify-lg/:verifyToken" exact component={VerifyLG} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/verify-email/:uId/:token" exact component={VerifyEmail} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/verify-email/:token" exact component={VerifySecEmail} props={childProps}
                         commonRoute={true}/>
            <Route path="/access-denied" exact component={Page403} props={childProps}/>
            <PublicRoute path="/create-organization/:inviteCode?" exact component={CreateClub} props={childProps}
                         commonRoute={true} verifyPublic={true}/>
            <PublicRoute path="/create-org/:inviteCode?" exact component={RegisterClub} props={childProps}
                         commonRoute={true} verifyPublic={true}/>
            <PublicRoute path="/verify-org/:token" exact component={VerifyClub} props={childProps} commonRoute={true}/>
            <PublicRoute path="/privacy-policy" exact component={Privacy} props={childProps} commonRoute={true}/>
            <PublicRoute path="/terms-of-service" exact component={TermsandConditions} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/register-isr" exact component={Register} props={childProps} commonRoute={true}
                         verifyPublic={true}/>
            <PublicRoute path="/signup-isr/" exact component={RegisterISRLandingPage} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/verify-isr/:token" exact component={VerifyIsr} props={childProps} commonRoute={true}/>
            <PublicRoute path="/verify-invite/:token" exact component={VerifyInvite} props={childProps}
                         commonRoute={true}/>
            <PublicRoute path="/register/lg-verify/:verifyToken" exact component={VerifyRegisteredLG} props={childProps}
                         commonRoute={true}/>
            {/* PRIVATE ROUTES */}
            <PrivateRoute path="/" exact component={Dashboard} props={childProps}
                          permissions={[roles.SUPER_ADMIN, roles.COACH, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.ISR]}/>
            <PrivateRoute path="/teams" exact component={TeamListing} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/teams/add-member/:team_id/:type" exact component={addMember} props={childProps}
                          permissions={[roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.COACH]}/>
            <PrivateRoute path="/teams/add-instructor/:team_id" exact component={AddInstructor} props={childProps}
                          permissions={[roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN]}/>
            <PrivateRoute path="/team-details/:team_id" exact component={TeamDetail} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/broadcast-history" exact component={BroadcastMessage} props={childProps}
                          permissions={[roles.COACH, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN]}/>
            <PrivateRoute path="/contacts" exact component={Contact} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/bulk-upload/:team_id?" exact component={BulkUpload} props={childProps}
                          permissions={[roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN]}/>
            <PrivateRoute path="/bulk-upload-instructors" exact component={BulkUploadInstructors} props={childProps}
                          permissions={[roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN]}/>
            <PrivateRoute path="/profile" exact component={Profile} handleClubPic={handleClubPic}
                          handleProfilePic={handleProfilePic} props={childProps}
                          permissions={[roles.SUPER_ADMIN, roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN, roles.ISR]}/>
            <PrivateRoute path="/organization/:redirect?" exact component={ClubListing} props={childProps}
                          permissions={[roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/invoices" exact component={ClubInvoice} props={childProps}
                          permissions={[roles.SUPER_ADMIN, roles.CLUB_ADMIN]}/>
            <PrivateRoute path="/invoice-detail/:invoice_id" exact component={ClubInvoiceDetail} props={childProps}
                          permissions={[roles.SUPER_ADMIN, roles.CLUB_ADMIN]}/>
            <PrivateRoute path="/create-organization" exact component={CreateClub} props={childProps}
                          permissions={[roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/edit-organization/:club_id" exact component={EditClub} props={childProps}
                          permissions={[roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/student-data" exact component={VerifyPassword} props={childProps}
                          permissions={[roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/groups" exact component={GroupListing} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/groups/:is_admin" exact component={GroupListing} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/group-detail/:group_id" exact component={GroupDetail} props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/groups/add-member/:group_id/:total_members" exact component={addGroupMember}
                          props={childProps}
                          permissions={[roles.COACH, roles.ATHLETE, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/settings" exact component={Settings} props={childProps}
                          permissions={[roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/isr" exact component={ISRListing} props={childProps}
                          permissions={[roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/edit-isr/:user_id" exact component={EditISR} props={childProps}
                          permissions={[roles.SUPER_ADMIN]}/>
            <PrivateRoute path="/register-as-isr" exact component={RegisterISR} props={childProps} commonRoute={true}
                          verifyPublic={true}
                          permissions={[roles.COACH, roles.CLUB_ADMIN, roles.CLUB_SUB_ADMIN, roles.LEGAL_GUARDIAN]}/>
            <PrivateRoute path="/commission-history" exact component={IsrCommission} props={childProps}
                          commonRoute={true} verifyPublic={true} permissions={[roles.SUPER_ADMIN, roles.ISR]}/>

            {/* Redirect to 404 if route not found */}
            <Route component={Page404}/>

        </Switch>
    )
};

//#region PRIVATE METHODS

const PublicRoute = ({component: C, props: cProps, ...rest}) => {
    const redirect = queryString("redirect"),
        element = document.querySelector("#bdy"),
        elementId = document.getElementById("bdy"),
        allClasses = elementId.classList;


    !cProps.childProps.isAuthenticated && element.classList.add("bg-im");

    rest.commonRoute && element.classList.add("bg-grey");
    rest.verifyPublic && element.classList.add("bg-grey");
    (!cProps.childProps.isAuthenticated && allClasses.toString().includes("bg-grey")) ? element.classList.remove("bg-grey") : element.classList.add("bg-im");
    rest.commonRoute && element.classList.add("bg-grey");
    rest.verifyPublic && element.classList.add("bg-grey");
    cProps.childProps.isAuthenticated && document.querySelector('body').classList.add("bdy-logged-in");
    return (
        <>
            {
                // when user not logged in for eg in invite process we have consent form and privacy link
                cProps.childProps.isAuthenticated && (rest.path === '/privacy-policy' || rest.path === '/terms-of-service') ?
                    <>

                        <Header userState={cProps.childProps} history={cProps.history}
                                handleClubPic={cProps.handleClubPic} clubPic={cProps.clubPic}
                                headerPic={cProps.headerPic} handleLogout={cProps.handleLogout}/>
                        <OverlayBg/>
                        <div className="container-fluid ipd-height100">
                            <Row className="ipd-height100">

                                <Sidebar {...cProps.history} />
                                <Route
                                    {...rest}
                                    render={props =>
                                        <PublicRouteResponse
                                            cProps={cProps}
                                            props={props}
                                            rest={rest}
                                            redirect={redirect}
                                            component={C}

                                        />
                                    }
                                />
                                <IeFooterMsg ieMsgClass={cProps.ieMsgClass} ieBarClose={cProps.ieBarClose}/>
                            </Row>
                        </div>
                    </>
                    :
                    <>
                        <Route
                            {...rest}
                            render={props =>
                                <PublicRouteResponse
                                    cProps={cProps}
                                    props={props}
                                    rest={rest}
                                    redirect={redirect}
                                    component={C}

                                />
                            }
                        />
                        <IeFooterMsg ieMsgClass={cProps.ieMsgClass} ieBarClose={cProps.ieBarClose}/>

                    </>
            }
        </>
    );
}


const PrivateRoute = ({component: C, props: cProps, ...rest}) => {
    const isAccessAllowed = isAccessGranted(rest.permissions),
        redirect = queryString("redirect");
    cProps.childProps.isAuthenticated && document.querySelector('body').classList.add("bdy-logged-in");
    return (
        <>
            {
                cProps.childProps.isAuthenticated ?
                    <>
                        <Header userState={cProps.childProps} history={cProps.history}
                                handleClubPic={cProps.handleClubPic} clubPic={cProps.clubPic}
                                headerPic={cProps.headerPic} handleLogout={cProps.handleLogout}/>
                        <IeFooterMsg ieMsgClass={cProps.ieMsgClass} ieBarClose={cProps.ieBarClose}/>
                        <TimingMsgBar handleLogout={cProps.handleLogout}/>
                    </>
                    :
                    null
            }
            <OverlayBg/>
            <div className="container-fluid ipd-height100">
                <Row className="ipd-height100">
                    <Sidebar {...cProps.history} />
                    <Route
                        {...rest}
                        render={props =>
                            <PrivateRouteAuthenticatedResponse
                                props={props}
                                cProps={cProps}
                                permissionGranted={isAccessAllowed}

                                redirect={redirect}
                                component={C}
                            />
                        }
                    />
                </Row>
            </div>
        </>
    );
};

function queryString(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Generate response based on authentication and user roles
function PublicRouteResponse(attr) {
    // Default response to render required component
    if (!attr.cProps.childProps.isAuthenticated || attr.rest.commonRoute) {
        return (<attr.component {...attr.props} {...attr.cProps}  />);
    }
    // Redirect to dashboard after login
    else if (isDashboardAccessGranted()) {
        const isReferrer = attr.props.location.state && attr.props.location.state.referrer;
        let getReferrer = "/";
        if (isReferrer) {
            getReferrer = isReferrer;
        }
        return (<Redirect to={attr.redirect === "" || attr.redirect === null ? getReferrer : attr.redirect}/>)
    } else {
        return (<Redirect to={attr.redirect === "" || attr.redirect === null ? "teams" : attr.redirect}/>)
    }
}

// Generate response based on authentication and user roles
function PrivateRouteAuthenticatedResponse(attr) {
    // If user is not authenticated redirect to Login screen
    if (!attr.cProps.childProps.isAuthenticated) {
        //return (<Redirect to={attr.redirect === "" || attr.redirect === null ? "/login" : attr.redirect} />)
        const getReferrer = attr.props.location.pathname;
        return (<Redirect
            to={{
                pathname: attr.redirect === "" || attr.redirect === null ? "/login" : attr.redirect,
                state: {referrer: getReferrer && getReferrer === "/organization/review" ? getReferrer : null}
            }}
        />)

    }
        // If role access is not granted and component requested is Dashboard
    // redirect to teams view
    else if (!attr.permissionGranted && attr.props.match.path === "/") {
        return (<Redirect to={attr.redirect === "" || attr.redirect === null ? "teams" : attr.redirect}/>)
    }
        // If role access is not granted for the component
    // redirect to access denied screen
    else if (!attr.permissionGranted) {
        return (<Redirect to="/access-denied"/>)
    }
        // If logged in user is authenticated for the requested component
    // render the requested component
    else {
        return (<attr.component {...attr.props} {...attr.cProps}  />);
    }
}

//Check whether logged in user has
//access for the permissions passed in the route
function isAccessGranted(permissions) {
    let roles = [];
    let permissionGranted = false;

    const user = Storage.Get('user');

    //If any user roles info object is null access will not be granted
    if (user) {
        if (user.is_super_admin) {
            roles.push(Enum.UserRoles.SUPER_ADMIN);
        }
        if (user.is_isr) {
            roles.push(Enum.UserRoles.ISR);
        }
        const selectedClubId = user.selectedClubId;
        if (selectedClubId && selectedClubId !== 0) {
            roles.push(...user.role[selectedClubId]);
        }
        //compare with permissions attribute from
        if (roles && permissions) {
            for (let i = 0; i < roles.length; i++) {
                if (permissions.includes(roles[i])) {
                    permissionGranted = true;
                    break;
                }
            }
        }
    }
    return permissionGranted;
}

function isDashboardAccessGranted() {
    return (Helper.isCoachOrAdminLogin() || Helper.isSuperAdminLogin() || Helper.isISRLogin())
}

//#endregion
