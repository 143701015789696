/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Stepzilla
 * Name and Version: react-stepzilla, 6.0.2
 * Download link: https://www.npmjs.com/package/react-stepzilla
 */
import StepZilla from "react-stepzilla";
/* End - React Stepzilla */

/* Start - app imports */
import { AccountInformation } from './account-information';
import { KidInformation } from './kid-information';
import { ConsentUpdate } from './consent-update';

import { InviteReview } from './invitereview';
import * as Helper from '../../components/common/functions';
import { ErrorModal } from '../../components/modal/modalbox';
import { Messages } from '../../components/common/message';
import * as Storage from '../../components/common/storage';
import * as Enum from '../../components/common/enum';
import {Footer} from '../../components/footer/footer';
import {RegisterSuccess, InviteHeader, clubTeamName, RegisterError } from './invite-helper';
import './invite.scss';
/* End - app imports */

const nextText = {
    0: 'Next',
    1: 'Next',
    2: 'Next',
    3: 'Finish'
}
class InviteByInstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteDetails: Storage.Get('inviteDetails'),
            inviteToken: props.match.params.inviteToken,
            inviteError: "",
            serverValid: {
                guardian_first_name: "",
                guardian_last_name: "",
                guardian_email: "",
                guardian_username: "",
                guardian_phone: "",
                guardian_password: "",
                guardian_confirm_password: "",
                user_first_name: "",
                user_middle_name: "",
                user_last_name: "",
                user_email: "",
                user_username: "",
                user_birth_month: "",
                user_birth_day: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: "",
                initials: ""
            },
            serverSuccess: "",
            serverError: "",
            formProcessing: false,
            startAt: 0,
            registerMsg: "",
        };
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
    }

    componentDidMount() {
        const { inviteToken, inviteDetails } = this.state;
        if (inviteDetails === null || inviteDetails.invite_code === "" || inviteToken !== inviteDetails.invite_code) {
            this.setState({ inviteError: Enum.InviteError });
        }
        this.nextButton = document.getElementById("next-button");
    }

    handleStepChange = (step) => {
        this.setState({
            step: step
        })
    }

    handleDetailsSubmit(fields) {
        // update storage
        const inviteDetails = this.state.inviteDetails;
        // eslint-disable-next-line
        for (const index in fields) {
            inviteDetails[index] = fields[index];
        }
        Storage.Set('inviteDetails', JSON.stringify(inviteDetails));

        this.handleNextClick();
    }

    handleNextClick() {
        //click the next button of stepzilla
        if (this.nextButton) {
            this.nextButton.click();
        }

        const step = this.state.step;
        if (step === 3) {
            this.handleFinishClick();
        } else {
            Helper.scrollToTop();
        }
    }

    handlePrevClick() {
        Helper.scrollToTop();
        //click the previous button of stepzilla
        document.getElementById("prev-button").click();
    }

    resetServerError() {
        this.setState({
            serverValid : {
                guardian_first_name: "",
                guardian_last_name: "",
                guardian_email: "",
                guardian_username: "",
                guardian_phone: "",
                guardian_password: "",
                guardian_confirm_password: "",
                user_first_name: "",
                user_last_name: "",
                user_middle_name: "",
                user_email: "",
                user_username: "",
                user_birth_day: "",
                user_birth_month: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: "",
                initials: ""
            }
        });
    }

    handleFinishClick() {
        const me = this;
        this.setState({ formProcessing: true });
        let inviteDetails = this.state.inviteDetails,
            apiParams = {};
        if (inviteDetails.isKidChecked) {
            apiParams = {
                invite_code: inviteDetails.invite_code,
                guardian_first_name: inviteDetails.guardian_first_name,
                guardian_last_name: inviteDetails.guardian_last_name,
                guardian_email: inviteDetails.guardian_email,
                guardian_username: inviteDetails.guardian_username,
                guardian_phone: inviteDetails.guardian_phone,
                guardian_password: inviteDetails.guardian_password,
                guardian_confirm_password: inviteDetails.guardian_confirm_password,
                user_id: inviteDetails.radioButtonId,
                verification_required: 1
            }
        }
        else {
            // eslint-disable-next-line
            for (const index in inviteDetails) {
                if (index === "isKidChecked" || index === "associatedKids" || index === "radioButtonId") { continue; }
                apiParams[index] = inviteDetails[index];
            }
        }
        Helper.axiosInstance().post('register/lg', apiParams)
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    if (inviteDetails.isKidChecked) {
                        me.setState({ registerMsg: 'Your invite has been sent successfully and invite to the Guardian of the selected Student has also been sent.' })
                    }
                    else{
                        me.setState({ registerMsg: `You have been registered successfully with ${clubTeamName(inviteDetails)}` })
                    }
                    me.setState({ serverSuccess: response.data.message, formProcessing: false, });
                    Storage.Delete('inviteDetails');
                } else if(response.data.type == 'lg_pending_verification'){
                        this.props.history.push(`/verify-invite/${this.state.inviteToken}`);
                } else {

                    if(typeof response.data.type != 'undefined' && response.data.type == 'invite_code'){
                        this.setState({inviteError: Enum.InviteError});
                    } else {
                        // get the server form errors and set under state 'serverValid'
                        const getErrors = response.data.error;
                        me.resetServerError();

                        let serverValid = me.state.serverValid;
                        // eslint-disable-next-line
                        for (let errorIndex in getErrors) {
                            if (getErrors[errorIndex][0] !== "") {
                                serverValid[errorIndex] = getErrors[errorIndex][0];
                            }
                        }
                        me.setState({ serverValid, formProcessing: false });

                        let filteredNames = Object.keys(serverValid).filter((name) => {
                            return serverValid[name] !== "" ? /guardian_/.test(name) : "";
                        });

                        if (filteredNames.length) {
                            me.jumpToError(3);
                        } else {
                            me.jumpToError(2);
                        }
                    }
                }
            })
            .catch((error) => {
                me.setState({ serverError: error, formProcessing: false });
            });
    }

    jumpToError = (number) => {
        this.handlePrevClick();
        if (number > 1) this.jumpToError(number - 1);
    }

    render() {
        const { inviteDetails, inviteError, serverValid, serverSuccess, serverError, startAt, registerMsg,inviteToken } = this.state,
            steps = [
                {
                    name: 'Account Information', component:
                        <AccountInformation
                            inviteDetails={inviteDetails}
                            handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                            getLabelClass={Helper.getLabelClass}
                            serverValid={serverValid}
                            stepValidate={true}
                        />
                },
                {
                    name: 'Student Information', component:
                        <KidInformation
                            inviteDetails={inviteDetails}
                            handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                            getLabelClass={Helper.getLabelClass}
                            serverValid={serverValid}
                            handlePrevClick={this.handlePrevClick}
                            stepValidate={true}
                        />
                },
                {
                    name: 'Consent', component:
                        <ConsentUpdate
                            inviteDetails={inviteDetails}
                            handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                            getLabelClass={Helper.getLabelClass}
                            serverValid={serverValid}
                            handlePrevClick={this.handlePrevClick}
                            formProcessing={this.state.formProcessing}
                            handleRegister={false}
                        />
                },
                {
                    name: 'Review', component:
                        <InviteReview
                            inviteDetails={inviteDetails}
                            handlePrevClick={this.handlePrevClick}
                            handleNextClick={this.handleNextClick}
                            formProcessing={this.state.formProcessing}
                            formatDate={Helper.formatDate}
                        />
                }
            ];

        return (
            <>
                {
                    serverError !== "" ?
                        <ErrorModal
                            message={Messages.SERVER_ERROR}
                            show={true}
                            onConfirmClick={Helper.handleErrorClose}
                        />
                        :
                        null
                }
                <InviteHeader inviteDetails={inviteDetails} />

                <div className="container">
                    {
                        serverSuccess !== "" ?
                            <RegisterSuccess
                                registerMsg={registerMsg}
                            />
                            :
                            inviteError !== "" ?
                                <RegisterError
                                    inviteError={inviteError}
                                />
                            :

                                <Row className="content-space">
                                    <Col sm="12" md={8} lg={8} className="offset-md-2 offset-lg-2">
                                        <h1>Registration Process</h1>
                                    </Col>
                                    <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                                        <div className="widget invite-progress">
                                            <StepZilla
                                                steps={steps}
                                                startAtStep={startAt}
                                                nextButtonText={nextText[this.state.step]}
                                                showNavigation={false}
                                                stepsNavigation={false}
                                                preventEnterSubmission={true}
                                                prevBtnOnLastStep={true}
                                                onStepChange={this.handleStepChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                        }
                       <Footer />
                    </div>
                </>
            )
    }
}
export { InviteByInstructor };
