/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Container, Image} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/* Start - app imports */
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import './invite.scss';
/* End - app imports */

class ConsentUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: {
                initials: ""
            }
        };
    }

    componentDidMount(){
        const inviteDetails = this.props.inviteDetails;
        if(inviteDetails){
            const formFields = {
                initials: inviteDetails.initials ? inviteDetails.initials : ""
            };
            this.setState({formFields});
        }
    }
    
    handleFormSubmit = (fields) => {
        fields.initials = fields.initials.trim();
        this.props.handleFormSubmit(fields);
    }

    handleChange = (event, setFieldValue) => {
        const fields = this.state.formFields,
            key = event.target.name,
            value = event.target.value.toUpperCase();
        fields[key]= value;
        this.setState({ formFields : fields });
        setFieldValue(key,value);
    }

    render() {
        const  {formFields} = this.state,
            { getLabelClass, serverValid, handlePrevClick, formProcessing, handleRegister } = this.props;
        return (
            <>
                <Container>
                    <Row>
                        <Col md={10} className="offset-md-1 consent-desc">
                            <p>To comply with COPPA guidelines and for the protection of minors, this form must be completed to allow minors under 13 years of age to establish an account on app.safecommapp.com. SafeComm is a messaging app and website where users can message each other and upload digital content.</p>

                            <p>Safe Communications Consultants, LLC recognizes the obligation to protect any personal information obtained from children, and obtain your verifiable consent prior to any collection, use or disclosure of your child’s personal information. We urge you to instruct your children to never give out their real names, addresses, phone numbers, or other identifiable information when using the internet. Safe Communications Consultants, LLC is in compliance with COPPA (the Children’s Online Privacy Protection Act). Information provided below is for parental consent only and will not be shared with unaffiliated third parties per our <a href="/privacy-policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a>.</p>

                            <p>You may verify the terms of your child’s registration and allowable use of the site in accordance with our Privacy Policy as follows: I represent and warrant that the information provided below is true and accurate and is provided for the purpose of consenting to my child’s registration with safecommapp.com.</p>
                        </Col>
                    </Row>
                </Container>   
                <Formik
                    enableReinitialize
                    initialValues={formFields}
                    validationSchema={Yup.object().shape({
                        initials: Yup.string().trim()
                            .max(20, 'Initials are too Long!')
                            .required('Initials are required')
                            .matches(/^[a-zA-Z\s\\.]+$/, { message: 'Initials can contain letters, spaces and periods(.) only' })
                    })}
                    onSubmit={fields => {
                        this.handleFormSubmit(fields);
                    }}
                    render={({ errors, status, setFieldValue, touched, fields, values }) => (
                        <Form className="step-form-details" noValidate>
                            <div className="block-space">
                                <div className="field form-group a-card a-card--readonly">
                                    <label className="label-active a-card__label">Date</label>
                                    <span className="pl-2 pb-2 a-card__content">{Helper.formatDate(new Date())}</span>
                                </div>
                                <div className="field form-group">
                                    <Field name="initials"
                                        onChange={(e)=>this.handleChange(e,setFieldValue)}
                                        type="text" id="initials" onFocus={Helper.inputFocus} maxLength="20" className={'form-control' + ((errors.initials && touched.initials) || serverValid.initials !== '' ? ' is-invalid' : '')} placeholder="Letters only"/>
                                    <label htmlFor="initials" className={getLabelClass(values.initials)}>Initials</label>
                                    {
                                        (errors.initials && touched.initials) ?
                                            <ErrorMessage name="initials" component="div" className="invalid-feedback" />
                                            :
                                            <div className="invalid-feedback">{serverValid.initials}</div>
                                    }
                                </div>
                            </div>
                            
                            <div className="footer-buttons" style={this.state.hideFields}>
                                <button type="button" className="btn btn-next btn-primary btn-lg pull-left" onClick={handlePrevClick}>Previous</button>
                                {
                                    handleRegister ?    
                                        <button type="submit" disabled={formProcessing ? true : false} className={"btn align-self-center btn-medium btn-primary float-right" + (formProcessing ? " btn-loader" : "")}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /></div>
                                            Register
                                                <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                    :
                                        <button type="submit" className="btn btn-prev btn-primary btn-lg float-right">Next</button>
                                }
                            </div>
                        </Form>
                        )
                    }
                />
            </>
        )
    }
}

export { ConsentUpdate };