/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/* Start - app imports */
import * as Helper from '../../components/common/functions';
import * as Regex from '../../components/common/regex';
import * as Images from '../../components/common/images';
import './invite.scss';
import {Messages} from "../../components/common/message";
/* End - app imports */

class AccountInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: {
                guardian_first_name: "",
                guardian_last_name: "",
                guardian_email: "",
                guardian_username: "",
                guardian_phone: "",
                guardian_password: "",
                guardian_confirm_password: ""
            }
        };
    }

    componentDidMount(){
        const inviteDetails = this.props.inviteDetails;
        if(inviteDetails){
            const formFields = {
                guardian_first_name: inviteDetails.guardian_first_name ? inviteDetails.guardian_first_name : "",
                guardian_last_name: inviteDetails.guardian_last_name ? inviteDetails.guardian_last_name : "",
                guardian_email: inviteDetails.guardian_email ? inviteDetails.guardian_email : "",
                guardian_username: inviteDetails.guardian_username ? inviteDetails.guardian_username : "",
                guardian_phone: inviteDetails.guardian_phone ? inviteDetails.guardian_phone : "",
                guardian_password: inviteDetails.guardian_password ? inviteDetails.guardian_password : "",
                guardian_confirm_password: inviteDetails.guardian_confirm_password ? inviteDetails.guardian_confirm_password : "",
            }
            this.setState({formFields});
        }
    }

    handleFormSubmit = (fields) => {
        this.props.handleFormSubmit(fields);
    }

    render() {
        const { formFields } = this.state;
        const { getLabelClass, serverValid, formProcessing, stepValidate } = this.props;

        return (
            <Formik
                enableReinitialize
                initialValues={formFields}
                validationSchema={Yup.object().shape({
                    guardian_first_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    guardian_last_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    guardian_username: Yup.string().trim()
                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    guardian_email: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .email(Messages.INVALID_EMAIL)
                        .required(Messages.REQUIRED_ERR),
                    guardian_phone: Yup.string().trim()
                        .matches(Regex.phone, {
                            message: Messages.INVALID_PHONE,
                            excludeEmptyString: false
                        })
                        .max(15, Messages.INVALID_PHONE)
                        .required(Messages.REQUIRED_ERR),
                    guardian_password: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .min(8, Messages.MIN_LENGTH_8)
                        .required(Messages.REQUIRED_ERR)
                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                    guardian_confirm_password: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .oneOf([Yup.ref('guardian_password'), null], Messages.PASSWORD_CPASS_MATCH_ERR)
                })}
                onSubmit={fields => {
                    this.handleFormSubmit(fields);
                }}
                render={({ errors, status, touched,setFieldValue, values }) => (
                        <Form className="step-form-details" noValidate>
                            <div className="block-space">
                                <div className="field form-group">
                                    <Field name="guardian_first_name" type="text" id="guardian_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.guardian_first_name && touched.guardian_first_name) || serverValid.guardian_first_name !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="guardian_first_name" className={getLabelClass(values.guardian_first_name)}>First Name</label>
                                    {
                                        (errors.guardian_first_name && touched.guardian_first_name) ?
                                            <ErrorMessage name="guardian_first_name" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_first_name}</div>
                                    }
                                </div>

                                <div className="field form-group">
                                    <Field name="guardian_last_name" type="text" id="guardian_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.guardian_last_name && touched.guardian_last_name) || serverValid.guardian_last_name !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="guardian_last_name" className={getLabelClass(values.guardian_last_name)}>Last Name</label>
                                    {
                                        (errors.guardian_last_name && touched.guardian_last_name) ?
                                            <ErrorMessage name="guardian_last_name" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_last_name}</div>
                                    }
                                </div>
                                
                                {   
                                    formFields.guardian_email ?
                                        <div className="field form-group a-card a-card--readonly">
                                            <label className="label-active a-card__label">Email</label>
                                            <span name="guardian_email" className="pl-2 pb-2 a-card__content">{formFields.guardian_email}</span>
                                        </div>
                                : 
                                    <div className="field form-group">
                                        <Field name="guardian_email" type="email" id="guardian_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="70" className={'form-control' + ((errors.guardian_email && touched.guardian_email) || serverValid.guardian_email !== '' ? ' is-invalid' : '')} />
                                        <label htmlFor="guardian_email" className={getLabelClass(values.guardian_email)}>Email</label>
                                        {
                                            (errors.guardian_email && touched.guardian_email) ?
                                                <ErrorMessage name="guardian_email" component="div" className="invalid-feedback" />
                                            :
                                                <div className="invalid-feedback">{serverValid.guardian_email}</div>
                                        }
                                    </div>
                                }

                                <div className="field form-group">
                                    <Field name="guardian_username" type="text" id="guardian_username" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.guardian_username && touched.guardian_username) || serverValid.guardian_username !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="guardian_username" className={getLabelClass(values.guardian_username)}>Parent Username</label>
                                    {
                                        (errors.guardian_username && touched.guardian_username) ? 
                                            <ErrorMessage name="guardian_username" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_username}</div>
                                    }
                                </div>

                                <div className="field form-group">
                                    <Field name="guardian_phone" type="text">
                                        {({
                                            field, // { name, value, onChange, onBlur }
                                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.                                      
                                        }) => (
                                                <Helper.PhoneMaskedInput
                                                    field={field}
                                                    onChange={(e)=>{
                                                        formFields.guardian_phone=e.target.value;
                                                        setFieldValue('guardian_phone',e.target.value)}}
                                                    className={'form-control' + ((errors.guardian_phone && touched.guardian_phone) || serverValid.guardian_phone !== '' ? ' is-invalid' : '')} 
                                                    id="guardian_phone"
                                                />
                                            )}
                                    </Field>
                                    <label htmlFor="guardian_phone" className={getLabelClass(values.guardian_phone)}>Phone Number</label>
                                    {
                                        (errors.guardian_phone && touched.guardian_phone) ?
                                            <ErrorMessage name="guardian_phone" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_phone}</div>
                                    }
                                </div>

                                <div className="field form-group">
                                    <Field name="guardian_password" type="password" id="guardian_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.guardian_password && touched.guardian_password) || serverValid.guardian_password !== '' ? ' is-invalid' : '')} autoComplete="new-password"/>
                                    <label htmlFor="guardian_password" className={getLabelClass(values.guardian_password)}>Password</label>
                                    {
                                        (errors.guardian_password && touched.guardian_password) ? 
                                            <ErrorMessage name="guardian_password" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_password}</div>
                                    }
                                </div>

                                <div className="field form-group">
                                    <Field name="guardian_confirm_password" type="password" id="guardian_confirm_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.guardian_confirm_password && touched.guardian_confirm_password) || serverValid.guardian_confirm_password !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="guardian_confirm_password" className={getLabelClass(values.guardian_confirm_password)}>Confirm Password</label>
                                    {
                                        (errors.guardian_confirm_password && touched.guardian_confirm_password) ? 
                                            <ErrorMessage name="guardian_confirm_password" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.guardian_confirm_password}</div>
                                    }
                                </div>
                            </div>

                            {
                                stepValidate ?
                                    <div className="footer-buttons">
                                        <button type="submit" className="btn btn-prev btn-primary btn-medium btn-lg pull-right">Next</button>
                                    </div>
                                :
                                    <div className="widgetfoter-buttons justify-content-end">
                                        <button type="submit" disabled={formProcessing ? true : false} className={"btn align-self-center btn-medium btn-primary" + (formProcessing ? " btn-loader" : "")}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Register" /></div>
                                            Register
                                            <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                    </div> 
                            }
                        </Form>
                    )
                }
            />
        )
    }
}

export { AccountInformation };