export const TeamMessages = {
    PHOTO_UPLOADED: "Member group photo updated successfully.",
    MAX_LENGTH_50: "Please enter no more than 50 characters.",
    MAX_LENGTH_500: "Please enter no more than 500 characters.",
    NAME_REQUIRED_ERROR: "Member group name is required",
    DESC_REQUIRED_ERROR: "Member group description is required",
    INVALID_DATA: "Oops! Some error occured on the server, please try again.",
    PHOTO_UPLOAD_FAIL: "Photo failed to upload, please try again.",
    CONFIRM_REMOVE_GROUP: "Do you want to remove this contact from the member group?",
    DEACTIVATE_GROUP: "Do you want to deactivate this member group?",
    DEACTIVATE_MULTIPLE_GROUP: "Do you want to deactivate selected member group(s)?",
    ACTIVATE_GROUP: "Do you want to make this member group active?",
    PHOTO_MAX_SIZE: "Maximum file size allowed is 6 MB",
    PHOTO_TYPE_ALLOWED: "Please upload image with .jpeg or .jpg extension"
};