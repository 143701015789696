/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Stepzilla
 * Name and Version: react-stepzilla, 6.0.2
 * Download link: https://www.npmjs.com/package/react-stepzilla
 */
import StepZilla from "react-stepzilla";
/* End - React Stepzilla */

/* Start - app imports */
import { KidInformation } from './kid-information';
import * as Helper from '../../components/common/functions';
import { ErrorModal } from '../../components/modal/modalbox';
import { Messages } from '../../components/common/message';
import * as Storage from '../../components/common/storage';
import * as Enum from '../../components/common/enum';
import {Footer} from '../../components/footer/footer';
import { RegisterSuccess, InviteHeader, clubTeamName, RegisterError } from './invite-helper';
import './invite.scss';
import { ConsentUpdate } from './consent-update';
/* End - app imports */

const nextText = {
    0: 'Next',
    1: 'Finish'
}

class InviteStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteDetails: Storage.Get('inviteDetails'),
            inviteToken: props.match.params.inviteToken,
            inviteError: "",
            serverValid: {
                user_first_name: "",
                user_last_name: "",
                user_middle_name: "",
                user_email: "",
                user_username: "",
                user_birth_month: "",
                user_birth_day: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: "",
                initials: ""
            },
            serverSuccess: "",
            serverError: "",
            formProcessing: false,
            registerMsg: "",
            startAt: 0
        };
    }

    componentDidMount() {
        const { inviteToken, inviteDetails } = this.state;

        if (inviteDetails === null || inviteDetails.invite_code === "" || inviteToken !== inviteDetails.invite_code || inviteDetails.guardian_id === null) {
            this.setState({ inviteError: Enum.InviteError });
        }
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.nextButton = document.getElementById("next-button");
    }

    handleStepChange = (step) => {
        this.setState({
            step: step
        })
    }

    handlePrevClick() {
        Helper.scrollToTop();
        //click the previous button of stepzilla
        document.getElementById("prev-button").click();
    }

    resetServerError() {
        this.setState({
            serverValid: {
                user_first_name: "",
                user_middle_name: "",
                user_last_name: "",
                user_email: "",
                user_username: "",
                user_birth_month: "",
                user_birth_day: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: "",
                initials: ""
            }
        });
    }

    handleDetailsSubmit(fields) {
        // update storage
        let inviteDetails = this.state.inviteDetails;
        // eslint-disable-next-line
        for (const index in fields) {
            inviteDetails[index] = fields[index];
        }
        Storage.Set('inviteDetails', JSON.stringify(inviteDetails));
        this.handleNextClick();
    }

    handleNextClick() {
        //click the next button of stepzilla
        if (this.nextButton) {
            this.nextButton.click();
        }

        const step = this.state.step;
        if (step === 1) {
            this.handleFinishClick();
        } else {
            Helper.scrollToTop();
        }
    }

    handleFinishClick(fields) {
        const inviteToken = this.state.inviteToken;
        let inviteDetails = this.state.inviteDetails,
            apiParams = {};
        this.setState({ formProcessing: true });


        const me = this;
        if (inviteDetails.isKidChecked) {
            apiParams = {
                invite_code: inviteDetails.invite_code,
                guardian_id: inviteDetails.guardian_id,
                user_id: inviteDetails.radioButtonId,
                verification_required: 1
            }
        }
        else {
            // eslint-disable-next-line
            for (const index in inviteDetails) {
                if (index === "isKidChecked" || index === "associatedKids" || index === "radioButtonId") { continue; }
                apiParams[index] = inviteDetails[index];
            }
        }

        // Api request to save student Info.
        Helper.axiosInstance().post('register/lg', apiParams)
            .then((response) => {
                // If server response message same as Data Matched
                if (response.data.success) {
                    if (inviteDetails.isKidChecked) {
                        me.setState({ registerMsg: 'The legal guardian you are associating with your student has been invited' })
                    }
                    else {
                        me.setState({ registerMsg: `Student has been registered successfully with ${clubTeamName(inviteDetails)}` })
                    }
                    me.setState({ serverSuccess: response.data.message, formProcessing: false, });
                    Storage.Delete('inviteDetails');
                } else if(response.data.type == 'lg_pending_verification'){
                    this.props.history.push({
                        pathname: `/verify-invite/${inviteToken}`,
                        errorMsg: response.data.error ? response.data.error :''
                    });

                } else {
                    if(typeof response.data.type != 'undefined' && response.data.type == 'invite_code'){
                        this.setState({inviteError: Enum.InviteError});
                    } else {
                        // get the server form errors and set under state 'serverValid'
                        const getErrors = response.data.error;
                        me.resetServerError();

                        let serverValid = me.state.serverValid;
                        // eslint-disable-next-line
                        for (let errorIndex in getErrors) {
                            if (getErrors[errorIndex][0] !== "") {
                                serverValid[errorIndex] = getErrors[errorIndex][0];
                            }
                        }
                        me.setState({ serverValid, formProcessing: false });
                        this.handlePrevClick();
                    }
                }
            })
            .catch((error) => {
                me.setState({ serverError: error, formProcessing: false });
            });
    }
  
    render() {
        const {inviteDetails, inviteError, serverValid, serverSuccess, serverError, formProcessing, registerMsg, startAt} = this.state,
            steps = [
                {
                    name: 'Student Information', component:
                        <>
                            <div className="block-space block-space--pb-0">
                                Please fill the information below to complete {Enum.Student}'s registration with <strong>{clubTeamName(inviteDetails)}</strong>.
                            </div> 
                            <KidInformation
                                inviteDetails={inviteDetails}
                                handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                                getLabelClass={Helper.getLabelClass}
                                serverValid={serverValid}
                                stepValidate={true}
                            />
                        </>
                },
                {
                    name: 'Consent', component:
                        <ConsentUpdate
                            inviteDetails={inviteDetails}
                            handleFormSubmit={(fields) => this.handleDetailsSubmit(fields)}
                            getLabelClass={Helper.getLabelClass}
                            serverValid={serverValid}
                            handlePrevClick={this.handlePrevClick}
                            formProcessing={formProcessing}
                            handleRegister={true}
                        />
                }
            ];
        return (
            <>
                {
                    serverError !== "" ?
                        <ErrorModal
                            message={Messages.SERVER_ERROR}
                            show={true}
                            onConfirmClick={Helper.handleErrorClose}
                        />
                        :
                        null
                }

                <InviteHeader inviteDetails={inviteDetails} />

                    <div className="container">
                        {
                            serverSuccess !== "" ?
                                <RegisterSuccess 
                                    registerMsg={registerMsg}
                                />
                            :   
                                inviteError !== "" ?
                                    <RegisterError
                                        inviteError={inviteError}
                                    /> 
                                :
                                    <Row className="content-space">
                                        <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                                            <h1>Student Registration</h1>
                                        </Col>
                                        <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                                            <div className="widget invite-progress invite-progress--student">
                                                <StepZilla 
                                                    steps={steps} 
                                                    startAtStep={startAt}
                                                    nextButtonText={nextText[this.state.step]} 
                                                    showNavigation={false} 
                                                    stepsNavigation={false} 
                                                    preventEnterSubmission={true}
                                                    prevBtnOnLastStep={true} 
                                                    onStepChange={this.handleStepChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                            }
                        <Footer />
                    </div>
                </>  
            )  
    }
}
export { InviteStudent };