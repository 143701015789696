/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import './club.scss';
import { CreateClubMessages } from './create-club-message';
import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import {PhoneInput} from "../../components/input/phone";
import * as Regex from "../../components/common/regex";


class PointOfContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
        };

        this.isValidated = this.isValidated.bind(this);
        this.fields = {}
        this.submitFormRef = null;
        this.isFormValid = false;
        this.hasValidateMethodRun = false;
        this.fields = {};
        if (this.props.data) {
            this.fields = this.props.data.fields;
        }
    }

    isValidated() {

        if (this.fields.emailGeneral !== "" && !this.hasValidateMethodRun) {
            this.props.handleFormSubmit(this.fields);
            return true;
        }
        else {
            console.log("else");
            if (this.submitFormRef)
                this.submitFormRef();

            if (this.isFormValid) {
                this.props.handleFormSubmit(this.fields);
            }
            console.log("is form valid:" + this.isFormValid);
            return this.isFormValid;
        }
    }

    bindSubmitForm = (submitForm, errors, isValid) => {

        console.log("bindSubmitForm");
        console.log(isValid);
        console.log(errors);
        console.log(this.props.data.firstLoad);


        if (!this.submitFormRef)
            this.submitFormRef = submitForm;
        this.isFormValid = this.props.data.firstLoad ? isValid : Object.keys(errors).length === 0;
    };


    onContactCheckboxChange = (event, setFieldValue, values) => {
        if (event.target.checked) {
            setFieldValue("emailGeneral", this.props.generalInfoFields.adminEmail);
            setFieldValue("firstNameGeneral", this.props.generalInfoFields.adminFirstName);
            setFieldValue("lastNameGeneral", this.props.generalInfoFields.adminLastName);
            setFieldValue("phoneGeneral", this.props.generalInfoFields.adminPhone);
            setFieldValue("phoneGeneralCode", this.props.generalInfoFields.adminPhoneCode);
            //this.setState({setCode:this.props.generalInfoFields.adminPhoneCode});
        }else{
            setFieldValue("emailGeneral", '')
            setFieldValue("firstNameGeneral", '')
            setFieldValue("lastNameGeneral", '')
            setFieldValue("phoneGeneral", '')
            setFieldValue("phoneGeneralCode", '+1')
            //this.setState({setCode:''});

        }
    }
    onBillingCheckboxChange = (event, setFieldValue, values) => {
        if (event.target.checked) {
            setFieldValue("emailBilling", this.props.generalInfoFields.adminEmail);
            setFieldValue("firstNameBilling", this.props.generalInfoFields.adminFirstName);
            setFieldValue("lastNameBilling", this.props.generalInfoFields.adminLastName);
            setFieldValue("phoneBilling", this.props.generalInfoFields.adminPhone);
            setFieldValue("phoneBillingCode", this.props.generalInfoFields.adminPhoneCode);
        }else{
            setFieldValue("emailBilling", '')
            setFieldValue("firstNameBilling", '')
            setFieldValue("lastNameBilling", '')
            setFieldValue("phoneBilling", '')
            setFieldValue("phoneBillingCode", '+1')
        }
    }

    render() {
        let labelClassInActive = "form-label";
        let labelClassActive = "form-label label-active";
        let me = this;
        console.log('me.props', me.props)
        const formContent = (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    emailGeneral: this.fields.emailGeneral,
                    firstNameGeneral: this.fields.firstNameGeneral,
                    lastNameGeneral: this.fields.lastNameGeneral,
                    phoneGeneral: this.fields.phoneGeneral,
                    phoneGeneralCode: this.fields.phoneGeneralCode ? this.fields.phoneGeneralCode :'+1',
                    emailContact2: this.fields.emailContact2,
                    firstNameContact2: this.fields.firstNameContact2,
                    lastNameContact2: this.fields.lastNameContact2,
                    phoneContact2: this.fields.phoneContact2,
                    phoneContact2Code: this.fields.phoneContact2Code ? this.fields.phoneContact2Code :'+1',
                    emailBilling: this.fields.emailBilling,
                    firstNameBilling: this.fields.firstNameBilling,
                    lastNameBilling: this.fields.lastNameBilling,
                    phoneBilling: this.fields.phoneBilling,
                    phoneBillingCode: this.fields.phoneBillingCode ? this.fields.phoneBillingCode :'+1',
                }}
                validationSchema={Yup.object().shape({
                    emailGeneral: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .email(Messages.INVALID_EMAIL)
                        .required(CreateClubMessages.EMAILGENERAL_REQUIRED_ERROR),
                    firstNameGeneral:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.FIRST_NAME_REQUIRED_ERROR),
                    lastNameGeneral:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.LAST_NAME_REQUIRED_ERROR),
                    phoneGeneral: Yup.string().trim()
                        .matches(Regex.phone, {
                            message: Messages.INVALID_PHONE,
                            excludeEmptyString: false
                        })
                        .max(15, Messages.INVALID_PHONE)
                        .required(Messages.REQUIRED_ERR),
                    emailContact2: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .email(Messages.INVALID_EMAIL)
                        .required(CreateClubMessages.EMAILCONTACT2_REQUIRED_ERROR),
                    firstNameContact2:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.FIRST_NAME_REQUIRED_ERROR),
                    lastNameContact2:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.LAST_NAME_REQUIRED_ERROR),
                    phoneContact2: Yup.string().trim()
                        .matches(Regex.phone, {
                            message: Messages.INVALID_PHONE,
                            excludeEmptyString: false
                        })
                        .max(15, Messages.INVALID_PHONE)
                        .required(Messages.REQUIRED_ERR),
                    firstNameBilling:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.FIRST_NAME_REQUIRED_ERROR),
                    lastNameBilling:Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(CreateClubMessages.LAST_NAME_REQUIRED_ERROR),
                    phoneBilling: Yup.string().trim()
                        .matches(Regex.phone, {
                            message: Messages.INVALID_PHONE,
                            excludeEmptyString: false
                        })
                        .max(15, Messages.INVALID_PHONE)
                        .required(Messages.REQUIRED_ERR),
                    emailBilling: Yup.string().trim()
                        .max(150, Messages.MAX_LENGTH_150)
                        .email(Messages.INVALID_EMAIL)
                        .required(CreateClubMessages.EMAILBILL_REQUIRED_ERROR),
                })}
                validate={(values) => { this.hasValidateMethodRun = true; if (this.isFormValid) { this.fields = values; } }}
                render={
                    (formikProps) => {
                        const { errors, touched, submitForm, isValid, values,setFieldValue } = formikProps;
                        this.bindSubmitForm(submitForm, errors, isValid);
                        return (
                            <Form noValidate>
                                 <div className="row">
                                    <Col md={12}>
                                        <div className="block-heading mb-2">
                                            Main Contact Information
                                        </div>
                                        {
                                            !me.props.isLoggedIn || me.props.isSuperAdmin ?
                                            <div className="form-group">
                                                <div className="checkbox-wrap pt-2">
                                                    <input type="checkbox" id="chkForContact" onChange={(e) => this.onContactCheckboxChange(e, setFieldValue, values)} />
                                                    <label htmlFor="chkForContact">Same as Administrator</label>
                                                </div>
                                            </div> : null
                                        }
                                    </Col>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="firstNameGeneral" type="text" id="firstNameGeneral" autoComplete="off" className={'form-control' + (errors.firstNameGeneral && touched.firstNameGeneral ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="firstNameGeneral" className={values.firstNameGeneral === "" ? labelClassInActive : labelClassActive}>First Name</label>
                                            <ErrorMessage name="firstNameGeneral" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="lastNameGeneral" type="text" id="lastNameGeneral" autoComplete="off" className={'form-control' + (errors.lastNameGeneral && touched.lastNameGeneral ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="lastNameGeneral" className={values.lastNameGeneral === "" ? labelClassInActive : labelClassActive}>Last Name</label>
                                            <ErrorMessage name="lastNameGeneral" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <PhoneInput
                                                parent={this}
                                                codeField={'phoneGeneralCode'}
                                                defaultCode={this.fields.phoneGeneralCode ? this.fields.phoneGeneralCode : '+1'}
                                                phoneField={'phoneGeneral'}
                                                fieldLabel={'Phone'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={false}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="emailGeneral" type="text" id="emailGeneral" autoComplete="off" className={'form-control' + (errors.emailGeneral && touched.emailGeneral ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="emailGeneral" className={values.emailGeneral === "" ? labelClassInActive : labelClassActive}>Email Address </label>
                                            <ErrorMessage name="emailGeneral" component="div" className="invalid-feedback" />
                                        </div>  
                                    </div> 
                                </div>
                                <div className="row">
                                    <Col md={12}>
                                        <div className="block-heading mb-2">
                                            Secondary Contact Information
                                        </div>
                                    </Col>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="firstNameContact2" type="text" id="firstNameContact2" autoComplete="off" className={'form-control' + (errors.firstNameContact2 && touched.firstNameContact2 ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="firstNameContact2" className={values.firstNameContact2 === "" ? labelClassInActive : labelClassActive}>First Name</label>
                                            <ErrorMessage name="firstNameContact2" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="lastNameContact2" type="text" id="lastNameContact2" autoComplete="off" className={'form-control' + (errors.lastNameContact2 && touched.lastNameContact2 ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="lastNameContact2" className={values.lastNameContact2 === "" ? labelClassInActive : labelClassActive}>Last Name</label>
                                            <ErrorMessage name="lastNameContact2" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <PhoneInput
                                                parent={this}
                                                codeField={'phoneContact2Code'}
                                                defaultCode={this.fields.phoneContact2Code ? this.fields.phoneContact2Code : '+1'}
                                                phoneField={'phoneContact2'}
                                                fieldLabel={'Phone'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={false}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="emailContact2" type="text" id="emailContact2" autoComplete="off" className={'form-control' + (errors.emailContact2 && touched.emailContact2 ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="emailContact2" className={values.emailContact2 === "" ? labelClassInActive : labelClassActive}>Email Address </label>
                                            <ErrorMessage name="emailContact2" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Col md={12}>
                                        <div className="block-heading mb-2">
                                            Billing Contact Information
                                        </div>
                                        {
                                            !me.props.isLoggedIn || me.props.isSuperAdmin ?
                                            <div className="form-group">
                                                <div className="checkbox-wrap pt-2">
                                                    <input type="checkbox" id="chkForBilling"
                                                           onChange={(e) => this.onBillingCheckboxChange(e, setFieldValue, values)}/>
                                                    <label htmlFor="chkForBilling">Same as Administrator</label>
                                                </div>
                                            </div> : null
                                        }
                                    </Col>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="firstNameBilling" type="text" id="firstNameBilling" autoComplete="off" className={'form-control' + (errors.firstNameBilling && touched.firstNameBilling ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="firstNameBilling" className={values.firstNameBilling === "" ? labelClassInActive : labelClassActive}>First Name</label>
                                            <ErrorMessage name="firstNameBilling" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="lastNameBilling" type="text" id="lastNameBilling" autoComplete="off" className={'form-control' + (errors.lastNameBilling && touched.lastNameBilling ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="lastNameBilling" className={values.lastNameBilling === "" ? labelClassInActive : labelClassActive}>Last Name</label>
                                            <ErrorMessage name="lastNameBilling" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>    
                                </div> 
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <PhoneInput
                                                parent={this}
                                                codeField={'phoneBillingCode'}
                                                defaultCode={this.fields.phoneBillingCode ? this.fields.phoneBillingCode : '+1'}
                                                phoneField={'phoneBilling'}
                                                fieldLabel={'Phone'}
                                                errors={errors}
                                                touched={touched}
                                                serverValid={false}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                        </div>
                                    </div>    
                                    <div className="col-md-6">
                                        <div className="field form-group">
                                            <Field name="emailBilling" type="text" id="emailBilling" autoComplete="off" className={'form-control' + (errors.emailBilling && touched.emailBilling ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                            <label htmlFor="emailBilling" className={values.emailBilling === "" ? labelClassInActive : labelClassActive}>Email Address </label>
                                            <ErrorMessage name="emailBilling" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>        
                            </Form>
                        )
                    }
                }
            />
        )
        return (
            <>
                <Row className="steps-content">
                    <Col sm={10} className="offset-sm-1">
                        {formContent}
                    </Col>
                </Row>
            </>
        )
    }
}
export { PointOfContact };