/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Table, Media } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - React Date Picker
 * Name and Version: react-datepicker, 2.9.6
 * Download link: https://www.npmjs.com/package/react-datepicker
 */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* End - React Date Picker */

/* Start - app imports */
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Months } from '../../components/common/months';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Enum from '../../components/common/enum';
import {Messages} from "../../components/common/message";
import './invite.scss';
import * as Regex from "../../components/common/regex";
/* End - app imports */

class KidInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: {
                user_first_name: "",
                user_middle_name: "",
                user_last_name: "",
                user_email: "",
                user_username: "",
                user_birth_month: "",
                user_birth_day: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: "",

            },
            associatedKids: null,
            hideFields: { display: 'block' },
            kidDataLoader: false,
            kidCheckData: {
                fName: '',
                lName: '',
                mName: '',
                bDay: '',
                bMonth: ''
            },
            radio: null,
            lgLimitError: '',
            monthDays: (props.inviteDetails != null && props.inviteDetails.user_birth_month) ? Helper.returnNoOfDays(props.inviteDetails.user_birth_month) : null,
            selectedMonth: (props.inviteDetails != null && props.inviteDetails.user_birth_month) ? props.inviteDetails.user_birth_month : '',
            selectedDay: (props.inviteDetails != null && props.inviteDetails.user_birth_day) ? props.inviteDetails.user_birth_day : '',
        };
    }

    componentDidMount() {
        const inviteDetails = this.props.inviteDetails;
        if (inviteDetails) {
            const formFields = {
                user_first_name: inviteDetails.user_first_name ? inviteDetails.user_first_name : "",
                user_last_name: inviteDetails.user_last_name ? inviteDetails.user_last_name : "",
                user_middle_name: inviteDetails.user_middle_name ? inviteDetails.user_middle_name : "",
                user_birth_month: inviteDetails.user_birth_month ? inviteDetails.user_birth_month : "",
                user_birth_day: inviteDetails.user_birth_day ? inviteDetails.user_birth_day : "",
                user_email: inviteDetails.user_email ? inviteDetails.user_email : "",
                user_username: inviteDetails.user_username ? inviteDetails.user_username : "",
                user_phone: inviteDetails.user_phone ? inviteDetails.user_phone : "",
                user_password: inviteDetails.user_password ? inviteDetails.user_password : "",
                user_confirm_password: inviteDetails.user_confirm_password ? inviteDetails.user_confirm_password : "",
                userHasEmail: inviteDetails.userHasEmail,
            }
            this.setState({ formFields });
            if (inviteDetails.isKidChecked) {
                this.setState({
                    associatedKids: inviteDetails.associatedKids, hideFields: { display: 'none' }, kidDataLoader: false, radio: inviteDetails.radioButtonId,
                    kidCheckData: { fName: inviteDetails.user_first_name, mName: inviteDetails.user_middle_name, lName: inviteDetails.user_last_name, bMonth: inviteDetails.user_birth_month, bDay: inviteDetails.user_birth_day }
                });
            }
        }
        this.userDobLabel = document.getElementById("user_dob_label");
    }

    handleFormSubmit = (fields) => {
        const userBirthMonth = this.state.selectedMonth;
        fields.user_birth_month = userBirthMonth;
        fields.isKidChecked = false;
        fields.associatedKids = null;
        fields.radioButtonId = null;
        this.props.handleFormSubmit(fields);
    }

    setAssociatedKid = () => {
        var fields = {
            user_first_name: this.state.kidCheckData.fName,
            user_middle_name: this.state.kidCheckData.mName,
            user_last_name: this.state.kidCheckData.lName,
            user_birth_day: this.state.kidCheckData.bDay,
            user_birth_month: this.state.kidCheckData.bMonth,
            isKidChecked: true,
            associatedKids: this.state.associatedKids,
            radioButtonId: this.state.radio,
        }
        this.props.handleFormSubmit(fields);

    }

    checkStudentExists = (event, type) => {
        if (!type) {
            Helper.inputBlur(event);
        }
        var data = {
            first_name: this.state.formFields.user_first_name,
            middle_name: this.state.formFields.user_middle_name,
            last_name: this.state.formFields.user_last_name,
            birth_month: this.state.formFields.user_birth_month,
            birth_day: this.state.formFields.user_birth_day
        };
        if (data.first_name.trim() !== "" && data.middle_name.trim() !== "" && data.last_name.trim() !== "" && data.birth_month !== "" && data.birth_day !== "") {
            if (this.state.kidCheckData.fName !== data.first_name || this.state.kidCheckData.mName !== data.middle_name || this.state.kidCheckData.lName !== data.last_name || this.state.kidCheckData.bDay !== data.birth_day  || this.state.kidCheckData.bMonth !== data.birth_month ) {
                this.setState({ kidDataLoader: true });
                Helper.axiosInstance().post('user/by-name-dob', data)
                    .then((response) => {
                        if (response.data.success) {
                            this.setState({
                                lgLimitError: '',
                                associatedKids: response.data.data, hideFields: { display: 'none' }, kidDataLoader: false,
                                kidCheckData: { fName: data.first_name, lName: data.last_name, mName: data.middle_name, bDay: data.birth_day,  bMonth: data.birth_month}
                            });
                        }
                        else {
                            let lgMaxError = '';
                            let hideFields = { display: 'block' };
                            if(typeof response.data.errorType != 'undefined' && response.data.errorType != ''){
                                lgMaxError = response.data.error;
                                hideFields = { display: 'none' };
                            }
                            this.setState({
                                lgLimitError: lgMaxError,
                                associatedKids: null, hideFields: hideFields, kidDataLoader: false,
                                kidCheckData: { fName: data.first_name, lName: data.last_name, mName: data.middle_name, bDay: data.birth_day,  bMonth: data.birth_month}, radio: null
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            lgLimitError: '',
                            associatedKids: null, hideFields: { display: 'block' }, kidDataLoader: false,
                            kidCheckData: { fName: '', lName: '', mName: '', bDay: '', bMonth: '' }, radio: null
                        });
                    });
            }
        }
    }
    handleChange = (event, setFieldValue) => {
        const fields = this.state.formFields;
        if(event.target.name == 'user_birth_month'){
            let selectedMonth = event.target.options[event.target.selectedIndex].dataset.id;
            fields[event.target.name]= selectedMonth;
        } else {
            fields[event.target.name]= event.target.value;
        }
        this.setState({ formFields : fields })
        setFieldValue(event.target.name,event.target.value)
    }

    toggleFields = () => {
        this.setState({
            associatedKids: null,
            hideFields: { display: 'block' }
        })
    }

    radioChange = (event) => {
        this.setState({
            radio: event.target.value
        });
    }

    onBirthMonthChange = (event, setFieldValue) => {
        setFieldValue('user_birth_month', event.target.value);
        setFieldValue('user_birth_day', '');
        let selectedMonth = event.target.options[event.target.selectedIndex].dataset.id;
        let totalDays = Helper.returnNoOfDays(selectedMonth);
        this.setState({
            monthDays: totalDays,
            selectedMonth: selectedMonth,
            selectedDay: ''
        });
        this.handleChange(event, setFieldValue);
    }

    onBirthDayChange = (event, setFieldValue) => {
        setFieldValue('user_birth_day', event.target.value);
        this.setState({
            selectedDay: event.target.value
        });
        this.handleChange(event, setFieldValue);
    }

    render() {
        const { formFields, associatedKids, kidDataLoader, monthDays } = this.state,
            { getLabelClass, serverValid, handlePrevClick } = this.props;

        let optionsHtml = [];

        for(let i = 1; i <= monthDays; i++) {
            optionsHtml.push(<option key={i} value={i} >{i}</option>)
        }
        return (
            <Formik
                enableReinitialize
                initialValues={formFields}
                validationSchema={Yup.object().shape({
                    user_first_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_middle_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_last_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_email: Yup.string().trim()
                        .email(Messages.INVALID_EMAIL)
                        .max(70, Messages.MAX_LENGTH_50),
                    user_username: Yup.string().trim()
                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_birth_month: Yup.string().trim()
                        .required('Month of birth is required'),
                    user_birth_day: Yup.string().trim()
                        .required('Day of birth is required'),
                    user_phone: Yup.string().trim()
                        .matches(Regex.phone, { message: Messages.INVALID_PHONE, excludeEmptyString: false })
                        .max(15, Messages.INVALID_PHONE),
                    user_password: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .min(8, Messages.MIN_LENGTH_8)
                        .required(Messages.REQUIRED_ERR)
                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                    user_confirm_password: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .oneOf([Yup.ref('user_password'), null], Messages.PASSWORD_CPASS_MATCH_ERR)
                })}
                onSubmit={fields => {
                    this.handleFormSubmit(fields);
                }}
                render={({ errors, status, setFieldValue, touched, fields, values }) => (
                    <Form className="step-form-details" noValidate>
                        <div className="block-space">
                            <div className="field form-group">
                                <Field name="user_first_name"
                                    onChange={(e)=>this.handleChange(e,setFieldValue)}
                                    onBlur={this.checkStudentExists}
                                    type="text" id="user_first_name" onFocus={Helper.inputFocus} maxLength="50" className={'form-control' + ((errors.user_first_name && touched.user_first_name) || serverValid.user_first_name !== '' ? ' is-invalid' : '')} />
                                <label htmlFor="user_first_name" className={getLabelClass(values.user_first_name)}>First Name</label>
                                {
                                    (errors.user_first_name && touched.user_first_name) ?
                                        <ErrorMessage name="user_first_name" component="div" className="invalid-feedback" />
                                        :
                                        <div className="invalid-feedback">{serverValid.user_first_name}</div>
                                }
                            </div>
                            <div className="field form-group">
                                <Field name="user_middle_name"
                                    onChange={(e)=>this.handleChange(e,setFieldValue)}
                                    onBlur={this.checkStudentExists}
                                    type="text" id="user_middle_name" onFocus={Helper.inputFocus} maxLength="50" className={'form-control' + ((errors.user_middle_name && touched.user_middle_name) || serverValid.user_middle_name !== '' ? ' is-invalid' : '')} />
                                <label htmlFor="user_middle_name" className={getLabelClass(values.user_middle_name)}>Middle Name</label>
                                {
                                    (errors.user_middle_name && touched.user_middle_name) ?
                                        <ErrorMessage name="user_middle_name" component="div" className="invalid-feedback" />
                                        :
                                        <div className="invalid-feedback">{serverValid.user_middle_name}</div>
                                }
                            </div>

                            <div className="field form-group">
                                <Field name="user_last_name"
                                   onChange={(e)=>this.handleChange(e,setFieldValue)}
                                    onBlur={this.checkStudentExists}
                                    type="text" id="user_last_name" onFocus={Helper.inputFocus} maxLength="50" className={'form-control' + ((errors.user_last_name && touched.user_last_name) || serverValid.user_last_name !== '' ? ' is-invalid' : '')} />
                                <label htmlFor="user_last_name" className={getLabelClass(values.user_last_name)}>Last Name</label>
                                {
                                    (errors.user_last_name && touched.user_last_name) ?
                                        <ErrorMessage name="user_last_name" component="div" className="invalid-feedback" />
                                        :
                                        <div className="invalid-feedback">{serverValid.user_last_name}</div>
                                }
                            </div>
                            <div className="field-month-date">
                                <div className="field form-group field--month">
                                    <div className="select-wrap">
                                        <Field component="select" placeholder="Select Birth Month"
                                            className={'form-control' + ((errors.user_birth_month && touched.user_birth_month) || serverValid.user_birth_month !== '' ? ' is-invalid' : '')}
                                            name="user_birth_month" id="user_birth_month"
                                            onChange={(event) => this.onBirthMonthChange(event, setFieldValue)}
                                            onBlur={this.checkStudentExists}
                                            value= {Months[this.state.selectedMonth]}
                                        >
                                            <option value="">Select Birth Month</option>
                                            {
                                                Object.keys(Months).map((key) => {
                                                    return (
                                                        <option key={key} value={Months[key]} data-id={key}>{Months[key]}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                        {
                                        (errors.user_birth_month && touched.user_birth_month) ?
                                            <ErrorMessage name="user_birth_month" component="div" className="invalid-feedback" />
                                            :
                                            <div className="invalid-feedback">{serverValid.user_birth_month}</div>
                                        }
                                    </div>
                                </div>

                                <div className="field form-group field--date">
                                    <div className="select-wrap">
                                        <Field component="select" placeholder="Select Birth Day"
                                            className={'form-control' + ((errors.user_birth_day && touched.user_birth_day) || serverValid.user_birth_day !== '' ? ' is-invalid' : '')}
                                            name="user_birth_day" id="user_birth_day"
                                            onChange={(event) => this.onBirthDayChange(event, setFieldValue)}
                                            onBlur={this.checkStudentExists}    
                                            value = {this.state.selectedDay}
                                        >
                                            <option value="">Select Birth Day</option>
                                            {optionsHtml}
                                        </Field>
                                        {
                                        (errors.user_birth_day && touched.user_birth_day) ?
                                            <ErrorMessage name="user_birth_day" component="div" className="invalid-feedback" />
                                            :
                                            <div className="invalid-feedback">{serverValid.user_birth_day}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {kidDataLoader ?
                                <>
                                    <SmallLoader />
                                    <div className="text-center loader-msg">Kindly wait for sometime</div>
                                </> :
                                <div>
                                    {associatedKids ?
                                        <div className="elements-outer">
                                            <h1>Student already registered?</h1>
                                            <h3>We found following matches:</h3>
                                            <Table className="tab-responsive table--elements table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Name </th>
                                                        <th>Legal Guardian</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {associatedKids.map((row, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="radio">
                                                                        <input id={i} name={row.name} type="radio" value={row.id} checked={parseInt(this.state.radio, 10) === row.id} onChange={this.radioChange} />
                                                                        <label htmlFor={i} className="radio-label"></label>
                                                                    </div>
                                                                </td>
                                                                <td data-label="Name" className="td-content-space">
                                                                    <Media>
                                                                        <Media.Body className="align-self-center">
                                                                            <h5>{row.name}</h5>
                                                                        </Media.Body>
                                                                    </Media>
                                                                </td>
                                                                <td data-label="Legal Guardian" className="td-content-space">
                                                                    <Media>
                                                                        <Media.Body className="align-self-center">
                                                                            {row.guardian.map((guardians, j) => {
                                                                                return (<h5 key={j}>{j + 1}{'. ' + row.guardian[j]}</h5>)
                                                                            })}
                                                                        </Media.Body>
                                                                    </Media>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </Table>
                                            <div className="elements-outer__footer">
                                                {
                                                    handlePrevClick &&
                                                    <button type="button" className="btn btn-medium btn-primary btn-cancel btn-cancel--width-auto pull-left" onClick={handlePrevClick}>
                                                        Previous</button>
                                                }
                                                <div className={handlePrevClick ? "pull-right" : 'd-flex justify-content-between w-100'}>
                                                    <button type="button" className="btn btn-medium ripple btn-cancel btn-cancel--width-auto" onClick={this.toggleFields}>No</button>
                                                    <button type="button" disabled={this.state.radio === null ? true : false} className="ripple btn-medium btn btn-primary d-inline-flex align-items-center btn-ternary--width-auto btn ml-3"
                                                        onClick={this.setAssociatedKid}>
                                                        <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Member" /></div> Yes</button>
                                                </div>
                                            </div>
                                        </div> : null}
                                    <div style={this.state.hideFields}>
                                        {
                                            formFields.userHasEmail ?
                                                <div className="field form-group a-card a-card--readonly">
                                                    <label className="label-active a-card__label">Email</label>
                                                    <span name="user_email" className="pl-2 pb-2 a-card__content">{formFields.user_email}</span>
                                                </div>
                                                :
                                                <div className="field form-group">
                                                    <Field name="user_email" onChange={(e)=>this.handleChange(e,setFieldValue)} type="email" id="user_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="70" className={'form-control' + ((errors.user_email && touched.user_email) || serverValid.user_email !== '' ? ' is-invalid' : '')} />
                                                    <label htmlFor="user_email" className={getLabelClass(values.user_email)}>Email (Optional)</label>
                                                    {
                                                        (errors.user_email && touched.user_email) ?
                                                            <ErrorMessage name="user_email" component="div" className="invalid-feedback" />
                                                            :
                                                            <div className="invalid-feedback">{serverValid.user_email}</div>
                                                    }
                                                </div>
                                        }
                                        <div className="opacity-light">
                                            <div className="field form-group">
                                                <Field name="user_username" type="text"
                                                    onChange={(e) => this.handleChange(e, setFieldValue)}
                                                    id="user_username" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_username && touched.user_username) || serverValid.user_username !== '' ? ' is-invalid' : '')} autoComplete="new-username" />
                                                <label htmlFor="user_username" className={getLabelClass(values.user_username)}>{Enum.Student} Username</label>
                                                {
                                                    (errors.user_username && touched.user_username) ?
                                                        <ErrorMessage name="user_username" component="div" className="invalid-feedback" />
                                                        :
                                                        <div className="invalid-feedback">{serverValid.user_username}</div>
                                                }
                                            </div>
                                            <div className="field form-group">
                                                <Field name="user_phone" type="text">
                                                    {({
                                                        field, // { name, value, onChange, onBlur }
                                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                    }) => (
                                                            <Helper.PhoneMaskedInput
                                                                field={field}
                                                                onChange={(e)=>this.handleChange(e,setFieldValue)}
                                                                className={'form-control' + ((errors.user_phone && touched.user_phone) || serverValid.user_phone !== '' ? ' is-invalid' : '')}
                                                                id="user_phone"
                                                            />
                                                        )}
                                                </Field>
                                                <label htmlFor="user_phone" className={getLabelClass(values.user_phone)}>Phone Number (Optional)</label>
                                                {
                                                    (errors.user_phone && touched.user_phone) ?
                                                        <ErrorMessage name="user_phone" component="div" className="invalid-feedback" />
                                                        :
                                                        <div className="invalid-feedback">{serverValid.user_phone}</div>
                                                }
                                            </div>
                                            <div className="field form-group">
                                                <Field name="user_password" onChange={(e)=>this.handleChange(e,setFieldValue)} type="password" id="user_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_password && touched.user_password) || serverValid.user_password !== '' ? ' is-invalid' : '')} autoComplete="new-password" />
                                                <label htmlFor="user_password" className={getLabelClass(values.user_password)}>Password</label>
                                                {
                                                    (errors.user_password && touched.user_password) ?
                                                        <ErrorMessage name="user_password" component="div" className="invalid-feedback" />
                                                        :
                                                        <div className="invalid-feedback">{serverValid.user_password}</div>
                                                }
                                            </div>
                                            <div className="field form-group">
                                                <Field name="user_confirm_password" onChange={(e)=>this.handleChange(e,setFieldValue)} type="password" id="user_confirm_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_confirm_password && touched.user_confirm_password) || serverValid.user_confirm_password !== '' ? ' is-invalid' : '')} />
                                                <label htmlFor="user_confirm_password" className={getLabelClass(values.user_confirm_password)}>Confirm Password</label>
                                                {
                                                    (errors.user_confirm_password && touched.user_confirm_password) ?
                                                        <ErrorMessage name="user_confirm_password" component="div" className="invalid-feedback" />
                                                        :
                                                        <div className="invalid-feedback">{serverValid.user_confirm_password}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.lgLimitError != '' ? 
                                            <div className="mt-4 alert alert-warning">
                                                { this.state.lgLimitError }
                                            </div>
                                        : null
                                    }
                                </div>
                            }
                        </div>
                        <div className="footer-buttons" style={this.state.hideFields}>
                            {
                                handlePrevClick ?
                                    <button type="button" className="btn btn-next btn-primary btn-lg pull-left" onClick={handlePrevClick}>Previous</button>
                                :
                                    null
                            }
                            <button type="submit" className="btn btn-prev btn-primary btn-lg float-right">Next</button>
                        </div>
                    </Form>
                )
                }
            />
        )
    }
}
export { KidInformation };