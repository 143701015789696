/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Tab, Tabs, Image, Button, Modal, Dropdown, DropdownButton, InputGroup} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form as FormValid, ErrorMessage} from 'formik';
/* End - Formik */

import {ModalBox, SuccessModal, ErrorModal, ConfirmModal} from '../../components/modal/modalbox';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Enum from '../../components/common/enum'
import * as Storage from "../../components/common/storage";
import {Messages} from "../../components/common/message";

export default class InviteClub extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            email: '',
            message: '',
            serverValid: {
                subject: '',
                email: '',
                message: '',
            },
            serverSuccess: '',
            serverError: '',
            formProcessing: false,
            loader: false,
            showCopied: false,
            fields: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sendInvite = (fields, type) => {
        if (this._isMounted) {
            const me = this;
            this.setState({formProcessing: true});

            let api_url = `isr/send-invite`,
                api_params = {
                    subject: fields.subject,
                    email: fields.email,
                    message: fields.message
                };

            Helper.axiosInstance().post(api_url, api_params)
                .then((response) => {
                    // If server response message same as Data Matched
                    if (response.data.success) {
                        me.setState({serverSuccess: response.data.message, formProcessing: false});
                    } else {
                        // get the server form errors and set under state 'serverValid'
                        let getErrors = response.data.error;
                        if (Array.isArray(getErrors) || typeof getErrors === 'object') {
                            me.resetServerError();

                            let serverValid = me.state.serverValid;
                            // eslint-disable-next-line
                            for (let errorIndex in getErrors) {
                                if (getErrors[errorIndex][0] !== "") {
                                    serverValid[errorIndex] = getErrors[errorIndex][0];
                                }
                            }
                            this.setState({serverValid, formProcessing: false});
                        } else {
                            this.setState({errorMessage: getErrors, formProcessing: false});
                        }
                    }

                })
                .catch((error) => {
                    me.setState({serverError: error, formProcessing: false});
                });
        }
    }

    resetServerError() {
        this.setState({
            serverValid: {
                subject: '',
                email: '',
                message: ''
            }
        });
    }

    resetModal = () => {
        this.resetServerError();
        this.setState({serverSuccess: '', formProcessing: false});
        this.props.toggleModal(!this.props.modalVisibility)
    }

    handleErrorClose = () => {
        window.location.reload();
    }

    copyLink = () => {
        let me = this;
        const user = Storage.Get('user');
        navigator.clipboard.writeText( process.env.REACT_APP_SITE_URL+'create-org/'+user.isr_invite_code);
        this.setState({'showCopied': true})
        setTimeout(function () {
            me.setState({'showCopied': false})
        }, 2000)
    }

    render() {
        const {modalVisibility} = this.props,
            {subject, email, message, serverValid, formProcessing, serverSuccess, serverError} = this.state;

        const handleClose = () => this.resetModal(),
            modalHeader = (
                <>
                    <Modal.Title>Invite New Organization</Modal.Title>
                </>
            ),
            modalContent = (
                <>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            subject: subject,
                            email: email,
                            message: message
                        }}
                        validationSchema={Yup.object().shape({
                            subject: Yup.string().trim()
                                .required(Messages.REQUIRED_ERR)
                                .max(150, Messages.MAX_LENGTH_150),
                            email: Yup.string().trim()
                                .max(150, Messages.MAX_LENGTH_150)
                                .email(Messages.INVALID_EMAIL)
                                .required(Messages.REQUIRED_ERR),
                            message: Yup.string().trim()
                                .required(Messages.REQUIRED_ERR)
                                .max(1000, 'Message is Too Long!')
                        })}
                        onSubmit={fields => {
                            this.setState({fields: fields});
                            this.sendInvite(fields);
                        }}
                        render={({errors, status, touched, setFieldValue, values}) => (
                            <FormValid noValidate>
                                <Modal.Body>
                                    <div className="d-flex flex-row">
                                        <div className="ml-left">
                                            <Image src={Images.inviteClub} width="100" alt="group"/>
                                        </div>
                                        <div className="ml-right">
                                            <div className="field form-group">
                                                <Field name="email" type="text" id="email" onFocus={Helper.inputFocus}
                                                       onBlur={Helper.inputBlur} maxLength="150"
                                                       className={'form-control' + ((errors.email && touched.email) || serverValid.email !== '' ? ' is-invalid' : '')}/>
                                                <label htmlFor="email" className={Helper.getLabelClass(values.email)}>Organization's
                                                    Email Address</label>
                                                {
                                                    (errors.email && touched.email) ?
                                                        <ErrorMessage name="email" component="div"
                                                                      className="invalid-feedback"/>
                                                        :
                                                        <div className="invalid-feedback">{serverValid.email}</div>
                                                }
                                            </div>
                                            <div className="field form-group">
                                                <Field name="subject" type="text" id="subject"
                                                       onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}
                                                       maxLength="100"
                                                       className={'form-control' + ((errors.subject && touched.subject) || serverValid.subject !== '' ? ' is-invalid' : '')}/>
                                                <label htmlFor="subject"
                                                       className={Helper.getLabelClass(values.subject)}>Subject</label>
                                                {
                                                    (errors.subject && touched.subject) ?
                                                        <ErrorMessage name="subject" component="div"
                                                                      className="invalid-feedback"/>
                                                        :
                                                        <div
                                                            className="invalid-feedback">{serverValid.subject}</div>
                                                }
                                            </div>
                                            <div className="field form-group form-group--additonal-info">
                                                <Field name="message" component="textarea" id="message"
                                                       onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}
                                                       maxLength="100"
                                                       className={'form-control' + ((errors.message && touched.message) || serverValid.message !== '' ? ' is-invalid' : '')}/>
                                                <label htmlFor="message"
                                                       className={Helper.getLabelClass(values.message)}>Message</label>
                                                {
                                                    (errors.message && touched.message) ?
                                                        <ErrorMessage name="message" component="div"
                                                                      className="invalid-feedback"/>
                                                        :
                                                        <div
                                                            className="invalid-feedback">{serverValid.message}</div>
                                                }
                                            </div>
                                            <ul className="d-note"><strong>Note:</strong>
                                                <li>Your unique invitation link will be automatically added to the form above when you click send link below.</li>
                                                <li>Your invite link contains a unique invite code associated with your account.</li>
                                                <li>When an organisation registers using your invite link, it is automatically added to your associated organisations.</li>
                                                <li>You can also copy the invite link below to send an invitation from your personal email or text the invitation from your phone.</li>
                                            </ul>
                                            <div className="copy-link">
                                                <span className="copy-link__inner" onClick={()=>this.copyLink()}>
                                                    <img src={Images.link} alt="copy link" width="25"/> Copy Invite Link
                                                </span>
                                                {
                                                    this.state.showCopied ? <span className="copy-link__sm-value">Copied!</span> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="m-footer">
                                    <div className="m-footer__btnwrap">
                                        <Button variant="secondary" className="ripple"
                                                disabled={formProcessing ? true : false} onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button type="Submit" disabled={formProcessing ? true : false}
                                                className={"btn-primary btn-medium ripple ml-auto align-self-center btn__sendic" + (formProcessing ? " btn-loader" : "")}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.SendIc}
                                                                                             alt="Send Invite"/>
                                            </div>
                                            <span className="align-self-center">Send Invite </span>
                                            <span style={{'display': 'none'}}
                                                  className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </FormValid>
                        )}
                    />
                </>
            );
        let errorMsg = serverError ? serverError : '';
        return (
            <>
                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        errorMsg !== "" ?
                            <ErrorModal
                                message={errorMsg}
                                show={modalVisibility}
                                onConfirmClick={this.handleErrorClose}
                            />
                            :
                            <ModalBox
                                size="lg"
                                show={modalVisibility}
                                onHide={handleClose}
                                animation={true}
                                modalheader={modalHeader}
                                modalcontent={modalContent}
                            />
                }
            </>
        )
    }
}
