/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Navbar, Nav,Form } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/* Start - app imports */
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
/* End - app imports */

const RegisterSuccess = ({registerMsg}) => {
    return (
            <Row className="content-space">
                <Col sm={12 } md={8} lg={8} className="offset-md-2 offset-lg-2">
                    <div className="widget">
                        <div className="text-center success-msg success-msg--font-size">
                            <Image src={Images.SuccessTick} alt="Success" />
                            <p className="mb-4">{registerMsg}.</p>
                        </div>
                         <div className="text-center d-flex flex-column">
                                <h3 className="success-msg__heading mt-4">Continue with the <Link to="/">Web
                                    app</Link> or Download the Mobile app.</h3>
                                <a href='https://apps.apple.com/us/app/safecomm/id1483905310' target='_blank'
                                   rel="noopener noreferrer"><Image src={Images.AppStore} alt={Images.AppStore}
                                                                    width="200" className="ml-auto mr-auto mb-3"/></a>

                                <a href='https://play.google.com/store/apps/details?id=com.safecomm' target='_blank'
                                   rel="noopener noreferrer"><Image alt='Get it on Google Play' src={Images.PlayStore}
                                                                    title="Get it on Google Play" width="240"
                                                                    className="ml-auto mr-auto mb-5"/></a>
                            </div>

                    </div>
                </Col>
            </Row>
    )
};

const RegisterError = ({inviteError}) => {
    return (
         <Row className="content-space">
            <Col sm={12 } md={8} lg={8} className="offset-md-2 offset-lg-2">
                <div className="widget py-5 px-3">
                    <div className="text-center">
                        <Image src={Images.Confirm} alt="Error" width="100" />
                        <h1>Error</h1>
                        <p className="mb-4">{inviteError}</p>
                    </div>
                    <Form className="text-center">
                        {
                            inviteError && inviteError.indexOf("login") !== -1 ?
                                <Link to="/" className="btn--green btn justify-content-center">
                                    Login
                                </Link>
                            :
                                 <Link to="#" onClick={Helper.handleSupportContact} className="btn--green btn justify-content-center">
                                        Contact Us
                                    </Link>
                        }
                    </Form>
                </div>
            </Col>
        </Row>
    )
};

const InviteHeader = ({inviteDetails}) => {
    return(
        <Navbar bg="light" expand="lg" className="topbar invite-header">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto org-logo">
                    {
                        inviteDetails && inviteDetails.club && inviteDetails.club.photo ?
                            <Image src={inviteDetails.club.photo} alt={inviteDetails.club.name} title={inviteDetails.club.name}/>
                        :
                            <Image src={Images.SafecommLogo} alt="SafeComm" title="SafeComm" width="245"/>
                    }
                </Nav>
                <Nav className="ml-auto org-logo">
                    {
                        inviteDetails && inviteDetails.team && inviteDetails.team.photo ?
                            <Image src={inviteDetails.team.photo} alt={inviteDetails.team.name} title={inviteDetails.team.name}/>
                        :
                            null
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

const clubTeamName = (inviteDetails) => {
    let clubTeamName = "";
    if(inviteDetails && inviteDetails.club && inviteDetails.club.name){
        clubTeamName = inviteDetails.club.name;
        if(inviteDetails.team && inviteDetails.team.name){
            clubTeamName += ` (`+inviteDetails.team.name+`)`;
        }
    } else {
        clubTeamName = "Safecomm App"
    }
    return clubTeamName;
};

export { RegisterSuccess, InviteHeader, clubTeamName, RegisterError };
