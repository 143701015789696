import React, { Component } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../privacy/privacy.scss';

class Privacy extends React.Component {

    render () {
        return (
            <div className={this.props.childProps.isAuthenticated ? "rgt-content": "container"}>
                <Row className="row--space">
                    <Col sm={12}>
                        <h1 className="mb-2">Privacy Policy</h1>
                        <span className="privacy-sub--heading text-dark">Last Modified: October 16, 2021</span>
                    </Col>
                    <div className="md--full-width col-lg-12 col-md-12">
                        <div className="widget widget--privacy">
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Introduction</div>
                                <p className="privacy__text">Safe Communications Consultants, LLC, a Delaware limited liability company (“SCC”, “we”, “us” and “our”) respects your privacy and is committed to protecting it through our compliance with this privacy policy (“Privacy Policy”). This Privacy Policy was created to make sure you understand the data we collect about you (“you”, “your” or “yours”) when you download or use the SCC mobile message application (the “App”), other services offered in connection with the App, in any email, text message, and other electronic communications sent through or in connection with the App (collectively, the “Services”), what we do with it, and how it is protected. This Privacy Policy does not apply to information we collect through any other means, or to information collected on any affiliate or third-party site or by any third-party application.</p>
                                <p className="privacy__text">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use the App or any of the Services. By downloading, registering with, or using this App, you agree to this Privacy Policy. This policy may change from time to time. Your continued use of this App after we revise this Privacy Policy means you accept those changes, so please check the policy periodically for updates.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Privacy Certifications</div>
                                <p className="privacy__text">Protecting Children’s and student’s privacy is especially important to us. We participate in the iKeepSafe Privacy program. We have been granted the iKeepSafe FERPA seal signifying that our Site and SafeComm App have been reviewed and approved for having policies and practices surrounding the collection, use, maintenance and disclosure of personal information from Children (as defined below) consistent with the iKeepSafe FERPA program guidelines. Below is an email and phone number for the iKeepSafe Privacy program:</p>
                                <p className="privacy__text mb-0">
                                    <span className="mr-2">Email:</span>
                                    <a href="mailto:privacy@ikeepsafe.org">privacy@ikeepsafe.org</a>
                                </p>
                                <p className="privacy__text">
                                    <span className="mr-2">Phone:</span>
                                    <span>(540) 385-9862</span>
                                </p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Information We Collect</div>
                                <p className="privacy__text">There are three basic categories of information we collect: (i) information you provide, (ii) information we get when you use the Services, and (iii) information we get from third parties.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Information You Provide</div>
                                <p className="privacy__text">When you interact with the Services, we collect information that you provide to us, such as:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li><span className="bold">Registration information:</span> When you download the App or otherwise sign up to use the Services, you provide us with basic information like your name, username, password, birthdate, email address, and phone number.</li>
                                        <li><span className="bold">Transaction information:</span> Payment information such as your credit or debit card details, purchase history, delivery address and other information related to your transactions.</li>
                                        <li><span className="bold">Profile information:</span> Including your individual profile pictures, background photo, your name and any other information you choose to provide on your profile.</li>
                                        <li><span className="bold">Message Content:</span> When you send or receive a message through the App, the text and any other information or data included in your message are transmitted through our servers and may be stored until deleted by us. You are responsible for saving or backing up your messages outside of the App.</li>
                                        <li><span className="bold">Conversation attributes:</span> When you create group or private chats, you have the ability to configure certain elements of your conversations, including, for example, things like group name, group profile picture, themes, administrators, and other available options (if any).</li>
                                        <li><span className="bold">Your Communications with Us:</span> When you reach out to us (through, for example, in-App reports, emails sent by you to us, or voluntary polls, surveys, or questionnaires initiated by us that you respond to), we collect and store the content and details of that communication, including things like date, time, content and method of communication, and identity verification information (if applicable). When you voluntarily respond to polls, surveys, and questionnaires, the content of personal information you provide may include your address and basic demographic information.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Information we get when you use the Services</div>
                                <p className="privacy__text">When you use the Services, we collect information about the Services you have used and how you have used them to ensure proper performance of the App and to assess for analytic purposes how you are using the App and how we may improve the performance of the App for you and other users. We might know, for instance, that you sent or viewed messages and other information such as:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li><span className="bold">Log and Data Usage Information:</span> We collect and process a record of your activity on our servers. This information may include details such as the date and time a message was sent, who you messaged with, the time and date of your communications, the number of messages you exchange with your friends, which friends you exchange messages with the most, your interactions with messages (such as when you open a message or capture a screenshot), your internet protocol (“IP”) address, and how you use third party websites or services accessed through the App.</li>
                                        <li><span className="bold">Device Information:</span> We collect information from and about the device you use to access our Services, which may include information like your hardware model, operating system version, unique device identifiers, and information about your wireless and mobile network connections, such as mobile phone number, service provider and signal strength.</li>
                                        <li><span className="bold">Location Information:</span> Based on your IP address, we may collect information about your general geographical location (which may identify you as being in a particular city or state). With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses. However, we do not provide location information as a function of our App; such location information is only collected to the extent it is automatically provided by the phone manufacturer or carrier.</li>
                                        <li><span className="bold">Cookie information:</span> We may collect information stored or recognized on your computer or mobile phone about things like your website activity and/or browser or device information through the use of cookies and similar technologies like web beacons.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Information we get from Third Parties</div>
                                <p className="privacy__text">We may collect information about you from other users, our partners and third parties such as:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li><span className="bold">User reports:</span> When another user flags a conversation and reports it to us for investigation, we may collect information provided to us from the user regarding their complaint, which may include the offending chat and any associated pictures or videos.</li>
                                        <li><span className="bold">Third Party Information:</span> We may collect and process information about you from third-parties who offer services to you and/or us through the App, such as analytics companies who help us do things like understand what may have gone wrong in the App that caused your app to crash, or companies who offer services that you may choose to use. To the extent that you use any third- party services through the App, the third party’s terms and privacy policies govern the use of those services.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">How We Use Your Information</div>
                                <p className="privacy__text">We use information that we collect about you or that you provide to us, including any personal information, to:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li>provide you with the App and its contents, and any other information, products or services that you request from us;</li>
                                        <li>analyze, develop, operate, improve, deliver, maintain, and protect our products and services as well as monitor and analyze trends and usage;</li>
                                        <li>notify you when App updates are available, and of changes to any products or services we offer or provide though it;</li>
                                        <li>use information we have collected from cookies and other technology to personalize or enhance our services or your experiences;</li>
                                        <li>communicate with you (including, without limitation, give you notices about your account, including expiration and renewal notices);</li>
                                        <li>carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                                        <li>verify identity, determine eligibility to use the Services, and prevent fraud or other unauthorized or illegal activity;</li>
                                        <li>enforce our Terms of Service and other usage policies and comply with legal requirements;</li>
                                        <li>carry out any other purpose for which you provide it</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">How We Share Information</div>
                                <p className="privacy__text">We may share your information if you ask us to. For example, we send the message you asked us to send to another user or within a group. You should note, however, that the information you choose to share on any third party sites is governed by the privacy policy of those sites and is not covered by this Privacy Policy and that you (not us) will be solely responsible for your use of those sites.</p>
                                <p className="privacy__text">Except for information that you have asked us to share, we do not share, sell or transfer your personal information without your consent, except as described below:</p>
                                <div className="privacy__text">
                                    <ul className="privacy__listing">
                                        <li><span className="bold">Our service providers:</span> We use third-party service providers to help operate our business. Examples include suppliers who host our data or provide customer service. We require all service providers to maintain confidentiality. Use of your personal information by our service providers is further limited to the information they need to provide services on our behalf. The links to the privacy policies of our service providers are listed below:
                                            <p className="privacy__listing--links">
                                                <span className="mr-2">Cometchat:</span>
                                                <a target="_blank" href="https://www.cometchat.com/legal-privacy-policy">https://www.cometchat.com/legal-privacy-policy</a>
                                            </p>
                                            <p className="privacy__listing--links">
                                                <span className="mr-2">AWS:</span>
                                                <a target="_blank" href="https://aws.amazon.com/privacy/">https://aws.amazon.com/privacy/</a>
                                            </p>
                                            <p className="privacy__listing--links mb-2">
                                                <span className="mr-2">Covalience:</span>
                                                <a target="_blank" href="https://covalience.com/privacy-policy/">https://covalience.com/privacy-policy/</a>
                                            </p>
                                            For more information about how these service providers treat your personal information, contact us at the address below.
                                        </li>
                                        <li><span className="bold">To Protect Us or Our Users, or for Legal Purposes:</span> We may disclose your information: (i) if we think it’s needed to detect, prevent or address illegal activity, or identify, contact or bring legal action against someone who appears to be causing injury to or interfering with the rights or property of us or our users; (ii) as part of a legal matter or investigation, including an investigation related to a suspected breach of our Terms of Service; (iii) when we believe that disclosure is required or permitted by law, including when responding to subpoenas, warrants, production orders, or similar instruments; or (iv) if we believe that disclosure is needed to protect your safety or the safety of others, including when there is an emergency involving potential harm, loss of security or serious injury to people or threats of such emergencies.</li>
                                        <li><span className="bold">Merger, financing or sale:</span> We may share your information in connection with a merger, financing, acquisition, dissolution transaction, bankruptcy or proceeding involving sale, transfer, divestiture of all or a portion of our business or assets (collectively, a “Transfer” of our business). If another company acquires our business or assets, that company will have your information collected by us and will assume the rights and obligations regarding your information as allowed by this privacy policy. We will notify you within 30 days of the completion of any such Transfer of our business.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">How Long We Keep Your Information</div>
                                <p className="privacy__text">SafeComm will delete all your messages two (2) years after you cease usage of the App. SafeComm retains the right to store any of your information needed for business purposes as set forth in this Privacy Policy. We keep your personal information until it is deleted or until we no longer need it to provide you with the Services (or as needed for business purposes as set forth in this Privacy Policy). We will not retain a minor’s personal information for any longer than is necessary: 1) for educational purposes and legal obligations; or 2) to provide the Services for which we receive or collect the minor’s personal information. In addition, we only keep a minor’s personal information for as long as the minor's account is active, unless we are required by law or the minor’s school to retain it or need it to protect the safety of our users. Note that some content may be kept after an account is deleted for legal compliance reasons (e.g., maintenance of "education records" under FERPA or "student records" under various state student privacy laws).</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Control Over Your Information</div>
                                <p className="privacy__text">We want you to be in control of your information, so we provide you with certain tools. For example, you can access and edit or delete most of your basic account information right in our App. We may also reject your request to access or update your personal information for a number of reasons, including, for example, if the request risks the privacy of other users or is unlawful. Additionally, in most cases, if you let us use your information, you can simply revoke your permission by changing the settings in the App or on your device if your device offers those options. It is important to us that you stay in control over whom you communicate with, which is ultimately up to you.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Data Security</div>
                                <p className="privacy__text">The safety and security of your information also depends on you. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through the App or otherwise in connection with the Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures.</p>
                            </Col>

                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Your California Privacy Rights</div>
                                <p className="privacy__text privacy__text--uppercase">NOTICE TO CALIFORNIA RESIDENTS – YOUR CALIFORNIA PRIVACY RIGHTS (AS PROVIDED BY CALIFORNIA CIVIL CODE SECTION 1798.83).</p>
                                <p className="privacy__text privacy__text--uppercase">A CALIFORNIA RESIDENT WHO HAS PROVIDED PERSONAL DATA TO A BUSINESS WITH WHOM HE/SHE HAS ESTABLISHED A BUSINESS RELATIONSHIP FOR PERSONAL, FAMILY, OR HOUSEHOLD PURPOSES (A “CALIFORNIA CUSTOMER”) MAY REQUEST INFORMATION ABOUT WHETHER THE BUSINESS HAS DISCLOSED PERSONAL INFORMATION TO ANY THIRD PARTIES FOR THE THIRD PARTIES’ DIRECT MARKETING PURPOSES.  IN GENERAL, IF THE BUSINESS HAS MADE SUCH A DISCLOSURE OF PERSONAL DATA, UPON RECEIPT OF A REQUEST BY A CALIFORNIA CUSTOMER, THE BUSINESS IS REQUIRED TO PROVIDE A LIST OF ALL THIRD PARTIES TO WHOM PERSONAL DATA WAS DISCLOSED IN THE PRECEDING CALENDAR YEAR, AS WELL AS A LIST OF THE CATEGORIES OF PERSONAL DATA THAT WERE DISCLOSED. CALIFORNIA CUSTOMERS MAY REQUEST FURTHER INFORMATION ABOUT OUR COMPLIANCE WITH THIS LAW BY E-MAILING SUPPORT@SAFECOMM.APP. PLEASE NOTE THAT WE ARE REQUIRED TO RESPOND TO ONE REQUEST PER CALIFORNIA CUSTOMER EACH YEAR AND WE ARE NOT REQUIRED TO RESPOND TO REQUESTS MADE BY MEANS OTHER THAN THROUGH THIS E-MAIL ADDRESS.</p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Changes to Our Privacy Policy</div>
                                <p className="privacy__text">We may update our privacy policy from time to time. It is our policy to post any changes we make to our Privacy Policy on this page. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting this Privacy Policy to check for any changes.</p>
                                <p className="privacy__text">If we make any changes to this Privacy Policy that materially affect our practices with regard to the personal identifiable information we have previously collected from a Child, we will endeavor to provide you with notice in advance of such change <span className="bold">by highlighting the change on the Site and the SafeComm App as well as by emailing the school and/or parent that we have in our records</span></p>
                            </Col>
                            <Col sm={12}>
                                <div className="privacy-sub--heading mb-2">Contact Information</div>
                                <p className="privacy__text">You may direct your inquiries about any operator’s privacy practices and use of Children’s information directly to such operator or to Safe Communications Consultants at:</p>
                                <p className="privacy__text">
                                    <ul className="list-address">
                                        <li>Safe Communications Consultants</li>
                                        <li>690 Main St. #862</li>
                                        <li>Safety Harbor, FL 34695</li>
                                        <li>Attention: Bill Schalz</li>
                                        <li>
                                            <a href="mailto:Support@Safecommapp.com">Support@Safecommapp.com</a>
                                        </li>
                                        <li>630-989-8157</li>
                                    </ul>
                                </p>
                            </Col>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default Privacy;
