/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Modal, Button, Image } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import * as Images from '../../components/common/images';
import * as Storage from '../../components/common/storage';

class ModalBox extends Component {
    render() {
        const { size, show, onHide, animation } = this.props,
            modalHeader = this.props.modalheader ? <Modal.Header closeButton> {this.props.modalheader} </Modal.Header> : null;
        return (
            <>
                <Modal
                    size={size}
                    show={show}
                    onHide={onHide}
                    animation={animation}
                >
                    {modalHeader}
                    {this.props.modalcontent}
                </Modal>
            </>
        )
    }
}

const DeleteConfirmModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.deleteConfirmShow}
            onHide={props.onCancelClick}
            animation={true}
            confirmButtonText={props.confirmButtonText}
            confirmText={props.confirmText}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    {/* {(props.confirmType !== null && (props.confirmType === "Activate" || props.confirmType === "Add")) ?
                        <Image src={Images.ConfirmActivate} width="100" height="100" />
                        : */}
                    <Image src={Images.Confirm} width="100" height="100" />
                    {/* } */}
                    <h1>Are you sure?</h1>
                    <p>{props.confirmText}</p>
                    <div className="confirmbox__footer">
                        <Button variant="secondary" className="ripple btn-cancel mr-3" disabled={props.modalLoader} onClick={props.onCancelClick} >
                            Cancel
                                    </Button>
                        {(props.confirmType !== null && (props.confirmType === "Activate" || props.confirmType === "Add" || props.confirmType === "Approve")) ?
                            <Button disabled={props.modalLoader} className={`ripple btn-activate ${props.modalLoader ? 'btn-loader' : ''}`} onClick={props.onConfirmClick}>
                                {props.confirmButtonText} <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </Button>
                            : <Button disabled={props.modalLoader} className={`ripple btn-delete ${props.modalLoader ? 'btn-loader' : ''}`} variant="secondary" onClick={props.onConfirmClick}>
                                {props.confirmButtonText} <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </Button>
                        }
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const GroupOverrideModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.show}
            // onHide={props.onCancelClick}
            animation={true}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} width="100" height="100" />
                    <h1>Please select the action to proceed.</h1>
                    <div className="confirmbox__footer confirmbox__footer--mrgn">
                        <Button variant="primary" className="ripple btn-cancel btn-cancel--delete mr-3" onClick={props.onOverrideClick} >Override</Button>
                        <Button variant="primary" className="ripple btn-cancel btn-cancel--update mr-3" onClick={props.sendInvite} >Update</Button>
                        <Button variant="secondary" className="ripple btn-cancel" disabled={props.modalLoader} onClick={props.onCancelClick}>Cancel</Button>
                    </div>
                    <p className="mb-3"><b>Override:</b> Will delete all the existing students from the member group and add the selected students.</p>
                    <p className="mb-3"><b>Update:</b> Will keep all the existing students in the member group and add the selected students.</p>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const SuccessModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.successConfirmShow}
            animation={true}
            onHide={props.onHide}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.SuccessTick} width="100" height="100" />
                    <h1>Success</h1>
                    <p>{props.successText}</p>
                    <div className="confirmbox__footer">
                        <Button variant="secondary" className="ripple btn-ok" onClick={props.onConfirmClick}>
                            {props.successButtonText}
                        </Button>
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const ErrorModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.show}
            animation={true}
            onHide={() => {return false}}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                    <h1>Error</h1>
                    <p dangerouslySetInnerHTML={{__html: props.message}} />
                    <div className="confirmbox__footer">
                        <Button className="ripple btn-ok" onClick={props.onConfirmClick}>
                            {props.buttonText ? props.buttonText : "OK"}
                        </Button>
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const ErrorAccessDeniedModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.show}
            animation={true}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                    <h1>Access Denied</h1>
                    <p>{props.message}</p>
                    <div className="confirmbox__footer">
                        {
                            (Helper.isCoachOrAdminLogin() || Storage.Get('user').is_super_admin) ?
                                <Button className="ripple btn-ok" onClick={() => props.currentProps.history.push('/')}>
                                    Go to Home
                                </Button>
                            :
                                props.buttonText && props.buttonLink ?
                                    <Button className="ripple btn-ok" onClick={() => props.currentProps.history.push(props.buttonLink)}>
                                        {props.buttonText}
                                    </Button>
                                :
                                    <Button className="ripple btn-ok" onClick={() => props.currentProps.history.push('/teams')}>
                                        Go to Member Groups
                                    </Button>
                        }

                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const UpdateMiddleNameModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.show}
            animation={true}
            onHide={() => {return false}}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                    <h1>Student Middle Name Update</h1>
                    <p>Please add middle names for all <b>your</b> students on the profile page.</p>
                    <div className="confirmbox__footer confirmbox__footer--update-profile">
                        <Button variant="secondary" className="ripple btn-cancel" onClick={props.handleLogout}>
                            Cancel
                        </Button>
                        <Button className="ripple btn-ok" onClick={() => props.currentProps.history.push('/profile')}>
                            <div className="btn__icon btn__icon--red"><Image src={Images.EditIcon} alt="Edit" /></div>
                            Go to Profile
                        </Button>

                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const ConfirmModal = (props) => (
    <>
        <ModalBox
            size="md"
            show={props.show}
            animation={true}
            onHide={() => {return false}}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                    <h1>{props.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: props.body}} />
                    {
                        props.note ? <p>{`Note: ${props.note}`}</p> : null
                    }

                    <div className="confirmbox__footer confirmbox__footer--update-profile">
                        <Button variant="secondary" className="ripple btn-cancel" disabled={props.modalLoader} onClick={props.onCancelClick} >
                            Cancel
                        </Button>
                        <Button disabled={props.modalLoader} className={`ripple btn-activate ${props.modalLoader ? 'btn-loader' : ''}`} onClick={props.onConfirmClick}>
                            {props.buttonText} <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

const TimeoutModal = (props) => {
     return  <ModalBox
        size="md"
        show={props.showModal}
        animation={true}

        modalcontent={
            <Modal.Body className="text-center confirmbox">
                <Image src={Images.warningIc} width="60" height="60" className="mb-3" />
                <h3>Your session is about to expire!</h3>
                <p className="mb-0 ml-1">You will be logged out in 5 minutes.</p>
                <p>Do you want to stay signed in?</p>
                <div className="confirmbox__footer confirmbox__footer--update-profile">
                    <Button variant="secondary" className="ripple btn-cancel" onClick={props.onCancelClick} >
                       Yes, Keep me signed in
                    </Button>
                    <Button  disabled={props.modalLoader} className={`text-transform-none ripple btn-activate ${props.modalLoader ? 'btn-loader' : ''}`} onClick={props.onConfirmLogout}>
                        No, Sign me out
                        <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </Button>
                </div>
            </Modal.Body>
        }
    >
    </ModalBox>
}
const TermsModal = (props) => (
    <>
        <ModalBox
            size="lg"
            show={props.show}
            animation={true}
            modalcontent={
                <Modal.Body className="text-center confirmbox">
                    <h1>Terms and Conditions</h1>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    <div className="confirmbox__footer">
                        <Button variant="secondary" className="ripple btn-cancel" onClick={props.onBtnClick} >
                            OK
                        </Button>
                    </div>
                </Modal.Body>
            }
        >
        </ModalBox>
    </>
)

export { ModalBox, DeleteConfirmModal, SuccessModal, ErrorModal, ErrorAccessDeniedModal, UpdateMiddleNameModal, GroupOverrideModal, ConfirmModal,TermsModal, TimeoutModal };
