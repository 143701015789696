/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Image} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form, ErrorMessage} from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

import * as Images from '../../components/common/images';
import './create-club-message';
import {Messages} from '../../components/common/message';
import {CreateClubMessages} from './create-club-message';
import {ErrorModal} from '../../components/modal/modalbox';
import * as Helper from '../../components/common/functions';
import './club.scss';
import {PhoneInput} from "../../components/input/phone";
import * as Regex from "../../components/common/regex";

class GeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: false,
            showError: false,
            errorMessage: null,
            uploadedPhotoUrl: null
        };
        this.isValidated = this.isValidated.bind(this);
        this.hasValidateMethodRun = false;
        this.photo = {};
        this.photoEvent = false;
        this.submitFormRef = null;
        this.isFormValid = false;
        this.fields = {};
        if (this.props.data) {
            this.fields = this.props.data.fields;
            this.photo = this.props.data.photo;
        }
    }

    componentDidMount() {
        if (this.photo) {
            this.setState({uploadedPhotoUrl: this.photo.url});
        }
        console.log('this.props', this.props)
    }

    isValidated() {
        // This means that correct data is already filled
        // and no changes were made to the form so bypass the validation
        if (this.fields.clubName !== "" && !this.hasValidateMethodRun) {
            this.props.handleFormSubmit(this.fields, this.photo);
            return this.isFormValid;
        } else {

            if (this.submitFormRef)
                this.submitFormRef();

            if (this.isFormValid)
                this.props.handleFormSubmit(this.fields, this.photo, this.resetForm);

            return this.isFormValid;
        }
    }

    bindSubmitForm = (submitForm, errors, isValid) => {
        if (!this.submitFormRef)
            this.submitFormRef = submitForm;

        this.isFormValid = this.props.data.firstLoad ? isValid : Object.keys(errors).length === 0;
    };

    photoEventBlockHack = () => {
        this.photoEvent = true;
        setTimeout(() => {
            this.photoEvent = false;
        }, 1000);
    }

    onPhotoChange = (event) => {
        this.photoEventBlockHack();
        let uploadedFile = event.target.files[0];
        if (uploadedFile !== null && uploadedFile !== undefined) {

            if (uploadedFile.size / 1024 > 6000) {
                this.setState({errorMessage: Messages.PHOTO_MAX_SIZE, showError: true,});
                event.target.value = null;
                return;
            }
            if (uploadedFile.type !== "image/jpeg" && uploadedFile.type !== "image/jpg") {
                this.setState({errorMessage: Messages.PHOTO_TYPE_ALLOWED, showError: true,});
                event.target.value = null;
                return;
            }
            this.photo = {
                file: uploadedFile,
                url: window.URL.createObjectURL(uploadedFile)
            };
            this.setState({uploadedPhotoUrl: this.photo.url});
        }
        this.props.data.firstLoad = false;
    }

    render() {
        let labelClass = `form-label ${this.fields.clubName !== undefined && this.fields.clubName !== "" ? 'label-active' : ''}`;
        let validations = {
            clubName: Yup.string().trim()
                .max(100, Messages.MAX_LENGTH_100)
                .required(Messages.REQUIRED_ERR),
            orgWebsite: Yup.string().trim()
                .max(150, Messages.MAX_LENGTH_150)
                .matches(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, {
                    message: 'Please enter valid url',
                    excludeEmptyString: false
                })
                .required(Messages.REQUIRED_ERR).nullable(),
            additionalInformation: Yup.string().trim()
                .max(1000, 'Message is Too Long!')
                .matches(/^[a-zA-Z\d\-_.,!@#$%^&*()+={}|[\]:;"'\/\\<>?\s]+$/, {message: 'Some of the special characters are not allowed. Please remove those.'})
        };
        if(this.props.isSuperAdmin || !this.props.isLoggedIn){
            Object.assign(validations, {
                adminFirstName: Yup.string().trim()
                    .max(100, Messages.MAX_LENGTH_100)
                    .required(Messages.REQUIRED_ERR),
                adminLastName: Yup.string().trim()
                    .max(100, Messages.MAX_LENGTH_100)
                    .required(Messages.REQUIRED_ERR),
                adminEmail: Yup.string().trim()
                    .max(150, Messages.MAX_LENGTH_150)
                    .email(Messages.INVALID_EMAIL)
                    .required(Messages.REQUIRED_ERR),
                adminPhone: Yup.string().trim()
                    .matches(Regex.phone, {message: Messages.INVALID_PHONE, excludeEmptyString: false})
                    .max(15, Messages.INVALID_PHONE)
                    .required(Messages.REQUIRED_ERR),
                adminPhoneCode: Yup.string().trim()
                    .required(Messages.REQUIRED_ERR)
            });
            if(!this.props.isSuperAdmin){
                Object.assign(validations, {
                    adminUsername: Yup.string().trim()
                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    adminPassword: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .min(8, Messages.MIN_LENGTH_8)
                        .required(Messages.REQUIRED_ERR)
                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                    adminConfirmPassword: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .oneOf([Yup.ref('adminPassword'), null], Messages.PASSWORD_CPASS_MATCH_ERR)
                });
            }
        }


        const formContent = (
            <Formik
                //  enableReinitialize={true}
                initialValues={{
                    clubName: this.fields.clubName,
                    adminFirstName: this.fields.adminFirstName,
                    adminLastName: this.fields.adminLastName,
                    adminEmail: this.fields.adminEmail,
                    adminUsername: this.fields.adminUsername,
                    adminPassword: this.fields.adminPassword,
                    adminConfirmPassword: this.fields.adminConfirmPassword,
                    adminPhone: this.fields.adminPhone,
                    orgWebsite: this.fields.orgWebsite,
                    additionalInformation: this.fields.additionalInformation,
                    adminPhoneCode: this.fields.adminPhoneCode ? this.fields.adminPhoneCode : '+1',
                }}
                validationSchema={Yup.object().shape(validations)}
                validate={(values) => {
                    this.hasValidateMethodRun = true;
                    if (this.isFormValid) {
                        this.fields = values;
                    }
                }}
                render={
                    (formikProps) => {
                        const {errors, touched, submitForm, isValid, setFieldValue, values} = formikProps;
                        if (!this.photoEvent) this.bindSubmitForm(submitForm, errors, isValid);
                        return (
                            <Form>
                                <div className="field form-group">
                                    <Field name="clubName" type="text" id="clubName" autoComplete="off"
                                           className={'form-control' + (errors.clubName && touched.clubName ? ' is-invalid' : '')}
                                           onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                    <label htmlFor="clubName" className={labelClass}>Organization Name</label>
                                    <ErrorMessage name="clubName" component="div" className="invalid-feedback"/>
                                </div>
                                {
                                    this.props.isSuperAdmin || !this.props.isLoggedIn ?
                                        <div>
                                            <div className="field form-group">
                                                <Field name="adminFirstName" type="text" id="adminFirstName" autoComplete="off"
                                                       className={'form-control' + (errors.adminFirstName && touched.adminFirstName ? ' is-invalid' : '')}
                                                       onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                                <label htmlFor="adminFirstName" className={labelClass}>Admin First Name</label>
                                                <ErrorMessage name="adminFirstName" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="field form-group">
                                                <Field name="adminLastName" type="text" id="adminLastName" autoComplete="off"
                                                       className={'form-control' + (errors.adminLastName && touched.adminLastName ? ' is-invalid' : '')}
                                                       onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                                <label htmlFor="adminLastName" className={labelClass}>Admin Last Name</label>
                                                <ErrorMessage name="adminLastName" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="field form-group">
                                                <Field name="adminEmail" type="text" id="adminEmail" autoComplete="off"
                                                       className={'form-control' + (errors.adminEmail && touched.adminEmail ? ' is-invalid' : '')}
                                                       onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                                <label htmlFor="adminEmail" className={labelClass}>Admin Email</label>
                                                <ErrorMessage name="adminEmail" component="div" className="invalid-feedback"/>
                                            </div>
                                            {
                                                !this.props.isSuperAdmin && <>
                                                    <div className="field form-group">
                                                        <Field maxLength="150" name="adminUsername" type="text" id="adminUsername" autoComplete="off" className={'form-control' + (errors.adminUsername && touched.adminUsername ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                                        <label htmlFor="adminUsername" className={labelClass}>Admin Username</label>
                                                        <ErrorMessage name="adminUsername" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="adminPassword" type="password" id="adminPassword" autoComplete="off" className={'form-control' + (errors.adminPassword && touched.adminPassword ? ' is-invalid' : '')} onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} />
                                                        <label htmlFor="adminPassword" className={labelClass}>Admin Password</label>
                                                        <ErrorMessage name="adminPassword" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="adminConfirmPassword" type="password" id="adminConfirmPassword" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.adminConfirmPassword && touched.adminConfirmPassword)  ? ' is-invalid' : '')} />
                                                        <label htmlFor="adminConfirmPassword" className={labelClass}>Admin Confirm Password</label>
                                                        <ErrorMessage name="adminConfirmPassword" component="div" className="invalid-feedback" />
                                                    </div>
                                                </>
                                            }
                                            <div className="field form-group">
                                                <PhoneInput
                                                    parent={this}
                                                    codeField={'adminPhoneCode'}
                                                    defaultCode={this.fields.adminPhoneCode ? this.fields.adminPhoneCode : '+1'}
                                                    phoneField={'adminPhone'}
                                                    fieldLabel={'Admin Phone'}
                                                    errors={errors}
                                                    touched={touched}
                                                    serverValid={false}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                />
                                            </div>
                                        </div>
                                    : null
                                }
                                <div className="field form-group">
                                    <Field name="orgWebsite" type="url" id="orgWebsite" autoComplete="off"
                                           className={'form-control' + (errors.orgWebsite && touched.orgWebsite ? ' is-invalid' : '')}
                                           onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                    <label htmlFor="orgWebsite" className={labelClass}>Organization Website</label>
                                    <ErrorMessage name="orgWebsite" component="div" className="invalid-feedback"/>
                                </div>
                                <div className="field form-group form-group--additonal-info">
                                    <Field component="textarea" name="additionalInformation" type="text"
                                           id="additionalInformation" autoComplete="off" className={'form-control'}
                                           className={'form-control' + (errors.additionalInformation && touched.additionalInformation ? ' is-invalid' : '')}
                                           onFocus={Helper.inputFocus} onBlur={Helper.inputBlur}/>
                                    <label htmlFor="additionalInformation" className={labelClass}>Additional
                                        Information</label>
                                    <ErrorMessage name="additionalInformation" component="div"
                                                  className="invalid-feedback"/>
                                </div>
                            </Form>
                        )
                    }
                }
            />
        )
        return (
            <>
                <Row className="steps-content">
                    <Col sm={12} md={6}>
                        <div className="info-box info-box--no-shadow">
                            <div className="info-box__header info-box__header--org">
                                <div className="info-box__uplaod">
                                    <input name="clubPicture" type='file' onChange={this.onPhotoChange}
                                           accept=".jpg,.jpeg"/>
                                    <Image src={Images.CameraIcRed} alt="camera" width="24"/>
                                </div>
                                <Image
                                    src={this.state.uploadedPhotoUrl ? this.state.uploadedPhotoUrl : Images.DefaultLogo}/>
                            </div>
                            <div className="info-box__content mt-2 info-box__content--no-padding">
                                <div className="content__lft-col">Upload Logo</div>
                                <div className="content__rgt-col">Recommended dimensions of 300 x 169 pixels</div>
                            </div>
                        </div>

                    </Col>
                    <Col sm={12} md={6}>
                        {formContent}
                    </Col>
                </Row>
                <ErrorModal
                    show={this.state.showError}
                    message={this.state.errorMessage}
                    onConfirmClick={() => {
                        this.setState({showError: false});
                        this.photoEventBlockHack();
                    }}
                ></ErrorModal>
            </>
        )
    }
}

export {GeneralInfo};
