/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Image, Navbar, Nav, Form, Button } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import './profile.scss';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Messages } from '../../components/common/message';

class VerifySecEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyToken: props.match.params.token,
            isValidLink: false,
            isValidMessage: null,
            loading: false,
        };
    }

    componentDidMount() {
        const verifyToken = this.state.verifyToken;
        this.setState({ loading: true });
        Helper.axiosInstance().post(`user/verify-email`, {
            token: verifyToken
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ isValidLink: true, isValidMessage: response.data.message, loading: false, })
                }
                else {
                    this.setState({ isValidLink: false, loading: false, isValidMessage: response.data.error, })
                }
            })
            .catch((error) => {
                this.setState({ isValidMessage: Messages.SERVER_ERROR, isValidLink: false, loading: false, })
            });
    }

    render() {
        return (
            <>
                <Navbar bg="light" expand="lg" className="topbar invite-header">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto org-logo">
                            <Image src={Images.SafecommLogo} alt="SafeComm" title="SafeComm" width="245" />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="container">
                    <Row className="content-space">
                        <Col sm={12} md={8} lg={8} className="offset-md-2 offset-lg-2">
                            <div className="widget">
                                {
                                    this.state.loading ? <div className="loader-center"><SmallLoader /></div> :
                                        this.state.isValidLink ?
                                            <div className="text-center py-5 px-3 confirmbox">
                                                <Image src={Images.SuccessTick} alt="Success" width="100" />
                                                <h1 className="mt-3 mb-3">Success</h1>
                                                <p className="mb-4">{this.state.isValidMessage}</p>
                                                <p className="mb-4">	
                                                    <Button variant="secondary" className="ripple btn-ok" onClick={() => this.props.history.push('/')}>	
                                                        Continue	
                                                    </Button>	
                                                </p>
                                            </div>
                                            :
                                            <div className="text-center py-5 px-3 confirmbox">
                                                <Image src={Images.Confirm} alt={Images.Confirm} width="100" />
                                                <h1 className="mt-3 mb-3">Error</h1>
                                                <p className="mb-4">{this.state.isValidMessage}</p>
                                                <Form>
                                                    <Link to="#" onClick={Helper.handleSupportContact} className="btn--green btn justify-content-center">Contact Us</Link>
                                                </Form>
                                            </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
export { VerifySecEmail };