/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

import './loader.scss';

export default class Loader extends Component {
    render() {
        const loading = this.props.loading ? "loader" : "";   
        return (
            <>  
                <div className={loading}>                   
                    <div className="loader-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>    
            </>
        )
    }
}