/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */


/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col } from 'react-bootstrap';
/* End - React Bootstrap */
import './footer.scss';
import { SafeCommWebsiteSupportUrl, SafeCommWebsite } from '../common/enum'

export const Footer = () => (
    <footer className="footer-wrap">
        <Row>
            <Col sm={12}>
                <ul>
                    <li><a target="_blank" href={SafeCommWebsite}>SafeComm Website</a></li>
                    <li><a target="_blank" href={SafeCommWebsiteSupportUrl}>Support</a></li>
                </ul>
            </Col>
            <Col sm={12}>
                <p>© {(new Date().getFullYear())} by Safe Communication Consultants. </p>
            </Col>
        </Row>


    </footer>
);
