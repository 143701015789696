import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Messages} from "../../components/common/message";
import * as Regex from "../../components/common/regex";
import {Button, Image} from "react-bootstrap";
import * as Images from "../../components/common/images";
import {UsStates} from "../../components/common/us-states";
import {SSNInput} from "../../components/input/ssn";
import React from "react";

export function ISRAddress({ISRDetail, toggleEdit, isrAddressFieldDisabled,  isrBankInfoFieldDisabled, inputFocus, inputBlur,
                               editAddressFlag, editBankInfoFlag, updateISRBankLoader,updateISRAddressLoader, cancelAddressClick,
                               cancelBankInfoClick, updateISRDetails, onSelectStateChange, updateAddressFlag, updateBankInfoFlag}) {
      return( <>
           {
               ISRDetail &&
               <Formik
                    enableReinitialize
                    initialValues={{
                       mailingAddress: ISRDetail.address,
                       mailingAddress2: ISRDetail.address2 ? ISRDetail.address2:'',
                       bankAccountName: ISRDetail.bank_account_name,
                       bankAccountNumber: ISRDetail.bank_account_number,
                       bankRoutingNumber: ISRDetail.bank_routing_number,
                       city: ISRDetail.city,
                       state: ISRDetail.state,
                       ssn: ISRDetail.ssn,
                       zip: ISRDetail.zip
                    }}
                   validationSchema={Yup.object().shape({
                       mailingAddress: Yup.string().trim()
                           .max(255, Messages.MAX_LENGTH_255)
                           .required('Please enter mailing address'),
                       mailingAddress2: Yup.string().trim()
                           .max(190, Messages.MAX_LENGTH_255).nullable(),
                       bankRoutingNumber: Yup.string().trim()
                           .max(9, Messages.MAX_LENGTH_9)
                           .required('Bank Routing Number is required'),
                       bankAccountName: Yup.string().trim().nullable()
                           .max(150, Messages.MAX_LENGTH_150)
                           .required('Bank Account Name is required'),
                       bankAccountNumber: Yup.string().trim()
                           .max(17, Messages.MAX_LENGTH_17)
                           .required('Bank Account Number is required'),
                       city: Yup.string().trim()
                           .max(150, Messages.MAX_LENGTH_150)
                           .required('Please enter mailing address city'),
                       state: Yup.string().trim()
                           .max(150, Messages.MAX_LENGTH_150)
                           .required('Please select mailing address state.'),
                       zip: Yup.number()
                           .typeError(Messages.INVALID_ZIP_TYPE)
                           .min(10000, Messages.INVALID_ZIP_LENGTH)
                           .max(99999, Messages.INVALID_ZIP_LENGTH)
                           .required('Please enter mailing address zip code.'),
                       ssn: Yup.string().trim()
                           .matches(Regex.ssn, {message: Messages.INVALID_SSN, excludeEmptyString: false})
                           .required('Social Security Number is required').nullable(),
                   })}
                   onSubmit={fields => {
                       updateISRDetails(fields);
                   }}
                   render={({errors, setFieldValue, setFieldTouched, status, touched, values}) => (
                       <Form noValidate>
                           <div className="slab-header">
                               <div className="slab-header__heading">Mailing Address</div>
                               <div className="add-linkbtn" style={updateAddressFlag} onClick={()=>toggleEdit(true)}>
                                   <Image src={Images.EditBlue}/> <i>Edit Address</i>
                               </div>
                           </div>
                            <div className="slab-content">
                               <div className=" row">
                                   <div className="col-sm-6">
                                       <div className="field form-group">
                                           <Field name="mailingAddress" disabled={(isrAddressFieldDisabled) ? "disabled" : ""}
                                                  type="text" id="mailingAddress" onFocus={inputFocus} maxLength="250"
                                                  className={'form-control' + (errors.mailingAddress && touched.mailingAddress ? ' is-invalid' : '')}/>
                                           <label htmlFor="mailingAddress"

                                                  className={'form-label ' + (ISRDetail.address ? ' label-active' : '')}>Address</label>
                                           <ErrorMessage name="mailingAddress" component="div"
                                                         className="invalid-feedback"/>
                                       </div>
                                   </div>
                                   <div className="col-sm-6">
                                       <div className="field form-group">
                                           <Field name="mailingAddress2" disabled={(isrAddressFieldDisabled) ? "disabled" : ""}
                                                  type="text" id="mailingAddress2" onFocus={inputFocus} maxLength="250"
                                                  className={'form-control' + (errors.mailingAddress2 && touched.mailingAddress2 ? ' is-invalid' : '')}/>
                                           <label htmlFor="mailingAddress2"
                                                  className={'form-label ' + (ISRDetail.address2 ? ' label-active' : '')}>Address
                                               2</label>
                                           <ErrorMessage name="mailingAddress2" component="div"
                                                         className="invalid-feedback"/>
                                       </div>

                                   </div>
                               </div>
                               <div className="row">
                                   <div className="col-sm-6">
                                       <div className="field form-group">
                                           <div className="select-wrap">
                                               <Field component="select"
                                                      disabled={(isrAddressFieldDisabled) ? "disabled" : ""}
                                                      className={' form-control' + (errors.state && touched.state ? ' is-invalid' : '')}
                                                      name="state"
                                                      id="state" maxLength="150"
                                                      onBlur={() => setFieldTouched("state", true)}
                                                      onChange={(event) => onSelectStateChange(event, setFieldValue)}
                                               >
                                                   <option value=''>-- Select State --</option>
                                                   {
                                                       Object.keys(UsStates).map((key) => {
                                                           return (
                                                               <option key={key}
                                                                       value={UsStates[key]}>{UsStates[key]}</option>
                                                           )
                                                       })
                                                   }
                                               </Field>
                                               <ErrorMessage name="state"
                                                             component="div"
                                                             className="invalid-feedback"/>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="col-sm-6">
                                       <div className="field form-group">
                                           <Field name="city" disabled={(isrAddressFieldDisabled) ? "disabled" : ""} id="city"
                                                  onFocus={inputFocus} type="text" maxLength="150"
                                                  className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}/>
                                           <label htmlFor="city"
                                                  className={'form-label ' + (ISRDetail.city ? ' label-active' : '')}>City</label>
                                           <ErrorMessage name="city" component="div" className="invalid-feedback"/>
                                       </div>
                                   </div>

                               </div>
                               <div className="row">
                                   <div className="col-sm-6">
                                       <div className="field form-group">
                                           <Field name="zip" disabled={(isrAddressFieldDisabled) ? "disabled" : ""} id="zip"
                                                  onFocus={inputFocus}  type="text" maxLength="5"
                                                  className={'form-control' + (errors.zip && touched.zip ? ' is-invalid' : '')}/>
                                           <label htmlFor="zip"
                                                  className={'form-label ' + (ISRDetail.zip ? ' label-active' : '')}>Zip</label>
                                           <ErrorMessage name="zip" component="div" className="invalid-feedback"/>
                                       </div>
                                   </div>
                                    <div className="col-sm-12">
                                        <div style={editAddressFlag}>
                                            <Button type="Submit" disabled={updateISRAddressLoader}
                                                    className={`btn ripple btn-medium d-inline-flex align-items-center ${updateISRAddressLoader ? 'btn-loader' : ''}`}>
                                                <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="btn"/>
                                                </div>
                                                Save <span style={{'display': 'none'}} className="spinner-border spinner-border-sm"
                                                           role="status" aria-hidden="true"></span>
                                            </Button>
                                            <Button disabled={updateISRAddressLoader} className="btn-cancel ripple"
                                                    onClick={(event) => cancelAddressClick(event, setFieldValue)}>Cancel</Button>
                                        </div>
                                    </div>
                               </div>
                            </div>
                           <div className="slab-header">
                               <div className="slab-header__heading">Bank Information</div>
                               <div className="add-linkbtn" style={updateBankInfoFlag} onClick={()=>toggleEdit(false)}>
                                   <Image src={Images.EditBlue}/> <i>Edit Bank Info</i>
                               </div>
                           </div>
                           <div className="slab-content">
                            <div className="row">

                               <div className="col-sm-6">
                                   <div className="field form-group">
                                       <Field name="bankAccountName" maxLength="150" disabled={(isrBankInfoFieldDisabled) ? "disabled" : ""}
                                              id="bankAccountName" onFocus={inputFocus} type="text"
                                              className={'form-control' + (errors.bankAccountName && touched.bankAccountName ? ' is-invalid' : '')}/>
                                       <label htmlFor="bankAccountName"
                                              className={'form-label ' + (ISRDetail.bank_account_name ? ' label-active' : '')}>Bank
                                           Account
                                           Name</label>
                                       <ErrorMessage name="bankAccountName" component="div"
                                                     className="invalid-feedback"/>
                                   </div>
                               </div>
                               <div className="col-sm-6">
                                   <div className="field form-group">
                                       <Field name="bankAccountNumber" maxLength="17" disabled={(isrBankInfoFieldDisabled) ? "disabled" : ""}
                                              id="bankAccountNumber" onFocus={inputFocus} type="text"
                                              className={'form-control' + (errors.bankAccountNumber && touched.bankAccountNumber ? ' is-invalid' : '')}/>
                                       <label htmlFor="bankAccountNumber"
                                              className={'form-label ' + (ISRDetail.bank_account_number ? ' label-active' : '')}>Bank
                                           Account
                                           Number</label>
                                       <ErrorMessage name="bankAccountNumber" component="div"
                                                     className="invalid-feedback"/>
                                   </div>
                               </div>
                               <div className="col-sm-6">
                                   <div className="field form-group">
                                       <Field name="bankRoutingNumber" maxLength="9" disabled={(isrBankInfoFieldDisabled) ? "disabled" : ""}
                                              id="bankRoutingNumber" onFocus={inputFocus} type="text"
                                              className={'form-control' + (errors.bankRoutingNumber && touched.bankRoutingNumber ? ' is-invalid' : '')}/>
                                       <label htmlFor="bankRoutingNumber"
                                              className={'form-label ' + (ISRDetail.bank_routing_number ? ' label-active' : '')}>Bank
                                           Routing
                                           Number</label>
                                       <ErrorMessage name="bankRoutingNumber" component="div"
                                                     className="invalid-feedback"/>
                                   </div>
                               </div>
                                <div className="col-sm-6">
                                    <div className="field form-group">
                                        <SSNInput
                                            parent={this}
                                            fieldLabel={'Social Security Number'}
                                            SSNField={'ssn'}
                                            errors={errors}
                                            touched={touched}
                                            serverValid={false}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            disabled={(isrBankInfoFieldDisabled) ? "disabled" : ""}
                                        />
                                        <ErrorMessage name="SSN" component="div" className="invalid-feedback"/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div style={editBankInfoFlag}>
                                        <Button type="Submit" disabled={updateISRBankLoader}
                                                className={`btn ripple btn-medium d-inline-flex align-items-center ${updateISRBankLoader ? 'btn-loader' : ''}`}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="btn"/>
                                            </div>
                                            Save <span style={{'display': 'none'}} className="spinner-border spinner-border-sm"
                                                       role="status" aria-hidden="true"></span>
                                        </Button>
                                        <Button disabled={updateISRBankLoader} className="btn-cancel ripple"
                                                onClick={(event) => cancelBankInfoClick(event, setFieldValue)}>Cancel</Button>
                                    </div>
                                </div>
                           </div>
                           </div>
                       </Form>
                   )}
               />
           }
        </>
   )
}
