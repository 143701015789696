/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

class OverlayBg extends Component {
render() {
    return (
        <div className="overlay"></div>
        )
    }
}
export {OverlayBg};