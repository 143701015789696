/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Dropdown, Table, Media, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

import { SmallLoader } from '../../components/small-loader/sm-loader';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import { NoRecord } from '../../components/no-record/no-record';
import './team.scss';

//  * Get the Kid list for profile
class TeamList extends Component {
    render() {
        let props = this.props;
        let sortImage = Images.ArrowSort;
        if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'desc'){
            sortImage = Images.ArrowDown;
        } else if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'asc') {
            sortImage = Images.ArrowUp;
        }

        return (<Table bordered hover className="tab-responsive table--listing table--listing--width">
            <thead>
                <tr>
                    {
                        props.memberGroupsType != 'archived' && Helper.isCoachOrAdminLogin() &&
                        <th>
                            <span className="checkwrap">	
                                <input className="checkwrap__cbx" id="checkedall"	
                                    checked={props.checkedAll ? true : false}	
                                    type="checkbox" onChange={() => props.handleAllChecked()} />	
                                <label className="checkwrap__label" htmlFor="checkedall">	
                                    <span className="checkwrap__label_chk">	
                                        <svg width="14px" height="12px" viewBox="0 0 14 12">	
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>	
                                        </svg>	
                                    </span>	
                                </label>	
                            </span>
                        </th>
                    }
                    {
                        (typeof props.sortCriteria != 'undefined'  && Object.keys(props.data).length > 1)  ?
                            <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('name')}>Member Groups
                                { props.sortCriteria.column === 'name' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>Member Groups</th>

                    }
                    {
                        (typeof props.sortCriteria != 'undefined'  && Object.keys(props.data).length > 1)  ?
                            <th className='custom-width-column col-sortable' onClick={() => props.sortHandler('teams.location')}>Location
                                { props.sortCriteria.column === 'teams.location' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            : <th>Location</th>

                    }
                    {
                        (typeof props.sortCriteria != 'undefined'  && Object.keys(props.data).length > 1) ?
                            <th className='custom-width-column date_created_col col-sortable' onClick={() => props.sortHandler('created_at')}>Date Created
                                { props.sortCriteria.column === 'created_at' ?
                                    <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                    <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                                }</th>
                            :  <th>Date Created </th>

                    }

                    {
                        Helper.isCoachOrAdminLogin() ?
                            <th>Students </th>
                            : null
                    }
                    <th>Instructor</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    (typeof props.data == "object" && Object.keys(props.data).length > 0) &&
                    props.data.map((row, i) => {
                        return (
                            <tr key={i}>
                                {
                                    props.memberGroupsType != 'archived' && Helper.isCoachOrAdminLogin() &&
                                    <td>	
                                        <span className="checkwrap">	
                                            <input className="checkwrap__cbx" id={i} type="checkbox"	
                                            checked={props.checkedItems.get(row.id) ? true : false} 	
                                                onChange={() => props.onCheckboxChange(row)} />	
                                            <label className="checkwrap__label" htmlFor={i}>	
                                                <span className="checkwrap__label_chk">	
                                                    <svg width="14px" height="12px" viewBox="0 0 14 12">	
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>	
                                                    </svg>	
                                                </span>	
                                            </label>	
                                        </span>
                                    </td>
                                }
                                <td data-label="Member Groups" className="td-content-space">
                                    <Media>
                                        <div className="im-rounded-outer">
                                            <Image src={row.photo} alt={row.name} />
                                        </div>
                                        <Media.Body className="align-self-center media-body--link">
                                            <Link to={`/team-details/${row.id}`}>
                                                <h5>{row.name}</h5>
                                            </Link>
                                        </Media.Body>
                                    </Media>
                                </td>
                                <td data-label="Location" className="td-content-space">
                                    <Media>
                                        <Media.Body className="align-self-center media-body--link">
                                                <h5>{row.location}</h5>
                                        </Media.Body>
                                    </Media>
                                </td>
                                <td data-label="Date Created">{row.created_at}</td>
                                { Helper.isCoachOrAdminLogin() ?
                                        <td data-label="Students">{row.total_member}</td>
                                        : null  }
                                <td data-label="Instructor" className="td-content-space">
                                    <div className="coach-grp">
                                        { (row.coach !== undefined && row.coach.length > 0)
                                            &&
                                            row.coach.map((coachRow, i) => {
                                                let coachId = 'coach' + i;
                                                return (<OverlayTrigger key={coachId}
                                                    overlay={
                                                        <Tooltip id={`tooltip`}>
                                                            {coachRow.first_name} {coachRow.last_name}
                                                        </Tooltip>
                                                    }>
                                                    <div className="im-rounded-outer"><Image src={coachRow.photo} alt={coachRow.first_name} /></div>
                                                </OverlayTrigger>)
                                            })
                                        }
                                    </div>
                                </td>
                                <td>
                                    <Dropdown className="more-actions">
                                        <Dropdown.Toggle>
                                            <span className="more-im"></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className="dropdown-item" to={`/team-details/${row.id}`}>
                                                <Image className="mr-2" src={Images.EditIc} alt="Details"  width="21" />
                                                <span>Details</span>
                                            </Link>
                                            { Helper.isAdminOrSubAdmin() ?
                                                    row.status === 1 ?
                                                        <Dropdown.Item onClick={() => props.onDeactivateTeamClick(row.id, row.status)} >
                                                            <Image className="mr-2" src={Images.DeleteIc} alt="Deactivate" width="21" />
                                                            <span className="de-color">Archive</span>
                                                        </Dropdown.Item>
                                                        :
                                                        <Dropdown.Item onClick={() => props.onDeactivateTeamClick(row.id, row.status)} >
                                                            <Image className="mr-2" src={Images.ActivateIcon} alt="Activate" width="21" />
                                                            <span className="color">Activate</span>
                                                        </Dropdown.Item>
                                                    : null }
                                            {
                                                props.teamMemberOf.includes(row.id) ?
                                                    row.type === 'A' ?
                                                        <Dropdown.Item
                                                            onClick={() => Helper.fetchSelectedMembersCount(false, this.props.mainObj, 'teams', row.id)}>
                                                            <Image
                                                                className="mr-2"
                                                                src={Images.announcement}
                                                                alt="Chat"
                                                                width="21"
                                                            />
                                                            <span>Announcement</span>
                                                        </Dropdown.Item>
                                                        : <Dropdown.Item
                                                            onClick={() => this.props.mainObj.props.setChatWithGroupId(`T_${row.id}`)}>
                                                            <Image
                                                                className="mr-2"
                                                                src={Images.ChatIc}
                                                                alt="Chat"
                                                                width="21"
                                                            />
                                                            <span>Chat</span>
                                                        </Dropdown.Item>
                                                    : null
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        );
                    })
                }
                {!props.recordLoaded &&
                    <>
                        <tr className="mb--norecord">
                            <td colSpan="6">
                                <SmallLoader />
                            </td>
                        </tr>
                    </>
                }
                {
                    (props.recordLoaded && props.data.length === 0) &&
                    <tr className="mb--norecord">
                        <td colSpan="6">
                            <NoRecord />
                        </td>
                    </tr>
                }
            </tbody>
                <tfoot>
                <tr>
                    {
                        props.memberGroupsType != 'archived' && Helper.isCoachOrAdminLogin() &&
                        <th>
                            <span className="checkwrap">
                                <input className="checkwrap__cbx" id="checkedAllBtm"
                                       checked={props.checkedAll ? true : false}
                                       type="checkbox" onChange={() => props.handleAllChecked()} />
                                <label className="checkwrap__label" htmlFor="checkedAllBtm">
                                    <span className="checkwrap__label_chk">
                                        <svg width="14px" height="12px" viewBox="0 0 14 12">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                </label>
                            </span>
                        </th>
                    }
                    <th>Member Groups</th>
                    <th>Location</th>
                    <th>Date Created </th>
                    {
                        Helper.isCoachOrAdminLogin() ?
                            <th>Students </th>
                            : null
                    }
                    <th>Instructor</th>
                    <th></th>
                </tr>
                </tfoot>
        </Table>
        )
    }
}


export { TeamList };