/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Container} from 'react-bootstrap';
/* End - React Bootstrap */

/* Start - app imports */
import {Footer} from '../../components/footer/footer';
import './../invite-process/invite.scss';
/* End - app imports */
import { InviteHeader } from './../invite-process/invite-helper';
export const RegisterISRLandingPage = ()=>{
       return (
            <>
                <InviteHeader/>
                <Container>
                    <Row className="vh-content">
                        <Col sm={12} className="in-card-center">
                            <div className="in-card-subheading">SafeComm - Marketing Affiliate Program</div>
                        </Col>
                        <Col sm={6} className="in-card-center">
                            <div className="in-card">
                                <div className="in-card__header">
                                    <div className="in-card__header__subheading">
                                        New Here?
                                    </div>
                                    <div className="in-card__header__heading">
                                        Start Your Membership
                                    </div>
                                </div>
                                <div className="in-card__content">
                                    <p>If you are not currently a member the SafeComm then click the button below to
                                        create a Marketing Affiliate account. </p>
                                </div>

                                    <Link to={`/register-isr/#info`} className="in-card__btn btn justify-content-center">
                                        Register Now
                                    </Link>

                            </div>
                        </Col>
                        <Col sm={6} className="in-card-center">
                            <div className="in-card">
                                <div className="in-card__header">
                                    <div className="in-card__header__subheading">
                                        Registered Member?
                                    </div>
                                    <div className="in-card__header__heading">
                                        Link Your Account
                                    </div>
                                </div>
                                <div className="in-card__content">
                                    <p>If you are already a member of the Safecomm then click the button below to Login.
                                        After login, click on register as Marketing Affiliate link from the sidebar menu. </p>
                                </div>
                                    <Link to={`/login/contacts`} className="in-card__btn btn justify-content-center">
                                        Login Now
                                    </Link>

                            </div>
                        </Col>
                    </Row>
                    <Footer/>
                </Container>
            </>
        )
}

