import {InputGroup} from "react-bootstrap";
import {phoneCountryCode} from "../data/phone-country-code";
import {ErrorMessage, Field} from "formik";
import * as Helper from "../common/functions";
import React from "react";

export const PhoneInput = (props)=>{
    return(
            <InputGroup>
                <span className="select-text">Country Code</span>

                <Field name={props.codeField} disabled={props.disabled ? true : false} component="select"
                       onChange={(e)=>{
                           props.setFieldValue(props.codeField, e.target.value);
                       }}>
                    {
                        Object.keys(phoneCountryCode).map((key, val) => {
                            return (
                                <option key={props.i ? props.i+'-'+key : key} value={phoneCountryCode[key].dial_code}>{phoneCountryCode[key].code} {' '} ({phoneCountryCode[key].dial_code})</option>
                            )
                        })
                    }

                </Field>
                <Field name={props.phoneField} type="text" >
                    {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      }) => (
                        <Helper.PhoneMaskedInput
                            field={field}
                            disabled={props.disabled ? true : false}
                            onChange={(e)=>{
                                props.setFieldValue(props.phoneField, e.target.value);
                            }}
                            className={'form-control' + ((props.errors[props.phoneField] && props.touched[props.phoneField]) || (props.serverValid && props.serverValid[props.phoneField] !== '') ? ' is-invalid' : '')}
                            id={props.phoneField}
                        />
                    )}
                </Field>
                <label htmlFor={props.phoneField} className={Helper.getLabelClass(props.values ? props.values[props.phoneField] : null)}>{props.fieldLabel}</label>
                {
                    (props.errors[props.phoneField] && props.touched[props.phoneField]) ?
                        <ErrorMessage name={props.phoneField} component="div" className="invalid-feedback" />
                        :
                        <div className="invalid-feedback">{props.serverValid ? props.serverValid[props.phoneField] : ''}</div>
                }
            </InputGroup>
    );
}
