/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Form} from 'react-bootstrap';
import '../club/club.scss';
import * as Helper from "../../components/common/functions";


class AgreementText extends Component {
    constructor(props) {
        super(props);
        console.log('props', props)
    }

    render() {
        const curDate = new Date();

        return (
            <Col sm={12} className="pt-3" style={{'overflow': 'auto', 'height': '500px'}}>
                <h2>AFFILIATED MARKETING AGENT AGREEMENT</h2>
                <p>This AFFILIATED MARKETING AGENT AGREEMENT (the “Agreement”), made and entered into
                    this {Helper.getDate(curDate, 'd', true)} day
                    of {Helper.getDate(curDate, 'm')}, {Helper.getDate(curDate, 'y')} (the “Effective Date”) by
                    and between Safe Communications Consultants, LLC, a Delaware limited liability company,
                    having its principal place of business at, 241 S. Main St., Unit 210, Bartlett, Illinois
                    60103 (hereinafter called “SCC”)
                    and {this.props.general.firstname} {this.props.general.lastname}, an individual having a
                    principal place of residence
                    at {this.props.address.mailAddress} {this.props.address.mailAddress2},
                    {this.props.address.mailCity}, {this.props.address.mailState}, {this.props.address.mailZip}
                    (hereinafter called “Agent”) (SCC and Agent are each referred to herein generally as a “Party” and together as the “Parties”). </p>
                <p>WHEREAS, SCC designs, licenses, and services a mobile and website-based message application
                    for communication;</p>
                <p>WHEREAS, Agent has an ongoing professional relationship with youth dance teams and
                    organizations that may be interested in the products and services of SCC; </p>
                <p>WHEREAS, SCC desires to engage Agent to identify prospective customers, make introductions,
                    and generate accounts for SCC products and services as specified and set forth herein;
                    and</p>
                <p>WHEREAS, Agent desires to be engaged by SCC to identify prospective customers, make
                    introductions, and generate accounts for SCC products and services as specified and set
                    forth herein.</p>
                <p>NOW THEREFORE, for and in consideration of the promises and covenants contained herein, the
                    sufficiency of which are hereby acknowledged, the Parties agree as follows:</p>
                <p><strong>1) Recitals.</strong> The foregoing recitals are an integral part of this Agreement
                    and are incorporated herein by this reference thereto.</p>

                <p><strong>2) Definitions.</strong> “Authorized Customer” means any person or entity that SCC
                    approves in writing for the purpose of Agent making an introduction and generating accounts
                    for SCC products and services. </p>
                <p>“New Account” means any new organization which was not a prior customer of SCC and signs an
                    Organization Account Agreement with SCC, generated by Agent’s introduction pursuant to the
                    terms of this Agreement. </p>
                <p><strong>3) Appointment and Acceptance.</strong> SCC hereby appoints Agent as a non-exclusive
                    independent Agent to make introductions to generate New Accounts for SCC products and
                    services with Authorized Customers during the term of this Agreement and any renewal terms,
                    and Agent hereby accepts the appointment and agrees to make introductions and to generate
                    New Accounts for SCC products and services pursuant to the terms of this Agreement.</p>
                <p><strong>4) Duties of Agent.</strong> Agent shall use its reasonable best efforts to: </p>


                <p>(a) Identify potential customers to SCC for written approval by SCC for solicitation of New
                    Accounts by Agent;</p>

                <p>(b) Make introductions between SCC and Authorized Customers on a timely basis; and</p>

                <p>(c) Present all proposals for New Accounts from Authorized Customers to SCC management for
                    review, negotiation, acceptance, or rejection by SCC on a timely basis.</p>

                <p><strong>5) Relationship of the Parties.</strong> The parties hereto agree that they are two
                    independent parties whose only relationship is that expressed in this Agreement between a
                    service provider and Affiliated Marketing Agent. This Agreement does not constitute an
                    employment contract, nor does it confer upon Agent any power as agent to contract for or
                    bind SCC, except as otherwise set forth in this Agreement. Agent’s sole function is to make
                    introductions between SCC and Authorized Customers and solicit New Accounts as an
                    independent Agent as hereinafter set forth. </p>

                <p><strong>6) Pricing, Terms, Operational Policies, and Regulations.</strong> Agent shall
                    observe, comply with, and be bound by all the pricing, terms, and operation policies and
                    regulations approved or authorized by SCC in writing. The prices and terms set forth by SCC
                    may be changed from time to time by SCC, in SCC’s sole discretion, and SCC will timely
                    inform Agent of any such policy, price, or change in terms.</p>

                <p><strong>7) New Account Opportunities Declined by SCC.</strong> If Agent presents a proposal
                    to SCC for a New Account or makes an introduction which the customer wishes to proceed with
                    SCC (a “Project”), SCC agrees to advise Agent of its decision to accept or reject the
                    Project within fifteen (15) business days of such presentation by Agent. SCC may accept or
                    reject a Project in SCC’s sole discretion.</p>

                <p><strong>8) Books and Records.</strong> SCC shall keep accounting records in sufficient detail
                    so that each commission payment to Agent can be clearly and accurately reported to and
                    ascertained by Agent. Agent may disclose such information to its accountants or attorneys,
                    subject to such advisor’s agreement to maintain the confidentiality thereof in accordance
                    with the obligations of Agent pursuant to this Agreement. </p>

                <p><strong>9) Commissions. </strong> (a) Agent earns commissions when New Accounts are opened
                    with SCC and paid for as a direct result of Agent’s introduction to SCC. The commission
                    shall be five percent (5%) of the Participant Fees paid to SCC per quarter per New Account
                    generated as a direct result of Agent’s introduction to SCC. The beginning of the commission
                    period is the commencement date of the account. The end of the commission period for any
                    given New Account is twenty-four (24) calendar months following the commencement date of the
                    account. </p>
                <p> (b) All of the commissions earned by Agent and due and payable by SCC shall be paid no later
                    thirty days after the last day of the quarter following the month during which SCC is paid
                    the Participant Fee by the New Account customer. For purposes of this Agreement, quarters
                    are deemed to end on the last day of March, June, September and December.</p>
                <p><strong>10) Billing and Collections.</strong> All billing to customers shall be made by SCC
                    in its name. Agent shall not invoice customers in its name nor collect any funds in its name
                    due to SCC for generation of New Accounts under this Agreement. SCC shall be responsible for
                    all collections. Agent agrees to cooperate and assist SCC in the collection of accounts.</p>

                <p><strong>11) Sales Costs and Expenses.</strong> Agent agrees to be solely responsible for any
                    and all of its own costs and expenses incurred by it in connection with the solicitation or
                    generation of New Accounts. </p>

                <p><strong>12) Representations or Warranties.</strong> Agent agrees not to make any
                    representation or warranties which will be binding upon SCC, except those authorized by SCC
                    in writing, nor will Agent incur or contract for any obligation on behalf of SCC.</p>

                <p><strong>13) Renewal, Termination, and Severance.</strong> SCC and Agent mutually agree that:
                </p>

                <p>(a) The original term of this Agreement shall begin as the Effective Date of this Agreement
                    and shall terminate on the day prior to the one (1) year anniversary of this Agreement. THIS
                    AGREEMENT WILL AUTOMATICALLY RENEW FOR ONE YEAR PERIODS THEREAFTER, UNLESS TERMINATED BY
                    EITHER PARTY BY ADVANCE WRITTEN NOTICE. EITHER PARTY MAY TERMINATE THIS AGREEMENT BY
                    PROVIDING NINETY (90) DAYS’ ADVANCE WRITTEN NOTICE TO THE OTHER PARTY AT ANYTIME.</p>

                <p>(b) In the event Agent terminates this Agreement by providing written notice per subparagraph
                    14(a) above, SCC will pay commissions, based upon the payment terms contained in this
                    Agreement, for three (3) months after the effective termination date for all New Accounts
                    generated by Authorized Customers which were introduced by Agent.</p>

                <p>(c) In the event SCC terminates this Agreement by providing written notice per subparagraph
                    13(a) above, SCC will pay commissions, based upon the payment terms contained in this
                    Agreement, for the remaining portion of the twenty-four (24) month commissions period on all
                    New Accounts generated by Authorized Customers which were introduced by Agent.</p>

                <p>(d) In the event of the death or Disability (defined below) of Agent, this Agreement shall
                    immediately terminate on the date of such event and SCC shall pay commissions to Agent or
                    Agent’s estate, based upon the payment terms contained in this Agreement, for six (6) months
                    thereafter on all New Accounts generated by Authorized Customers which were introduced by
                    Agent. “Disability” means the inability of the Agent to perform their essential duties
                    hereunder with reasonable accommodation by reason of illness or other physical or mental
                    incapacity for a period of more than sixty (60) consecutive days. In addition, a Disability
                    shall be conclusively deemed if Agent is determined eligible to receive disability benefits
                    from (i) any policy of disability insurance issued by a commercial insurer, (ii) a waiver of
                    premium benefit forming a part of any policy of life insurance, or (iii) Social
                    Security. </p>

                <p>(e) This Agreement may be immediately terminated by SCC for Cause at any time upon actual
                    notice thereof from SCC to Agent. For purposes of this Agreement, "Cause" shall mean: (i)
                    Agent's material breach of any provision of this Agreement; (ii) Agent's material or
                    repeated failure or neglect to perform its duties to SCC; (iii) Agent's development or
                    pursuit of interests adverse to SCC; (iv) Agent's willful misconduct, dishonesty, gross
                    negligence, theft, fraud, or other illegal conduct; or (v) Agent's indictment for a felony
                    or charge under a misdemeanor that in the judgment of SCC affects the ability of Agent to
                    represent the SCC under this Agreement, including without limitation an adverse change in
                    the public reputation of Agent. In the event SCC terminates this Agreement pursuant to this
                    subparagraph (e), SCC will pay commissions, based upon the payment terms contained in this
                    Agreement, for three (3) months after the effective termination date for all New Accounts
                    generated by Authorized Customers which were introduced by Agent.</p>

                <p><strong>14) Severability.</strong> If any one of the paragraphs of this Agreement is deemed
                    to be void or unenforceable, it shall in no way affect the remaining paragraphs of said
                    Agreement.</p>

                <p><strong>15) Counterparts.</strong> This Agreement may be executed in one (1) or more
                    counterparts, each of which shall be deemed an original, but all of which together
                    constitute one (1) and the same instrument.</p>

                <p><strong>16) Governing Law; Venue; Waiver.</strong> The Parties acknowledge and agree that the
                    laws of the State of Illinois shall be applied in construing the terms and conditions of
                    this Agreement, without respect to such state’s law related to conflicts of law. The Parties
                    further agree that all actions or proceedings arising directly or indirectly in connection
                    with, out of, related to, or from this Agreement shall be litigated in courts having situs
                    within the United States of America, State of Illinois, County of DuPage. Each Party hereby
                    consents and submits to the jurisdiction of any local, state, or federal court located
                    within such county. Each Party hereby waives any right it may have to transfer or change the
                    venue of any litigation brought against it, or brought by it, in accordance with this
                    paragraph. EACH PARTY HEREBY KNOWINGLY, VOLUNTARILY, AND INTENTIONALLY WAIVES ANY RIGHTS
                    THAT EACH MAY HAVE TO A TRIAL BY JURY IN ANY LITIGATION BASED HEREON, OR ARISING OUT OF,
                    UNDER, OR IN CONNECTION WITH THIS AGREEMENT. </p>

                <p><strong>17) Amendment.</strong> This Agreement may not be amended, changed, or modified
                    except in writing signed by both Parties. </p>

                <p><strong>18) Confidentiality and Non-Disclosure.</strong> Agent represents to SCC that it will
                    never use or disclose, or cause or allow to be used or disclosed, any of SCC’s Confidential
                    Information (as defined below) acquired by Agent. “Confidential Information” means any data
                    or information relating to SCC products and services, which is proprietary, not generally
                    known by the public and which is the subject of reasonable efforts to maintain its secrecy.
                    Confidential Information shall include, but not be limited to, the following: (a) customer
                    records, names of vendors, profit and performance reports, prices, selling and pricing
                    procedures and techniques, and financing methods of SCC; (b) customer lists of record at the
                    original signing date of this Agreement and thereafter, and information pertaining to
                    identities of these customers, their special demands, and their past, current, and future
                    requirements for the products and services of SCC; (c) specifications, procedures,
                    techniques, processes, manuals, and all other information and documentation pertaining to
                    products and services of SCC, or of others for which SCC has assumed an obligation of
                    confidentiality; and (d) business plans, accounting records, financial statements and
                    information, and projections of SCC. </p>

                <p><strong>19) Return of SCC Property and Confidential Information.</strong> SCC advertising
                    materials, catalogs, and any and all Confidential Information supplied to Agent by SCC shall
                    remain the exclusive property of SCC, and upon request by SCC, or in the event of the
                    expiration or termination of this Agreement, Agent agrees to immediately return such items
                    in Agent’s possession or control to SCC.</p>

                <p><strong>20) Non-Solicitation and Non-Competition. </strong> During the term of this
                    Agreement, and for a period of twenty-four (24) months thereafter, Agent shall not, directly
                    or indirectly, induce or recruit any employee or representative of SCC to apply for, or
                    accept employment or an independent contractor agreement with, any other person or entity
                    that sells, promotes, distributes, or represents for sale any products or services
                    substantially competitive with the products or services of SCC. Furthermore, except as
                    otherwise provided herein, during the term of this Agreement, and for a period of
                    twenty-four (24) months thereafter, Agent shall not, directly or indirectly, sell, promote,
                    distribute, or represent for sale any products or services which are substantially
                    competitive with the products or services of SCC for its own account or for any third party.
                    Agent acknowledges that the restrictions contained in this paragraph are reasonably
                    necessary to protect SCC and their legitimate business interests in customer goodwill and
                    confidential business information. SCC agrees that nothing in this Agreement will preclude
                    Agent from marketing and selling items products or services which are not substantially
                    competitive with the products or services of SCC for its own account or for any third party.
                </p>

                <p><strong>21) Successors and Assigns.</strong> This Agreement shall be binding upon and shall
                    inure to the benefit of the Parties hereto and their respective successors and assigns;
                    provided, however, that Agent may not assign its rights or delegate its duties under this
                    Agreement without the prior written approval of SCC, which shall be in SCC’s sole
                    discretion. </p>

                <p><strong>22) Limitation of Liability.</strong> IN NO EVENT SHALL SCC BE LIABLE TO AGENT OR ANY
                    THIRD PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE
                    DAMAGES, INCLUDING ANY DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF USE, DATA, REVENUE, OR
                    PROFIT, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                    OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT SCC WAS
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL SCC’S AGGREGATE LIABILITY
                    ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF
                    CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAYABLE TO AGENT PURSUANT TO THIS
                    AGREEMENT. THIS PARAGRAPH 23 SETS FORTH SCC’S SOLE LIABILITY AND ENTIRE OBLIGATION AND
                    AGENT’S EXCLUSIVE REMEDY FOR ANY ACTION THAT IS BROUGHT AGAINST SCC. THE LIMITATION OF
                    LIABILITY PROVIDED HEREIN SHALL BE SUBJECT TO ILLINOIS COMPILED STATUTES, CHAPTER 820, ACT
                    120, COMMISSIONS, AND SUCH SIMILAR LAWS OF ANY OTHER JURISDICTION IN WHICH THIS AGREEMENT
                    MAY BE ENFORCEABLE, IT BEING THE INTENT OF THE PARTIES THAT THIS LIMITATION OF LIABILITY BE
                    ENFORCEABLE TO THE MAXIMUM EXTENT POSSIBLE, SUBJECT TO SUCH SPECIFICT STATUTORY PROTECTION
                    AFFORDED SALES AGENTS.</p>

                <p><strong>23) Entire Agreement.</strong> This Agreement constitutes the entire Agreement
                    between the Parties with respect to the subject matter specifically set forth in this
                    Agreement and supersedes any and all previous arrangements, understandings, or agreements,
                    written or oral, which may have been entered into between the Parties. The Parties
                    acknowledge that they have not relied in entering into this Agreement upon any
                    representation, warranties, promises, or inducements not specifically set forth in the
                    Agreement.</p>

                <p>IN WITNESS WHEREOF the Parties hereto have executed this Affiliated Marketing Agent Agreement
                    as of the Effective Date.</p>
            </Col>
        )
    }
}

export {AgreementText};
