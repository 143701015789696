/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image, Button } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */
import * as Images from '../../components/common/images';
import './profile.scss';
import {Messages} from "../../components/common/message";

//  * Get the Kid list for profile
class SecEmailLists extends Component {
    render() {
        let props = this.props;
        let secEmails = '';
        if (typeof props.data == "object" && Object.keys(props.data).length > 0) {
            secEmails = props.data.map((row, i) => {
                let secId = 'secEmail' + i;
                let editSecEmailStyle = row.editSecEmailStyle ? { display: 'none' } : {};
                let updateSecEmailStyle = row.updateSecEmailStyle ? { display: 'none' } : {};
                let rowDataId = row.data.id;
                return (
                    <div key={secId}>
                        <div style={editSecEmailStyle}>
                            <div className="slab-content__block">
                                <div className="label-group">
                                    <label>Email ID {i + 1}:</label>
                                    <span>{row.data.email ? row.data.email : null}</span>
                                    {
                                        row.data.status === 0 ?
                                            <span className="badge-pndg">Verification Pending</span>
                                            :
                                            <span className="badge-acpt">Verified</span>
                                    }
                                    <span className="edit-icon" onClick={() => props.handleEditEmail(row.index, i)}><Image src={Images.EditBlue} alt="Edit" /></span>
                                    <span className="delete-icon" onClick={() => props.handleDeleteEmail(rowDataId, i)}><Image src={Images.DeleteIc} alt="Delete" /></span>
                                </div>
                            </div>
                        </div>
                        <div style={updateSecEmailStyle} className="mb-3 mt-2">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    secEmailId: row.data.email,
                                }}
                                validationSchema={Yup.object().shape({
                                    secEmailId: Yup.string().trim()
                                        .max(150, Messages.MAX_LENGTH_150)
                                        .email(Messages.INVALID_EMAIL)
                                        .required(Messages.REQUIRED_ERR),
                                })}
                                onSubmit={(fields) => { props.handleUpdateSecondaryEmail(fields, { rowDataId }, { i }); }}
                                render={({ errors, touched }) => (
                                    <Form noValidate>
                                        <div className="field form-group">
                                            <Field name="secEmailId" type="text" id={rowDataId} onFocus={props.inputFocus} onBlur={props.inputBlur} className={'form-control' + (errors.secEmailId && touched.secEmailId ? ' is-invalid' : '')} />
                                            <label htmlFor="secEmailId" className="form-label label-active">Email address</label>
                                            <ErrorMessage name="secEmailId" component="div" className="invalid-feedback" />
                                        </div>
                                        <Button type="submit" disabled={props.updateSecEmailLoader} className={`ripple btn-primary btn-medium d-inline-flex align-items-center ${props.updateSecEmailLoader ? 'btn-loader' : ''}`}>
                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /></div>
                                            Update <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </Button>
                                        <Button className="btn-cancel ripple" disabled={props.updateSecEmailLoader} onClick={() => props.handleEditEmail(row.index, i)}>
                                            Cancel</Button>
                                    </Form>
                                )}
                            />
                        </div>
                    </div>
                );
            });
        }
        return secEmails;
    }
}


export { SecEmailLists };