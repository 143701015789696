/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Image } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */
import * as Yup from 'yup';
/* End - Yup */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/* Start - app imports */
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import {Messages} from "../../components/common/message";
import './invite.scss';
import * as Regex from "../../components/common/regex";
/* End - app imports */

class InstructorAdminInfo extends Component {  
    constructor(props){
        super(props);
        this.state = {
            formFields: {
                user_first_name: "",
                user_last_name: "",
                user_email: "",
                user_username: "",
                user_phone: "",
                user_password: "",
                user_confirm_password: ""
            }
        };
        this.dateFocus = this.dateFocus.bind(this);
        this.dateBlur = this.dateBlur.bind(this);
    } 
     
    componentDidMount(){
        const inviteDetails = this.props.inviteDetails;
        if(inviteDetails){
            const formFields = {
                user_first_name: inviteDetails.user_first_name ? inviteDetails.user_first_name : "",
                user_last_name: inviteDetails.user_last_name ? inviteDetails.user_last_name : "",
                user_email: inviteDetails.user_email ? inviteDetails.user_email : "",
                user_username: inviteDetails.user_username ? inviteDetails.user_username : "",
                user_phone: inviteDetails.user_phone ? inviteDetails.user_phone : "",
                user_password: inviteDetails.user_password ? inviteDetails.user_password : "",
                user_confirm_password: inviteDetails.user_confirm_password ? inviteDetails.user_confirm_password : "",
                userHasEmail: inviteDetails.userHasEmail,
            }
            this.setState({formFields});
        }
    }
    
    dateFocus(){ 
        this.userDobLabel.classList.add("label-active");
    }

    dateBlur({ target: { value } }){
        const userDob = this.state.selectedDate;
        if(userDob === "" || userDob === null){
            this.userDobLabel.classList.remove("label-active");
        }
    }

    handleFormSubmit = (fields) => {
        this.props.handleFormSubmit(fields);
    }

    render() {
        const { formFields } = this.state,
            { getLabelClass, serverValid, formProcessing } = this.props;

        return (
            <Formik
                enableReinitialize
                initialValues={formFields}
                validationSchema={Yup.object().shape({
                    user_first_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_last_name: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_email: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .email(Messages.INVALID_EMAIL)
                        .max(100, Messages.MAX_LENGTH_100),
                    user_username: Yup.string().trim()
                        .matches(Regex.username1, { message: Messages.INVALID_USERNAME1, excludeEmptyString: false })
                        .matches(Regex.username2, { message: Messages.INVALID_USERNAME2, excludeEmptyString: false })
                        .matches(Regex.username3, { message: Messages.INVALID_USERNAME3, excludeEmptyString: false })
                        .matches(Regex.username4, { message: Messages.INVALID_USERNAME4, excludeEmptyString: false })
                        .matches(Regex.username5, { message: Messages.INVALID_USERNAME5, excludeEmptyString: false })
                        .max(50, Messages.MAX_LENGTH_50)
                        .required(Messages.REQUIRED_ERR),
                    user_phone: Yup.string().trim()
                        .matches(Regex.phone, { message: Messages.INVALID_PHONE, excludeEmptyString: false })
                        .max(15, Messages.INVALID_PHONE),
                    user_password: Yup.string().trim()
                        .max(50, Messages.MAX_LENGTH_50)
                        .min(8, Messages.MIN_LENGTH_8)
                        .required(Messages.REQUIRED_ERR)
                        .matches(Regex.password,  { message: Messages.PASSWORD_FORMAT_ERR}),
                    user_confirm_password: Yup.string().trim()
                        .required(Messages.REQUIRED_ERR)
                        .oneOf([Yup.ref('user_password'), null], Messages.PASSWORD_CPASS_MATCH_ERR)
                })}
                onSubmit={fields => {
                    this.handleFormSubmit(fields);
                }}
                render={({ errors, status, touched }) => (
                        <Form className="step-form-details" noValidate>
                            <div className="block-space">
                                <div className="field form-group">
                                    <Field name="user_first_name" type="text" id="user_first_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_first_name && touched.user_first_name) || serverValid.user_first_name !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="user_first_name" className={getLabelClass(formFields.user_first_name)}>First Name</label>
                                    {
                                        (errors.user_first_name && touched.user_first_name) ?
                                            <ErrorMessage name="user_first_name" component="div" className="invalid-feedback" />
                                        :
                                            <div className="invalid-feedback">{serverValid.user_first_name}</div>
                                    }
                                </div>

                                <div className="field form-group">
                                    <Field name="user_last_name" type="text" id="user_last_name" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_last_name && touched.user_last_name) || serverValid.user_last_name !== '' ? ' is-invalid' : '')} />
                                    <label htmlFor="user_last_name" className={getLabelClass(formFields.user_last_name)}>Last Name</label>
                                    {
                                        (errors.user_last_name && touched.user_last_name) ?
                                            <ErrorMessage name="user_last_name" component="div" className="invalid-feedback" />
                                        :    
                                            <div className="invalid-feedback">{serverValid.user_last_name}</div>
                                    }
                                </div>

                                {
                                    formFields.userHasEmail ?
                                        <div className="field form-group a-card a-card--readonly">
                                            <label className="label-active a-card__label">Email</label>
                                            <span name="user_email" className="pl-2 pb-2 a-card__content">{formFields.user_email}</span>
                                        </div>
                                    : 
                                        <div className="field form-group">
                                            <Field name="user_email" type="email" id="user_email" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="70" className={'form-control' + ((errors.user_email && touched.user_email) || serverValid.user_email !== '' ? ' is-invalid' : '')} />
                                            <label htmlFor="user_email" className={getLabelClass(formFields.user_email)}>Email</label>
                                            {
                                                (errors.user_email && touched.user_email) ?
                                                    <ErrorMessage name="user_email" component="div" className="invalid-feedback" />
                                                :
                                                    <div className="invalid-feedback">{serverValid.user_email}</div>
                                            }
                                        </div>
                                }
                                <div className="opacity-light">    
                                    <div className="field form-group">
                                        <Field name="user_username" type="text" id="user_username" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_username && touched.user_username) || serverValid.user_username !== '' ? ' is-invalid' : '')} autoComplete="new-username"/>
                                        <label htmlFor="user_username" className={getLabelClass(formFields.user_username)}>Username</label>
                                        
                                        {
                                            (errors.user_username && touched.user_username) ?
                                                <ErrorMessage name="user_username" component="div" className="invalid-feedback" />
                                            :
                                                <div className="invalid-feedback">{serverValid.user_username}</div>
                                        }
                                    </div>

                                    <div className="field form-group">
                                        <Field name="user_phone" type="text">
                                            {({
                                                field, // { name, value, onChange, onBlur }
                                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.                                      
                                            }) => (
                                                    <Helper.PhoneMaskedInput
                                                        field={field}
                                                        className={'form-control' + ((errors.user_phone && touched.user_phone) || serverValid.user_phone !== '' ? ' is-invalid' : '')} 
                                                        id="user_phone"
                                                    />
                                                )}
                                        </Field>
                                        
                                        <label htmlFor="user_phone" className={getLabelClass(formFields.user_phone)}>Phone Number (Optional)</label>
                                        {
                                            (errors.user_phone && touched.user_phone) ?
                                                <ErrorMessage name="user_phone" component="div" className="invalid-feedback" />
                                            :
                                                <div className="invalid-feedback">{serverValid.user_phone}</div>
                                        }
                                    </div>

                                    <div className="field form-group">
                                        <Field name="user_password" type="password" id="user_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_password && touched.user_password) || serverValid.user_password !== '' ? ' is-invalid' : '')} autoComplete="new-password"/>
                                        <label htmlFor="user_password" className={getLabelClass(formFields.user_password)}>Password</label>
                                        {
                                            (errors.user_password && touched.user_password) ?
                                                <ErrorMessage name="user_password" component="div" className="invalid-feedback" />
                                            :
                                                <div className="invalid-feedback">{serverValid.user_password}</div>
                                        }
                                    </div>

                                    <div className="field form-group">
                                        <Field name="user_confirm_password" type="password" id="user_confirm_password" onFocus={Helper.inputFocus} onBlur={Helper.inputBlur} maxLength="50" className={'form-control' + ((errors.user_confirm_password && touched.user_confirm_password) || serverValid.user_confirm_password !== '' ? ' is-invalid' : '')} />
                                        <label htmlFor="user_confirm_password" className={getLabelClass(formFields.user_confirm_password)}>Confirm Password</label>
                                        {
                                            (errors.user_confirm_password && touched.user_confirm_password) ?
                                                <ErrorMessage name="user_confirm_password" component="div" className="invalid-feedback" />
                                            :
                                                <div className="invalid-feedback">{serverValid.user_confirm_password}</div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="widgetfoter-buttons justify-content-end">
                                <button type="submit" disabled={formProcessing ? true : false} className={"btn align-self-center btn-medium btn-primary" + (formProcessing ? " btn-loader" : "")}>
                                    <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /></div>
                                    Register
                                    <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </div> 
                        </Form>
                    )
                }
            />
        )  
    }
}
export {InstructorAdminInfo};