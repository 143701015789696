export const CreateClubMessages = {
    CLUB_REQUIRED_ERROR: "Please enter organization name.",
    FIRSTNAME_REQUIRED_ERROR: 'Please enter admin first name.',
    LASTNAME_REQUIRED_ERROR: 'Please enter admin last name.',

    EMAIL_REQUIRED_ERROR: 'Please enter admin email.',
    USERNAME_REQUIRED_ERROR:'Please enter admin username.',
    PASSWORD_REQUIRED_ERROR:'Please enter admin password.',
    PHONE_REQUIRED_ERROR: 'Please enter admin phone.',
    EMAILGENERAL_REQUIRED_ERROR: 'Please enter email address for general information.',
    EMAILCONTACT2_REQUIRED_ERROR: 'Please enter email address for secondary contact information.',
    FIRST_NAME_REQUIRED_ERROR: 'Please enter first name.',
    LAST_NAME_REQUIRED_ERROR: 'Please enter last name.',
    EMAILBILL_REQUIRED_ERROR: 'Please enter email address for billing information.',

    MAILADDRESS_REQUIRED_ERROR: 'Please enter organization\'s mailing address.',
    MAILCITY_REQUIRED_ERROR: 'Please enter mailing address city.',
    MAILSTATE_REQUIRED_ERROR: 'Please select mailing address state.',
    MAILZIP_REQUIRED_ERROR: 'Please enter mailing address zip code.',

    BILLADDRESS_REQUIRED_ERROR: 'Please enter organization\'s billing address.',
    BILLCITY_REQUIRED_ERROR: 'Please enter billing address city.',
    BILLSTATE_REQUIRED_ERROR: 'Please select billing address state.',
    BILLZIP_REQUIRED_ERROR: 'Please enter billing address zip code.',

};