/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
import * as Storage from "./storage";
import {ConfirmModal, TimeoutModal} from '../../components/modal/modalbox';
/* End - React */

class TimingMsgBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timingBarClass: "",
            modalVisibility:false,
            modalLoader:false,

        };
        this.trackEvents();
        this.onIdeal = null;
    }

    componentDidMount(){
        this.setTimeBar();
        this.checkTimeOut();
       // Check time after every 1 minute
        this.checkTime = setInterval(() => {
            this.setTimeBar();
        }, 60 * 1000);
    }

    trackEvents = ()=>{
        //window.addEventListener("mousemove", this.eventHandler);
        //window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("click", this.eventHandler);
    }
    eventHandler = ()=>{
        let user = Storage.Get('user'), now = Date.now();
        if(user){
            user['logged_in_time'] = now;
            Storage.Set('user', JSON.stringify(user));
        }
    }
    checkTimeOut =()=>{
        this.isTimeOut = setInterval(() => {
            let user = Storage.Get('user'), now = Date.now();
            if(user) {
                let loginTime = now - user.logged_in_time, sessionTime = 60 * 60 * 1000; //1 hour session time.
                if (loginTime > sessionTime) {
                    this.setState({modalVisibility: true });
                    this.onIdeal =  setInterval(() => {
                        console.log('onideal')
                        this.props.handleLogout()
                    }, 60 * 5 * 1000);
                    return false;
                }
            }
        }, 5 * 1000);
    }



    setTimeBar = () => {
        const hour = new Date().getHours();
        if(hour >= 20 || hour <= 7){
            if(this.state.timingBarClass === ""){
                this.setState({timingBarClass: "timebar--visible"});
            }
        } else {
            if(this.state.timingBarClass !== ""){
                this.setState({timingBarClass: ""});
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.checkTime);
    }

    onCancelClick = ()=>{
        clearInterval(this.onIdeal);
        this.setState({modalVisibility: false }, ()=>{
            let user = Storage.Get('user'), now = Date.now();
            if(user){
                user['logged_in_time'] = now;
                Storage.Set('user', JSON.stringify(user));
            }
        });
    }

    onConfirmLogout = ()=>{
        this.setState({modalLoader: true});
        this.props.handleLogout();
    }

    render() {
        return (
            <>
            <div className={`timebar ${this.state.timingBarClass}`}>
                Please refrain from messaging between 8PM and 8AM.
            </div>
                {
                    this.state.modalVisibility ?
                        <TimeoutModal showModal={this.state.modalVisibility} onCancelClick={this.onCancelClick}
                                      onConfirmLogout={this.onConfirmLogout}  modalLoader={this.state.modalLoader}/> : null


                } </>
        )
    }
}

export {TimingMsgBar};
