/**
 * Start - React App Polyfill
 * Name and Version: react-app-polyfill, 1.0.4
 * Download link: https://www.npmjs.com/package/react-app-polyfill
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* End - React App Polyfill */
/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import ReactDOM from 'react-dom';
/* End - React Router Dom */

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { CometChat } from "@cometchat-pro/chat"

// Hack to remove console.log from production build
function noop() {}
if (process.env.NODE_ENV === 'production') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = "us";

const appSettings = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();

// 1. Initialize the CometChat app
// On successful initialization, proceed to render your app
// Log a message to the console on failure.
//
// Next, we register the Service Worker below.
CometChat.init(appID, appSettings).then(() => {
  if (CometChat.setSource) {
    CometChat.setSource('ui-kit', 'web', 'reactjs');
  }
  console.log('Initialization successful');

  ReactDOM.render(<Router basename='/'><App /></Router>, document.getElementById('root'));
}, error => {
  console.log('Initialization failed', error);
});

// 2. Register the Service Worker
// To ensure delivery of notifications when the tab is not in focus
// or the user has closed your application's browser tab.
// Checkout public/firebase-messaging-sw.js file for more details.
// You need to add the FIREBASE_CONFIG object in that file.
//
// Next, proceed to Home.js for Step 3.
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(error => console.log('Registration error', error));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
