/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {Link} from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {
    Row,
    Col,
    Dropdown,
    Tab,
    Tabs,
    Table,
    Media,
    Image,
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import {Formik, Field, Form, ErrorMessage} from 'formik';
/* End - Formik */

import {DeleteConfirmModal, SuccessModal, ErrorModal, ConfirmModal, ModalBox} from '../../components/modal/modalbox';
import { NoRecord } from '../../components/no-record/no-record';
import * as Images from '../../components/common/images';
import * as Helper from '../../components/common/functions';
import * as Storage from '../../components/common/storage';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import { Messages } from '../../components/common/message';
import * as Enum from '../../components/common/enum';
import Loader from '../../components/loader/loader';
import { orderBy } from 'lodash';
import './club.scss';
import * as Yup from "yup";

class ClubListing extends Component {
    constructor(props) {
        super(props);
        const isRedirect = props.match.params.redirect;
        this.state = {
            showDiv: false,
            showModal:false,
            recordLoaded: false,
            activeClubListSearch: [],
            inactiveClubListSearch: [],
            iSROptions: [],
            modalLoader: false,
            dropDownValue: isRedirect && isRedirect === "review" ? "Pending" : "All",
            search: "",
            loading: false,
            modalVisibility: false,
            serverSuccess: "",
            showError: false,
            errorMessage: "",
            handelActivate: {
                deleteConfirmShow: false,
                clubIdForAction: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
                status: 0,
            },
            handelBilling: {
                billingConfirmShow: false,
                clubIdForAction: null,
                confirmButtonText: "",
                confirmText: "",
                confirmTypeStatus: "",
                billing: 0,
            },
            selectedTab:'active',
            sortCriteria: {
                column: 'name',
                direction: 'asc'
            },
            defaultTab: isRedirect && isRedirect === "review" ?  "archived" : "active",
            clubISR:0

        };
        this.fields = {}
    }

    componentDidMount() {
        this.getClubsList();
    }

    getClubsList = () => {
        if (Storage.Get('user') != null) {
            this.setState({ recordLoaded: false, });
            let sortCriteria = this.state.sortCriteria;
            Helper.axiosInstance().get(`club/list`)
                .then((response) => {
                    if (response.data.success) {
                        const clubs = response.data.clubs,
                            activeClubs = clubs.filter(x => x.status === 1),
                            inactiveClubs = clubs.filter(x => x.status === 0),

                            pendingActiveClubs = clubs.filter(x => x.status === 1 && x.approval_status === 'PA'),
                            pendingInactiveClubs = clubs.filter(x => x.status === 0 && x.approval_status === 'PA'),

                            approvedActiveClubs = clubs.filter(x => x.status === 1 && x.approval_status === 'A'),
                            approvedInactiveClubs = clubs.filter(x => x.status === 0 && x.approval_status === 'A');

                        this.setState({
                                coachList: response.data.coaches,
                                originalActiveClub: activeClubs,
                                originalActiveClubSearch: activeClubs,
                                originalInactiveClub: inactiveClubs,
                                originalInactiveClubSearch: inactiveClubs,

                                activeClubList: activeClubs,
                                activeClubListSearch: activeClubs,
                                inactiveClubList: inactiveClubs,
                                inactiveClubListSearch: inactiveClubs,

                                pendingActiveClub: pendingActiveClubs,
                                pendingActiveClubSearch: pendingActiveClubs,
                                pendingInactiveClub: pendingInactiveClubs,
                                pendingInactiveClubSearch: pendingInactiveClubs,

                                approvedActiveClub: approvedActiveClubs,
                                approvedActiveClubSearch: approvedActiveClubs,
                                approvedInactiveClub: approvedInactiveClubs,
                                approvedInactiveClubSearch: approvedInactiveClubs,
                                recordLoaded: true,
                                isrList: response.data.isrList
                            },
                            () => {
                                const isRedirect = this.props.match.params.redirect;
                                if(isRedirect && isRedirect === "review"){
                                    this.changeValue("Pending");
                                }
                            }
                        );
                    }
                    else {
                        this.setState({ errorMessage: response.data.error, showError: true, recordLoaded: true });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, recordLoaded: true });
                });

        }
    }

    filterList = (target) => {
        this.setState({search: target.value})
        this.setState({ activeClubListSearch: this.state.activeClubList.filter(x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1) })

        this.setState({ inactiveClubListSearch: this.state.inactiveClubList.filter(x => (x.name).toLowerCase().indexOf(target.value.toLowerCase().trim()) !== -1) })
    }

    onClubAction = (clubId, status) => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = true;
            handelActivate.clubIdForAction = clubId;
            if (status === 'A' || status === 'R') {
                const action = status === 'A' ? 'Approve' : 'Reject',
                    rejectMsg = status === 'R' ? `This will remove the organization's data permanently.` : '';
                handelActivate.status = status;
                handelActivate.confirmText = `Are you sure you want to ${action.toLowerCase()} this organization? ${rejectMsg}`;
                handelActivate.confirmButtonText = action;
                handelActivate.confirmTypeStatus = action;
            } else if (status) {
                handelActivate.status = 0;
                handelActivate.confirmText = "Are you sure you want to deactivate this organization?";
                handelActivate.confirmButtonText = "Deactivate";
                handelActivate.confirmTypeStatus = "Deactivate";
            } else {
                handelActivate.status = 1;
                handelActivate.confirmText = "Are you sure you want to activate this organization?";
                handelActivate.confirmButtonText = "Activate";
                handelActivate.confirmTypeStatus = "Activate";
            }
            return { handelActivate };
        });
    }

    automatedBillingAction = (clubId, status) => {
        this.setState(prevState => {
            let handelBilling = Object.assign({}, prevState.handelBilling);
            handelBilling.billingConfirmShow = true;
            handelBilling.clubIdForAction = clubId;
            if (status) {
                handelBilling.billing = 0;
                handelBilling.confirmText = "Are you sure you want to stop the billing for this organization?";
                handelBilling.confirmTypeStatus = "off";
            } else {
                handelBilling.billing = 1;
                handelBilling.confirmText = "Are you sure you want to start the billing for this organization?";
                handelBilling.confirmTypeStatus = "on";
            }
            handelBilling.confirmButtonText = "Confirm";
            return { handelBilling };
        });
    }

    onClubConfirmClick = () => {
        const handelActivate = this.state.handelActivate;
        if(handelActivate.status === 'A' || handelActivate.status === 'R'){
            this.handleApprovalStatus(handelActivate.clubIdForAction, handelActivate.status);
        } else {
            this.setState({
                modalLoader: true
            })
            if (handelActivate.clubIdForAction != null) {
                Helper.axiosInstance()
                    .put('club/update-status', {
                        id: handelActivate.clubIdForAction,
                        status: handelActivate.status
                    })
                    .then((response) => {
                        if (response.data.success === true) {
                            this.setState(prevState => {
                                let handelActivate = Object.assign({}, prevState.handelActivate);
                                handelActivate.deleteConfirmShow = false;
                                handelActivate.clubIdForAction = null;
                                return { handelActivate };
                            });
                            this.setState({
                                modalLoader: false,
                            })
                            this.getClubsList();
                        }
                        else {
                            this.setState(prevState => {
                                let handelActivate = Object.assign({}, prevState.handelActivate);
                                handelActivate.deleteConfirmShow = false;
                                handelActivate.clubIdForAction = null;
                                return { handelActivate };
                            });
                            this.setState({ errorMessage: response.data.error, showError: true, modalLoader: false, });
                        }
                    })
                    .catch((error) => {
                        this.setState(prevState => {
                            let handelActivate = Object.assign({}, prevState.handelActivate);
                            handelActivate.deleteConfirmShow = false;
                            handelActivate.clubIdForAction = null;
                            return { handelActivate };
                        });
                        this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                    });
            }
        }
    }

    onBillingConfirmClick = () => {
        const handelBilling = this.state.handelBilling;
        this.setState({
            modalLoader: true
        })
        if (handelBilling.clubIdForAction != null) {
            Helper.axiosInstance()
                .put('club/manage-billing', {
                    id: handelBilling.clubIdForAction,
                    automated_billing: handelBilling.billing
                })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState(prevState => {
                            let handelBilling = Object.assign({}, prevState.handelBilling);
                            handelBilling.billingConfirmShow = false;
                            handelBilling.clubIdForAction = null;
                            return { handelBilling };
                        });
                        this.setState({
                            modalLoader: false,
                        })
                        this.getClubsList();
                    }
                    else {
                        this.setState(prevState => {
                            let handelBilling = Object.assign({}, prevState.handelBilling);
                            handelBilling.billingConfirmShow = false;
                            handelBilling.clubIdForAction = null;
                            return { handelBilling };
                        });
                        this.setState({ errorMessage: response.data.error, showError: true, modalLoader: false, });
                    }
                })
                .catch((error) => {
                    this.setState(prevState => {
                        let handelBilling = Object.assign({}, prevState.handelBilling);
                        handelBilling.billingConfirmShow = false;
                        handelBilling.clubIdForAction = null;
                        return { handelBilling };
                    });
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, modalLoader: false, });
                });
        }
    }

    onClubCancelClick = () => {
        this.setState(prevState => {
            let handelActivate = Object.assign({}, prevState.handelActivate);
            handelActivate.deleteConfirmShow = false;
            return { handelActivate };
        })
    }

    onClubBillingCancelClick = () => {
        this.setState(prevState => {
            let handelBilling = Object.assign({}, prevState.handelBilling);
            handelBilling.billingConfirmShow = false;
            return { handelBilling };
        })
    }

    changeValue(text) {
        const { originalActiveClub,
            originalActiveClubSearch,
            originalInactiveClub,
            originalInactiveClubSearch,

            pendingActiveClub,
            pendingActiveClubSearch,
            pendingInactiveClub,
            pendingInactiveClubSearch,

            approvedActiveClub,
            approvedActiveClubSearch,
            approvedInactiveClub,
            approvedInactiveClubSearch
        } = this.state;
        let updatedList = {};
        this.setState({ dropDownValue: text },
            () => {
                const dropDownValue = this.state.dropDownValue;
                this.setState({search: ""});

                switch (dropDownValue){
                    case "Pending":
                        updatedList = {
                            activeClubList: pendingActiveClub,
                            activeClubListSearch: pendingActiveClubSearch,
                            inactiveClubList: pendingInactiveClub,
                            inactiveClubListSearch: pendingInactiveClubSearch
                        };
                        break;
                    case "Approved":
                        updatedList = {
                            activeClubList: approvedActiveClub,
                            activeClubListSearch: approvedActiveClubSearch,
                            inactiveClubList: approvedInactiveClub,
                            inactiveClubListSearch: approvedInactiveClubSearch
                        };
                        break;
                    default:
                        updatedList = {
                            activeClubList: originalActiveClub,
                            activeClubListSearch: originalActiveClubSearch,
                            inactiveClubList: originalInactiveClub,
                            inactiveClubListSearch: originalInactiveClubSearch
                        };
                }
                this.setState(updatedList);
            }
        );
    }

    handleApprovalStatus = (id, status) => {
        this.setState({
            loading: true,
            modalLoader: true
        })
        if (id && status) {
            Helper.axiosInstance()
                .put('club/update-approval-status', {
                    id: id,
                    approval_status: status
                })
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState({
                            modalVisibility: true,
                            serverSuccess: response.data.message
                        })
                        this.getClubsList();
                    } else {
                        this.setState({ errorMessage: response.data.error, showError: true });
                    }
                })
                .catch((error) => {
                    this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true });
                })
                .finally(() => {
                    this.setState(prevState => {
                        let handelActivate = Object.assign({}, prevState.handelActivate);
                        handelActivate.deleteConfirmShow = false;
                        handelActivate.clubIdForAction = null;
                        return { handelActivate };
                    });
                    this.setState({ loading: false, modalLoader: false });
                });
        }
    }

    handleClose = () => {
        this.setState({ serverSuccess: '', modalVisibility: !this.state.modalVisibility });
    }


    onClubTabSelect = (key) => {
        this.setState(st => {
            st.selectedTab = key
        });
    }

    sortHandler = (column) => {
        const { sortCriteria, selectedTab } = this.state;
        let newSortCriteria = sortCriteria;
        newSortCriteria.column = column;

        if(sortCriteria.column  == column){
            newSortCriteria.direction = (sortCriteria.direction == 'asc') ? 'desc' : 'asc';
        } else {
            newSortCriteria.direction = 'asc';
        }
        if(selectedTab =='active') {
            let sortedOrg = orderBy(this.state.activeClubListSearch, [org => isNaN(org[column]) ? org[column].toLowerCase() : org[column]], [newSortCriteria.direction]);
            this.setState({activeClubListSearch: sortedOrg});
        }else{
            let sortedOrg = orderBy(this.state.inactiveClubListSearch, [org => org[column].toLowerCase()], [newSortCriteria.direction]);
            this.setState({inactiveClubListSearch: sortedOrg});
        }
    }

    showISRList = (clubId, ISRId=null)=>{
        this.setState({ showModal: true,clubId, clubISR: ISRId});
    }

    accessClubAsAdmin = (clubId)=> {
       // console.log(clubId)
        let user = Storage.Get('user');
        if (clubId && clubId !== 0) {
            let role=[], cId= parseInt(clubId);
            role.push(Enum.UserRoles.CLUB_SUB_ADMIN);
            user.role = {[cId]:role};
            user.selectedClubId = cId;
            user.is_super_admin = false;
            user.club_access_by_superadmin = true;
            this.setState({ selectedClub: cId });
            Storage.Set('user', JSON.stringify(user));
            this.props.history.push("/");
            window.location.reload();
        }
    }
    linkISR = (fields) => {
        this.setState({ showLoader: true })
        Helper.axiosInstance().put('club/update-isr', {id: this.state.clubId, isr_id: fields.clubISR ? fields.clubISR : 0})
            .then((response) => {
                if (response.data.success) {
                    this.getClubsList();
                    setTimeout(()=>{
                        this.setState({ showModal: false,showLoader:false});
                    }, 1000);

                } else {
                    this.setState({ showError: true, errorMessage: Messages.SERVER_ERROR, showLoader: false,});
                }
            })
            .catch(() => {
                this.setState({ errorMessage: Messages.SERVER_ERROR, showError: true, showLoader: false,});
            });
    }

    onISRChange = (event, setFieldValue) => {
        this.setState({ clubISR: event.target.value})
        setFieldValue('clubISR', event.target.value);
    }
    render() {
        const {search, loading, serverSuccess, showError, errorMessage, modalVisibility, defaultTab, sortCriteria, clubISR, isrList} = this.state;
          const ISRModalHeader = (
                <>
                    <Modal.Title>Link MA</Modal.Title>
                </>
            ),
              ISRlistContent = (
                <Formik
                    ref={(ref) => this.formik = ref}
                    enableReinitialize
                    initialValues={{
                        clubISR:clubISR,
                    }}
                    onSubmit={fields => {
                        this.linkISR(fields);
                    }}
                    render={({errors, status, touched, setFieldValue, setFieldTouched, values}) => (
                        <Form id="js-settings-frm" noValidate>
                            <>
                                <Modal.Body>
                                    <label htmlFor="clubISR">Please select Marketing Affiliate</label>
                                    <div className="select-wrap col-sm-10 field form-group">
                                        <Field component="select" placeholder="Select MA"
                                               className='form-control'
                                               value={ clubISR ? clubISR : 0}
                                               name="clubISR"
                                               id="clubISR"
                                               onChange={(event) => this.onISRChange(event, setFieldValue)}>
                                            <option value="0">-- None --</option>
                                            {
                                                Object.values(isrList).map((data) => {
                                                    return (
                                                        <option key={data.id} value={data.id}>{data.first_name} {data.last_name}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage name="clubISR" component="div" className="invalid-feedback"/>
                                    </div>
                                    </Modal.Body>
                                    <Modal.Footer className="m-footer">
                                        <div className="m-footer__btnwrap m-footer__btnwrap--lg-btn">
                                            <Button variant="secondary"
                                                    onClick={() => this.setState({showModal: false})} disabled={this.state.showLoader}>
                                                Cancel
                                            </Button>
                                            <Button type="Submit" disabled={this.state.showLoader} className={`ripple btn btn-primary d-inline-flex align-items-center btn-ternary--width-auto" ${this.state.showLoader ? 'btn-loader' : ''}`} >
                                                <div className="btn__icon btn__icon--red">
                                                    <Image src={Images.CompleteIc} alt="Marketing Affiliate"/>
                                                </div>
                                                Submit
                                                <span style={{'display': 'none'}} className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>
                                        </div>
                                </Modal.Footer>
                            </>
                        </Form>
                    )}
                />
            );

        return (
            <>
                <Loader loading={loading} />
                <div className="rgt-content">
                    <Row className="row--space align-items-center">
                        <Col sm="4">
                            <h1>Organizations</h1>
                        </Col>
                        <Col sm={8} className="text-right cb-action-wrap">
                            <div className="action-filter">
                                <label>Filter by:</label>
                                <div className="sel-wrap">
                                    <Dropdown className="sel-dropdown">
                                        <Dropdown.Toggle id="dropdown-item-button" title={this.state.dropDownValue} className="format">
                                            {this.state.dropDownValue}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="1" onClick={(e) => this.changeValue(e.target.textContent)}><div>All</div></Dropdown.Item>
                                            <Dropdown.Item eventKey="2" onClick={(e) => this.changeValue(e.target.textContent)}><div>Pending</div></Dropdown.Item>
                                            <Dropdown.Item eventKey="3" onClick={(e) => this.changeValue(e.target.textContent)}><div>Approved</div></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <OverlayTrigger placement="auto"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Create a Organization
                                                </Tooltip>}>
                                <Link to="/create-organization" className="outline-circle-wrap">
                                    <div className="outline-circle">
                                        <Image src={Images.AddIconRed} alt="Organization" width="25" />
                                    </div>
                                </Link>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row className="vh-height">
                        <Col md={12} lg={12} className="md--full-width">
                            <div className="widget">
                                <div className="tab-search tab-search--big">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            search: search
                                        }}
                                        onSubmit={() => false}
                                        render={() => (
                                            <Form className="form-inline" noValidate>
                                                <Field name="search" type="text" maxLength="100" onChange={(e) => this.filterList(e.target)} className="form-control tab-search__box" placeholder="Search"/>
                                                <button className="close-icon" type="reset" onClick={(e) => this.filterList(e.target)}></button>
                                            </Form>
                                        )}
                                    />
                                </div>
                                <Tabs defaultActiveKey={defaultTab} onSelect={this.onClubTabSelect}>
                                    <Tab eventKey="active" title="Active">
                                        <ClubList data={this.state.activeClubListSearch}
                                                  onClubAction={(id, status) => this.onClubAction(id, status)}
                                                  recordLoaded={this.state.recordLoaded}
                                                  automatedBillingAction={(id, status) => this.automatedBillingAction(id, status)}
                                                  sortCriteria={sortCriteria}
                                                  sortHandler={this.sortHandler}
                                                  showISRList={this.showISRList}
                                                  screen={"Active"}
                                                  accessClubAsAdmin={(id)=>this.accessClubAsAdmin(id)}
                                        />
                                    </Tab>
                                    <Tab eventKey="archived" title="Inactive">
                                        <ClubList data={this.state.inactiveClubListSearch}
                                                  onClubAction={(id, status) => this.onClubAction(id, status)}
                                                  recordLoaded={this.state.recordLoaded}
                                                  sortCriteria={sortCriteria}
                                                  sortHandler={this.sortHandler}
                                                  showISRList={this.showISRList}
                                                  screen={"Inactive"}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ModalBox
                    size="md"
                    show={this.state.showModal}
                    onHide={()=> this.setState({showModal: false})}
                    animation={true}
                    modalheader={ISRModalHeader}
                    modalcontent={ISRlistContent} />
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handelActivate.deleteConfirmShow}
                    onHide={this.onDeactivateClubConfirmCancel}
                    onCancelClick={this.onClubCancelClick}
                    onConfirmClick={this.onClubConfirmClick}
                    confirmText={this.state.handelActivate.confirmText}
                    confirmButtonText={this.state.handelActivate.confirmButtonText}
                    confirmType={this.state.handelActivate.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />
                <DeleteConfirmModal
                    deleteConfirmShow={this.state.handelBilling.billingConfirmShow}
                    onHide={this.onDeactivateClubConfirmCancel}
                    onCancelClick={this.onClubBillingCancelClick}
                    onConfirmClick={this.onBillingConfirmClick}
                    confirmText={this.state.handelBilling.confirmText}
                    confirmButtonText={this.state.handelBilling.confirmButtonText}
                    confirmType={this.state.handelBilling.confirmTypeStatus}
                    modalLoader={this.state.modalLoader}
                />

                {
                    serverSuccess !== "" ?
                        <SuccessModal
                            successConfirmShow={modalVisibility}
                            onConfirmClick={this.handleClose}
                            successText={serverSuccess}
                            successButtonText={"OK"}
                        />
                        :
                        showError !== "" ?
                            <ErrorModal
                                show={showError}
                                message={errorMessage}
                                onConfirmClick={() => { this.setState({ showError: false }) }}
                            />
                            :
                            null
                }
            </>
        )
    }
}

const ClubList = (props) => {
    let status = null,
        badgeClass = null,
        sortImage = Images.ArrowSort;
    if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'desc'){
        sortImage = Images.ArrowDown;
    } else if(typeof props.sortCriteria != 'undefined' && props.sortCriteria.direction == 'asc') {
        sortImage = Images.ArrowUp;
    }
    return (
        <Table bordered hover className="tab-responsive table-responsive table--listing table--super-admin-width">
            <thead>
            <tr>
                {
                    props.data && props.data.length >1  &&  props.sortCriteria   ?
                        <th className="col-sortable" onClick={() => props.sortHandler('name')}>Organizations
                            { props.sortCriteria.column == 'name' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th>Organizations</th>
                }
                <th>Date created </th>
                <th>Member groups </th>
                <th>Total Students</th>
                <th>Cost Per User</th>
                <th>Linked MA</th>
                {
                    /**
                     * This might be required under the next phase
                     * <th>Next invoice</th>
                     */
                }

                {
                    props.data && props.data.length >1 && props.sortCriteria  && props.automatedBillingAction && props.screen == 'Active' ?
                        <th className="col-sortable date_created_col" onClick={() => props.sortHandler('automated_billing')}>Billing
                            { props.sortCriteria.column == 'automated_billing' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                        : <th className="col-sortable" onClick={() => props.sortHandler('approval_status')}>Billing
                            { props.sortCriteria.column == 'approval_status' ?
                                <Image src={sortImage} alt="Sort Direction" className="ml-2"/> :
                                <Image src={Images.ArrowSort} alt="Sort Direction" className="ml-2"/>
                            }</th>
                }
                <th>Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                (typeof props.data == "object" && Object.keys(props.data).length > 0) &&
                props.data.map((row, i) => {
                    status = row.approval_status ? row.approval_status : null;
                    badgeClass = status === "A" ? "badge-acpt" : "badge-pndg";

                    return (
                        <tr key={i}>
                            <td data-label="Organizations" className="td-content-space">
                                <Media>
                                    <div className="im-rounded-outer">
                                        <Image className="mr-2" src={row.photo} alt="User" />
                                    </div>
                                    <Media.Body className="align-self-center"><Link className="org-detail-link" to={`/edit-organization/${row.id}`}><h5>{row.name}</h5></Link></Media.Body>
                                </Media>
                            </td>
                            <td data-label="Date created">{row.created_at}</td>
                            <td data-label="Member groups">{row.total_member_group}</td>
                            <td data-label="Total Students">{row.total_member}</td>
                            <td data-label="Cost Per User">${row.cost_per_user}</td>
                            <td data-label="Linked MA Name">
                                {
                                   <Link  to={'#'}  onClick={()=>props.showISRList(row.id,row.isr_id)} className="org-detail-link"><h5> {row.isr_id ?  `${row.isr_first_name} ${row.isr_last_name}` : 'Link MA' }</h5></Link>
                               }
                            </td>
                            {/**
                             * This might be required under the next phase
                             * <td data-label="Next invoice" className="td-content-space">01-12-2019</td>
                             */
                            }
                            {
                                typeof props.automatedBillingAction != 'undefined' &&
                                <td data-label="Billing" className="billing-switch">
                                    <div className="billing-switch__toggle">
                                        <div className="button rounded">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => props.automatedBillingAction(row.id, row.automated_billing)}
                                                checked={row.automated_billing} />
                                            <div className="knobs"></div>
                                            <div className="layer"></div>
                                        </div>
                                    </div>
                                </td>
                            }

                            <td data-label="Status">
                                { status ? <span className={`badge--f-size ${badgeClass}`}>{Enum.OrgStatus[status]}</span>
                                        : null
                                }
                            </td>
                            <td>
                                <ul className="action-listing">
                                    {
                                        status && status === "R" ?
                                            <li onClick={() => props.onClubAction(row.id, 'A')}>
                                                <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip id={`Approve`}>
                                                                        Approve
                                                                    </Tooltip>}>
                                                    <Image className="mr-2" src={Images.Approve} alt="Activate" width="21" />
                                                </OverlayTrigger>
                                            </li>
                                            :
                                            status && status !== "A" ?
                                                <>
                                                    <li onClick={() => props.onClubAction(row.id, 'A')}>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip id={`Approve`}>
                                                                                Approve
                                                                            </Tooltip>}>
                                                            <Image className="mr-2" src={Images.Approve} alt="Activate" width="21" />
                                                        </OverlayTrigger>
                                                    </li>
                                                    <li onClick={() => props.onClubAction(row.id, 'R')}>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip id={`Reject`}>
                                                                                Reject
                                                                            </Tooltip>}>
                                                            <Image className="mr-2" src={Images.Reject} alt="Activate" width="21" />
                                                        </OverlayTrigger>
                                                    </li>
                                                </>
                                                :
                                                null
                                    }

                                    {
                                        row.status === 1 ?
                                            <>
                                                <li>
                                                {
                                                    status === "A" &&
                                                        <>
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip id={`Login as Club Sub-admin`}>
                                                                        Login as Club Sub-admin
                                                                    </Tooltip>}>
                                                                <Image className="mr-2" onClick={() => props.accessClubAsAdmin(row.id)} src={Images.arrowLogin} alt="Activate" width="21" />
                                                            </OverlayTrigger>
                                                        </>
                                                }
                                                </li>
                                                <li onClick={() => props.onClubAction(row.id, row.status)}>
                                                    <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip id={`Deactivate`}>
                                                                        Deactivate
                                                                    </Tooltip>}>
                                                    <Image className="mr-2" src={Images.DeleteIc} alt="Deactivate" width="21" />
                                                    </OverlayTrigger>
                                                </li>
                                            </>
                                            :
                                            status && status === "A" ?
                                                <li onClick={() => props.onClubAction(row.id, row.status)}>
                                                    <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`Activate`}>
                                                                            Activate
                                                                        </Tooltip>}>
                                                        <Image className="mr-2" src={Images.ActivateIcon} alt="Activate" width="21" />
                                                    </OverlayTrigger>
                                                </li>
                                                :
                                                null
                                    }
                                </ul>

                            </td>
                        </tr>
                    );
                })
            }
            {!props.recordLoaded &&
            <>
                <tr>
                    <td colSpan="6">
                        <SmallLoader />
                    </td>
                </tr>
            </>
            }
            {(props.recordLoaded && props.data.length === 0) &&
            <tr className="mb--norecord">
                <td colSpan="8">
                    <NoRecord />
                </td>
            </tr>
            }
            </tbody>
        </Table>
    )
};

export { ClubListing };
