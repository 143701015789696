export const ContactMessages = {
    DEACTIVATE_ACCOUNT: "Do you want to deactivate contact(s)?",
    ACTIVATE_ACCOUNT: "Do you want to activate contact(s)?",
    REMOVE_SUB_ADMIN: "Do you want to revoke this contact as Sub-Admin?",
    REMOVE_INSTRUCTOR_ROLE: "Do you want to revoke this contact as Instructor?",
    ADD_SUB_ADMIN: "Do you want to make this contact as Sub-Admin?",
    FILE_FORMAT_NOT_SUPPORTED: "File format uploaded is not supported. Please upload a valid csv file.",
    FILE_MULTIPLE_NOT_SUPPORTED: "Multiple file upload is not supported. Please upload a single csv file.",
    UNMAPPED_COLUMNS_ERROR: "{0} data column(s) still not mapped to the csv.",
    CONFLICTED_COLUMNS_ERROR: "'{0}' is mapped to multiple csv columns",
    INVALID_COLUMNS_EMAIL_ERROR: "'Guardian Email' is mapped to a csv column which doesn't contain valid email addresses.",
    INVALID_COLUMNS_INSTRUCTOR_EMAIL_ERROR: "'Instructor Email' is mapped to a csv column which doesn't contain valid email addresses."
}
