/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, {Component} from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import {withRouter} from 'react-router-dom';
/* End - React Router Dom */

import {Routes} from './routes';
import * as Storage from './components/common/storage';
import * as SessStorage from './components/common/sess-storage';
import { CometChat } from "@cometchat-pro/chat";
import {CometChatConversationListWithMessages} from "./CometChatWorkspace/src";
import { messaging } from './components/common/firebase';
import './App.css';

class App extends Component {
    constructor(props, permission) {
        super(props);

        const user = Storage.Get('user');
        let isAuthenticated = !!user,
            profilePic = user && user.photo ? user.photo : null,
            clubPicture = user && user.club_photo ? user.club_photo[user.selectedClubId] : null;

        //if cometchat token is not set logout users.
        if(isAuthenticated &&  !user.is_super_admin && !user.cometchat_token){
            this.handleLogout();
        }

        this.state = {
            isAuthenticated: isAuthenticated,
            hasCometChatToken: user && !!user.cometchat_token,
            isAuthenticating: true,
            profilePic: profilePic,
            clubPicture: clubPicture,
            ieFooterMsgClass: "fixed-bar-hide",
            showChat: false,
            chatWithGroupId:''
        };
        this.msieversion = this.msieversion.bind(this);

        this.chatRef = React.createRef();
        this.chatBtnRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener('storage', function (event) {
            if (event.key === 'logout-event') {
                Storage.Delete('logout-event');
                window.location.reload();
            }
        });
        this.msieversion();
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.chatRef && this.state.showChat && (!this.chatRef.current.contains(event.target) && !this.chatBtnRef.current.contains(event.target))) {
            this.setState({showChat: false});
        }
    }

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
    }
    msieversion = () => {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv[ :]*11\./))  // If Internet Explorer, return version number
        {
            this.setState({ieFooterMsgClass: " "});
        } else {
            // If another browser, return 0
        }
        return false;
    }

    handleLogout = async () => {
        const user = Storage.Get('user');
        Storage.Delete('user');
        if (SessStorage.Get('verifiedPassword')) {
            SessStorage.Delete('verifiedPassword');
        }
        // 4. Delete the FCM_TOKEN on logout.
        // Delete the FCM_TOKEN using the deleteToken() call on the messaging object.
        // Delete the FCM_TOKEN from localStorage to enable creation of new on relogin.
        if(!user.is_super_admin && messaging){
            await messaging.deleteToken();
            window.localStorage.removeItem('FCM_TOKEN');

            // Logout the user
            await CometChat.logout();
            console.log('5. Logged out');
        }

        let history = this.props.history;
        if (history.location && history.location.pathname && history.location.pathname === "/organization/review") {
            let location = {...history.location};
            location.pathname = "/login";
            history.replace({...history.location, ...location});
        }
        Storage.Set('logout-event', 'logout' + Math.random());
        // reload to unload the chat scripts, to avoid duplicacy under DOM
        window.location.reload();
        //document.getElementById('mck-sidebox-launcher').style.display = 'none';
    }

    ieBarClose = () => {
        this.setState({ieFooterMsgClass: "fixed-bar-hide"});
    }

    handleProfilePic = (picture) => {
        this.setState({
            profilePic: picture
        })
    }

    handleClubPic = (picture) => {
        this.setState({
            clubPicture: picture
        })
    }

    setChatWithGroupId = (groupId, showChat= true) => {
        this.setState({
            chatWithGroupId: groupId,
            showChat: showChat
        })
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            hasCometChatToken: this.state.hasCometChatToken,
            userHasAuthenticated: this.userHasAuthenticated
        };
        const user = Storage.Get('user');
        return (
            <div className="base">
                <Routes
                    handleClubPic={this.handleClubPic}
                    handleProfilePic={this.handleProfilePic}
                    childProps={childProps}
                    history={this.props.history}
                    clubPic={this.state.clubPicture}
                    headerPic={this.state.profilePic}
                    handleLogout={this.handleLogout}
                    ieMsgClass={this.state.ieFooterMsgClass}
                    ieBarClose={this.ieBarClose}
                    setChatWithGroupId={this.setChatWithGroupId}
                />
                {
                    this.state.showChat ?
                        <div className="chat-wrap" ref={this.chatRef}>
                            <CometChatConversationListWithMessages chatWithGroup={this.state.chatWithGroupId} />
                        </div>
                        : null
                }
                {
                    this.state.isAuthenticated && !user.is_super_admin ?
                        <div className="chat-launcher"  ref={this.chatBtnRef} onClick={() => this.setState({'showChat': !this.state.showChat, chatWithGroupId: ''})}>
                            <div className="icon-chat"></div>
                        </div>
                        : null
                }

            </div>
        );
    }
}

export default withRouter(App);
