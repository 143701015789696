/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import { Row, Col, Media, Image, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
/* End - React Bootstrap */

/**
 * Start - Formik
 * Name and Version: formik, 1.5.8
 * Download link: https://www.npmjs.com/package/formik
 */
import { Formik, Field, Form, ErrorMessage } from 'formik';
/* End - Formik */

/**
 * Start - Yup
 * Name and Version: yup, 0.27.0
 * Download link: https://www.npmjs.com/package/yup
 */

import * as Yup from 'yup';
/* End - Yup */


import * as Enum from '../../components/common/enum';
import * as Images from '../../components/common/images';
import './profile.scss';
import { SmallLoader } from '../../components/small-loader/sm-loader';
import {Messages} from "../../components/common/message";

//  * Get the Kid list for profile
class KidsList extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let props = this.props;
        let kids = '';
        let serverValid = this.props.serverValid;
        if (typeof props.data == "object" && Object.keys(props.data).length > 0) {
            kids = props.data.map((row, i) => {
                let kidData = row.data;
                let editKidStyle = row.editKidStyle ? { display: 'none' } : {};
                let updateKidStyle = row.updateKidStyle ? { display: 'none' } : {};
                var kidEditId = 'kidedit' + i;
                return (
                    <div className="col-sm-6 col-lg-4" key={kidEditId}>
                        <div className="slab-content__media " style={editKidStyle}>
                            <Media className="flex-wrap media--wrap">
                                <Image
                                    className="border-semirounded im--plft"
                                    src={kidData.photo}
                                    alt="student"
                                />
                                <Media.Body>
                                    <h5><span>{kidData.first_name ? kidData.first_name : null} {kidData.last_name ? kidData.last_name : null}</span>
                                        <div className="edit-icon"
                                            onClick={() => props.handleEditKid(row.index)} >
                                            <Image src={Images.EditBlue} alt="Edit" /></div>
                                    </h5>
                                    <p>{kidData.email}</p>
                                    <ul className="slab-content__listing">
                                        {
                                            kidData.team.length > 0 &&
                                            kidData.team.map((team, id) => {
                                                return (
                                                    <li key={'kidsOverlay'+id}>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id={`tooltip`}>
                                                                    {team.name}
                                                                </Tooltip>
                                                            } >
                                                            <Image src={team.photo} className="border-semirounded mr-2" alt="team" />
                                                        </OverlayTrigger>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Media.Body>
                                <div className="lg-wrap">
                                    <div className="lg-wrap__label">Legal Guardians</div>
                                    <div className="lg-wrap__im-wrap">
                                        {
                                            kidData.guardian.map((grow, j) => {
                                                let popoverId = 'propover' + j;
                                                let triggerId = 'trigger' + j;
                                                const popover = (
                                                    <Popover id="popover-basic" key={popoverId}>
                                                        <Popover.Title as="h3">{grow.first_name} {grow.last_name}</Popover.Title>
                                                        <Popover.Content>
                                                            {grow.invite_accepted ? <span className="badge-acpt">Approved</span> : <span className="badge-pndg">Pending</span>}
                                                        </Popover.Content>
                                                    </Popover>
                                                )
                                                return (
                                                    grow.invite_accepted ?
                                                        <OverlayTrigger trigger="hover" placement="bottom"
                                                            key={triggerId} className="lg-popover" overlay={popover} >
                                                            <div className="lg-wrap__im-hover im-wrap__invite-acpt lg-wrap__im-hover--accepted" onClick={() => props.handleShow(i)}>
                                                                <Image src={grow.photo} alt={grow.first_name} width="25" />
                                                            </div>
                                                        </OverlayTrigger>
                                                        :
                                                        <OverlayTrigger trigger="hover" placement="bottom"
                                                            key={triggerId} className="lg-popover" overlay={popover} >
                                                            <div className="lg-wrap__im-hover im-wrap__invite-pndg" onClick={() => props.handleShow(i)}>
                                                                <Image src={grow.photo} alt={grow.first_name} width="25" />
                                                            </div>
                                                        </OverlayTrigger>
                                                );
                                            })
                                        }
                                        {kidData.guardian.length >= Enum.MaxLgPerStudent ? null :
                                            <div className="add-lg" onClick={() => props.handleShow(i)}>
                                                <Image src={Images.AddLg} alt={Images.AddLg} className="mr-2" />
                                            </div>}
                                    </div>
                                </div>
                            </Media>
                        </div>
                        <div style={updateKidStyle}>
                            <div className="info-box__content mb-3">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        fName: kidData.first_name != null ? kidData.first_name : '',
                                        mName: kidData.middle_name != null ? kidData.middle_name : '',
                                        lName: kidData.last_name != null ? kidData.last_name : '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        fName: Yup.string().trim()
                                            .max(50, Messages.MAX_LENGTH_50)
                                            .required(Messages.REQUIRED_ERR),
                                        mName: Yup.string().trim()
                                            .max(50, Messages.MAX_LENGTH_50)
                                            .required(Messages.REQUIRED_ERR),
                                        lName: Yup.string().trim()
                                            .max(50, Messages.MAX_LENGTH_50)
                                            .required(Messages.REQUIRED_ERR),
                                    })}
                                    onSubmit={fields => {
                                        props.updateKidDetails(fields, i, kidData.id);
                                    }}
                                    render={({ errors, touched }) => (
                                        <Form noValidate>
                                            <Row>
                                                {props.kidPicLoader ? <SmallLoader /> :
                                                    <Col sm={12}>
                                                        <div className="prf-upload">
                                                            <div className="prf-upload__im">
                                                                <Image
                                                                    src={kidData.photo}
                                                                    name="photo" alt="detail" />
                                                            </div>
                                                            <div className="prf-upload__input">
                                                                <input type='file' accept="image/jpg,image/jpeg" onChange={(event, index, kidId) => props.kidPhotoChange(event, i, kidData.id)} />
                                                                <span className="prf-upload__link">Upload Photo</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                }
                                                <Col sm={12}>
                                                    <div className="field form-group">
                                                        <Field name="fName" type="text" id={"fName" + i} onFocus={props.inputFocus} onBlur={props.inputBlur} className={'form-control' + ((errors.fName && touched.fName)  || serverValid.first_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="fName" className="form-label label-active">First Name</label>
                                                        {
                                                            (errors.fName && touched.fName) ?
                                                                <ErrorMessage name="fName" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.first_name}</div>
                                                        }
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="mName" type="text" id={"mName" + i} onFocus={props.inputFocus} onBlur={props.inputBlur} className={'form-control' + ((errors.mName && touched.mName)  || serverValid.middle_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="mName" className="form-label label-active">Middle Name</label>
                                                        {
                                                            (errors.mName && touched.mName) ?
                                                                <ErrorMessage name="mName" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.middle_name}</div>
                                                        }
                                                    </div>
                                                    <div className="field form-group">
                                                        <Field name="lName" type="text" id={"lName" + i} onFocus={props.inputFocus} onBlur={props.inputBlur} className={'form-control' + ((errors.lName && touched.lName)  || serverValid.last_name !== '' ? ' is-invalid' : '')} />
                                                        <label htmlFor="lName" className="form-label label-active">Last Name</label>
                                                        {
                                                            (errors.lName && touched.lName) ?
                                                                <ErrorMessage name="lName" component="div" className="invalid-feedback" />
                                                                :
                                                                <div className="invalid-feedback">{serverValid.last_name}</div>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <div>
                                                        <Button type="Submit" disabled={props.kidDetailLoader} className={`btn ripple btn-primary btn-medium mb-2 ${props.kidDetailLoader ? 'btn-loader' : ''}`}>
                                                            <div className="btn__icon btn__icon--red"><Image src={Images.CompleteIc} alt="Complete" /></div>
                                                            Save <span style={{ 'display': 'none' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </Button>
                                                        <Button type="reset" disabled={props.kidDetailLoader} className="btn-cancel ripple mb-2" onClick={() => props.handleEditKid(i)}>Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return kids;
    }
}


export { KidsList };