/**
 * Start - React
 * Name and Version: react, 16.9.0
 * Download link: https://www.npmjs.com/package/react
 */
import React, { Component } from 'react';
/* End - React */

/**
 * Start - React Router Dom
 * Name and Version: react-router-dom, 5.0.1
 * Download link: https://www.npmjs.com/package/react-router-dom
 */
import { Link } from 'react-router-dom';
/* End - React Router Dom */

/**
 * Start - React Bootstrap
 * Name and Version: react-bootstrap, 1.0.0-beta.12
 * Download link: https://www.npmjs.com/package/react-bootstrap
 */
import {Row, Col, Container, Image } from 'react-bootstrap';
/* End - React Bootstrap */

import * as Helper from '../../components/common/functions';
import { Messages } from '../../components/common/message';
import * as Images from '../../components/common/images';
import Loader from '../../components/loader/loader';
import {HeaderFullWidth} from '../../components/header/headerfullwidth';
import {Footer} from '../../components/footer/footer';
import { RegisterError,  } from './invite-helper';
import * as Enum from '../../components/common/enum';
import { SuccessModal } from '../../components/modal/modalbox';
class VerifyInvite extends Component {
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            token: props.match.params.token,
            errorMsg: props.location.errorMsg ? props.location.errorMsg:Enum.inviteMsg,
            loading: false,
            resendMsg: '',
            modalShow: false,
        };
    }

     resendInvite = ()=>{
        const { token } = this.state;
        this.setState({ loading: true }
            , () => {
                Helper.axiosInstance().post(`register/resend-lg-verify`, {
                    invite_code: token
                })
                    .then((response) => {
                        const resp = response.data;
                        if(response.data.type=='invite_code'){
                            this.setState({ errorMsg: '',resendMsg:'', loading: false });
                        }else if (!resp.success && response.data.error !='') {
                           this.setState({ errorMsg: response.data.error, loading: false });
                        }else{
                            this.setState({  resendMsg: response.data.message, loading: false, modalShow:true });
                        }
                    })
                    .catch((error) => {
                        this.setState({ errorMsg: Messages.SERVER_ERROR });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        );
    }
    handleClose =  () =>{
        this.setState({ modalShow:false });
    }

    render() {
        const { errorMsg, loading, resendMsg, modalShow } = this.state;
        console.log(resendMsg)
        return (
            <>
                <HeaderFullWidth />
                <Container>
                    <Loader loading={loading} />
                    {
                        resendMsg &&
                        <SuccessModal
                            successConfirmShow={modalShow}
                            onConfirmClick={this.handleClose}
                            successText={'Account verification email resent successfully.'}
                            successButtonText={"OK"}


                        />
                    }
                     {


                         errorMsg && errorMsg !='' ?
                                <PendingLGVerification
                                    resendInvite={this.resendInvite}
                                    message={ errorMsg }
                                />:
                                 <RegisterError
                                    inviteError={'This link is not valid. Please contact the SafeComm support.'}
                                />

                    }
                    <Footer />
                </Container>
            </>
        )
    }
}

const PendingLGVerification = ({resendInvite, message}) => {
    return (
        <Row className="content-space">
            <Col sm={12 } md={8} lg={8} className="offset-md-2 offset-lg-2">
                <div className="widget py-5 px-3">
                    <div className="text-center success-msg success-msg--font-size">
                        <Image src={Images.SuccessTick} alt="Error" width="100" />

                        <p className="mb-4">{message}</p>
                    </div>
                    <div className="text-center">
                        {
                           <>
                               Can't find the account verification email? <Link to="#" className="link-btn mt-4" onClick={resendInvite}> Resend email now</Link>.
                           </>

                        }
                    </div>
                </div>
            </Col>
        </Row>
    )
};
export { VerifyInvite };

